import React from "react";
import { Redirect } from "react-router-dom";
import _ from "underscore";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Overview from "./WizardSteps/Step1.js";
import Amenities from "./WizardSteps/Step2.js";
import SellerInfo from "./WizardSteps/Step3.js";
import Photos from "./WizardSteps/Step4.js";

import PropertyWizard from "./Components/PropertyWizard.js";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

// AWS
import AppConstants from "variables/constants.js";
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from 'graphql/queries';

const AWS = require('aws-sdk');
const uuidv1 = require('uuid/v1');
const nonce = require('nonce')();
const moment = require("moment");

const styles = {
  ...buttonStyle
};

const useStyles = makeStyles(styles);

export default function WizardView(props) {
  const [finished, setFinished] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const [uploadMessage, setUploadMessage] = React.useState("");

  const { location, signedOut, user } = props;
  const editProperty = _.isEmpty(location.state) ? null : location.state.property;
  const classes = useStyles();
  //console.log("classes: %s", JSON.stringify(classes));

  // AWS S3
  const s3client = new AWS.S3({apiVersion: '2006-03-01', region: "us-east-1",
    accessKeyId: AppConstants["aws_s3_accessKey"], secretAccessKey: AppConstants["aws_s3_secretKey"]
  });

  function getOwner(phone) {
    return API.graphql(graphqlOperation(queries.listOwners, 
      { filter: { phone: { eq: `${phone}` }}, limit: 1000 }
    ));
  }

  function getOwnerById(phone) {
    return API.graphql(graphqlOperation(queries.getOwnerByPhone, { phone } ));
  }

  function propertyCode(title) {
    const str = _.isEmpty(title) ? "" : title.replace(/\s+/g, "")
    const titleIdx = _.isEmpty(str) ? 0 : Math.floor(Math.random() * Math.floor(str.length));
    const code = Math.floor(Math.random()*90000) + 10000;
    return `P${str.charAt(titleIdx)}${code}`;
  }

  /*
  https://bulooka-images.s3.amazonaws.com/properties/house-kitende-f3e32490-0a37-11ea-847b-b9c7f55abaf5-b.jpg
  */
  function createImageLinks(imageFile, isFeatured) {
    // Selected Image
		const lastDot = imageFile.name.lastIndexOf('.');
    const ext = imageFile.name.substring(lastDot + 1);
    const imgName = imageFile.name.substring(0, lastDot);
		const imageKey = createS3BucketKey(imgName, isFeatured, ext);
		const imageLink = `https://${AppConstants["aws_s3_bucket_images"]}.${AppConstants["aws_s3_endpoint"]}/${AppConstants["aws_s3_bucket_images_path"]}/${imageKey}`;
		const s3imageKey = `${AppConstants["aws_s3_bucket_original_images_path"]}/${imageKey}`;

		return { link: imageLink, s3key: s3imageKey };
  }

  function createS3BucketKey(name, isFeatured, ext) {
    let abbrv = isFeatured ? "s" : "b";
		return name.replace(/\s/ig, "-").toLowerCase() + `-${uuidv1()}-${abbrv}.${ext}`;
	}
  
  /*
    names have name-b.jpg and name-s.jpg for differentiation.
    featured images have -s.jpg other images 1-6 end  with -b.jpg
  */
  function savePropertyImage(imageKeys, imageFile, isFeatured = false) {
    //debugger;
    if (_.isEmpty(imageKeys)) { return Promise.resolve("No Property Image"); }
    //const { link, s3key } = createImageLinks(imageFile, isFeatured);
    const { link, s3key } = imageKeys
    //debugger;

		// ACL: 'public-read'
		return s3client.upload({
			Bucket: AppConstants["aws_s3_bucket_images"], 
			Key: s3key,
      ACL: 'public-read',
      CacheControl: 'public, max-age=604800, immutable',
			Body: imageFile,
		}).promise();		
  }
  
  // https://bulooka-images.s3.amazonaws.com/properties…26.47-e4bf1d30-3092-11ea-9344-dbb7bff22b01-s.jpeg
  function deletePropertyImage(imgLink) {
    const photoKey = getS3BucketKey(imgLink);
    //console.log("Delete photo key: %s in bucket: %s", photoKey, AppConstants["aws_s3_bucket_images"]);
		return s3client.deleteObject({Bucket: AppConstants["aws_s3_bucket_images"], Key: photoKey}).promise();
	}

	function getS3BucketKey(s3Link) {
		//const link = new URL(s3Link);
		const keyParts = s3Link.split(".com", 2);
		//console.log("Key parts: %s", keyParts.toString());
		return keyParts[1].substring(1);
  }
  
  function handleImages(images, deleteImages) {
    //debugger;
    const promises = [];
    images.forEach((image, idx) => {
      const { link, file, s3key } = image;
      if (file) {
        // New images to upload
        const savePromise = savePropertyImage({link, s3key}, file, idx === 0);
        promises.push(savePromise);
      }
    });

    deleteImages.forEach((image, idx) => {
      // images to delete
      const { imgLink } = image;
      const deletePromise = deletePropertyImage(imgLink);
      promises.push(deletePromise);
    });

    // Upload
    // Promise.all(promises).then(function(result) {
    //   console.log("Image upload result: " + JSON.stringify(result));
    // }).catch(function(error) {
    //   console.log("Image upload error: " + JSON.stringify(error));
    // })

    return promises;
  }

  /*
    Called when the user clicks the Finish button
    1- We do the s3 uploads here and update state with S3 Image Links
    2- we create/update the Property
    3- categoryId key - bulooka_category_realestate
  */
  function finishedWizard(states) {
    //console.log("All states: %j", states);
    if (_.isEmpty(states)) {
        // Back to Main
        setFinished(true);
        return;
    }
    
    const allstates = Object.assign({}, states.amenities, states.overview, states.photos, states.seller);

    // Uploading
    setUploading(true);

    /*
    1- Check is customer already exists based on phone number entered
    2- If customer exists use their ID else create a customer
    */
    const { phone, images, deleteImages, propertyName, selectedPropertyType, 
      selectedPurpose, ownerType  } = allstates;
      //console.log("All states: %j", allstates);

    // Images
    const selectedImages = images.map((image, idx) => {
      //  new image: { dataLink: reader.result, file: file, imgLink: null }
      // existing image: { dataLink: null, file: null, imgLink: image }
      const { imgLink, file } = image;
      if (_.isEmpty(imgLink)) {
        const { link, s3key } = createImageLinks(file, idx === 0);
        return { link, s3key, file };
      } else {
        return {link: imgLink, s3key: null, file: null};
      }
    });


    const selectImages = _.pluck(selectedImages, 'link').map(img => img.replace(`${AppConstants["aws_s3_bucket_images"]}.${AppConstants["aws_s3_endpoint"]}`, `${AppConstants["aws_cdn_images"]}`) );

    getOwnerById(phone).then(function( { data }) {
        //console.log("Phone: %s has owner: %j", phone, data);
        // Create promises
        const imagePromises = handleImages(selectedImages, deleteImages);
        //handleImages(images, deleteImages);

        let ownerId = null;
        if (data && !_.isEmpty(data.getOwnerByPhone) && !_.isEmpty(data.getOwnerByPhone.items)) {
          const [owner] = data.getOwnerByPhone.items;
          ownerId = owner.id;
        }

        // IDs
        const mode = { newOwner: _.isEmpty(ownerId), newProperty: _.isEmpty(editProperty) };
        const now = moment().utc().format();
        const propertyId = _.isEmpty(editProperty) ? `gid://Bulooka/Property/${nonce()}` : editProperty.id;
        ownerId = _.isEmpty(ownerId) ? `gid://Bulooka/Owner/${nonce()}` : ownerId;
        const categoryId = AppConstants["bulooka_category_realestate"];

        const propertyMutation = 
            createPropertyMutation(allstates, propertyId, ownerId, 
              AppConstants["bulooka_category_realestate"], mode, selectImages );
        const propertyPromise = API.graphql(graphqlOperation(propertyMutation, {
          ownerType: _.isEmpty(ownerType) ? "Individual" : ownerType,
          propertyType: selectedPropertyType, propertyPurpose: selectedPurpose, propertyId,
          ownerId, createdAt: now, updatedAt: now, categoryId, featuredImage: selectImages[0],
          images: selectImages
        }));

        Promise.all([ propertyPromise, ...imagePromises]).then(function(result) {
          setUploading(false);
          setUploadMessage("Success creating/updating property: " + propertyName);
          //console.log("Result: " + JSON.stringify(result));
        }).catch(function(err) {
          setUploading(false);
          setUploadMessage("Error: " + JSON.stringify(err));
        });

    }).catch(function(error) {
        setUploading(false);
        setUploadMessage("Error: " + JSON.stringify(error));
    });

  }

  /*
  Step 1:
  this.state = {
      propertyName: "",
      propertyNameState: "",
      propertyDescription: "",
      propertyDescriptionState: "",
      price: "",
      priceState: "",
      currencyCode: "",
      size: "",
      sizeState: "",
      sizeUnits: "",
      selectedPropertyType: null,
      selectedPropertyTypeError: false,
      selectedBedrooms: null,
      selectedBathrooms: null,
      selectedPurpose: null,
      selectedPurposeError: false,
      checkIsFeatured: false,
      checkIsOffPlan: false,
      checkIsForeclosed: false,
      checkIsDeal: false
    };

    Step 2:
    this.state = {
      district: "",
      districtState: false,
      streetName: "",
      streetNameState: "",
      streetNumber: "",
      streetNumberState: "",
      city: "",
      cityState: "",
      firstname: "",
      firstnameState: "",
      organizationname: "",
      organizationnameState: "",
      ownerType: "",
      lastname: "",
      lastnameState: "",
      email: "",
      emailState: "",
      phone: "",
      phoneState: "",
      website: "",
      websiteState: "",
      sellerAddress: "",
      sellerAddressState: "",
      sellerCity: "",
      sellerCityState: "",
      sellerDistrict: "",
      sellerDistrictState: "",
      latitude: "",
      latitudeState: "",
      longtitude: "",
      longtitudeState: "",
      profilePhoto: null,
      searching: false
    };

    Step 3:
    this.state = {
      loading: true,
      amenities: []
    };

    Step 4:
    this.state = {
      images: [], 
      deleteImages: [] // Only has S3 URLs e.g https//s3....
    };
  */
  function createPropertyMutation(states, propertyId, ownerId, categoryId, mode, selectImages) {
    const { propertyName, city, selectedPropertyType, selectedPurpose, currencyCode = "UGX",
      price, propertyDescription, images, streetNumber, streetName, district,
      latitude, longitude, selectedBedrooms, selectedBathrooms, size, sizeUnits,
      firstname, lastname, organizationname, email, phone, sellerAddress, sellerCity,
      sellerDistrict, website, ownerType, amenities, profilePhoto, checkIsFeatured, checkIsFeaturedBottom,
      checkIsOffPlan, checkIsForeclosed, checkIsDeal, checkIsEstate, country,
      checkIsHostel, checkIsHotelRoom, hotelStars, hotelId  } = states;
    
    // Mutation
    let mutation = "";
    
    // Property, Owner
    const now = moment().utc().format();
    const { newOwner, newProperty } = mode;

    const propertyInput = {
      id: propertyId,
      name: propertyName,
      slug: `${propertyName} ${streetName} ${city}`.replace(/\s+/ig, "-").toLowerCase(),
      //propertyType: selectedPropertyType,
      //propertyPurpose: selectedPurpose,
      currencyCode : _.isEmpty(currencyCode) ? "UGX" : currencyCode,
      description: propertyDescription,
      createdAt: now,
      updatedAt: now,
      address: `${streetNumber} ${streetName}`,
      city: _.isEmpty(city) ? null : city,
      district: _.isEmpty(district) ? null : district,
      country: _.isEmpty(country) ? "Uganda" : country,
      latitude: _.isEmpty(latitude) ? null : latitude,
      longitude: _.isEmpty(longitude) ? null : longitude,
      bedrooms: _.isEmpty(selectedBedrooms) ? 0 : parseInt(selectedBedrooms, 10),
      bathrooms: _.isEmpty(selectedBathrooms) ? 0 : parseInt(selectedBathrooms, 10),
      status: "active",
      size: _.isEmpty(size) ? null : size,
      intSize: _.isEmpty(size) ? 0 : parseInt(size, 10),
      sizeUnits: _.isEmpty(sizeUnits) ? null : sizeUnits,
      propertyCategoryId: categoryId,
      propertyOwnerId: ownerId,
      hotelStars: _.isEmpty(hotelStars) ? 0 : parseInt(hotelStars, 10),
      hotelId: _.isEmpty(hotelId) ? null : hotelId,
    };

    // Search Field
    const search = `${propertyName} ${propertyDescription} ${propertyInput.address} ${city} ${district}`.toLowerCase();
    Object.assign(propertyInput, { search });

    // Price
    const salePrice = selectedPurpose === "Sale" ? price : "0";
    const rentPrice = selectedPurpose === "Rent" ? price : "0";
    if (selectedPurpose === "Sale") {
      Object.assign(propertyInput, { salePrice });
    }
    if (selectedPurpose === "Rent") {
      Object.assign(propertyInput, { rentPrice });
    }

    // Images
    // const selectedImages = images.map((image, idx) => {
    //     const { imgLink, file } = image;
    //     if (_.isEmpty(imgLink)) {
    //       const { link } = createImageLinks(file, idx === 0);
    //       return link;
    //     } else {
    //       return imgLink;
    //     }
    // });
    Object.assign(propertyInput, { featuredImage: selectImages[0], images: selectImages });

    
    // Property Mutation
    if (newProperty) {
      // Create Property Mode
      const shortId = propertyCode(propertyInput.name);
      mutation += ` a1: createProperty(input: {
        id: $propertyId, name: "${propertyInput.name}", slug: "${propertyInput.slug}", shortID: "${shortId}",
        propertyType: $propertyType, propertyPurpose: $propertyPurpose,
        salePrice: "${salePrice}", rentPrice: "${rentPrice}", intSalePrice: ${parseInt(salePrice, 10)}, intRentPrice: ${parseInt(rentPrice, 10)},
        currencyCode: "${propertyInput.currencyCode}", description: "${propertyInput.description}",
        createdAt: $createdAt, updatedAt: $updatedAt, address: "${propertyInput.address}", 
        city: "${propertyInput.city}", district: "${propertyInput.district}", country: "${propertyInput.country}",
        latitude: "${propertyInput.latitude}", longitude: "${propertyInput.longitude}",
        bedrooms: ${propertyInput.bedrooms}, bathrooms: ${propertyInput.bathrooms},
        status: "${propertyInput.status}", size: "${propertyInput.size}", sizeUnits: "${propertyInput.sizeUnits}", intSize: ${propertyInput.intSize},
        featuredImage: $featuredImage, images: $images, search: "${search}",
        isFeatured: ${checkIsFeatured}, isOffPlan: ${checkIsOffPlan}, isDeal: ${checkIsDeal},
        isForeclosed: ${checkIsForeclosed}, isFeaturedBottom: ${checkIsFeaturedBottom}, isEstate: ${checkIsEstate},
        propertyCategoryId: $categoryId, propertyOwnerId: $ownerId, isHotelRoom: ${checkIsHotelRoom}, isHostel: ${checkIsHostel},
        hotelStars: ${propertyInput.hotelStars}, hotelId: "${propertyInput.hotelId}"
      }) {
        id
      },`;
    } else {
      // Edit mode
      mutation += ` a1: updateProperty(input: {
        id: $propertyId, name: "${propertyInput.name}", slug: "${propertyInput.slug}",
        propertyType: $propertyType, propertyPurpose: $propertyPurpose, 
        salePrice: "${salePrice}", rentPrice: "${rentPrice}", intSalePrice: ${parseInt(salePrice, 10)}, intRentPrice: ${parseInt(rentPrice, 10)},
        currencyCode: "${propertyInput.currencyCode}", description: "${propertyInput.description}",
        updatedAt: $updatedAt, address: "${propertyInput.address}", 
        city: "${propertyInput.city}", district: "${propertyInput.district}", country: "${propertyInput.country}",
        latitude: "${propertyInput.latitude}", longitude: "${propertyInput.longitude}",
        bedrooms: ${propertyInput.bedrooms}, bathrooms: ${propertyInput.bathrooms},
        status: "${propertyInput.status}", size: "${propertyInput.size}", sizeUnits: "${propertyInput.sizeUnits}", intSize: ${propertyInput.intSize},
        featuredImage: $featuredImage, images: $images, search: "${search}",
        isFeatured: ${checkIsFeatured}, isOffPlan: ${checkIsOffPlan}, isDeal: ${checkIsDeal},
        isForeclosed: ${checkIsForeclosed}, isFeaturedBottom: ${checkIsFeaturedBottom}, isEstate: ${checkIsEstate},
        propertyCategoryId: $categoryId, propertyOwnerId: $ownerId, isHotelRoom: ${checkIsHotelRoom}, isHostel: ${checkIsHostel},
        hotelStars: ${propertyInput.hotelStars}, hotelId: "${propertyInput.hotelId}"
      }) {
        id
      },`;
    }


    // Owner Input
    const ownerInput = {
      id: ownerId,
      firstName: _.isEmpty(firstname) ? null : firstname,
      lastName: _.isEmpty(lastname) ? null : lastname,
      name: _.isEmpty(organizationname) ? `${firstname} ${lastname}` : organizationname,
      displayName: _.isEmpty(organizationname) ? `${firstname} ${lastname}` : organizationname,
      email: _.isEmpty(email) ? null : email,
      phone: _.isEmpty(phone) ? null : phone,
      profilePhoto: _.isEmpty(profilePhoto) ? null : profilePhoto,
      acceptsCookies: true,
      createdAt: now,
      updatedAt: now,
      address: _.isEmpty(sellerAddress) ? null : sellerAddress,
      city: _.isEmpty(sellerCity) ? null : sellerCity,
      province: _.isEmpty(sellerDistrict) ? null : sellerDistrict,
      country: "Uganda",
      website: _.isEmpty(website) ? null : website,
      //ownerType: _.isEmpty(ownerType) ? "Individual" : ownerType,
      fullyPaid: false
    };

    // Owner Mutation
    if (newOwner) {
      // Create owner mode
      mutation += ` o1: createOwner(input: {
        id: $ownerId, firstName: "${ownerInput.firstName}", lastName: "${ownerInput.lastName}",
        name: "${ownerInput.name}", displayName: "${ownerInput.displayName}", email: "${ownerInput.email}",
        phone: "${ownerInput.phone}", profilePhoto: "${ownerInput.profilePhoto}", 
        acceptsCookies: true, createdAt: $createdAt, updatedAt: $updatedAt, address: "${ownerInput.address}",
        city: "${ownerInput.city}", province: "${ownerInput.province}", country: "${ownerInput.country}",
        website: "${ownerInput.website}", ownerType: $ownerType, fullyPaid: false }) {
        id
      },`;
    } else {
      // Edit owner mode
      mutation += ` o1: updateOwner(input: {
        id: $ownerId, firstName: "${ownerInput.firstName}", lastName: "${ownerInput.lastName}",
        name: "${ownerInput.name}", displayName: "${ownerInput.displayName}", email: "${ownerInput.email}",
        phone: "${ownerInput.phone}", profilePhoto: "${ownerInput.profilePhoto}", 
        acceptsCookies: true, updatedAt: $updatedAt, address: "${ownerInput.address}",
        city: "${ownerInput.city}", province: "${ownerInput.province}", country: "${ownerInput.country}",
        website: "${ownerInput.website}", ownerType: $ownerType }) {
        id
      },`;
    }

    // Property Amenities
    const selectedAmenities = amenities.filter((amen) => states[amen.state] === true );
    let createdAmenities = 0;
    for (let i=0; i<selectedAmenities.length; i++) {
      const { amenityId, propertyAmenityId } = selectedAmenities[i];
      if (_.isEmpty(propertyAmenityId)) {
        // Create Property Amenity
        createdAmenities++;
        const apropertyAmenityId = `gid://Bulooka/PropertyAmenity/${nonce()}`;
        
        mutation += ` c${i+1}: createPropertyAmenity(input: {
          id: "${apropertyAmenityId}", createdAt: $createdAt, propertyAmenityPropertyId: $propertyId,
          propertyAmenityAmenityId: "${amenityId}"
        }) {
          id
        },`;
      } else {
        // Update Property Amenity - Do nothing since amenity and property do not change
      }
    }

    // Deleted Amenities
    const deletedAmenities = amenities.filter((amen) => (states[amen.state] === false && !_.isEmpty(amen.propertyAmenityId)) );
    for (let i=0; i<deletedAmenities.length; i++) {
      const { propertyAmenityId } = deletedAmenities[i];
      
      mutation += ` d${i+1}: deletePropertyAmenity(input: { id: "${propertyAmenityId}" }) {
        id
      },`;
    }
    // console.log("amenities: " + JSON.stringify(amenities));
    // console.log("states: " + JSON.stringify(states));
    // console.log("mutation: " + mutation);
    const created = newOwner || newProperty || createdAmenities > 0 ? "$createdAt: String!," : "";

    return `mutation createPropertyOwner($propertyId: ID!, $ownerId: ID!, $propertyType: PropertyType!, 
      $propertyPurpose: PropertyPurpose!, $ownerType: OwnerType!, $categoryId: ID!,
      ${created} $updatedAt: String!, $featuredImage: String!, $images: [String!]) { ${mutation} }`;

  }

  /*
    Show alert and on success redirect back to Main.
  */

  function getAlert() {
    //console.log("Upload message: " + uploadMessage);
    return (
      <SweetAlert
        show={!_.isEmpty(uploadMessage)}
        type={uploadMessage.startsWith("Error") ? "error" : "success"}
        showCancel={false}
        style={{ display: "block", marginTop: "-100px" }}
        title={uploadMessage.startsWith("Error") ? "Error" : "Success"}
        onConfirm={() => {
          if (uploadMessage.startsWith("Error")) {
            setUploadMessage("");
          } else {
            setUploadMessage("");
            setFinished(true);
          }
        }}
        onCancel={() => setUploadMessage("")}
        confirmBtnCssClass={
          classes.button + " " + classes.success
        }
      >
        {uploadMessage}
      </SweetAlert>
    );
  }

  // Sign Out
  if (signedOut) return (<Redirect to="/" /> );

  return (
    <React.Fragment>
        {getAlert()}
        { finished ? <Redirect to={"/admin/properties"} /> :
        <GridContainer justify="center">
            <GridItem xs={12} sm={12}>
              <PropertyWizard
                validate
                steps={[
                  { stepName: "Overview", stepComponent: Overview, stepId: "overview" },
                  { stepName: "Seller Info", stepComponent: SellerInfo, stepId: "seller" },
                  { stepName: "Amenities", stepComponent: Amenities, stepId: "amenities" },
                  { stepName: "Photos", stepComponent: Photos, stepId: "photos" },
                ]}
                title={_.isEmpty(editProperty) ? "Create a Property" : `Edit Property - ID: ${editProperty.id.substring(editProperty.id.lastIndexOf("/") + 1)}, ShortID: ${editProperty.shortID} ` }
                color={"info"}
                subtitle=""
                property={editProperty}
                loading={uploading}
                finishButtonClick={finishedWizard}
              />
          </GridItem>
        </GridContainer>
        }
        { uploading && <CircularProgress /> }
    </React.Fragment>
  
  );
}
