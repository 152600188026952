/*eslint-disable*/
import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import Timeline from "@material-ui/icons/Timeline";

// core components
import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

//AWS
import { API, graphqlOperation } from 'aws-amplify';
import {
  roundedLineChart,
  straightLinesChart,
  simpleBarChart,
  colouredLineChart,
  multipleBarsChart,
  colouredLinesChart,
  pieChart
} from "variables/charts.js";

import chartstyles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  ...chartstyles,
  cardTitle,
  pageTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
    width: '100%'
  },
  errorTitle: {
    color: 'red',
    fontWeight: 'bold'
  }
};

// utils
const _ = require("underscore");
const moment = require("moment");

const useStyles = makeStyles(styles);

const GETPROPERTY = `query GetProperty($id: ID!, $pastDate: String!) {
  getProperty(id: $id) {
    id
    propertyType
    propertyPurpose
    city
    district
    views
    propertyViewsByDate(month: {ge: $pastDate }, limit: 100) {
      items {
        id
        ownerID
        propertyID
        month
        dayViews
        views
        duration
      }
      nextToken
    }
  }
}
`;

/*
  // Line
  data: {
    labels: [
      "7",
      "6",
      "5",
      "4",
      "3",
      "2",
      "1",
    ],
    series: [[287, 480, 290, 554, 690, 690, 500]]
  },

  // Bar
  data: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mai",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ],
    series: [[542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]]
  },
*/

export default function ReportCharts(props) {
  const { property } = props;
  const [propertyViews, setPropertyViews] = React.useState([]);
  const [propSpinner, setPropSpinner] = React.useState(true);
  const [ propError, setPropError] = React.useState("");
  const now = moment();
  const classes = useStyles();

  React.useEffect(() => {
    if (_.isEmpty(property)) {
      setPropSpinner(false);
      return;
    }

    API.graphql(graphqlOperation(GETPROPERTY, { id: property.id, pastDate: moment().utc().subtract(1, 'year').format("YYYY-MM") }))
    .then(({ data }) => {
      if (data && data.getProperty) {
        if (!_.isEmpty(data.getProperty.propertyViewsByDate.items)) {
          setPropertyViews(data.getProperty.propertyViewsByDate.items);
        }
      }
      setPropSpinner(false);
    })
    .catch((err) => {
      setPropSpinner(false);
      setPropError("Failed to retrieve property data. Please try again later.")
    });
  }, [property]); 
  
  function getChartData() {

    const sevenSeries = [0, 0, 0, 0, 0, 0, 0];
    const sevenData = {
      labels: [ "7", "6", "5", "4", "3", "2", "1"],
      series: []
    };

    const thirtySeries = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const thirtyData = {
      labels: [ "30", "28", "26", "24", "22", "20", "18", "16", "14", "12", "10", "8", "6", "4", "2", "1" ],
      series: []
    };

    const yearSeries = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    const yearData = {
      labels: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      series: []
    };

    const yearNow = moment(`${now.year()}`, "YYYY");

    propertyViews.forEach((propView) => {
        const { month, dayViews, views } = propView;
        let momMonth = moment(month, "YYYY-MM");

        if (momMonth.isSame(yearNow.month(0), 'month')) yearSeries[0] = views;
        if (momMonth.isSame(yearNow.month(1), 'month')) yearSeries[1] = views;
        if (momMonth.isSame(yearNow.month(2), 'month')) yearSeries[2] = views;
        if (momMonth.isSame(yearNow.month(3), 'month')) yearSeries[3] = views;
        if (momMonth.isSame(yearNow.month(4), 'month')) yearSeries[4] = views;
        if (momMonth.isSame(yearNow.month(5), 'month')) yearSeries[5] = views;
        if (momMonth.isSame(yearNow.month(6), 'month')) yearSeries[6] = views;
        if (momMonth.isSame(yearNow.month(7), 'month')) yearSeries[7] = views;
        if (momMonth.isSame(yearNow.month(8), 'month')) yearSeries[8] = views;
        if (momMonth.isSame(yearNow.month(9), 'month')) yearSeries[9] = views;
        if (momMonth.isSame(yearNow.month(10), 'month')) yearSeries[10] = views;
        if (momMonth.isSame(yearNow.month(11), 'month')) yearSeries[11] = views;


        if (!_.isEmpty(dayViews)) {
          const dayjson = JSON.parse(dayViews);
          dayjson.forEach((item) => {
            const { day, views } = item;
            momMonth.date(day);

            if (momMonth.isSame(moment().subtract(30, 'days'), 'day')) thirtySeries[0] = views;
            if (momMonth.isSame(moment().subtract(28, 'days'), 'day')) thirtySeries[1] = views;
            if (momMonth.isSame(moment().subtract(26, 'days'), 'day')) thirtySeries[2] = views;
            if (momMonth.isSame(moment().subtract(24, 'days'), 'day')) thirtySeries[3] = views;
            if (momMonth.isSame(moment().subtract(22, 'days'), 'day')) thirtySeries[4] = views;
            if (momMonth.isSame(moment().subtract(20, 'days'), 'day')) thirtySeries[5] = views;
            if (momMonth.isSame(moment().subtract(18, 'days'), 'day')) thirtySeries[6] = views;
            if (momMonth.isSame(moment().subtract(16, 'days'), 'day')) thirtySeries[7] = views;
            if (momMonth.isSame(moment().subtract(14, 'days'), 'day')) thirtySeries[8] = views;
            if (momMonth.isSame(moment().subtract(12, 'days'), 'day')) thirtySeries[9] = views;
            if (momMonth.isSame(moment().subtract(10, 'days'), 'day')) thirtySeries[10] = views;
            if (momMonth.isSame(moment().subtract(8, 'days'), 'day')) thirtySeries[11] = views;

            if (momMonth.isSame(moment().subtract(7, 'days'), 'day')) sevenSeries[0] = views;
            if (momMonth.isSame(moment().subtract(6, 'days'), 'day')) {
              sevenSeries[1] = views;
              thirtySeries[12] = views;
            }
            if (momMonth.isSame(moment().subtract(5, 'days'), 'day')) sevenSeries[2] = views;
            if (momMonth.isSame(moment().subtract(4, 'days'), 'day')) {
              sevenSeries[3] = views;
              thirtySeries[13] = views;
            }
            if (momMonth.isSame(moment().subtract(3, 'days'), 'day')) {
              sevenSeries[4] = views;
            }
            if (momMonth.isSame(moment().subtract(2, 'days'), 'day')) {
              sevenSeries[5] = views;
              thirtySeries[14] = views;
            }
            if (momMonth.isSame(moment().subtract(1, 'days'), 'day')) {
              sevenSeries[6] = views;
              thirtySeries[15] = views;
            }

          });
        }
    });

    sevenData.series.push(sevenSeries);
    thirtyData.series.push(thirtySeries);
    yearData.series.push(yearSeries);

    return { sevenData, thirtyData, yearData };
  }

  const { sevenData, thirtyData, yearData } = getChartData();

  // Max
  let sevenMaxValue = _.max(sevenData.series[0]);
  const sevenOptions = Object.assign({}, straightLinesChart.options, { high:  Math.max(Math.round(sevenMaxValue * 1.5), 1), height: "300px"});
  let thirtyMaxValue = _.max(thirtyData.series[0]);
  const thirtyOptions = Object.assign({}, straightLinesChart.options, { high:  Math.max(Math.round(thirtyMaxValue * 1.25), 1), height: "300px"});

  return (
    <div>
      <Heading
        textAlign="center"
        title={property ? property.name : ""}
      />
      <h4 className={`${classes.pageTitle} ${classes.errorTitle}`}>{propError}</h4>
        {propSpinner && <CircularProgress />}
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Views - Last 7 Days
              </h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                data={sevenData}
                type="Line"
                options={sevenOptions}
                listener={straightLinesChart.animation}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Views - Last 30 Days
              </h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                data={thirtyData}
                type="Line"
                options={thirtyOptions}
                listener={straightLinesChart.animation}
              />
            </CardBody>
          </Card>
        </GridItem>
        
      </GridContainer>
      <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <Timeline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                {`Views - Year ${now.year()}`}
              </h4>
            </CardHeader>
            <CardBody>
              <ChartistGraph
                data={yearData}
                type="Bar"
                options={multipleBarsChart.options}
                listener={multipleBarsChart.animation}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
