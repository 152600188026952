/*
var firstRegion = locations[0].region, firstDistrict = locations[0].district;
locations = locations.map((item) => { const { region, district, town } = item; if (region.length > 0) { firstRegion = region; }; if (district.length > 0) { firstDistrict = district; }; return { region: firstRegion, district: firstDistrict, town  } });

*/
const _ = require("underscore");

const groupByDistricts = (locations) => {
    let aRegion = LOCATIONS[0].region, aDistrict = LOCATIONS[0].district;
    const filtered = locations.map(({ region, district, town }) => {
        if (region.length > 0) { aRegion = region; }
        if (district.length > 0) { aDistrict = district; }
        return { region: aRegion, district: aDistrict, town  };
    }).filter(item => !_.isEmpty(item.district) && !_.isEmpty(item.town));

    return _.groupBy(_.sortBy(filtered, 'district'), 'district');
};

const LOCATIONS = [
  {
    "region": "Central Region",
    "district": "Buikwe",
    "town": "Buikwe"
  },
  {
    "region": "Central Region",
    "district": "Buikwe",
    "town": "Kawolo"
  },
  {
    "region": "Central Region",
    "district": "Buikwe",
    "town": "Lugazi Tc"
  },
  {
    "region": "Central Region",
    "district": "Buikwe",
    "town": "Najja"
  },
  {
    "region": "Central Region",
    "district": "Buikwe",
    "town": "Najjembe"
  },
  {
    "region": "Central Region",
    "district": "Buikwe",
    "town": "Ngogwe"
  },
  {
    "region": "Central Region",
    "district": "Buikwe",
    "town": "Njeru Tc"
  },
  {
    "region": "Central Region",
    "district": "Buikwe",
    "town": "Nkokonjeru Tc"
  },
  {
    "region": "Central Region",
    "district": "Buikwe",
    "town": "Nyenga"
  },
  {
    "region": "Central Region",
    "district": "Buikwe",
    "town": "Ssi-bukunja"
  },
  {
    "region": "Central Region",
    "district": "Buikwe",
    "town": "Wakisi"
  },
  {
    "region": "Central Region",
    "district": "Bukomansimbi",
    "town": "Bigasa"
  },
  {
    "region": "Central Region",
    "district": "Bukomansimbi",
    "town": "Butenga"
  },
  {
    "region": "Central Region",
    "district": "Bukomansimbi",
    "town": "Kibinge"
  },
  {
    "region": "Central Region",
    "district": "Bukomansimbi",
    "town": "Kitanda"
  },
  {
    "region": "Central Region",
    "district": "Butambala",
    "town": "Budde"
  },
  {
    "region": "Central Region",
    "district": "Butambala",
    "town": "Bulo"
  },
  {
    "region": "Central Region",
    "district": "Butambala",
    "town": "Kalamba"
  },
  {
    "region": "Central Region",
    "district": "Butambala",
    "town": "Kibibi"
  },
  {
    "region": "Central Region",
    "district": "Butambala",
    "town": "Ngando"
  },
  {
    "region": "Central Region",
    "district": "Buvuma",
    "town": "Bugaya"
  },
  {
    "region": "Central Region",
    "district": "Buvuma",
    "town": "Busamuzi"
  },
  {
    "region": "Central Region",
    "district": "Buvuma",
    "town": "Bweema"
  },
  {
    "region": "Central Region",
    "district": "Buvuma",
    "town": "Nairambi"
  },
  {
    "region": "Central Region",
    "district": "Gomba",
    "town": "Kabulasoke"
  },
  {
    "region": "Central Region",
    "district": "Gomba",
    "town": "Kyegonza"
  },
  {
    "region": "Central Region",
    "district": "Gomba",
    "town": "Maddu"
  },
  {
    "region": "Central Region",
    "district": "Gomba",
    "town": "Mpenja"
  },
  {
    "region": "Central Region",
    "district": "Kalangala",
    "town": "Bubeke"
  },
  {
    "region": "Central Region",
    "district": "Kalangala",
    "town": "Bufumira"
  },
  {
    "region": "Central Region",
    "district": "Kalangala",
    "town": "Bujjumba"
  },
  {
    "region": "Central Region",
    "district": "Kalangala",
    "town": "Kalangala Tc"
  },
  {
    "region": "Central Region",
    "district": "Kalangala",
    "town": "Kyamuswa"
  },
  {
    "region": "Central Region",
    "district": "Kalangala",
    "town": "Mazinga"
  },
  {
    "region": "Central Region",
    "district": "Kalangala",
    "town": "Mugoye"
  },
  {
    "region": "Central Region",
    "district": "Kalungu",
    "town": "Bukulula"
  },
  {
    "region": "Central Region",
    "district": "Kalungu",
    "town": "Kalungu"
  },
  {
    "region": "Central Region",
    "district": "Kalungu",
    "town": "Kyamuliibwa"
  },
  {
    "region": "Central Region",
    "district": "Kalungu",
    "town": "Lukaya Tc"
  },
  {
    "region": "Central Region",
    "district": "Kalungu",
    "town": "Lwabenge"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Bakuli"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Banda"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Bugolobi"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Bukasa"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Bukoto I"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Bukoto II"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Bunga"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Busega"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Butabika"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Buziga"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Bwaise"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Central Division"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Ggaba"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "I.t.e.k"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Industrial Area"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kabalagala"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kabowa"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kabuusu"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Munyonyo"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kamwokya"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kansanga"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kanyaya"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kasubi"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kataza"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Katwe"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kawaala"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kawempe I"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kawempe II"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kazo Ward"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kibuli"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kibuye I"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kibuye II"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kikaya"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kikoni"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kimathi Avenue"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kirudu"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kisasi"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kisenyi"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kisugu"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kiswa"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kitebi"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kiwatule"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kololo"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Komamboga"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kyambogo"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kyanja"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kyanja"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Kyebando"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Lubia"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Lubiri"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Lukuli"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Lungujja"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Luwafu"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Luzira"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Luzira Prisons"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Makerere I"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Makerere II"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Makerere III"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Makindye I"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Makindye II"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Masaanafu"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Mbuya"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Mengo"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Mpererwe"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Mulago"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Mutundwe"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Mutungo"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Muyenga"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Nabisunsa"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Nabulagala"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Naguru"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Najjanankumbi"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Nakasero"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Nakawa"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Nakawa Institutions"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Nakulabye"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Nalukolongo"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Namirembe"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Namungoona"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Namwongo"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Nateete"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Ndeeba"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Nsambya Central"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Nsambya Housing Estate"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Nsambya Police Barracks"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Nsambya Railway"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Ntinda"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Nyanama"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Old Kiira Road"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Pilkington Road"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Rubaga"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Salaama"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "U.p.k"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "University (makerere)"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Upper Estate"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Wabigalo"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Wakaliga"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Wandegeya"
  },
  {
    "region": "Central Region",
    "district": "Kampala",
    "town": "Yusuf Lule Road"
  },
  {
    "region": "Central Region",
    "district": "Kayunga",
    "town": "Bbaale"
  },
  {
    "region": "Central Region",
    "district": "Kayunga",
    "town": "Busana"
  },
  {
    "region": "Central Region",
    "district": "Kayunga",
    "town": "Galiraaya"
  },
  {
    "region": "Central Region",
    "district": "Kayunga",
    "town": "Kangulumira"
  },
  {
    "region": "Central Region",
    "district": "Kayunga",
    "town": "Kayonza"
  },
  {
    "region": "Central Region",
    "district": "Kayunga",
    "town": "Kayunga"
  },
  {
    "region": "Central Region",
    "district": "Kayunga",
    "town": "Kayunga Tc"
  },
  {
    "region": "Central Region",
    "district": "Kayunga",
    "town": "Nazigo"
  },
  {
    "region": "Central Region",
    "district": "Kiboga",
    "town": "Bukomero"
  },
  {
    "region": "Central Region",
    "district": "Kiboga",
    "town": "Dwaniro"
  },
  {
    "region": "Central Region",
    "district": "Kiboga",
    "town": "Kapeke"
  },
  {
    "region": "Central Region",
    "district": "Kiboga",
    "town": "Kibiga"
  },
  {
    "region": "Central Region",
    "district": "Kiboga",
    "town": "Kiboga Tc"
  },
  {
    "region": "Central Region",
    "district": "Kiboga",
    "town": "Lwamata"
  },
  {
    "region": "Central Region",
    "district": "Kiboga",
    "town": "Muwanga"
  },
  {
    "region": "Central Region",
    "district": "Kyankwanzi",
    "town": "Butemba"
  },
  {
    "region": "Central Region",
    "district": "Kyankwanzi",
    "town": "Gayaza"
  },
  {
    "region": "Central Region",
    "district": "Kyankwanzi",
    "town": "Kyankwanzi Town"
  },
  {
    "region": "Central Region",
    "district": "Kyankwanzi",
    "town": "Mulagi"
  },
  {
    "region": "Central Region",
    "district": "Kyankwanzi",
    "town": "Nsambya"
  },
  {
    "region": "Central Region",
    "district": "Kyankwanzi",
    "town": "Ntwetwe"
  },
  {
    "region": "Central Region",
    "district": "Kyankwanzi",
    "town": "Wattuba"
  },
  {
    "region": "Central Region",
    "district": "Luweero",
    "town": "Bamunanika"
  },
  {
    "region": "Central Region",
    "district": "Luweero",
    "town": "Bombo Tc"
  },
  {
    "region": "Central Region",
    "district": "Luweero",
    "town": "Butuntumula"
  },
  {
    "region": "Central Region",
    "district": "Luweero",
    "town": "Kalagala"
  },
  {
    "region": "Central Region",
    "district": "Luweero",
    "town": "Kamira"
  },
  {
    "region": "Central Region",
    "district": "Luweero",
    "town": "Katikamu"
  },
  {
    "region": "Central Region",
    "district": "Luweero",
    "town": "Kikyusa"
  },
  {
    "region": "Central Region",
    "district": "Luweero",
    "town": "Luwero Tc"
  },
  {
    "region": "Central Region",
    "district": "Luweero",
    "town": "Luwero Town"
  },
  {
    "region": "Central Region",
    "district": "Luweero",
    "town": "Makulubita"
  },
  {
    "region": "Central Region",
    "district": "Luweero",
    "town": "Nyimbwa"
  },
  {
    "region": "Central Region",
    "district": "Luweero",
    "town": "Wobulenzi Tc"
  },
  {
    "region": "Central Region",
    "district": "Luweero",
    "town": "Zirobwe"
  },
  {
    "region": "Central Region",
    "district": "Lwengo",
    "town": "Kisekka"
  },
  {
    "region": "Central Region",
    "district": "Lwengo",
    "town": "Kyazanga"
  },
  {
    "region": "Central Region",
    "district": "Lwengo",
    "town": "Lwengo"
  },
  {
    "region": "Central Region",
    "district": "Lwengo",
    "town": "Malongo"
  },
  {
    "region": "Central Region",
    "district": "Lwengo",
    "town": "Ndagwe"
  },
  {
    "region": "Central Region",
    "district": "Lyantonde",
    "town": "Kaliiro"
  },
  {
    "region": "Central Region",
    "district": "Lyantonde",
    "town": "Kasagama"
  },
  {
    "region": "Central Region",
    "district": "Lyantonde",
    "town": "Kinuuka"
  },
  {
    "region": "Central Region",
    "district": "Lyantonde",
    "town": "Lyantonde"
  },
  {
    "region": "Central Region",
    "district": "Lyantonde",
    "town": "Lyantonde Tc"
  },
  {
    "region": "Central Region",
    "district": "Lyantonde",
    "town": "Mpumudde"
  },
  {
    "region": "Central Region",
    "district": "Masaka",
    "town": "Bukakata"
  },
  {
    "region": "Central Region",
    "district": "Masaka",
    "town": "Buwunga"
  },
  {
    "region": "Central Region",
    "district": "Masaka",
    "town": "Kabonera"
  },
  {
    "region": "Central Region",
    "district": "Masaka",
    "town": "Katwe/butego"
  },
  {
    "region": "Central Region",
    "district": "Masaka",
    "town": "Kimanya/kyabakuza"
  },
  {
    "region": "Central Region",
    "district": "Masaka",
    "town": "Kkingo"
  },
  {
    "region": "Central Region",
    "district": "Masaka",
    "town": "Kyanamukaaka"
  },
  {
    "region": "Central Region",
    "district": "Masaka",
    "town": "Masaka Town"
  },
  {
    "region": "Central Region",
    "district": "Masaka",
    "town": "Mukungwe"
  },
  {
    "region": "Central Region",
    "district": "Masaka",
    "town": "Nyendo/senyange"
  },
  {
    "region": "Central Region",
    "district": "Mityana",
    "town": "Bulera"
  },
  {
    "region": "Central Region",
    "district": "Mityana",
    "town": "Busimbi"
  },
  {
    "region": "Central Region",
    "district": "Mityana",
    "town": "Butayunja"
  },
  {
    "region": "Central Region",
    "district": "Mityana",
    "town": "Kakindu"
  },
  {
    "region": "Central Region",
    "district": "Mityana",
    "town": "Kikandwa"
  },
  {
    "region": "Central Region",
    "district": "Mityana",
    "town": "Maanyi"
  },
  {
    "region": "Central Region",
    "district": "Mityana",
    "town": "Malangala"
  },
  {
    "region": "Central Region",
    "district": "Mityana",
    "town": "Mityana Tc"
  },
  {
    "region": "Central Region",
    "district": "Mityana",
    "town": "Ssekanyonyi"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Bbongole"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Bukasa"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Bukemba"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Bukunge"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Bulerejje"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Bulunda"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Bumoozi"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Bunjako"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Buseese"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Butoolo"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Buwama"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Buyijja"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Gangu Fr"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Ggolo"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Jeza"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Jjalamba"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Kafumu"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Kakoola"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Kamengo"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Kammengo"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Kantini"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Kanyike"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Kasozi"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Kavule"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Kawumba"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Kayabwe"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Kikondo"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Kiringente"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Kisitu Fr"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Kituntu"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Kololo"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Konkoma"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Kyali"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Kyanja"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Lubugumu"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Lugyo"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Luvumbula"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Luwala"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Luwunga"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Lwagwa"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Lwanga"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Malima"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Maziba"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Mbazzi"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Mbizzinya"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Migamba"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Mpanga Fr"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Mpigi"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Mpigi Tc"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Muduma"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Mugge"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Musa"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Musaale-magala"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Muyira"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Nabitete"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Nabusanke"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Nakibanga"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Nkasi"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Nkozi"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Nnindye"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Sango"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Sekiwunga"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Tiribogo"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Ward A"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Ward B"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Ward C"
  },
  {
    "region": "Central Region",
    "district": "Mpigi",
    "town": "Ward D"
  },
  {
    "region": "Central Region",
    "district": "Mubende",
    "town": "Bagezza"
  },
  {
    "region": "Central Region",
    "district": "Mubende",
    "town": "Bukuya"
  },
  {
    "region": "Central Region",
    "district": "Mubende",
    "town": "Butoloogo"
  },
  {
    "region": "Central Region",
    "district": "Mubende",
    "town": "Kasambya"
  },
  {
    "region": "Central Region",
    "district": "Mubende",
    "town": "Kassanda"
  },
  {
    "region": "Central Region",
    "district": "Mubende",
    "town": "Kiganda"
  },
  {
    "region": "Central Region",
    "district": "Mubende",
    "town": "Kitenga"
  },
  {
    "region": "Central Region",
    "district": "Mubende",
    "town": "Kiyuni"
  },
  {
    "region": "Central Region",
    "district": "Mubende",
    "town": "Madudu"
  },
  {
    "region": "Central Region",
    "district": "Mubende",
    "town": "Mubende Tc"
  },
  {
    "region": "Central Region",
    "district": "Mubende",
    "town": "Myanzi"
  },
  {
    "region": "Central Region",
    "district": "Mukono",
    "town": "Bamusuuta"
  },
  {
    "region": "Central Region",
    "district": "Mukono",
    "town": "Bugombe"
  },
  {
    "region": "Central Region",
    "district": "Mukono",
    "town": "Bugoye"
  },
  {
    "region": "Central Region",
    "district": "Mukono",
    "town": "Bukasa"
  },
  {
    "region": "Central Region",
    "district": "Mukono",
    "town": "Bukerere"
  },
  {
    "region": "Central Region",
    "district": "Mukono",
    "town": "Bulijjo"
  },
  {
    "region": "Central Region",
    "district": "Mukono",
    "town": "Bulika"
  },
  {
    "region": "Central Region",
    "district": "Mukono",
    "town": "Bunakijja"
  },
  {
    "region": "Central Region",
    "district": "Mukono",
    "town": "Busanga"
  },
  {
    "region": "Central Region",
    "district": "Mukono",
    "town": "Ddundu"
  },
  {
    "region": "Central Region",
    "district": "Mukono",
    "town": "Ggulu Ward"
  },
  {
    "region": "Central Region",
    "district": "Mukono",
    "town": "Goma"
  },
  {
    "region": "Central Region",
    "district": "Mukono",
    "town": "Ham Mukasa"
  },
  {
    "region": "Central Region",
    "district": "Mukono",
    "town": "Kabanga"
  },
  {
    "region": "Central Region",
    "district": "Mukono",
    "town": "Kabembe"
  },
  {
    "region": "Central Region",
    "district": "Mukono",
    "town": "Kabimbiri"
  },
  {
    "region": "Central Region",
    "district": "Mukono",
    "town": "Kakukuulu"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kapeeka"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kasana"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kasangombe"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kasawo"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kasenge"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kateete"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Katente"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Katoogo"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kauga"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kawongo"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kayini"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kigogola"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kikamulo"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kimenyedde"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kitale"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kitovu"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kituula"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kiwafu"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kiwanga TC"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kiyoola"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kkome Islands"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kyabakadde"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kyabalogo"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kyabazaala"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kyajja"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kyampisi"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Kyetume"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Lwomolo"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Makukuba"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Mbalala"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Misindye"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Mpatta"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Mpoma"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Mpunge"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Mubembe"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Mukono Hill"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Mukono Tc"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Nabaale"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Nabalanga"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Nabuti"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Naggalama"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Nagojje"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Nagojje"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Nakanyonyi"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Nakaseke"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Nakibano"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Nakisunga"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Nama"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Namagunga"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Namaiba"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Namaliga"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Namaliiri"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Namanoga"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Namataba"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Namawojjolo"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Namayuba"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Namubiru"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Namuganga"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Namumira-anthony"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Namuyenje"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Nanga"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Nantabulirwa"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Ngoma"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Nsanja"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Nsuube-kauga"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Ntaawo Ward"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Ntanzi"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Ntenjeru"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Ntonto"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Ntunda"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Ntunda"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Nyenje"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Seeta"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Seeta-namuganga"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Seeta-nazigo"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Semuto"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Ssaayi"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Terere"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Upper Kauga"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Wagala"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Wakyato"
  },
  {
    "region": "Central Region",
    "district": "Nakaseke",
    "town": "Wankoba"
  },
  {
    "region": "Central Region",
    "district": "Nakasongola",
    "town": "Kakooge"
  },
  {
    "region": "Central Region",
    "district": "Nakasongola",
    "town": "Kalongo"
  },
  {
    "region": "Central Region",
    "district": "Nakasongola",
    "town": "Kalungi"
  },
  {
    "region": "Central Region",
    "district": "Nakasongola",
    "town": "Lwabyata"
  },
  {
    "region": "Central Region",
    "district": "Nakasongola",
    "town": "Lwampanga"
  },
  {
    "region": "Central Region",
    "district": "Nakasongola",
    "town": "Nabiswera"
  },
  {
    "region": "Central Region",
    "district": "Nakasongola",
    "town": "Nakasongola Tc"
  },
  {
    "region": "Central Region",
    "district": "Nakasongola",
    "town": "Nakitoma"
  },
  {
    "region": "Central Region",
    "district": "Nakasongola",
    "town": "Wabinyonyi"
  },
  {
    "region": "Central Region",
    "district": "Rakai",
    "town": "Byakabanda"
  },
  {
    "region": "Central Region",
    "district": "Rakai",
    "town": "Ddwaniro"
  },
  {
    "region": "Central Region",
    "district": "Rakai",
    "town": "Kabira"
  },
  {
    "region": "Central Region",
    "district": "Rakai",
    "town": "Kacheera"
  },
  {
    "region": "Central Region",
    "district": "Rakai",
    "town": "Kagamba (buyamba)"
  },
  {
    "region": "Central Region",
    "district": "Rakai",
    "town": "Kakuuto"
  },
  {
    "region": "Central Region",
    "district": "Rakai",
    "town": "Kalisizo"
  },
  {
    "region": "Central Region",
    "district": "Rakai",
    "town": "Kasaali"
  },
  {
    "region": "Central Region",
    "district": "Rakai",
    "town": "Kasasa"
  },
  {
    "region": "Central Region",
    "district": "Rakai",
    "town": "Kibanda"
  },
  {
    "region": "Central Region",
    "district": "Rakai",
    "town": "Kifamba"
  },
  {
    "region": "Central Region",
    "district": "Rakai",
    "town": "Kirumba"
  },
  {
    "region": "Central Region",
    "district": "Rakai",
    "town": "Kyalulangira"
  },
  {
    "region": "Central Region",
    "district": "Rakai",
    "town": "Kyebe"
  },
  {
    "region": "Central Region",
    "district": "Rakai",
    "town": "Kyotera Tc"
  },
  {
    "region": "Central Region",
    "district": "Rakai",
    "town": "Lwamaggwa"
  },
  {
    "region": "Central Region",
    "district": "Rakai",
    "town": "Lwanda"
  },
  {
    "region": "Central Region",
    "district": "Rakai",
    "town": "Lwankoni"
  },
  {
    "region": "Central Region",
    "district": "Rakai",
    "town": "Nabigasa"
  },
  {
    "region": "Central Region",
    "district": "Rakai",
    "town": "Rakai Tc"
  },
  {
    "region": "Central Region",
    "district": "Sembabule",
    "town": "Lugusulu"
  },
  {
    "region": "Central Region",
    "district": "Sembabule",
    "town": "Lwebitakuli"
  },
  {
    "region": "Central Region",
    "district": "Sembabule",
    "town": "Lwemiyaga"
  },
  {
    "region": "Central Region",
    "district": "Sembabule",
    "town": "Mateete"
  },
  {
    "region": "Central Region",
    "district": "Sembabule",
    "town": "Mijwala"
  },
  {
    "region": "Central Region",
    "district": "Sembabule",
    "town": "Ntusi"
  },
  {
    "region": "Central Region",
    "district": "Sembabule",
    "town": "Sembabule Tc"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Bakka"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Banda"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Bbale-mukwenda"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Bembe"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Buddo"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Bugonga"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Bukasa"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Bukondo"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Bulamu"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Bulindo"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Buloba"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Bulumbu"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Bulwanyi"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Bunamwaya"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Busabala"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Bussi"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Busukuma"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Buwambo"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Buwanuka"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Buwate"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Bweya"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Bweyogerere"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Central Ward"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Division A"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Division B"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Entebbe"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Gayaza"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Gguluddene"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Gombe"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Jjungo"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kabale"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kabale"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kabubbu"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kabumba"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kakiri"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kaliiti"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kamuli"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kanziro"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kanzize"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kasanje"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kasenge"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kasengejje"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kasokoso"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Katabi"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Katabi Ward"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Katadde"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Katereke"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Katikamu"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kavule"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kawanda"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kayunga/gombe Ward"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kazo Nabweru"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kigungu Ward"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kikajo"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kikandwa"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kikoko"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kimwanyi"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kira"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kireka"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kirinya"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kiryamuli"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kisimbiri Ward"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kisozi/kitemu"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kisubi"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kitala"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kitayita"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kiteezi"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kitende"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kitoro"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kiwafu Ward"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kiwenda"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kungu"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kyaliwajjala"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kyampisi"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kyanuuna"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kyasa"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kyebando"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kyengera"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Kyengeza"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Lubbe"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Lubowa"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Lugo"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Lugonjo"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Lugungudde"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Luguzi"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Lukwanga"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Lutisi"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Luwunga"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Lwemwendde"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Maganjo"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Magigye"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Magoggo"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Mako"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Manze"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Masajja"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Masooli"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Masulita"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Matuga"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Maya"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Mende"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Migadde"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Mpunga Ward"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Mutundwe"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Mutungo"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Mwererwe"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Naalya"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Nabbingo"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Nabweru"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Najeera I"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Najeera II"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Nakabugo"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Nakawuka"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Nakedde"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Nakikungube"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Nakyelongoosa"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Nakyesanja"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Nalugala"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Naluvule"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Namayumba"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Nampunge"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Namulanda"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Namusera"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Namusera Ward"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Nangabo"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Nankonge"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Nansana"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Nanziga"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Nasse"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Ndejje"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Ngongolo"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Nkumba"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Nkungulutale"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Nsaggu"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Nsamizi"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Nsangi"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Seguku"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Sokolo"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Ssala/kkona Ward"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Ssanga"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Ssazi"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Ssentema"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Ssisa"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Ssumbwe"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Ttikalu"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Ttumbaali"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Wamala"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Wamala"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Wambale"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Wamirongo"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Wampeewo"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Wattuba"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Zziba"
  },
  {
    "region": "Central Region",
    "district": "Wakiso",
    "town": "Zzinga"
  },
  {
    "region": "Eastern Region",
    "district": "Amuria",
    "town": "Abarilela"
  },
  {
    "region": "Eastern Region",
    "district": "Amuria",
    "town": "Acowa"
  },
  {
    "region": "Eastern Region",
    "district": "Amuria",
    "town": "Asamuk"
  },
  {
    "region": "Eastern Region",
    "district": "Amuria",
    "town": "Kapelebyong"
  },
  {
    "region": "Eastern Region",
    "district": "Amuria",
    "town": "Kuju"
  },
  {
    "region": "Eastern Region",
    "district": "Amuria",
    "town": "Morungatuny"
  },
  {
    "region": "Eastern Region",
    "district": "Amuria",
    "town": "Obalanga"
  },
  {
    "region": "Eastern Region",
    "district": "Amuria",
    "town": "Orungo"
  },
  {
    "region": "Eastern Region",
    "district": "Amuria",
    "town": "Wera"
  },
  {
    "region": "Eastern Region",
    "district": "Budaka",
    "town": "Budaka"
  },
  {
    "region": "Eastern Region",
    "district": "Budaka",
    "town": "Iki-iki"
  },
  {
    "region": "Eastern Region",
    "district": "Budaka",
    "town": "Kaderuna"
  },
  {
    "region": "Eastern Region",
    "district": "Budaka",
    "town": "Kameruka"
  },
  {
    "region": "Eastern Region",
    "district": "Budaka",
    "town": "Kamonkoli"
  },
  {
    "region": "Eastern Region",
    "district": "Budaka",
    "town": "Lyama"
  },
  {
    "region": "Eastern Region",
    "district": "Budaka",
    "town": "Naboa"
  },
  {
    "region": "Eastern Region",
    "district": "Bududa",
    "town": "Bubiita"
  },
  {
    "region": "Eastern Region",
    "district": "Bududa",
    "town": "Bududa"
  },
  {
    "region": "Eastern Region",
    "district": "Bududa",
    "town": "Bukibokolo"
  },
  {
    "region": "Eastern Region",
    "district": "Bududa",
    "town": "Bukigai"
  },
  {
    "region": "Eastern Region",
    "district": "Bududa",
    "town": "Bulucheke"
  },
  {
    "region": "Eastern Region",
    "district": "Bududa",
    "town": "Bumayoka"
  },
  {
    "region": "Eastern Region",
    "district": "Bududa",
    "town": "Bushika"
  },
  {
    "region": "Eastern Region",
    "district": "Bugiri",
    "town": "Budhaya"
  },
  {
    "region": "Eastern Region",
    "district": "Bugiri",
    "town": "Bugiri Tc"
  },
  {
    "region": "Eastern Region",
    "district": "Bugiri",
    "town": "Bulesa"
  },
  {
    "region": "Eastern Region",
    "district": "Bugiri",
    "town": "Bulidha"
  },
  {
    "region": "Eastern Region",
    "district": "Bugiri",
    "town": "Buluguyi"
  },
  {
    "region": "Eastern Region",
    "district": "Bugiri",
    "town": "Buwunga"
  },
  {
    "region": "Eastern Region",
    "district": "Bugiri",
    "town": "Iwemba"
  },
  {
    "region": "Eastern Region",
    "district": "Bugiri",
    "town": "Kapyanga"
  },
  {
    "region": "Eastern Region",
    "district": "Bugiri",
    "town": "Muterere"
  },
  {
    "region": "Eastern Region",
    "district": "Bugiri",
    "town": "Nabukalu"
  },
  {
    "region": "Eastern Region",
    "district": "Bugiri",
    "town": "Nankoma"
  },
  {
    "region": "Eastern Region",
    "district": "Bukedea",
    "town": "Bukedea"
  },
  {
    "region": "Eastern Region",
    "district": "Bukedea",
    "town": "Kachumbala"
  },
  {
    "region": "Eastern Region",
    "district": "Bukedea",
    "town": "Kidongole"
  },
  {
    "region": "Eastern Region",
    "district": "Bukedea",
    "town": "Kolir"
  },
  {
    "region": "Eastern Region",
    "district": "Bukedea",
    "town": "Malera"
  },
  {
    "region": "Eastern Region",
    "district": "Bukwo",
    "town": "Bukwa"
  },
  {
    "region": "Eastern Region",
    "district": "Bukwo",
    "town": "Chesower"
  },
  {
    "region": "Eastern Region",
    "district": "Bukwo",
    "town": "Kabei"
  },
  {
    "region": "Eastern Region",
    "district": "Bukwo",
    "town": "Suam"
  },
  {
    "region": "Eastern Region",
    "district": "Bulambuli",
    "town": "Buginyanya"
  },
  {
    "region": "Eastern Region",
    "district": "Bulambuli",
    "town": "Bukhalu"
  },
  {
    "region": "Eastern Region",
    "district": "Bulambuli",
    "town": "Bulago"
  },
  {
    "region": "Eastern Region",
    "district": "Bulambuli",
    "town": "Bulegeni"
  },
  {
    "region": "Eastern Region",
    "district": "Bulambuli",
    "town": "Buluganya"
  },
  {
    "region": "Eastern Region",
    "district": "Bulambuli",
    "town": "Bunambutye"
  },
  {
    "region": "Eastern Region",
    "district": "Bulambuli",
    "town": "Masiira"
  },
  {
    "region": "Eastern Region",
    "district": "Bulambuli",
    "town": "Muyembe"
  },
  {
    "region": "Eastern Region",
    "district": "Bulambuli",
    "town": "Sisiyi"
  },
  {
    "region": "Eastern Region",
    "district": "Busia",
    "town": "Buhehe"
  },
  {
    "region": "Eastern Region",
    "district": "Busia",
    "town": "Bulumbi"
  },
  {
    "region": "Eastern Region",
    "district": "Busia",
    "town": "Busia Tc"
  },
  {
    "region": "Eastern Region",
    "district": "Busia",
    "town": "Busitema"
  },
  {
    "region": "Eastern Region",
    "district": "Busia",
    "town": "Buteba"
  },
  {
    "region": "Eastern Region",
    "district": "Busia",
    "town": "Dabani"
  },
  {
    "region": "Eastern Region",
    "district": "Busia",
    "town": "Lumino"
  },
  {
    "region": "Eastern Region",
    "district": "Busia",
    "town": "Lunyo"
  },
  {
    "region": "Eastern Region",
    "district": "Busia",
    "town": "Masaba"
  },
  {
    "region": "Eastern Region",
    "district": "Busia",
    "town": "Masafu"
  },
  {
    "region": "Eastern Region",
    "district": "Butaleja",
    "town": "Budumba"
  },
  {
    "region": "Eastern Region",
    "district": "Butaleja",
    "town": "Busaba"
  },
  {
    "region": "Eastern Region",
    "district": "Butaleja",
    "town": "Busolwe"
  },
  {
    "region": "Eastern Region",
    "district": "Butaleja",
    "town": "Butaleja"
  },
  {
    "region": "Eastern Region",
    "district": "Butaleja",
    "town": "Kachonga"
  },
  {
    "region": "Eastern Region",
    "district": "Butaleja",
    "town": "Nawanjofu"
  },
  {
    "region": "Eastern Region",
    "district": "Butaleja",
    "town": "Nazimasa"
  },
  {
    "region": "Eastern Region",
    "district": "Buyende",
    "town": "Bugaya"
  },
  {
    "region": "Eastern Region",
    "district": "Buyende",
    "town": "Buyende"
  },
  {
    "region": "Eastern Region",
    "district": "Buyende",
    "town": "Kagulu"
  },
  {
    "region": "Eastern Region",
    "district": "Buyende",
    "town": "Kidera"
  },
  {
    "region": "Eastern Region",
    "district": "Buyende",
    "town": "Nkondo"
  },
  {
    "region": "Eastern Region",
    "district": "Iganga",
    "town": "Bulamagi"
  },
  {
    "region": "Eastern Region",
    "district": "Iganga",
    "town": "Buyanga"
  },
  {
    "region": "Eastern Region",
    "district": "Iganga",
    "town": "Ibulanku"
  },
  {
    "region": "Eastern Region",
    "district": "Iganga",
    "town": "Iganga Tc"
  },
  {
    "region": "Eastern Region",
    "district": "Iganga",
    "town": "Makuutu"
  },
  {
    "region": "Eastern Region",
    "district": "Iganga",
    "town": "Nabitende"
  },
  {
    "region": "Eastern Region",
    "district": "Iganga",
    "town": "Nakalama"
  },
  {
    "region": "Eastern Region",
    "district": "Iganga",
    "town": "Nakigo"
  },
  {
    "region": "Eastern Region",
    "district": "Iganga",
    "town": "Namalemba"
  },
  {
    "region": "Eastern Region",
    "district": "Iganga",
    "town": "Nambale"
  },
  {
    "region": "Eastern Region",
    "district": "Iganga",
    "town": "Namungalwe"
  },
  {
    "region": "Eastern Region",
    "district": "Iganga",
    "town": "Nawandala"
  },
  {
    "region": "Eastern Region",
    "district": "Jinja",
    "town": "Budondo"
  },
  {
    "region": "Eastern Region",
    "district": "Jinja",
    "town": "Busedde"
  },
  {
    "region": "Eastern Region",
    "district": "Jinja",
    "town": "Butagaya"
  },
  {
    "region": "Eastern Region",
    "district": "Jinja",
    "town": "Buwenge"
  },
  {
    "region": "Eastern Region",
    "district": "Jinja",
    "town": "Buwenge Tc"
  },
  {
    "region": "Eastern Region",
    "district": "Jinja",
    "town": "Buyengo"
  },
  {
    "region": "Eastern Region",
    "district": "Jinja",
    "town": "Central Division"
  },
  {
    "region": "Eastern Region",
    "district": "Jinja",
    "town": "Kakira"
  },
  {
    "region": "Eastern Region",
    "district": "Jinja",
    "town": "Mafubira"
  },
  {
    "region": "Eastern Region",
    "district": "Jinja",
    "town": "Masese/walukuba"
  },
  {
    "region": "Eastern Region",
    "district": "Jinja",
    "town": "Mpumudde/kimaka"
  },
  {
    "region": "Eastern Region",
    "district": "Kaberamaido",
    "town": "Alwa"
  },
  {
    "region": "Eastern Region",
    "district": "Kaberamaido",
    "town": "Anyara"
  },
  {
    "region": "Eastern Region",
    "district": "Kaberamaido",
    "town": "Bululu"
  },
  {
    "region": "Eastern Region",
    "district": "Kaberamaido",
    "town": "Kaberamaido"
  },
  {
    "region": "Eastern Region",
    "district": "Kaberamaido",
    "town": "Kaberamaido Tc"
  },
  {
    "region": "Eastern Region",
    "district": "Kaberamaido",
    "town": "Kalaki"
  },
  {
    "region": "Eastern Region",
    "district": "Kaberamaido",
    "town": "Kobulubulu"
  },
  {
    "region": "Eastern Region",
    "district": "Kaberamaido",
    "town": "Ochero"
  },
  {
    "region": "Eastern Region",
    "district": "Kaberamaido",
    "town": "Otuboi"
  },
  {
    "region": "Eastern Region",
    "district": "Kaliro",
    "town": "Bumanya"
  },
  {
    "region": "Eastern Region",
    "district": "Kaliro",
    "town": "Gadumire"
  },
  {
    "region": "Eastern Region",
    "district": "Kaliro",
    "town": "Namugongo"
  },
  {
    "region": "Eastern Region",
    "district": "Kaliro",
    "town": "Namwiwa"
  },
  {
    "region": "Eastern Region",
    "district": "Kaliro",
    "town": "Nawaikoke"
  },
  {
    "region": "Eastern Region",
    "district": "Kamuli",
    "town": "Balawoli"
  },
  {
    "region": "Eastern Region",
    "district": "Kamuli",
    "town": "Bugulumbya"
  },
  {
    "region": "Eastern Region",
    "district": "Kamuli",
    "town": "Bulopa"
  },
  {
    "region": "Eastern Region",
    "district": "Kamuli",
    "town": "Butansi"
  },
  {
    "region": "Eastern Region",
    "district": "Kamuli",
    "town": "Kamuli Tc"
  },
  {
    "region": "Eastern Region",
    "district": "Kamuli",
    "town": "Kisozi"
  },
  {
    "region": "Eastern Region",
    "district": "Kamuli",
    "town": "Kitayunjwa"
  },
  {
    "region": "Eastern Region",
    "district": "Kamuli",
    "town": "Mbulamuti"
  },
  {
    "region": "Eastern Region",
    "district": "Kamuli",
    "town": "Nabwigulu"
  },
  {
    "region": "Eastern Region",
    "district": "Kamuli",
    "town": "Namasagali"
  },
  {
    "region": "Eastern Region",
    "district": "Kamuli",
    "town": "Namwendwa"
  },
  {
    "region": "Eastern Region",
    "district": "Kamuli",
    "town": "Nawanyago"
  },
  {
    "region": "Eastern Region",
    "district": "Kamuli",
    "town": "Wankole"
  },
  {
    "region": "Eastern Region",
    "district": "Kapchorwa",
    "town": "Chema"
  },
  {
    "region": "Eastern Region",
    "district": "Kapchorwa",
    "town": "Kapchorwa Tc"
  },
  {
    "region": "Eastern Region",
    "district": "Kapchorwa",
    "town": "Kaptanya"
  },
  {
    "region": "Eastern Region",
    "district": "Kapchorwa",
    "town": "Kaserem"
  },
  {
    "region": "Eastern Region",
    "district": "Kapchorwa",
    "town": "Kawowo"
  },
  {
    "region": "Eastern Region",
    "district": "Kapchorwa",
    "town": "Sipi"
  },
  {
    "region": "Eastern Region",
    "district": "Kapchorwa",
    "town": "Tegeres"
  },
  {
    "region": "Eastern Region",
    "district": "Katakwi",
    "town": "Kapujan"
  },
  {
    "region": "Eastern Region",
    "district": "Katakwi",
    "town": "Katakwi"
  },
  {
    "region": "Eastern Region",
    "district": "Katakwi",
    "town": "Katakwi Tc"
  },
  {
    "region": "Eastern Region",
    "district": "Katakwi",
    "town": "Magoro"
  },
  {
    "region": "Eastern Region",
    "district": "Katakwi",
    "town": "Ngariam"
  },
  {
    "region": "Eastern Region",
    "district": "Katakwi",
    "town": "Omodoi"
  },
  {
    "region": "Eastern Region",
    "district": "Katakwi",
    "town": "Ongongoja"
  },
  {
    "region": "Eastern Region",
    "district": "Katakwi",
    "town": "Toroma"
  },
  {
    "region": "Eastern Region",
    "district": "Katakwi",
    "town": "Usuk"
  },
  {
    "region": "Eastern Region",
    "district": "Kibuku",
    "town": "Bulangira"
  },
  {
    "region": "Eastern Region",
    "district": "Kibuku",
    "town": "Buseta"
  },
  {
    "region": "Eastern Region",
    "district": "Kibuku",
    "town": "Kadama"
  },
  {
    "region": "Eastern Region",
    "district": "Kibuku",
    "town": "Kagumu"
  },
  {
    "region": "Eastern Region",
    "district": "Kibuku",
    "town": "Kibuku"
  },
  {
    "region": "Eastern Region",
    "district": "Kibuku",
    "town": "Kirika"
  },
  {
    "region": "Eastern Region",
    "district": "Kibuku",
    "town": "Tirinyi"
  },
  {
    "region": "Eastern Region",
    "district": "Kumi",
    "town": "Atutur"
  },
  {
    "region": "Eastern Region",
    "district": "Kumi",
    "town": "Kanyum"
  },
  {
    "region": "Eastern Region",
    "district": "Kumi",
    "town": "Kumi"
  },
  {
    "region": "Eastern Region",
    "district": "Kumi",
    "town": "Kumi Tc"
  },
  {
    "region": "Eastern Region",
    "district": "Kumi",
    "town": "Mukongoro"
  },
  {
    "region": "Eastern Region",
    "district": "Kumi",
    "town": "Nyero"
  },
  {
    "region": "Eastern Region",
    "district": "Kumi",
    "town": "Ongino"
  },
  {
    "region": "Eastern Region",
    "district": "Kween",
    "town": "Benet"
  },
  {
    "region": "Eastern Region",
    "district": "Kween",
    "town": "Binyiny"
  },
  {
    "region": "Eastern Region",
    "district": "Kween",
    "town": "Kaproron"
  },
  {
    "region": "Eastern Region",
    "district": "Kween",
    "town": "Kwanyiny"
  },
  {
    "region": "Eastern Region",
    "district": "Kween",
    "town": "Ngenge"
  },
  {
    "region": "Eastern Region",
    "district": "Luuka",
    "town": "Bukanga"
  },
  {
    "region": "Eastern Region",
    "district": "Luuka",
    "town": "Bukooma"
  },
  {
    "region": "Eastern Region",
    "district": "Luuka",
    "town": "Bulongo"
  },
  {
    "region": "Eastern Region",
    "district": "Luuka",
    "town": "Ikumbya"
  },
  {
    "region": "Eastern Region",
    "district": "Luuka",
    "town": "Irongo"
  },
  {
    "region": "Eastern Region",
    "district": "Luuka",
    "town": "Nawampiti"
  },
  {
    "region": "Eastern Region",
    "district": "Luuka",
    "town": "Waibuga"
  },
  {
    "region": "Eastern Region",
    "district": "Manafwa",
    "town": "Bubutu"
  },
  {
    "region": "Eastern Region",
    "district": "Manafwa",
    "town": "Bugobero"
  },
  {
    "region": "Eastern Region",
    "district": "Manafwa",
    "town": "Bumbo"
  },
  {
    "region": "Eastern Region",
    "district": "Manafwa",
    "town": "Bumwoni"
  },
  {
    "region": "Eastern Region",
    "district": "Manafwa",
    "town": "Bupoto"
  },
  {
    "region": "Eastern Region",
    "district": "Manafwa",
    "town": "Butiru"
  },
  {
    "region": "Eastern Region",
    "district": "Manafwa",
    "town": "Buwabwala"
  },
  {
    "region": "Eastern Region",
    "district": "Manafwa",
    "town": "Buwagogo"
  },
  {
    "region": "Eastern Region",
    "district": "Manafwa",
    "town": "Kaato"
  },
  {
    "region": "Eastern Region",
    "district": "Manafwa",
    "town": "Sibanga"
  },
  {
    "region": "Eastern Region",
    "district": "Mayuge",
    "town": "Baitambogwe"
  },
  {
    "region": "Eastern Region",
    "district": "Mayuge",
    "town": "Buwaaya"
  },
  {
    "region": "Eastern Region",
    "district": "Mayuge",
    "town": "Immanyiro"
  },
  {
    "region": "Eastern Region",
    "district": "Mayuge",
    "town": "Kigandalo"
  },
  {
    "region": "Eastern Region",
    "district": "Mayuge",
    "town": "Kityerera"
  },
  {
    "region": "Eastern Region",
    "district": "Mayuge",
    "town": "Malongo"
  },
  {
    "region": "Eastern Region",
    "district": "Mayuge",
    "town": "Mayuge Tc"
  },
  {
    "region": "Eastern Region",
    "district": "Mbale",
    "town": "Bufumbo"
  },
  {
    "region": "Eastern Region",
    "district": "Mbale",
    "town": "Bukonde"
  },
  {
    "region": "Eastern Region",
    "district": "Mbale",
    "town": "Bukyiende"
  },
  {
    "region": "Eastern Region",
    "district": "Mbale",
    "town": "Bungokho"
  },
  {
    "region": "Eastern Region",
    "district": "Mbale",
    "town": "Bungokho-mutoto"
  },
  {
    "region": "Eastern Region",
    "district": "Mbale",
    "town": "Busano"
  },
  {
    "region": "Eastern Region",
    "district": "Mbale",
    "town": "Busiu"
  },
  {
    "region": "Eastern Region",
    "district": "Mbale",
    "town": "Busoba"
  },
  {
    "region": "Eastern Region",
    "district": "Mbale",
    "town": "Industrial Division"
  },
  {
    "region": "Eastern Region",
    "district": "Mbale",
    "town": "Nakaloke"
  },
  {
    "region": "Eastern Region",
    "district": "Mbale",
    "town": "Namanyonyi"
  },
  {
    "region": "Eastern Region",
    "district": "Mbale",
    "town": "Northern Division"
  },
  {
    "region": "Eastern Region",
    "district": "Mbale",
    "town": "Wanale"
  },
  {
    "region": "Eastern Region",
    "district": "Mbale",
    "town": "Wanale Division"
  },
  {
    "region": "Eastern Region",
    "district": "Namayingo",
    "town": "Banda"
  },
  {
    "region": "Eastern Region",
    "district": "Namayingo",
    "town": "Buswale"
  },
  {
    "region": "Eastern Region",
    "district": "Namayingo",
    "town": "Buyinja"
  },
  {
    "region": "Eastern Region",
    "district": "Namayingo",
    "town": "Mutumba"
  },
  {
    "region": "Eastern Region",
    "district": "Namayingo",
    "town": "Sigulu Islands"
  },
  {
    "region": "Eastern Region",
    "district": "Namutumba",
    "town": "Bulange"
  },
  {
    "region": "Eastern Region",
    "district": "Namutumba",
    "town": "Ivukula"
  },
  {
    "region": "Eastern Region",
    "district": "Namutumba",
    "town": "Kibaale"
  },
  {
    "region": "Eastern Region",
    "district": "Namutumba",
    "town": "Magada"
  },
  {
    "region": "Eastern Region",
    "district": "Namutumba",
    "town": "Namutumba"
  },
  {
    "region": "Eastern Region",
    "district": "Namutumba",
    "town": "Nsinze"
  },
  {
    "region": "Eastern Region",
    "district": "Ngora",
    "town": "Kapir"
  },
  {
    "region": "Eastern Region",
    "district": "Ngora",
    "town": "Kobwin"
  },
  {
    "region": "Eastern Region",
    "district": "Ngora",
    "town": "Mukura"
  },
  {
    "region": "Eastern Region",
    "district": "Ngora",
    "town": "Ngora"
  },
  {
    "region": "Eastern Region",
    "district": "Pallisa",
    "town": "Agule"
  },
  {
    "region": "Eastern Region",
    "district": "Pallisa",
    "town": "Apopong"
  },
  {
    "region": "Eastern Region",
    "district": "Pallisa",
    "town": "Butebo"
  },
  {
    "region": "Eastern Region",
    "district": "Pallisa",
    "town": "Gogonyo"
  },
  {
    "region": "Eastern Region",
    "district": "Pallisa",
    "town": "Kabwangasi"
  },
  {
    "region": "Eastern Region",
    "district": "Pallisa",
    "town": "Kakoro"
  },
  {
    "region": "Eastern Region",
    "district": "Pallisa",
    "town": "Kameke"
  },
  {
    "region": "Eastern Region",
    "district": "Pallisa",
    "town": "Kamuge"
  },
  {
    "region": "Eastern Region",
    "district": "Pallisa",
    "town": "Kasodo"
  },
  {
    "region": "Eastern Region",
    "district": "Pallisa",
    "town": "Kibale"
  },
  {
    "region": "Eastern Region",
    "district": "Pallisa",
    "town": "Pallisa"
  },
  {
    "region": "Eastern Region",
    "district": "Pallisa",
    "town": "Pallisa Tc"
  },
  {
    "region": "Eastern Region",
    "district": "Pallisa",
    "town": "Petete"
  },
  {
    "region": "Eastern Region",
    "district": "Pallisa",
    "town": "Puti-puti"
  },
  {
    "region": "Eastern Region",
    "district": "Serere",
    "town": "Atiira"
  },
  {
    "region": "Eastern Region",
    "district": "Serere",
    "town": "Bugondo"
  },
  {
    "region": "Eastern Region",
    "district": "Serere",
    "town": "Kadungulu"
  },
  {
    "region": "Eastern Region",
    "district": "Serere",
    "town": "Kateta"
  },
  {
    "region": "Eastern Region",
    "district": "Serere",
    "town": "Kyere"
  },
  {
    "region": "Eastern Region",
    "district": "Serere",
    "town": "Olio"
  },
  {
    "region": "Eastern Region",
    "district": "Serere",
    "town": "Pingire"
  },
  {
    "region": "Eastern Region",
    "district": "Sironko",
    "town": "Buhugu"
  },
  {
    "region": "Eastern Region",
    "district": "Sironko",
    "town": "Bukhulo"
  },
  {
    "region": "Eastern Region",
    "district": "Sironko",
    "town": "Bumasifwa"
  },
  {
    "region": "Eastern Region",
    "district": "Sironko",
    "town": "Busulani"
  },
  {
    "region": "Eastern Region",
    "district": "Sironko",
    "town": "Butandiga"
  },
  {
    "region": "Eastern Region",
    "district": "Sironko",
    "town": "Buteza"
  },
  {
    "region": "Eastern Region",
    "district": "Sironko",
    "town": "Buwalasi"
  },
  {
    "region": "Eastern Region",
    "district": "Sironko",
    "town": "Buyobo"
  },
  {
    "region": "Eastern Region",
    "district": "Sironko",
    "town": "Sironko Tc"
  },
  {
    "region": "Eastern Region",
    "district": "Sironko",
    "town": "Zesui"
  },
  {
    "region": "Eastern Region",
    "district": "Soroti",
    "town": "Arapai"
  },
  {
    "region": "Eastern Region",
    "district": "Soroti",
    "town": "Asuret"
  },
  {
    "region": "Eastern Region",
    "district": "Soroti",
    "town": "Eastern Division"
  },
  {
    "region": "Eastern Region",
    "district": "Soroti",
    "town": "Gweri"
  },
  {
    "region": "Eastern Region",
    "district": "Soroti",
    "town": "Kamuda"
  },
  {
    "region": "Eastern Region",
    "district": "Soroti",
    "town": "Katine"
  },
  {
    "region": "Eastern Region",
    "district": "Soroti",
    "town": "Northern Division"
  },
  {
    "region": "Eastern Region",
    "district": "Soroti",
    "town": "Soroti Town"
  },
  {
    "region": "Eastern Region",
    "district": "Soroti",
    "town": "Tubur"
  },
  {
    "region": "Eastern Region",
    "district": "Soroti",
    "town": "Western Division"
  },
  {
    "region": "Eastern Region",
    "district": "Tororo",
    "town": "Eastern Division"
  },
  {
    "region": "Eastern Region",
    "district": "Tororo",
    "town": "Iyolwa"
  },
  {
    "region": "Eastern Region",
    "district": "Tororo",
    "town": "Kirewa"
  },
  {
    "region": "Eastern Region",
    "district": "Tororo",
    "town": "Kisoko"
  },
  {
    "region": "Eastern Region",
    "district": "Tororo",
    "town": "Kwapa"
  },
  {
    "region": "Eastern Region",
    "district": "Tororo",
    "town": "Mella"
  },
  {
    "region": "Eastern Region",
    "district": "Tororo",
    "town": "Merikit"
  },
  {
    "region": "Eastern Region",
    "district": "Tororo",
    "town": "Molo"
  },
  {
    "region": "Eastern Region",
    "district": "Tororo",
    "town": "Mukuju"
  },
  {
    "region": "Eastern Region",
    "district": "Tororo",
    "town": "Mulanda"
  },
  {
    "region": "Eastern Region",
    "district": "Tororo",
    "town": "Nabuyoga"
  },
  {
    "region": "Eastern Region",
    "district": "Tororo",
    "town": "Nagongera"
  },
  {
    "region": "Eastern Region",
    "district": "Tororo",
    "town": "Osukuru"
  },
  {
    "region": "Eastern Region",
    "district": "Tororo",
    "town": "Paya"
  },
  {
    "region": "Eastern Region",
    "district": "Tororo",
    "town": "Petta"
  },
  {
    "region": "Eastern Region",
    "district": "Tororo",
    "town": "Tororo Town"
  },
  {
    "region": "Northern Region",
    "district": "Abim",
    "town": "Abim"
  },
  {
    "region": "Northern Region",
    "district": "Abim",
    "town": "Alerek"
  },
  {
    "region": "Northern Region",
    "district": "Abim",
    "town": "Lotukei"
  },
  {
    "region": "Northern Region",
    "district": "Abim",
    "town": "Morulem"
  },
  {
    "region": "Northern Region",
    "district": "Abim",
    "town": "Nyakwae"
  },
  {
    "region": "Northern Region",
    "district": "Adjumani",
    "town": "Adjumani Tc"
  },
  {
    "region": "Northern Region",
    "district": "Adjumani",
    "town": "Adropi"
  },
  {
    "region": "Northern Region",
    "district": "Adjumani",
    "town": "Ciforo"
  },
  {
    "region": "Northern Region",
    "district": "Adjumani",
    "town": "Dzaipi"
  },
  {
    "region": "Northern Region",
    "district": "Adjumani",
    "town": "Ofua"
  },
  {
    "region": "Northern Region",
    "district": "Adjumani",
    "town": "Pakelle"
  },
  {
    "region": "Northern Region",
    "district": "Agago",
    "town": "Adilang"
  },
  {
    "region": "Northern Region",
    "district": "Agago",
    "town": "Agago Tc"
  },
  {
    "region": "Northern Region",
    "district": "Agago",
    "town": "Arum"
  },
  {
    "region": "Northern Region",
    "district": "Agago",
    "town": "Kalongo Tc"
  },
  {
    "region": "Northern Region",
    "district": "Agago",
    "town": "Kotomor"
  },
  {
    "region": "Northern Region",
    "district": "Agago",
    "town": "Lamiyo"
  },
  {
    "region": "Northern Region",
    "district": "Agago",
    "town": "Lapono"
  },
  {
    "region": "Northern Region",
    "district": "Agago",
    "town": "Lira Palwo"
  },
  {
    "region": "Northern Region",
    "district": "Agago",
    "town": "Lukole"
  },
  {
    "region": "Northern Region",
    "district": "Agago",
    "town": "Omiya Pachwa"
  },
  {
    "region": "Northern Region",
    "district": "Agago",
    "town": "Omot"
  },
  {
    "region": "Northern Region",
    "district": "Agago",
    "town": "Paimol"
  },
  {
    "region": "Northern Region",
    "district": "Agago",
    "town": "Parabongo"
  },
  {
    "region": "Northern Region",
    "district": "Agago",
    "town": "Patongo"
  },
  {
    "region": "Northern Region",
    "district": "Agago",
    "town": "Patongo Tc"
  },
  {
    "region": "Northern Region",
    "district": "Alebtong",
    "town": "Abako"
  },
  {
    "region": "Northern Region",
    "district": "Alebtong",
    "town": "Aloi"
  },
  {
    "region": "Northern Region",
    "district": "Alebtong",
    "town": "Amugo"
  },
  {
    "region": "Northern Region",
    "district": "Alebtong",
    "town": "Apala"
  },
  {
    "region": "Northern Region",
    "district": "Alebtong",
    "town": "Omoro"
  },
  {
    "region": "Northern Region",
    "district": "Amolatar",
    "town": "Aputi"
  },
  {
    "region": "Northern Region",
    "district": "Amolatar",
    "town": "Awelo"
  },
  {
    "region": "Northern Region",
    "district": "Amolatar",
    "town": "Muntu"
  },
  {
    "region": "Northern Region",
    "district": "Amolatar",
    "town": "Namasale"
  },
  {
    "region": "Northern Region",
    "district": "Amudat",
    "town": "Amudat"
  },
  {
    "region": "Northern Region",
    "district": "Amudat",
    "town": "Karita"
  },
  {
    "region": "Northern Region",
    "district": "Amudat",
    "town": "Loroo"
  },
  {
    "region": "Northern Region",
    "district": "Amuru",
    "town": "Amuru"
  },
  {
    "region": "Northern Region",
    "district": "Amuru",
    "town": "Atiak"
  },
  {
    "region": "Northern Region",
    "district": "Amuru",
    "town": "Lamogi"
  },
  {
    "region": "Northern Region",
    "district": "Amuru",
    "town": "Pabbo"
  },
  {
    "region": "Northern Region",
    "district": "Apac",
    "town": "Abongomola"
  },
  {
    "region": "Northern Region",
    "district": "Apac",
    "town": "Aduku"
  },
  {
    "region": "Northern Region",
    "district": "Apac",
    "town": "Akokoro"
  },
  {
    "region": "Northern Region",
    "district": "Apac",
    "town": "Apac"
  },
  {
    "region": "Northern Region",
    "district": "Apac",
    "town": "Apac Tc"
  },
  {
    "region": "Northern Region",
    "district": "Apac",
    "town": "Cegere"
  },
  {
    "region": "Northern Region",
    "district": "Apac",
    "town": "Chawente"
  },
  {
    "region": "Northern Region",
    "district": "Apac",
    "town": "Ibuje"
  },
  {
    "region": "Northern Region",
    "district": "Apac",
    "town": "Inomo"
  },
  {
    "region": "Northern Region",
    "district": "Apac",
    "town": "Nambieso"
  },
  {
    "region": "Northern Region",
    "district": "Arua",
    "town": "Adumi"
  },
  {
    "region": "Northern Region",
    "district": "Arua",
    "town": "Ajia"
  },
  {
    "region": "Northern Region",
    "district": "Arua",
    "town": "Arivu"
  },
  {
    "region": "Northern Region",
    "district": "Arua",
    "town": "Aroi"
  },
  {
    "region": "Northern Region",
    "district": "Arua",
    "town": "Arua Hill"
  },
  {
    "region": "Northern Region",
    "district": "Arua",
    "town": "Dadamu"
  },
  {
    "region": "Northern Region",
    "district": "Arua",
    "town": "Logiri"
  },
  {
    "region": "Northern Region",
    "district": "Arua",
    "town": "Manibe"
  },
  {
    "region": "Northern Region",
    "district": "Arua",
    "town": "Offaka"
  },
  {
    "region": "Northern Region",
    "district": "Arua",
    "town": "Ogoko"
  },
  {
    "region": "Northern Region",
    "district": "Arua",
    "town": "Okollo"
  },
  {
    "region": "Northern Region",
    "district": "Arua",
    "town": "Oli River"
  },
  {
    "region": "Northern Region",
    "district": "Arua",
    "town": "Oluko"
  },
  {
    "region": "Northern Region",
    "district": "Arua",
    "town": "Pajulu"
  },
  {
    "region": "Northern Region",
    "district": "Arua",
    "town": "Rhino Camp"
  },
  {
    "region": "Northern Region",
    "district": "Arua",
    "town": "Rigbo"
  },
  {
    "region": "Northern Region",
    "district": "Arua",
    "town": "Uleppi"
  },
  {
    "region": "Northern Region",
    "district": "Arua",
    "town": "Vurra"
  },
  {
    "region": "Northern Region",
    "district": "Dokolo",
    "town": "Agwata"
  },
  {
    "region": "Northern Region",
    "district": "Dokolo",
    "town": "Batta"
  },
  {
    "region": "Northern Region",
    "district": "Dokolo",
    "town": "Dokolo"
  },
  {
    "region": "Northern Region",
    "district": "Dokolo",
    "town": "Kangai"
  },
  {
    "region": "Northern Region",
    "district": "Dokolo",
    "town": "Kwera"
  },
  {
    "region": "Northern Region",
    "district": "Gulu",
    "town": "Awach"
  },
  {
    "region": "Northern Region",
    "district": "Gulu",
    "town": "Bar-dege"
  },
  {
    "region": "Northern Region",
    "district": "Gulu",
    "town": "Bobi"
  },
  {
    "region": "Northern Region",
    "district": "Gulu",
    "town": "Bungatira"
  },
  {
    "region": "Northern Region",
    "district": "Gulu",
    "town": "Koro"
  },
  {
    "region": "Northern Region",
    "district": "Gulu",
    "town": "Lakwana"
  },
  {
    "region": "Northern Region",
    "district": "Gulu",
    "town": "Lalogi"
  },
  {
    "region": "Northern Region",
    "district": "Gulu",
    "town": "Laroo"
  },
  {
    "region": "Northern Region",
    "district": "Gulu",
    "town": "Layibi"
  },
  {
    "region": "Northern Region",
    "district": "Gulu",
    "town": "Odek"
  },
  {
    "region": "Northern Region",
    "district": "Gulu",
    "town": "Ongako"
  },
  {
    "region": "Northern Region",
    "district": "Gulu",
    "town": "Paicho"
  },
  {
    "region": "Northern Region",
    "district": "Gulu",
    "town": "Palaro"
  },
  {
    "region": "Northern Region",
    "district": "Gulu",
    "town": "Patiko"
  },
  {
    "region": "Northern Region",
    "district": "Gulu",
    "town": "Pece"
  },
  {
    "region": "Northern Region",
    "district": "Gulu",
    "town": "Unyama"
  },
  {
    "region": "Northern Region",
    "district": "Kaabong",
    "town": "Kaabong"
  },
  {
    "region": "Northern Region",
    "district": "Kaabong",
    "town": "Kaabong Tc"
  },
  {
    "region": "Northern Region",
    "district": "Kaabong",
    "town": "Kalapata"
  },
  {
    "region": "Northern Region",
    "district": "Kaabong",
    "town": "Kapedo"
  },
  {
    "region": "Northern Region",
    "district": "Kaabong",
    "town": "Karenga"
  },
  {
    "region": "Northern Region",
    "district": "Kaabong",
    "town": "Kathile"
  },
  {
    "region": "Northern Region",
    "district": "Kaabong",
    "town": "Lolelia"
  },
  {
    "region": "Northern Region",
    "district": "Kaabong",
    "town": "Loyoro"
  },
  {
    "region": "Northern Region",
    "district": "Kaabong",
    "town": "Sidok"
  },
  {
    "region": "Northern Region",
    "district": "Kitgum",
    "town": "Kitgum Matidi"
  },
  {
    "region": "Northern Region",
    "district": "Kitgum",
    "town": "Kitgum Tc"
  },
  {
    "region": "Northern Region",
    "district": "Kitgum",
    "town": "Labongo Akwang"
  },
  {
    "region": "Northern Region",
    "district": "Kitgum",
    "town": "Labongo Amida"
  },
  {
    "region": "Northern Region",
    "district": "Kitgum",
    "town": "Labongo Layamo"
  },
  {
    "region": "Northern Region",
    "district": "Kitgum",
    "town": "Lagoro"
  },
  {
    "region": "Northern Region",
    "district": "Kitgum",
    "town": "Mucwini"
  },
  {
    "region": "Northern Region",
    "district": "Kitgum",
    "town": "Namokora"
  },
  {
    "region": "Northern Region",
    "district": "Kitgum",
    "town": "Omiya Anyima"
  },
  {
    "region": "Northern Region",
    "district": "Kitgum",
    "town": "Orom"
  },
  {
    "region": "Northern Region",
    "district": "Koboko",
    "town": "Koboko Tc"
  },
  {
    "region": "Northern Region",
    "district": "Koboko",
    "town": "Kuluba"
  },
  {
    "region": "Northern Region",
    "district": "Koboko",
    "town": "Lobule"
  },
  {
    "region": "Northern Region",
    "district": "Koboko",
    "town": "Ludara"
  },
  {
    "region": "Northern Region",
    "district": "Koboko",
    "town": "Midia"
  },
  {
    "region": "Northern Region",
    "district": "Kole",
    "town": "Aboke"
  },
  {
    "region": "Northern Region",
    "district": "Kole",
    "town": "Akalo"
  },
  {
    "region": "Northern Region",
    "district": "Kole",
    "town": "Alito"
  },
  {
    "region": "Northern Region",
    "district": "Kole",
    "town": "Ayer"
  },
  {
    "region": "Northern Region",
    "district": "Kole",
    "town": "Bala"
  },
  {
    "region": "Northern Region",
    "district": "Kotido",
    "town": "Kacheri"
  },
  {
    "region": "Northern Region",
    "district": "Kotido",
    "town": "Kotido"
  },
  {
    "region": "Northern Region",
    "district": "Kotido",
    "town": "Kotido Tc"
  },
  {
    "region": "Northern Region",
    "district": "Kotido",
    "town": "Nakapelimoru"
  },
  {
    "region": "Northern Region",
    "district": "Kotido",
    "town": "Panyangara"
  },
  {
    "region": "Northern Region",
    "district": "Kotido",
    "town": "Rengen"
  },
  {
    "region": "Northern Region",
    "district": "Lamwo",
    "town": "Agoro"
  },
  {
    "region": "Northern Region",
    "district": "Lamwo",
    "town": "Lokung"
  },
  {
    "region": "Northern Region",
    "district": "Lamwo",
    "town": "Madi Opei"
  },
  {
    "region": "Northern Region",
    "district": "Lamwo",
    "town": "Padibe East"
  },
  {
    "region": "Northern Region",
    "district": "Lamwo",
    "town": "Padibe West"
  },
  {
    "region": "Northern Region",
    "district": "Lamwo",
    "town": "Palabek Gem"
  },
  {
    "region": "Northern Region",
    "district": "Lamwo",
    "town": "Palabek Kal"
  },
  {
    "region": "Northern Region",
    "district": "Lamwo",
    "town": "Palabek Ogili"
  },
  {
    "region": "Northern Region",
    "district": "Lamwo",
    "town": "Paloga"
  },
  {
    "region": "Northern Region",
    "district": "Lira",
    "town": "Adekokwok"
  },
  {
    "region": "Northern Region",
    "district": "Lira",
    "town": "Adyel"
  },
  {
    "region": "Northern Region",
    "district": "Lira",
    "town": "Amach"
  },
  {
    "region": "Northern Region",
    "district": "Lira",
    "town": "Aromo"
  },
  {
    "region": "Northern Region",
    "district": "Lira",
    "town": "Barr"
  },
  {
    "region": "Northern Region",
    "district": "Lira",
    "town": "Central"
  },
  {
    "region": "Northern Region",
    "district": "Lira",
    "town": "Lira"
  },
  {
    "region": "Northern Region",
    "district": "Lira",
    "town": "Ogur"
  },
  {
    "region": "Northern Region",
    "district": "Lira",
    "town": "Ojwina"
  },
  {
    "region": "Northern Region",
    "district": "Lira",
    "town": "Railway"
  },
  {
    "region": "Northern Region",
    "district": "Maracha",
    "town": "Aii-vu"
  },
  {
    "region": "Northern Region",
    "district": "Maracha",
    "town": "Beleafe"
  },
  {
    "region": "Northern Region",
    "district": "Maracha",
    "town": "Katrini"
  },
  {
    "region": "Northern Region",
    "district": "Maracha",
    "town": "Kijomoro"
  },
  {
    "region": "Northern Region",
    "district": "Maracha",
    "town": "Nyadri"
  },
  {
    "region": "Northern Region",
    "district": "Maracha",
    "town": "Odupi"
  },
  {
    "region": "Northern Region",
    "district": "Maracha",
    "town": "Oleba"
  },
  {
    "region": "Northern Region",
    "district": "Maracha",
    "town": "Oluffe"
  },
  {
    "region": "Northern Region",
    "district": "Maracha",
    "town": "Oluvu"
  },
  {
    "region": "Northern Region",
    "district": "Maracha",
    "town": "Omugo"
  },
  {
    "region": "Northern Region",
    "district": "Maracha",
    "town": "Tara"
  },
  {
    "region": "Northern Region",
    "district": "Maracha",
    "town": "Uriama"
  },
  {
    "region": "Northern Region",
    "district": "Maracha",
    "town": "Yiv"
  },
  {
    "region": "Northern Region",
    "district": "Moroto",
    "town": "Katikekile"
  },
  {
    "region": "Northern Region",
    "district": "Moroto",
    "town": "Nadunget"
  },
  {
    "region": "Northern Region",
    "district": "Moroto",
    "town": "Northern Division"
  },
  {
    "region": "Northern Region",
    "district": "Moroto",
    "town": "Rupa"
  },
  {
    "region": "Northern Region",
    "district": "Moroto",
    "town": "Southern Division"
  },
  {
    "region": "Northern Region",
    "district": "Moroto",
    "town": "Tapac"
  },
  {
    "region": "Northern Region",
    "district": "Moyo",
    "town": "Aliba"
  },
  {
    "region": "Northern Region",
    "district": "Moyo",
    "town": "Dufile"
  },
  {
    "region": "Northern Region",
    "district": "Moyo",
    "town": "Gimara"
  },
  {
    "region": "Northern Region",
    "district": "Moyo",
    "town": "Itula"
  },
  {
    "region": "Northern Region",
    "district": "Moyo",
    "town": "Lefori"
  },
  {
    "region": "Northern Region",
    "district": "Moyo",
    "town": "Metu"
  },
  {
    "region": "Northern Region",
    "district": "Moyo",
    "town": "Moyo Tc"
  },
  {
    "region": "Northern Region",
    "district": "Moyo",
    "town": "Moyo Town"
  },
  {
    "region": "Northern Region",
    "district": "Nakapiripirit",
    "town": "Kakomongole"
  },
  {
    "region": "Northern Region",
    "district": "Nakapiripirit",
    "town": "Lolachat"
  },
  {
    "region": "Northern Region",
    "district": "Nakapiripirit",
    "town": "Loregae"
  },
  {
    "region": "Northern Region",
    "district": "Nakapiripirit",
    "town": "Lorengedwat"
  },
  {
    "region": "Northern Region",
    "district": "Nakapiripirit",
    "town": "Moruita"
  },
  {
    "region": "Northern Region",
    "district": "Nakapiripirit",
    "town": "Nabilatuk"
  },
  {
    "region": "Northern Region",
    "district": "Nakapiripirit",
    "town": "Nakapiripirit Tc"
  },
  {
    "region": "Northern Region",
    "district": "Nakapiripirit",
    "town": "Namalu"
  },
  {
    "region": "Northern Region",
    "district": "Napak",
    "town": "Iriiri"
  },
  {
    "region": "Northern Region",
    "district": "Napak",
    "town": "Lokopo"
  },
  {
    "region": "Northern Region",
    "district": "Napak",
    "town": "Lopei"
  },
  {
    "region": "Northern Region",
    "district": "Napak",
    "town": "Lotome"
  },
  {
    "region": "Northern Region",
    "district": "Napak",
    "town": "Matany"
  },
  {
    "region": "Northern Region",
    "district": "Napak",
    "town": "Ngoleriet"
  },
  {
    "region": "Northern Region",
    "district": "Nebbi",
    "town": "Akworo"
  },
  {
    "region": "Northern Region",
    "district": "Nebbi",
    "town": "Erussi"
  },
  {
    "region": "Northern Region",
    "district": "Nebbi",
    "town": "Kucwiny"
  },
  {
    "region": "Northern Region",
    "district": "Nebbi",
    "town": "Nebbi Tc"
  },
  {
    "region": "Northern Region",
    "district": "Nebbi",
    "town": "Nebbi Town"
  },
  {
    "region": "Northern Region",
    "district": "Nebbi",
    "town": "Nyaravur"
  },
  {
    "region": "Northern Region",
    "district": "Nebbi",
    "town": "Pakwach Tc"
  },
  {
    "region": "Northern Region",
    "district": "Nebbi",
    "town": "Pakwach Town"
  },
  {
    "region": "Northern Region",
    "district": "Nebbi",
    "town": "Panyango"
  },
  {
    "region": "Northern Region",
    "district": "Nebbi",
    "town": "Panyimur"
  },
  {
    "region": "Northern Region",
    "district": "Nebbi",
    "town": "Parombo"
  },
  {
    "region": "Northern Region",
    "district": "Nebbi",
    "town": "Wadelai"
  },
  {
    "region": "Northern Region",
    "district": "Nwoya",
    "town": "Alero"
  },
  {
    "region": "Northern Region",
    "district": "Nwoya",
    "town": "Anaka"
  },
  {
    "region": "Northern Region",
    "district": "Nwoya",
    "town": "Koch Goma"
  },
  {
    "region": "Northern Region",
    "district": "Nwoya",
    "town": "Purongo"
  },
  {
    "region": "Northern Region",
    "district": "Otuke",
    "town": "Adwari"
  },
  {
    "region": "Northern Region",
    "district": "Otuke",
    "town": "Okwang"
  },
  {
    "region": "Northern Region",
    "district": "Otuke",
    "town": "Olilim"
  },
  {
    "region": "Northern Region",
    "district": "Otuke",
    "town": "Orum"
  },
  {
    "region": "Northern Region",
    "district": "Oyam",
    "town": "Aber"
  },
  {
    "region": "Northern Region",
    "district": "Oyam",
    "town": "Acaba"
  },
  {
    "region": "Northern Region",
    "district": "Oyam",
    "town": "Iceme"
  },
  {
    "region": "Northern Region",
    "district": "Oyam",
    "town": "Loro"
  },
  {
    "region": "Northern Region",
    "district": "Oyam",
    "town": "Minakulu"
  },
  {
    "region": "Northern Region",
    "district": "Oyam",
    "town": "Ngai"
  },
  {
    "region": "Northern Region",
    "district": "Oyam",
    "town": "Otwal"
  },
  {
    "region": "Northern Region",
    "district": "Pader",
    "town": "Acholibur"
  },
  {
    "region": "Northern Region",
    "district": "Pader",
    "town": "Angagura"
  },
  {
    "region": "Northern Region",
    "district": "Pader",
    "town": "Atanga"
  },
  {
    "region": "Northern Region",
    "district": "Pader",
    "town": "Awere"
  },
  {
    "region": "Northern Region",
    "district": "Pader",
    "town": "Kilak"
  },
  {
    "region": "Northern Region",
    "district": "Pader",
    "town": "Laguti"
  },
  {
    "region": "Northern Region",
    "district": "Pader",
    "town": "Lapul"
  },
  {
    "region": "Northern Region",
    "district": "Pader",
    "town": "Latanya"
  },
  {
    "region": "Northern Region",
    "district": "Pader",
    "town": "Ogom"
  },
  {
    "region": "Northern Region",
    "district": "Pader",
    "town": "Pader"
  },
  {
    "region": "Northern Region",
    "district": "Pader",
    "town": "Pader Tc"
  },
  {
    "region": "Northern Region",
    "district": "Pader",
    "town": "Pajule"
  },
  {
    "region": "Northern Region",
    "district": "Pader",
    "town": "Puranga"
  },
  {
    "region": "Northern Region",
    "district": "Yumbe",
    "town": "Apo"
  },
  {
    "region": "Northern Region",
    "district": "Yumbe",
    "town": "Drajani"
  },
  {
    "region": "Northern Region",
    "district": "Yumbe",
    "town": "Kei"
  },
  {
    "region": "Northern Region",
    "district": "Yumbe",
    "town": "Kuru"
  },
  {
    "region": "Northern Region",
    "district": "Yumbe",
    "town": "Midigo"
  },
  {
    "region": "Northern Region",
    "district": "Yumbe",
    "town": "Odravu"
  },
  {
    "region": "Northern Region",
    "district": "Yumbe",
    "town": "Romogi"
  },
  {
    "region": "Northern Region",
    "district": "Yumbe",
    "town": "Yumbe Tc"
  },
  {
    "region": "Northern Region",
    "district": "Zombo",
    "town": "Atyak"
  },
  {
    "region": "Northern Region",
    "district": "Zombo",
    "town": "Jangokoro"
  },
  {
    "region": "Northern Region",
    "district": "Zombo",
    "town": "Kango"
  },
  {
    "region": "Northern Region",
    "district": "Zombo",
    "town": "Nyapea"
  },
  {
    "region": "Northern Region",
    "district": "Zombo",
    "town": "Paidha"
  },
  {
    "region": "Northern Region",
    "district": "Zombo",
    "town": "Paidha Tc"
  },
  {
    "region": "Northern Region",
    "district": "Zombo",
    "town": "Zeu"
  },
  {
    "region": "Western Region",
    "district": "Buhweju",
    "town": "Buhweju Tc"
  },
  {
    "region": "Western Region",
    "district": "Buliisa",
    "town": "Biiso"
  },
  {
    "region": "Western Region",
    "district": "Buliisa",
    "town": "Budongo"
  },
  {
    "region": "Western Region",
    "district": "Buliisa",
    "town": "Buliisa Town"
  },
  {
    "region": "Western Region",
    "district": "Bundibugyo",
    "town": "Bubandi"
  },
  {
    "region": "Western Region",
    "district": "Bundibugyo",
    "town": "Bubukwanga"
  },
  {
    "region": "Western Region",
    "district": "Bundibugyo",
    "town": "Bundibugyo Tc"
  },
  {
    "region": "Western Region",
    "district": "Bundibugyo",
    "town": "Busaru"
  },
  {
    "region": "Western Region",
    "district": "Bundibugyo",
    "town": "Harugali"
  },
  {
    "region": "Western Region",
    "district": "Bundibugyo",
    "town": "Kasitu"
  },
  {
    "region": "Western Region",
    "district": "Bundibugyo",
    "town": "Nduguto"
  },
  {
    "region": "Western Region",
    "district": "Bushenyi",
    "town": "Bumbaire"
  },
  {
    "region": "Western Region",
    "district": "Bushenyi",
    "town": "Bushenyi-ishaka Tc"
  },
  {
    "region": "Western Region",
    "district": "Bushenyi",
    "town": "Kakanju"
  },
  {
    "region": "Western Region",
    "district": "Bushenyi",
    "town": "Katerera"
  },
  {
    "region": "Western Region",
    "district": "Bushenyi",
    "town": "Katunguru"
  },
  {
    "region": "Western Region",
    "district": "Bushenyi",
    "town": "Kichwamba"
  },
  {
    "region": "Western Region",
    "district": "Bushenyi",
    "town": "Kyabugimbi"
  },
  {
    "region": "Western Region",
    "district": "Bushenyi",
    "town": "Kyamuhunga"
  },
  {
    "region": "Western Region",
    "district": "Bushenyi",
    "town": "Kyeizoba"
  },
  {
    "region": "Western Region",
    "district": "Bushenyi",
    "town": "Nyabubare"
  },
  {
    "region": "Western Region",
    "district": "Bushenyi",
    "town": "Ryeru"
  },
  {
    "region": "Western Region",
    "district": "Hoima",
    "town": "Bugambe"
  },
  {
    "region": "Western Region",
    "district": "Hoima",
    "town": "Buhanika"
  },
  {
    "region": "Western Region",
    "district": "Hoima",
    "town": "Buhimba"
  },
  {
    "region": "Western Region",
    "district": "Hoima",
    "town": "Buseruka"
  },
  {
    "region": "Western Region",
    "district": "Hoima",
    "town": "Busiisi"
  },
  {
    "region": "Western Region",
    "district": "Hoima",
    "town": "Hoima Tc"
  },
  {
    "region": "Western Region",
    "district": "Hoima",
    "town": "Kabwoya"
  },
  {
    "region": "Western Region",
    "district": "Hoima",
    "town": "Kigorobya"
  },
  {
    "region": "Western Region",
    "district": "Hoima",
    "town": "Kigorobya Tc"
  },
  {
    "region": "Western Region",
    "district": "Hoima",
    "town": "Kitoba"
  },
  {
    "region": "Western Region",
    "district": "Hoima",
    "town": "Kiziranfumbi"
  },
  {
    "region": "Western Region",
    "district": "Hoima",
    "town": "Kyabigambire"
  },
  {
    "region": "Western Region",
    "district": "Hoima",
    "town": "Kyangwali"
  },
  {
    "region": "Western Region",
    "district": "Ibanda",
    "town": "Bisheshe"
  },
  {
    "region": "Western Region",
    "district": "Ibanda",
    "town": "Ibanda Tc"
  },
  {
    "region": "Western Region",
    "district": "Ibanda",
    "town": "Ishongororo"
  },
  {
    "region": "Western Region",
    "district": "Ibanda",
    "town": "Kicuzi"
  },
  {
    "region": "Western Region",
    "district": "Ibanda",
    "town": "Kikyenkye"
  },
  {
    "region": "Western Region",
    "district": "Ibanda",
    "town": "Nyabuhikye"
  },
  {
    "region": "Western Region",
    "district": "Ibanda",
    "town": "Nyamarebe"
  },
  {
    "region": "Western Region",
    "district": "Ibanda",
    "town": "Rukiri"
  },
  {
    "region": "Western Region",
    "district": "Isingiro",
    "town": "Birere"
  },
  {
    "region": "Western Region",
    "district": "Isingiro",
    "town": "Endinzi"
  },
  {
    "region": "Western Region",
    "district": "Isingiro",
    "town": "Kabingo"
  },
  {
    "region": "Western Region",
    "district": "Isingiro",
    "town": "Kabuyanda"
  },
  {
    "region": "Western Region",
    "district": "Isingiro",
    "town": "Kashumba"
  },
  {
    "region": "Western Region",
    "district": "Isingiro",
    "town": "Kikagate"
  },
  {
    "region": "Western Region",
    "district": "Isingiro",
    "town": "Masha"
  },
  {
    "region": "Western Region",
    "district": "Isingiro",
    "town": "Ngarama"
  },
  {
    "region": "Western Region",
    "district": "Isingiro",
    "town": "Nyakitunda"
  },
  {
    "region": "Western Region",
    "district": "Isingiro",
    "town": "Rugaaga"
  },
  {
    "region": "Western Region",
    "district": "Kabale",
    "town": "Bubare"
  },
  {
    "region": "Western Region",
    "district": "Kabale",
    "town": "Bufundi"
  },
  {
    "region": "Western Region",
    "district": "Kabale",
    "town": "Buhara"
  },
  {
    "region": "Western Region",
    "district": "Kabale",
    "town": "Bukinda"
  },
  {
    "region": "Western Region",
    "district": "Kabale",
    "town": "Hamurwa"
  },
  {
    "region": "Western Region",
    "district": "Kabale",
    "town": "Ikumba"
  },
  {
    "region": "Western Region",
    "district": "Kabale",
    "town": "Kabale Central"
  },
  {
    "region": "Western Region",
    "district": "Kabale",
    "town": "Kabale Northern"
  },
  {
    "region": "Western Region",
    "district": "Kabale",
    "town": "Kabale Southern"
  },
  {
    "region": "Western Region",
    "district": "Kabale",
    "town": "Kaharo"
  },
  {
    "region": "Western Region",
    "district": "Kabale",
    "town": "Kamuganguzi"
  },
  {
    "region": "Western Region",
    "district": "Kabale",
    "town": "Kamwezi"
  },
  {
    "region": "Western Region",
    "district": "Kabarole",
    "town": "Buheesi"
  },
  {
    "region": "Western Region",
    "district": "Kabarole",
    "town": "Bukuku"
  },
  {
    "region": "Western Region",
    "district": "Kabarole",
    "town": "Busoro"
  },
  {
    "region": "Western Region",
    "district": "Kabarole",
    "town": "Eastern"
  },
  {
    "region": "Western Region",
    "district": "Kabarole",
    "town": "Hakibaale"
  },
  {
    "region": "Western Region",
    "district": "Kabarole",
    "town": "Karambi"
  },
  {
    "region": "Western Region",
    "district": "Kabarole",
    "town": "Kibiito"
  },
  {
    "region": "Western Region",
    "district": "Kabarole",
    "town": "Kicwamba"
  },
  {
    "region": "Western Region",
    "district": "Kabarole",
    "town": "Kisomoro"
  },
  {
    "region": "Western Region",
    "district": "Kabarole",
    "town": "Mugusu"
  },
  {
    "region": "Western Region",
    "district": "Kabarole",
    "town": "Ruteete"
  },
  {
    "region": "Western Region",
    "district": "Kabarole",
    "town": "Rwiimi"
  },
  {
    "region": "Western Region",
    "district": "Kabarole",
    "town": "Southern"
  },
  {
    "region": "Western Region",
    "district": "Kabarole",
    "town": "Western"
  },
  {
    "region": "Western Region",
    "district": "Kamwenge",
    "town": "Bwiizi"
  },
  {
    "region": "Western Region",
    "district": "Kamwenge",
    "town": "Kahunge"
  },
  {
    "region": "Western Region",
    "district": "Kamwenge",
    "town": "Kamwenge"
  },
  {
    "region": "Western Region",
    "district": "Kamwenge",
    "town": "Kamwenge Tc"
  },
  {
    "region": "Western Region",
    "district": "Kamwenge",
    "town": "Kicheche"
  },
  {
    "region": "Western Region",
    "district": "Kamwenge",
    "town": "Mahyoro"
  },
  {
    "region": "Western Region",
    "district": "Kamwenge",
    "town": "Nkoma"
  },
  {
    "region": "Western Region",
    "district": "Kamwenge",
    "town": "Ntara"
  },
  {
    "region": "Western Region",
    "district": "Kamwenge",
    "town": "Nyabbani"
  },
  {
    "region": "Western Region",
    "district": "Kanungu",
    "town": "Kambuga"
  },
  {
    "region": "Western Region",
    "district": "Kanungu",
    "town": "Kanungu Tc"
  },
  {
    "region": "Western Region",
    "district": "Kanungu",
    "town": "Kanyantorogo"
  },
  {
    "region": "Western Region",
    "district": "Kanungu",
    "town": "Kayonza"
  },
  {
    "region": "Western Region",
    "district": "Kanungu",
    "town": "Kihiihi"
  },
  {
    "region": "Western Region",
    "district": "Kanungu",
    "town": "Kirima"
  },
  {
    "region": "Western Region",
    "district": "Kanungu",
    "town": "Mpungu"
  },
  {
    "region": "Western Region",
    "district": "Kanungu",
    "town": "Nyamirama"
  },
  {
    "region": "Western Region",
    "district": "Kanungu",
    "town": "Rugyeyo"
  },
  {
    "region": "Western Region",
    "district": "Kanungu",
    "town": "Rutenga"
  },
  {
    "region": "Western Region",
    "district": "Kasese",
    "town": "Bugoye"
  },
  {
    "region": "Western Region",
    "district": "Kasese",
    "town": "Bwera"
  },
  {
    "region": "Western Region",
    "district": "Kasese",
    "town": "Ihandiro"
  },
  {
    "region": "Western Region",
    "district": "Kasese",
    "town": "Karambi"
  },
  {
    "region": "Western Region",
    "district": "Kasese",
    "town": "Karusandara"
  },
  {
    "region": "Western Region",
    "district": "Kasese",
    "town": "Kasese Tc"
  },
  {
    "region": "Western Region",
    "district": "Kasese",
    "town": "Katwe Kabatoro Tc"
  },
  {
    "region": "Western Region",
    "district": "Kasese",
    "town": "Kilembe"
  },
  {
    "region": "Western Region",
    "district": "Kasese",
    "town": "Kisinga"
  },
  {
    "region": "Western Region",
    "district": "Kasese",
    "town": "Kitholhu"
  },
  {
    "region": "Western Region",
    "district": "Kasese",
    "town": "Kitswamba"
  },
  {
    "region": "Western Region",
    "district": "Kasese",
    "town": "Kyabarungira"
  },
  {
    "region": "Western Region",
    "district": "Kasese",
    "town": "Kyarumba"
  },
  {
    "region": "Western Region",
    "district": "Kasese",
    "town": "Kyondo"
  },
  {
    "region": "Western Region",
    "district": "Kasese",
    "town": "L. Katwe"
  },
  {
    "region": "Western Region",
    "district": "Kasese",
    "town": "Mahango"
  },
  {
    "region": "Western Region",
    "district": "Kasese",
    "town": "Maliba"
  },
  {
    "region": "Western Region",
    "district": "Kasese",
    "town": "Muhokya"
  },
  {
    "region": "Western Region",
    "district": "Kasese",
    "town": "Munkunyu"
  },
  {
    "region": "Western Region",
    "district": "Kasese",
    "town": "Nyakiyumbu"
  },
  {
    "region": "Western Region",
    "district": "Kasese",
    "town": "Rukoki"
  },
  {
    "region": "Western Region",
    "district": "Kibaale",
    "town": "Bwamiramira"
  },
  {
    "region": "Western Region",
    "district": "Kibaale",
    "town": "Bwanswa"
  },
  {
    "region": "Western Region",
    "district": "Kibaale",
    "town": "Bwikara"
  },
  {
    "region": "Western Region",
    "district": "Kibaale",
    "town": "Kagadi"
  },
  {
    "region": "Western Region",
    "district": "Kibaale",
    "town": "Kakindo"
  },
  {
    "region": "Western Region",
    "district": "Kibaale",
    "town": "Kasambya"
  },
  {
    "region": "Western Region",
    "district": "Kibaale",
    "town": "Kibaale Tc"
  },
  {
    "region": "Western Region",
    "district": "Kibaale",
    "town": "Kiryanga"
  },
  {
    "region": "Western Region",
    "district": "Kibaale",
    "town": "Kisiita"
  },
  {
    "region": "Western Region",
    "district": "Kibaale",
    "town": "Kyanaisoke"
  },
  {
    "region": "Western Region",
    "district": "Kibaale",
    "town": "Kyebando"
  },
  {
    "region": "Western Region",
    "district": "Kibaale",
    "town": "Mabaale"
  },
  {
    "region": "Western Region",
    "district": "Kibaale",
    "town": "Matale"
  },
  {
    "region": "Western Region",
    "district": "Kibaale",
    "town": "Mpeefu"
  },
  {
    "region": "Western Region",
    "district": "Kibaale",
    "town": "Mugarama"
  },
  {
    "region": "Western Region",
    "district": "Kibaale",
    "town": "Muhoro"
  },
  {
    "region": "Western Region",
    "district": "Kibaale",
    "town": "Nalweyo"
  },
  {
    "region": "Western Region",
    "district": "Kibaale",
    "town": "Nkooko"
  },
  {
    "region": "Western Region",
    "district": "Kibaale",
    "town": "Rugashari"
  },
  {
    "region": "Western Region",
    "district": "Kiruhura",
    "town": "Buremba"
  },
  {
    "region": "Western Region",
    "district": "Kiruhura",
    "town": "Burunga"
  },
  {
    "region": "Western Region",
    "district": "Kiruhura",
    "town": "Kanoni"
  },
  {
    "region": "Western Region",
    "district": "Kiruhura",
    "town": "Kanyaryeru"
  },
  {
    "region": "Western Region",
    "district": "Kiruhura",
    "town": "Kashongi"
  },
  {
    "region": "Western Region",
    "district": "Kiruhura",
    "town": "Kazo"
  },
  {
    "region": "Western Region",
    "district": "Kiruhura",
    "town": "Kenshunga"
  },
  {
    "region": "Western Region",
    "district": "Kiruhura",
    "town": "Kikatsi"
  },
  {
    "region": "Western Region",
    "district": "Kiruhura",
    "town": "Kinoni"
  },
  {
    "region": "Western Region",
    "district": "Kiruhura",
    "town": "Nyakashashara"
  },
  {
    "region": "Western Region",
    "district": "Kiruhura",
    "town": "Rwemikoma"
  },
  {
    "region": "Western Region",
    "district": "Kiruhura",
    "town": "Sanga"
  },
  {
    "region": "Western Region",
    "district": "Kiryandongo",
    "town": "Kigumba"
  },
  {
    "region": "Western Region",
    "district": "Kiryandongo",
    "town": "Kiryandongo Tc"
  },
  {
    "region": "Western Region",
    "district": "Kiryandongo",
    "town": "Masindi Port"
  },
  {
    "region": "Western Region",
    "district": "Kiryandongo",
    "town": "Mutunda"
  },
  {
    "region": "Western Region",
    "district": "Kisoro",
    "town": "Bukimbiri"
  },
  {
    "region": "Western Region",
    "district": "Kisoro",
    "town": "Busanza"
  },
  {
    "region": "Western Region",
    "district": "Kisoro",
    "town": "Chahi"
  },
  {
    "region": "Western Region",
    "district": "Kisoro",
    "town": "Kanaba"
  },
  {
    "region": "Western Region",
    "district": "Kisoro",
    "town": "Kirundo"
  },
  {
    "region": "Western Region",
    "district": "Kisoro",
    "town": "Kisoro Tc"
  },
  {
    "region": "Western Region",
    "district": "Kisoro",
    "town": "Muramba"
  },
  {
    "region": "Western Region",
    "district": "Kisoro",
    "town": "Murora"
  },
  {
    "region": "Western Region",
    "district": "Kisoro",
    "town": "Nyabwishenya"
  },
  {
    "region": "Western Region",
    "district": "Kisoro",
    "town": "Nyakabande"
  },
  {
    "region": "Western Region",
    "district": "Kisoro",
    "town": "Nyakinama"
  },
  {
    "region": "Western Region",
    "district": "Kisoro",
    "town": "Nyarubuye"
  },
  {
    "region": "Western Region",
    "district": "Kisoro",
    "town": "Nyarusiza"
  },
  {
    "region": "Western Region",
    "district": "Kisoro",
    "town": "Nyundo"
  },
  {
    "region": "Western Region",
    "district": "Kyegegwa",
    "town": "Hapuyo"
  },
  {
    "region": "Western Region",
    "district": "Kyegegwa",
    "town": "Kakabara"
  },
  {
    "region": "Western Region",
    "district": "Kyegegwa",
    "town": "Kasule"
  },
  {
    "region": "Western Region",
    "district": "Kyegegwa",
    "town": "Kyegegwa"
  },
  {
    "region": "Western Region",
    "district": "Kyegegwa",
    "town": "Mpara"
  },
  {
    "region": "Western Region",
    "district": "Kyenjojo",
    "town": "Bufunjo"
  },
  {
    "region": "Western Region",
    "district": "Kyenjojo",
    "town": "Bugaaki"
  },
  {
    "region": "Western Region",
    "district": "Kyenjojo",
    "town": "Butiiti"
  },
  {
    "region": "Western Region",
    "district": "Kyenjojo",
    "town": "Katoke"
  },
  {
    "region": "Western Region",
    "district": "Kyenjojo",
    "town": "Kihuura"
  },
  {
    "region": "Western Region",
    "district": "Kyenjojo",
    "town": "Kyarusozi"
  },
  {
    "region": "Western Region",
    "district": "Kyenjojo",
    "town": "Kyenjojo Tc"
  },
  {
    "region": "Western Region",
    "district": "Kyenjojo",
    "town": "Nyankwanzi"
  },
  {
    "region": "Western Region",
    "district": "Kyenjojo",
    "town": "Nyantungo"
  },
  {
    "region": "Western Region",
    "district": "Masindi",
    "town": "Bwijanga"
  },
  {
    "region": "Western Region",
    "district": "Masindi",
    "town": "Karujubu"
  },
  {
    "region": "Western Region",
    "district": "Masindi",
    "town": "Kimengo"
  },
  {
    "region": "Western Region",
    "district": "Masindi",
    "town": "Masindi Tc"
  },
  {
    "region": "Western Region",
    "district": "Masindi",
    "town": "Miirya"
  },
  {
    "region": "Western Region",
    "district": "Masindi",
    "town": "Nyangahya"
  },
  {
    "region": "Western Region",
    "district": "Masindi",
    "town": "Pakanyi"
  },
  {
    "region": "Western Region",
    "district": "Mbarara",
    "town": "Bubaare"
  },
  {
    "region": "Western Region",
    "district": "Mbarara",
    "town": "Bugamba"
  },
  {
    "region": "Western Region",
    "district": "Mbarara",
    "town": "Bukiro"
  },
  {
    "region": "Western Region",
    "district": "Mbarara",
    "town": "Kagongi"
  },
  {
    "region": "Western Region",
    "district": "Mbarara",
    "town": "Kakiika"
  },
  {
    "region": "Western Region",
    "district": "Mbarara",
    "town": "Kakoba"
  },
  {
    "region": "Western Region",
    "district": "Mbarara",
    "town": "Kamukuzi"
  },
  {
    "region": "Western Region",
    "district": "Mbarara",
    "town": "Kashare"
  },
  {
    "region": "Western Region",
    "district": "Mbarara",
    "town": "Mbarara Town"
  },
  {
    "region": "Western Region",
    "district": "Mbarara",
    "town": "Mwizi"
  },
  {
    "region": "Western Region",
    "district": "Mbarara",
    "town": "Ndaija"
  },
  {
    "region": "Western Region",
    "district": "Mbarara",
    "town": "Nyakayojo"
  },
  {
    "region": "Western Region",
    "district": "Mbarara",
    "town": "Nyamitanga"
  },
  {
    "region": "Western Region",
    "district": "Mbarara",
    "town": "Rubaya"
  },
  {
    "region": "Western Region",
    "district": "Mbarara",
    "town": "Rubindi"
  },
  {
    "region": "Western Region",
    "district": "Mbarara",
    "town": "Rugando"
  },
  {
    "region": "Western Region",
    "district": "Mbarara",
    "town": "Rwanyamahembe"
  },
  {
    "region": "Western Region",
    "district": "Mitooma",
    "town": "Bitereko"
  },
  {
    "region": "Western Region",
    "district": "Mitooma",
    "town": "Kabira"
  },
  {
    "region": "Western Region",
    "district": "Mitooma",
    "town": "Kanyabwanga"
  },
  {
    "region": "Western Region",
    "district": "Mitooma",
    "town": "Kashenshero"
  },
  {
    "region": "Western Region",
    "district": "Mitooma",
    "town": "Kiyanga"
  },
  {
    "region": "Western Region",
    "district": "Mitooma",
    "town": "Mitooma"
  },
  {
    "region": "Western Region",
    "district": "Mitooma",
    "town": "Mutara"
  },
  {
    "region": "Western Region",
    "district": "Ntoroko",
    "town": "Kanara"
  },
  {
    "region": "Western Region",
    "district": "Ntoroko",
    "town": "Karugutu"
  },
  {
    "region": "Western Region",
    "district": "Ntoroko",
    "town": "Rwebisengo"
  },
  {
    "region": "Western Region",
    "district": "Ntungamo",
    "town": "Bwongyera"
  },
  {
    "region": "Western Region",
    "district": "Ntungamo",
    "town": "Ihunga"
  },
  {
    "region": "Western Region",
    "district": "Ntungamo",
    "town": "Itojo"
  },
  {
    "region": "Western Region",
    "district": "Ntungamo",
    "town": "Kayonza"
  },
  {
    "region": "Western Region",
    "district": "Ntungamo",
    "town": "Kibatsi"
  },
  {
    "region": "Western Region",
    "district": "Ntungamo",
    "town": "Ngoma"
  },
  {
    "region": "Western Region",
    "district": "Ntungamo",
    "town": "Ntungamo Tc"
  },
  {
    "region": "Western Region",
    "district": "Ntungamo",
    "town": "Ntungamo Town"
  },
  {
    "region": "Western Region",
    "district": "Ntungamo",
    "town": "Nyabihoko"
  },
  {
    "region": "Western Region",
    "district": "Ntungamo",
    "town": "Nyakyera"
  },
  {
    "region": "Western Region",
    "district": "Ntungamo",
    "town": "Rubaare"
  },
  {
    "region": "Western Region",
    "district": "Ntungamo",
    "town": "Rugarama"
  },
  {
    "region": "Western Region",
    "district": "Ntungamo",
    "town": "Ruhaama"
  },
  {
    "region": "Western Region",
    "district": "Ntungamo",
    "town": "Rukoni"
  },
  {
    "region": "Western Region",
    "district": "Ntungamo",
    "town": "Rweikiniro"
  },
  {
    "region": "Western Region",
    "district": "Rubirizi",
    "town": "Rubirizi Tc"
  },
  {
    "region": "Western Region",
    "district": "Rukungiri",
    "town": "Bugangari"
  },
  {
    "region": "Western Region",
    "district": "Rukungiri",
    "town": "Buhunga"
  },
  {
    "region": "Western Region",
    "district": "Rukungiri",
    "town": "Buyanja"
  },
  {
    "region": "Western Region",
    "district": "Rukungiri",
    "town": "Bwambara"
  },
  {
    "region": "Western Region",
    "district": "Rukungiri",
    "town": "Kagunga"
  },
  {
    "region": "Western Region",
    "district": "Rukungiri",
    "town": "Kebisoni"
  },
  {
    "region": "Western Region",
    "district": "Rukungiri",
    "town": "Nyakagyeme"
  },
  {
    "region": "Western Region",
    "district": "Rukungiri",
    "town": "Nyakishenyi"
  },
  {
    "region": "Western Region",
    "district": "Rukungiri",
    "town": "Nyarushanje"
  },
  {
    "region": "Western Region",
    "district": "Rukungiri",
    "town": "Ruhinda"
  },
  {
    "region": "Western Region",
    "district": "Rukungiri",
    "town": "Rukungiri Tc"
  },
  {
    "region": "Western Region",
    "district": "Sheema",
    "town": "Sheema Tc"
  }
];

  const countryList = [
    { id: 1, name: "South Africa", symbol: "SA" },
    { id: 2, name: "United Arab Emirates", symbol: "UAE" },
    { id: 3, name: "Rwanda", symbol: "RWA" },
    { id: 4, name: "Kenya", symbol: "KEN" },
  ];

    const DISTRICTS = groupByDistricts(LOCATIONS);

    const COUNTRIES = _.sortBy(countryList, 'name');

    export {
        LOCATIONS,
        DISTRICTS,
        COUNTRIES,
    };