import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Schedule from "@material-ui/icons/Schedule";
import Info from "@material-ui/icons/Info";
import LocationOn from "@material-ui/icons/LocationOn";
import Gavel from "@material-ui/icons/Gavel";
import HelpOutline from "@material-ui/icons/HelpOutline";
import BackIcon from '@material-ui/icons/ArrowBack';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import Accordion from "components/Accordion/Accordion.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Heading from "components/Heading/Heading.js";

//AWS
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from 'graphql/queries';

// Manzia
import ReportHeader from "bulooka/Reports/ReportHeader.js";
import PropertyMenu from "bulooka/Reports/PropertyMenu.js";
import ReportCharts from "bulooka/Reports/ReportCharts.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

// Utils
const _ = require("underscore");
const moment = require("moment");

const styles = {
  cardTitle,
  pageTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
    width: '100%'
  },
  cardCategory: {
    margin: "0",
    color: "#999999"
  },
  root: {
    margin: '12px 24px'
  },
  headerSpace: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  errorTitle: {
    color: 'red',
    fontWeight: 'bold'
  }
};

const GETOWNER = `query GetOwner($id: ID!, $pastDays: String!) {
  getOwner(id: $id) {
    id
    displayName
    email
    phone
    properties(limit: 1000) {
      items {
        id
        name
        propertyType
        propertyPurpose
        currencyCode
        salePrice
        rentPrice
        featuredImage
        city
        district
        views
      }
      nextToken
    }
    propertyViewsByDate(month: { ge: $pastDays}, limit: 200) {
      items {
        id
        ownerID
        propertyID
        month
        dayViews
        views
        duration
      }
      nextToken
    }
  }
}
`;

const useStyles = makeStyles(styles);

export default function Panels(props) {
  const { ownerId } = props;
  //const propOwnerId = "gid://Bulooka/Owner/157901693532201";  // TESTING !!!
  const [selectProperty, setSelectProperty] = React.useState(null);
  const [properties, setProperties] = React.useState([]);
  const [propSpinner, setPropSpinner] = React.useState(true);
  const [ propError, setPropError] = React.useState("");
  const [propertyViews, setPropertyViews] = React.useState([]);
  const [ownerName, setOwnerName] = React.useState("");

  const classes = useStyles();

  // Report Header - Fetch all owner properties
  React.useEffect(() => {
      API.graphql(graphqlOperation(GETOWNER, { id: ownerId, pastDays: moment().utc().subtract(90, 'days').format("YYYY-MM") }))
      .then(({ data }) => {
        if (data && data.getOwner) {
          if (!_.isEmpty(data.getOwner.properties.items)) {
            setProperties(data.getOwner.properties.items);
            setSelectProperty(data.getOwner.properties.items[0]);
            setOwnerName(data.getOwner.displayName);
          }
          if (!_.isEmpty(data.getOwner.propertyViewsByDate.items)) {
            setPropertyViews(data.getOwner.propertyViewsByDate.items);
          }
        }
        setPropSpinner(false);
      })
      .catch((err) => {
        console.log("Fetch error: %j", err);
        setPropSpinner(false);
        setPropError("Failed to fetch analytics. Please try again later.")
      });
  }, [ownerId]);

  return (
    <div className={classes.root}>
      <div className={classes.headerSpace}>
        <IconButton aria-label="delete" className={classes.margin} onClick={(e) => props.history.goBack()}>
            <BackIcon fontSize="large" />
        </IconButton>
      <h3 className={classes.pageTitle}>{ownerName}</h3>
      </div>
      <h3 className={`${classes.pageTitle} ${classes.errorTitle}`}>{propError}</h3>
      {propSpinner && <CircularProgress />}
      <GridContainer justify="center">
        
        <GridItem xs={12} sm={12} md={12}>
          <ReportHeader properties={properties} propertyViews={propertyViews} />
          <Card>
            <CardHeader>
              <h4 className={classes.cardTitle}>Your Properties</h4>
            </CardHeader>
            <CardBody>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={3}>
                    <PropertyMenu 
                      properties={properties} 
                      propertyHandler={(aproperty) => setSelectProperty(aproperty) } 
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={9}>
                  <ReportCharts property={selectProperty} />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
