import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.js";

import defaultImage from "assets/img/property-placeholder.png";
import defaultAvatar from "assets/img/placeholder.jpg";

/*
const { fileHandler } = this.props;
	
    let reader = new FileReader();
    let file = e.target.files[0];

    if (fileHandler) {
      fileHandler(file);
    }

    reader.onloadend = () => {
        // file: file,
      	this.setState({
        	imagePreviewUrl: reader.result
        });
    };
    reader.readAsDataURL(file);
*/
export default function ImageUpload(props) {
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    props.avatar ? defaultAvatar : defaultImage
  );

  let fileInput = React.createRef();
  const handleImageChange = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  // eslint-disable-next-line
  const handleSubmit = e => {
    e.preventDefault();
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself
  };

  const handleClick = () => {
    fileInput.current.click();
  };
  const handleAdd = () => {
    if (props.imageHandler) {
      props.imageHandler(file);
    }
    setFile(null);
    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
    fileInput.current.value = null;
  };
  let { avatar, addButtonProps, changeButtonProps, removeButtonProps } = props;
  return (
    <div className="fileinput text-center">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div className={"thumbnail" + (avatar ? " img-circle" : "")}>
        <img src={imagePreviewUrl} alt="..." />
      </div>
      <div>
        {file === null || imagePreviewUrl === defaultImage ? (
          <Button {...addButtonProps} onClick={() => handleClick()} disabled={props.loading}>
            {avatar ? "Add Photo" : "Select image"}
          </Button>
        ) : (
          <span>
            <Button {...changeButtonProps} onClick={() => handleClick()} disabled={props.loading}>
              Change
            </Button>
            {avatar ? <br /> : null}
            <Button {...removeButtonProps} onClick={() => handleAdd()} disabled={props.loading}>
              <i className="fas fa-plus" /> Add
            </Button>
          </span>
        )}
      </div>
    </div>
  );
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
};
