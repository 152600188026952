/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:74e6cd51-9615-4b7f-bfd0-13ec228f3523",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_baFWJsJE7",
    "aws_user_pools_web_client_id": "2583vi9f9k0kocrdj8q4o6jtq0",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://mz7ujzkvpbad5p6lqcw26bgouu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-3xdur6tnqndnpeyogauiufk4o4",
};


export default awsmobile;
