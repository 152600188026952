import AWizard from "bulooka/Wizard.js";
import AExtendedTables from "bulooka/ExtendedTables.js";
import PropertyOwners from "bulooka/PropertyOwners.js";
import VehicleWizard from "bulooka/Vehicles/Wizard";
import Vehicles from "bulooka/Vehicles/VehicleList"
import CreateAdvertiser from "bulooka/Advertisers/CreateAdvertiser"


// @material-ui/icons
import Home from "@material-ui/icons/Home";
import Add from "@material-ui/icons/Add";
import Group from "@material-ui/icons/Group";
import Vehicle from "@material-ui/icons/DirectionsCarOutlined";
import VehicleAdd from "@material-ui/icons/LibraryAddOutlined";
import Business from "@material-ui/icons/Business";
import AdvertiserList from "bulooka/Advertisers/AdvertiserList";


const bulookaRoutes = [
  {
    path: "/properties",
    name: "Properties",
    icon: Home,
    component: AExtendedTables,
    layout: "/admin"
  },
  {
    path: "/create",
    name: "Create Property",
    icon: Add,
    component: AWizard,
    layout: "/admin"
  },
  {
    path: "/vehicles",
    name: "Vehicles",
    icon: Vehicle,
    component: Vehicles,
    layout: "/admin"
  },
  {
    path: "/addvehicle",
    name: "Create Vehicle",
    icon: VehicleAdd,
    component: VehicleWizard,
    layout: "/admin"
  },
  {
    path: "/adverts",
    name: "Advertisers",
    icon: Business,
    component: AdvertiserList,
    layout: "/admin"
  },
  {
    path: "/adcreate",
    name: "Create Advertiser",
    icon: Add,
    component: CreateAdvertiser,
    layout: "/admin"
  },
  {
    path: "/owners",
    name: "Property Owners",
    icon: Group,
    component: PropertyOwners,
    layout: "/admin"
  },
];


//export default dashRoutes;
export default bulookaRoutes;
