import React from "react";
import { Link } from "react-router-dom";
import _ from "underscore";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from "@material-ui/core/Divider";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Refresh from "@material-ui/icons/RefreshOutlined";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import NavPills from "components/NavPills/NavPills.js";
import Button from "components/CustomButtons/Button.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import { API, graphqlOperation } from 'aws-amplify';
import * as queries from 'graphql/queries';
import { Connect } from "aws-amplify-react";

// Bulooka
import Property from "./Components/Property.js";
import OwnerPayments from "./Components/OwnerPayments";
import PaymentsTable from "bulooka/Tables/paymentstable.js";
import Vehicle from "./Components/Vehicle.js";

// Utils
const nonce = require('nonce')();

const styles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardKeywordTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
	  textAlign: "center"
  },
  paymentsTitle: {
    ...cardTitle,
    marginTop: "45px",
    marginBottom: "10px",
	  textAlign: "center"
  },
  centered: {
    marginTop: "10px",
    textAlign: "center",
    marginBottom: "10px",
  },
  rightLabel: {
    marginRight: "15px",
  }
};

const FETCH_LIMIT = 1000;

const getOwnerProperties = `query GetOwnerProperties($id: ID!, $limit: Int, $nextToken: String) {
  getOwner(id: $id) {
    id
    vehicles(limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        slug
        shortID
        options
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        condition
        purpose
        price
        intPrice
        currencyCode
        vehicleType
        year
        make
        model
        trim
        mileage
        cityMPG
        highwayMPG
        exterior
        interior
        driveType
        fuelType
        driveSetup
        transmission
        isDutyPaid
        engineSize
        sellerDescription
        isDealership
        isFeatured
        isDeal
        isForeclosed
        featuredImage
        images
        createdAt
        updatedAt
        address
        addressNumber
        city
        district
        province
        country
        status
        search
        views
        videos
        thumbnails
      }
    }
    properties(limit: $limit, nextToken: $nextToken) {
      items {
        id
        shortID
        name
        slug
        options
        isFeatured
        isFeaturedBottom
        isOffPlan
        isForeclosed
        isDeal
        isEstate
        isHotelRoom
        isHostel
        hotelId
        hotelStars
        propertyType
        propertyPurpose
        currencyCode
        salePrice
        rentPrice
        description
        featuredImage
        images
        createdAt
        updatedAt
        address
        addressNumber
        city
        district
        province
        country
        latitude
        longitude
        bathrooms
        bedrooms
        status
        size
        sizeUnits
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
      }
      nextToken
    }
  }
}`;

const getOwnerPayments = `query GetOwnerPayments($id: ID!, $limit: Int, $nextToken: String) {
  getOwner(id: $id) {
    id
    fullyPaid
    subscription {
      id
      name
      price
      recurring
      startDate
      status
      lastPaidAt
      payments(limit: $limit, nextToken: $nextToken) {
        items {
          id
          amount
          currencyCode
          processedAt
          processedBy
          notes
          paymentType
        }
        nextToken
      }
      options
    }
  }
}`;

function OwnerProperties(props) {
  const { properties, vehicles, classes, hasNextPage, onLoadMore } = props;

  return (
    <div>
      <GridContainer spacing={4}>
        {properties.map((property, key) => {
            return (
              <GridItem xs={12} sm={6} md={4} key={key}>
                <br />
                <Property property={property} />
                <br />
              </GridItem>
            );
          })}
      </GridContainer>
      <GridContainer>
        {vehicles.map((vehicle, key) => {
            return (
              <GridItem xs={12} sm={6} md={4} key={key}>
                <br />
                <Vehicle vehicle={vehicle} />
                <br />
              </GridItem>
            );
          })}
      </GridContainer>
      { hasNextPage && 
        <div className={classes.centered}>
          <Link to="/admin/create">
            <Button color="info" size="lg" onClick={onLoadMore}>
              <Refresh /> Load More
            </Button>
          </Link>
        </div>
      }
    </div>
    
  );
}

class PropertyOwner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      properties: [],
      vehicles: [],
      payments: [],
      propertyToken: "",
      vehicleToken: "",
      paymentsToken: "",
      ownerDisplay: {},
      owner: {},
      lastPaymentId: "",
      createSubscriptionId: "",
      newSubscriber: true
    };

    this.fetchMoreProperties = this.fetchMoreProperties.bind(this);
    this.fetchMorePayments = this.fetchMorePayments.bind(this);
    this.handleSubscribe = this.handleSubscribe.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
  }

  componentDidMount() {
    const { location: { state } } = this.props;
    if (_.isEmpty(state)) return;
    //console.log("Owner details: " + JSON.stringify(state));
    const { owner: { id, displayName, firstName, lastName, ownerType, email, phone, address, city,
    website, subscription } } = state;
    const name = `${ownerType}` === 'Individual' ? `${firstName} ${lastName}` : displayName;
    const display = this.convertStringNullToEmptyValues({ id, name, email, phone, address, city, website });
    const subscriptionId = _.isEmpty(subscription) ? `gid://Bulooka/AppSubscription/${nonce()}` : subscription.id;
    this.setState({ ownerDisplay: display, owner: state.owner, 
      createSubscriptionId: subscriptionId, newSubscriber: _.isEmpty(subscription) });

  }

  convertStringNullToEmptyValues(obj) {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "string" && obj[key] === "null") {
        obj[key] = "";
      } else if (typeof obj[key] === "object" && !_.isEmpty(obj[key])) {
        this.convertStringNullToEmptyValues(obj[key]);
      }
    });
    return obj;
  }

  fetchMoreProperties(propItems, propToken, vehItems, vehToken) {
    
    if (!_.isEmpty(propToken)) {
      this.setState((state, props) => {
        return { propertyToken: propToken, properties: [...state.properties, ...propItems] };
      });
    }

    if (!_.isEmpty(vehToken)) {
      this.setState((state, props) => {
        return { vehicleToken: vehToken, vehicles: [...state.vehicles, ...vehItems] };
      });
    }
      
  };

  fetchMorePayments(items, nextToken) {
    if (_.isEmpty(nextToken)) return;

      this.setState((state, props) => {
        return { paymentsToken: nextToken, payments: [...state.payments, ...items] };
      });
  };

  handleSubscribe(subscribeId) {
    if (_.isEmpty(subscribeId)) return;
    this.setState({ createSubscriptionId: subscribeId, newSubscriber: false });

    // if (_.isEmpty(subscription)) {
      // Refetch owner object with new subscription
      
    // }
  }

  handlePayment(payment) {
    if (_.isEmpty(payment)) return;
    //debugger;
    // Update local payments state
    this.setState((prevState, prevProps) => {
      return { payments: [payment, ...prevState.payments], lastPaymentId: payment.id };
    });
    

    // Refetch owner to get added payments
    // try {
    //   const { data } = await API.graphql(graphqlOperation(queries.getOwner, { id: this.state.owner.id }));
    //   if (data && !_.isEmpty(data.getOwner) ) {
    //       // Set State
    //       this.setState({ owner: data.getOwner, lastPaymentId: paymentId });
    //   }
    // } catch (error) {
    //   console.error(JSON.stringify(error));
    // }
  }

  renderSummary() {
    const { classes } = this.props;
    const { ownerDisplay } = this.state;

    return (
      <div>
          <h3 className={classes.cardKeywordTitle}>{ownerDisplay.name}</h3>
          <div className={classes.centered}>
              {!_.isEmpty(ownerDisplay.address) && <h5>Address: <strong> {ownerDisplay.address}</strong></h5>}
              <h5>{!_.isEmpty(ownerDisplay.email) && <span className={classes.rightLabel}>Email: <strong> {ownerDisplay.email}</strong></span>}Phone: <strong> {ownerDisplay.phone}</strong></h5>
              {!_.isEmpty(ownerDisplay.website) && <h5>Website: <strong> {ownerDisplay.website}</strong></h5>}
          </div>
      </div>
    );
  }
  
  render() {
    const { classes, user } = this.props;
    const { propertyToken, paymentsToken, ownerDisplay, properties, payments, owner,
    createSubscriptionId, newSubscriber, vehicles, vehicleToken } = this.state;

    // Fetching
    if (_.isEmpty(ownerDisplay)) return (<h4>Fetching owner...</h4>);

    // Next Properties Page
    const propertyVariables = { id: ownerDisplay.id,  limit: FETCH_LIMIT };
    if (!_.isEmpty(propertyToken)) { Object.assign(propertyVariables, { nextToken: propertyToken }); }
    if (!_.isEmpty(vehicleToken)) { Object.assign(propertyVariables, { nextToken: vehicleToken }); }

    // Next Payments Page
    const paymentVariables = { id: ownerDisplay.id, limit: FETCH_LIMIT };
    if (!_.isEmpty(paymentsToken)) { Object.assign(paymentVariables, { nextToken: paymentsToken }); }

    const that = this;

    // Variables

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Landlord</h4>
            </CardHeader>
            <CardBody>
              
              {this.renderSummary()}
              <br />
              <Divider />
              <br />
              <NavPills
                color="info"
                    tabs={[
                      {
                        tabButton: "Properties",
                        tabContent: (

                          <React.Fragment>
                            <h4 className={classes.cardKeywordTitle}>{"Active properties"}</h4>
                            <br />
                            <Connect query={graphqlOperation(getOwnerProperties, propertyVariables )}>
                                {({ data: { getOwner }, loading, errors }) => {
                                  if (!_.isEmpty(errors)) return (<h4 className={classes.cardKeywordTitle}>{`Errors: ${JSON.stringify(errors)}`}</h4>);
                                  if (loading || !getOwner) return (<h3 className={classes.cardKeywordTitle}>Loading...</h3>);
                                  
                                  if (_.isEmpty(getOwner.properties.items) && _.isEmpty(getOwner.vehicles.items)) {
                                    return (<h4 className={classes.cardKeywordTitle}>{`Found no properties or vehicles for ${ownerDisplay.name}.`}</h4>)
                                  } else {
                                    const { items: fetchedProperties = [], nextToken: propToken } = getOwner.properties;
                                    const { items: fetchedVehicles = [], nextToken: vehToken } = getOwner.vehicles;

                                      return (
                                        <OwnerProperties 
                                          properties={[ ...properties, ...fetchedProperties]}
                                          vehicles={[ ...vehicles, ...fetchedVehicles]}
                                          hasNextPage={(fetchedProperties.length > 0 && fetchedProperties.length % FETCH_LIMIT === 0 )|| (fetchedVehicles.length > 0 && fetchedVehicles.length % FETCH_LIMIT === 0)}
                                          onLoadMore={() => that.fetchMoreProperties(fetchedProperties, propToken, fetchedVehicles, vehToken)}
                                          classes={classes}
                                        />
                                      );
                                  }
                                }}
                            </Connect>
                          </React.Fragment>
                        )
                      },
                      {
                        tabButton: "Payments",
                        tabContent: (
                          <React.Fragment>
                            
                            <Connect query={graphqlOperation(getOwnerPayments, paymentVariables )}>
                                {({ data: { getOwner }, loading, errors }) => {
                                  if (!_.isEmpty(errors)) return (<h4 className={classes.cardKeywordTitle}>{`Errors: ${JSON.stringify(errors)}`}</h4>);
                                  if (loading || !getOwner) return (<h3 className={classes.cardKeywordTitle}>Loading...</h3>);
                                  //console.log("Owner: %j", getOwner);
                                  
                                  if (!_.isEmpty(getOwner.subscription) && !_.isEmpty(getOwner.subscription.payments) && !_.isEmpty(getOwner.subscription.payments.items)) {
                                    const { items, nextToken } = getOwner.subscription.payments;
                                  
                                    return (
                                      <React.Fragment>
                                          <OwnerPayments 
                                            owner={getOwner}
                                            user={user}
                                            subscriptionId={_.isEmpty(getOwner.subscription) ? createSubscriptionId : getOwner.subscription.id}
                                            onSubscriptionUpdate={that.handleSubscribe}
                                            onPayment={that.handlePayment}
                                            newSubscriber={_.isEmpty(getOwner.subscription)}
                                          />
                                          <br />
                                          <h4 className={classes.paymentsTitle}>{`Payment History for ${ownerDisplay.name}.`}</h4>
                                          <PaymentsTable
                                            user={user} 
                                            payments={[...payments, ...items ]}
                                            hasNextPage={items.length % 50 === 0}
                                            onLoadMore={() => that.fetchMorePayments(items, nextToken)}
                                          />
                                      </React.Fragment>
                                      
                                    );
                                    
                                  } else {
                                    if (_.isEmpty(payments)) {
                                      return (
                                        <React.Fragment>
                                          <OwnerPayments 
                                            owner={getOwner}
                                            user={user}
                                            subscriptionId={_.isEmpty(getOwner.subscription) ? createSubscriptionId : getOwner.subscription.id}
                                            onSubscriptionUpdate={that.handleSubscribe}
                                            onPayment={that.handlePayment}
                                            newSubscriber={_.isEmpty(getOwner.subscription)}
                                          />
                                          <br />
                                          <h4 className={classes.cardKeywordTitle}>{`Found no payments for ${ownerDisplay.name}.`}</h4>
                                        </React.Fragment>
                                      
                                      );
                                    } else {
                                      return (
                                        <React.Fragment>
                                            <OwnerPayments 
                                              owner={getOwner}
                                              user={user}
                                              subscriptionId={_.isEmpty(getOwner.subscription) ? createSubscriptionId : getOwner.subscription.id}
                                              onSubscriptionUpdate={that.handleSubscribe}
                                              onPayment={that.handlePayment}
                                              newSubscriber={_.isEmpty(getOwner.subscription)}
                                            />
                                            <br />
                                            <h4 className={classes.paymentsTitle}>{`Payment History for ${ownerDisplay.name}.`}</h4>
                                            <PaymentsTable
                                              user={user} 
                                              payments={payments}
                                              hasNextPage={false}
                                            />
                                        </React.Fragment>
                                        
                                      );
                                    }
                                  }
                                  
                                }}
                            </Connect>

                          </React.Fragment>
                          
                        )
                      },

                    ]}
                  />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
    );
  }
}

export default withStyles(styles)(PropertyOwner);
