import React from "react";
import PropTypes from "prop-types";
import _ from "underscore";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';

// Icons
import Face from "@material-ui/icons/Face";
import RecordVoiceOver from "@material-ui/icons/RecordVoiceOver";
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";
import Search from "@material-ui/icons/Search";
import Business from "@material-ui/icons/BusinessOutlined";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import PictureUpload from "components/CustomUpload/PictureUpload.js";

import Amplify, { API, graphqlOperation } from 'aws-amplify';
import * as queries from 'graphql/queries';

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

// Manzia
import { OwnerType } from "variables/general.js";
import { DISTRICTS, COUNTRIES } from "variables/locations.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 20px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  phoneSearch: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: '10px',
    }
  },
  centered: {
    textAlign: 'center'
  },
  ...customSelectStyle
};


class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      district: "",
      districtState: "",
      streetName: "",
      streetNameState: "",
      streetNumber: "",
      streetNumberState: "",
      city: "",
      cityState: "",
      firstname: "",
      firstnameState: "",
      organizationname: "",
      organizationnameState: "",
      ownerType: "",
      lastname: "",
      lastnameState: "",
      email: "",
      emailState: "",
      phone: "",
      phoneState: "",
      website: "",
      websiteState: "",
      sellerAddress: "",
      sellerAddressState: "",
      sellerCity: "",
      sellerCityState: "",
      sellerDistrict: "",
      sellerDistrictState: "",
      latitude: "",
      latitudeState: "",
      longitude: "",
      longitudeState: "",
      profilePhoto: null,
      searching: false,
      isForiegn: false,
      country: "",
      countryState: ""
    };

    this.handleSearch = this.handleSearch.bind(this);

  }

  componentDidMount() {
    const { vehicle } = this.props;
    if (_.isEmpty(vehicle)) return;
    const { owner: { firstName = "", lastName = "", name = "", displayName = "", email = "", phone = "", profilePhoto = "", 
      address = "", city = "", province = "", website = "", ownerType: sellerType }, address: propAddress,
      addressNumber = "", city: propCity = "", district: propDistrict = "", latitude = "", longitude = "", country = "" 
    } = this.convertStringNullToEmptyValues(vehicle);

    const isIndividual = sellerType ? `${sellerType}` === 'Individual' : false;
    this.setState({
        sellerDistrict: province, sellerAddress: address, firstname: firstName, lastname: lastName,
        organizationname: displayName, email, phone, profilePhoto, website, sellerCity: city,
        ownerType: `${sellerType}`, streetName: propAddress, streetNumber: _.isEmpty(addressNumber) ? "" : addressNumber, 
        city: propCity, district: propDistrict, 
        latitude: _.isEmpty(latitude) ? "" : latitude, 
        longitude: _.isEmpty(longitude) ? "": longitude,
        isForiegn: !_.isEmpty(country) && country !== "Uganda" ? true : false,
        country,  
        streetNameState: _.isEmpty(propAddress) ? "error" : "success",
        cityState: _.isEmpty(propCity) ? "error" : "success",
        firstnameState: isIndividual ? _.isEmpty(firstName) ? "error" : "success" : "",
        lastnameState: isIndividual ? _.isEmpty(lastName) ? "error" : "success" : "",
        organizationnameState: !isIndividual ? _.isEmpty(displayName) ? "error" : "success" : "",
        emailState: _.isEmpty(email) ? "error" : "success",
        phoneState: _.isEmpty(phone) ? "error" : "success",
        sellerAddressState: _.isEmpty(address) ? "error" : "success",
        sellerCityState: _.isEmpty(city) ? "error" : "success",
        sellerDistrictState: _.isEmpty(province) ? "error" : "success",
        countryState: _.isEmpty(country) ? "error" : "success",
        districtState: _.isEmpty(propDistrict) ? "error" : "success"
    });
  }

  convertStringNullToEmptyValues(obj) {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "string" && obj[key] === "null") {
        obj[key] = "";
      } else if (typeof obj[key] === "object" && !_.isEmpty(obj[key])) {
        this.convertStringNullToEmptyValues(obj[key]);
      }
    });
    return obj;
  }

  sendState() {
    return this.state;
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleSearch(e) {
    const { phone } = this.state;
    if (_.isEmpty(phone)) return;
    this.setState({ searching: true });
    const that = this;

    // Fetch
    API.graphql(graphqlOperation(queries.listOwners, 
      { filter: { phone: { contains: phone }}, limit: 1000 }
    )).then(function( { data } ) {
      
      //debugger;
      if (data && !_.isEmpty(data.listOwners) && !_.isEmpty(data.listOwners.items)) {
        const [owner] = data.listOwners.items;
        const { firstName = "", lastName = "", name = "", displayName = "", email = "", profilePhoto = "", 
          address = "", city = "", province = "", website = "", ownerType: sellerType } = owner;
        const isIndividual = sellerType ? `${sellerType}` === 'Individual' : false;
        that.setState({
            sellerDistrict: province, sellerAddress: address, firstname: firstName, lastname: lastName,
            organizationname: displayName, email, profilePhoto, website, sellerCity: city,
            ownerType: `${sellerType}`,
            firstnameState: isIndividual ? _.isEmpty(firstName) ? "error" : "success" : "",
            lastnameState: isIndividual ? _.isEmpty(lastName) ? "error" : "success" : "",
            organizationnameState: !isIndividual ? _.isEmpty(displayName) ? "error" : "success" : "",
            emailState: _.isEmpty(email) ? "error" : "success",
            phoneState: _.isEmpty(phone) ? "error" : "success",
            sellerAddressState: _.isEmpty(address) ? "error" : "success",
            sellerCityState: _.isEmpty(city) ? "error" : "success",
            sellerDistrictState: _.isEmpty(province) ? "error" : "success",
            searching: false
        });
      } else {
        that.setState({ searching: false });
      }
    }).catch(function(error) {
      //console.log("Error fetching customer by phone: %s", JSON.stringify(error));
      that.setState({ searching: false })
    });
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains only numbers
  verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  verifyPhones = value => {
    let phones = value.split(",").map(item=>item.trim());
    return phones.every(item => this.verifyNumber(item));
  };

  // function that verifies if value contains only numbers
  verifyLatLong = value => {
    var numberRex = new RegExp(/^([-+]?)([\d]{1,2})(((\.)(\d+)))/g);
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  // verifies if value is a valid URL
  verifyUrl = value => {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  };

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "phone":
        if (this.verifyPhones(event.target.value) && this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "latlong":
        if (this.verifyLatLong(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "url":
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    // switch (stateName) {
    //   case "district":
    //       this.setState({ districtState: _.isEmpty(this.state.district) });
    //     break;
    //   case "country":
    //       this.setState({ countryState: _.isEmpty(this.state.country) });
    //     break;
    //   default:
    //     break;
    // }
    this.setState({ [stateName]: event.target.value });
  }

  isValidated() {
    //return true;
    // this.state.streetNumberState === "success" &&
    const isIndividual = this.state.ownerType === 'Individual';
    const hasName = isIndividual ? this.state.firstnameState === "success" &&
    this.state.lastnameState === "success" : this.state.organizationnameState === "success";
    if (hasName && this.state.phoneState == "success" && this.state.cityState === "success"
    ) {
      if (this.state.isForiegn && !this.state.countryState === "success") return false;
      return true;
    } else {
      if (isIndividual && this.state.firstnameState !== "success") {
        this.setState({ firstnameState: "error" });
      }
      if (isIndividual && this.state.lastnameState !== "success") {
        this.setState({ lastnameState: "error" });
      }
      if (!isIndividual && this.state.organizationnameState !== "success") {
        this.setState({ organizationnameState: "error" });
      }
      if (this.state.emailState !== "success" && this.state.phoneState !== "success") {
        this.setState({ emailState: "error", phoneState: "error" });
      }
      if (this.state.streetNameState !== "success") {
        this.setState({ streetNameState: "error" });
      }
      if (this.state.streetNumberState !== "success") {
        this.setState({ streetNumberState: "error" });
      }
      if (this.state.cityState !== "success") {
        this.setState({ cityState: "error" });
      }
      if (this.state.districtState !== "success") {
        this.setState({ districtState: "error" });
      }
    }
    return false;
  }

  // 
  render() {
    const { classes } = this.props;
    const { isForiegn } = this.state;
    return (
      <React.Fragment>
        <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>
            Seller Information
          </h4>
        </GridItem>
        <GridItem xs={12} sm={4}>
          <PictureUpload profile={this.state.profilePhoto} />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <CustomInput
            success={this.state.firstnameState === "success"}
            error={this.state.firstnameState === "error"}
            labelText={
              <span>
                First Name <small>(required)</small>
              </span>
            }
            id="firstname"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "firstname", "length", 3),
              value: this.state.firstname,
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Face className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <CustomInput
            success={this.state.lastnameState === "success"}
            error={this.state.lastnameState === "error"}
            labelText={
              <span>
                Last Name <small>(required)</small>
              </span>
            }
            id="lastname"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "lastname", "length", 3),
              value: this.state.lastname,
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <RecordVoiceOver className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={4} lg={4}>
          <CustomInput
            success={this.state.organizationname === "success"}
            error={this.state.organizationnameState === "error"}
            labelText="Organization Name"
            id="organization-name"
            name="organizationname"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "organizationname", "length", 1),
              value: this.state.organizationname,
              placeholder: "e.g National Housing Corporation",
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Business className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="owner-type" className={classes.selectLabel}>
              Owner Type
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={this.state.ownerType}
              onChange={this.handleSimple}
              inputProps={{
                name: "ownerType",
                id: "owner-type",
                value: this.state.ownerType
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Owner Type
              </MenuItem>
              {OwnerType.map((type, idx) => {
                return (
                  <MenuItem
                    key={idx+1}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value={type}
                >
                  {type}
                </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>

        <GridItem xs={12} sm={6} md={3} lg={3}>
          <CustomInput
            success={this.state.emailState === "success"}
            error={this.state.emailState === "error"}
            labelText={
              <span>
                Email
              </span>
            }
            id="email"
            name="email"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "email", "email"),
              value: this.state.email,
              placeholder: "e.g abc@example.com",
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Email className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={4} lg={4}>
          <div className={classes.phoneSearch}>
            { this.state.searching && <CircularProgress /> }
            <CustomInput
              success={this.state.phoneState === "success"}
              error={this.state.phoneState === "error"}
              labelText={
                <span>
                  Phone <small>(required)</small>
                </span>
              }
              id="phone"
              name="phone"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => this.change(event, "phone", "phone", 8),
                value: this.state.phone,
                placeholder: "e.g 256772500034, 256772123456",
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Button
                      color="info"
                      onClick={this.handleSearch}
                      size="sm"
                    > <Search /> Search
                    </Button>
                  </InputAdornment>
                )
              }}
            />
          </div>
          
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <CustomInput
            success={this.state.websiteState === "success"}
            error={this.state.websiteState === "error"}
            labelText={
              <span>
                Website
              </span>
            }
            id="website"
            name="website"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "website", "url"),
              value: this.state.website,
              placeholder: "e.g https://www.example.com"
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={6} md={3} lg={3}>
          <CustomInput
            labelText="Seller Address"
            id="seller-address"
            name="sellerAddress"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "sellerAddress", "length", 1),
              value: this.state.sellerAddress,
              placeholder: "e.g 12 Hannington Drive"
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={4} lg={4}>
          {/* <CustomInput
            labelText="Seller City/Town"
            id="seller-city"
            name="sellerCity"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "sellerCity", "length", 1),
              value: this.state.sellerCity,
              placeholder: "e.g Ntinda",
            }}
          /> */}

          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="seller-city" className={classes.selectLabel}>
              Seller City/Town
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={this.state.sellerCity}
              onChange={event => this.change(event, "sellerCity", "length", 1)}
              inputProps={{
                name: "sellerCity",
                id: "seller-city",
                value: this.state.sellerCity
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                City/Town
              </MenuItem>
              {
                _.sortBy(DISTRICTS[_.isEmpty(this.state.sellerDistrict) ? "Kampala": this.state.sellerDistrict], 'town').map((item, idx) => {
                  return (
                    <MenuItem
                      key={idx}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={item.town}
                    >
                      {item.town}
                    </MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} lg={3}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Choose District
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={this.state.sellerDistrict}
              onChange={this.handleSimple}
              inputProps={{
                name: "sellerDistrict",
                id: "seller-district",
                value: this.state.sellerDistrict
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                District
              </MenuItem>
              {
                Object.keys(DISTRICTS).map((item, idx) => {
                  return (
                    <MenuItem
                      key={idx}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
        </GridItem>
      </GridContainer>
      <br />
      <GridContainer justify="center">
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>Vehicle Location?</h4>
          <div className={classes.centered}>
            <FormControlLabel
              control={
                <Switch
                  checked={isForiegn}
                  onChange={this.handleChange('isForiegn')}
                  value="isForiegn"
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Is Foriegn Location?"
            />
          </div>
          
        </GridItem>
        <GridItem xs={12} sm={7}>
          <CustomInput
            success={this.state.streetNameState === "success"}
            error={this.state.streetNameState === "error"}
            labelText="Street Name"
            id="streetname"
            name="streetName"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "streetName", "length", 1),
              value: this.state.streetName,
              placeholder: "e.g Hannington Road",
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={3}>
          <CustomInput
            success={this.state.streetNumberState === "success"}
            error={this.state.streetNumberState === "error"}
            labelText="Street No."
            id="streetno"
            name="streetNumber"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "streetNumber", "length", 1),
              value: this.state.streetNumber,
              placeholder: "e.g Plot 12"
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={4}>
          {isForiegn && (<CustomInput
            success={this.state.cityState === "success"}
            error={this.state.cityState === "error"}
            labelText="City/Town"
            id="city"
            name="city"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "city", "length", 1),
              value: this.state.city,
              placeholder: "e.g Kampala"
            }}
          />)}

          {!isForiegn && (<FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="city" className={classes.selectLabel} error={this.cityState}>
              City/Town
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={this.state.city}
              onChange={event => this.change(event, "city", "length", 1)}
              inputProps={{
                name: "city",
                id: "city",
                value: this.state.city
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                City/Town
              </MenuItem>
              {
                _.sortBy(DISTRICTS[_.isEmpty(this.state.district) ? "Kampala": this.state.district], 'town').map((item, idx) => {
                  return (
                    <MenuItem
                      key={idx}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={item.town}
                    >
                      {item.town}
                    </MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>)}
        </GridItem>

        <GridItem xs={12} sm={4}>
        {isForiegn && (<CustomInput
            success={this.state.districtState === "success"}
            error={this.state.districtState === "error"}
            labelText="State/Province/District"
            id="district"
            name="seller-district"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "district", "length", 1),
              value: this.state.district,
              placeholder: "e.g Pretoria"
            }}
          />)}

          {!isForiegn && (<FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel} error={this.districtState}>
              Choose District
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={this.state.district}
              onChange={event => this.change(event, "district", "length", 1)}
              inputProps={{
                name: "district",
                id: "select-district",
                value: this.state.district
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                District
              </MenuItem>
              {
                Object.keys(DISTRICTS).map((item, idx) => {
                  return (
                    <MenuItem
                      key={idx}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  );
                })
              }
              </Select>
          </FormControl>)}
        </GridItem>
        {isForiegn && (<GridItem xs={12} sm={4}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="select-country" className={classes.selectLabel} error={!this.countryState}>
              Choose Country
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={this.state.country}
              onChange={event => this.change(event, "country", "length", 1)}
              inputProps={{
                name: "country",
                id: "select-country",
                value: this.state.country
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Country
              </MenuItem>
              {
                COUNTRIES.map((item, idx) => {
                  return (
                    <MenuItem
                      key={idx}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={item.name}
                    >
                      {item.name}
                    </MenuItem>
                  );
                })
              }
              </Select>
          </FormControl>
        </GridItem>)}
        <GridItem xs={12} sm={6} md={5} lg={5}>
          <CustomInput
            success={this.state.latitudeState === "success"}
            error={this.state.latitudeState === "error"}
            labelText="Latitude."
            id="property-latitude"
            name="latitude"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "latitude", "latlong"),
              value: this.state.latitude,
              placeholder: "e.g 12.00334"
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={5} lg={5}>
          <CustomInput
            success={this.state.longitudeState === "success"}
            error={this.state.longitudeState === "error"}
            labelText="Longtitude."
            id="property-longitude"
            name="longitude"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "longitude", "latlong"),
              value: this.state.longitude,
              placeholder: "e.g -137.99900"
            }}
          />
        </GridItem>
      </GridContainer>
      </React.Fragment>
      
    );
  }
}

Step3.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Step3);
