import React from "react";
import { Link, Redirect } from "react-router-dom";
import _ from "underscore";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

// Auth Login
import { Auth } from 'aws-amplify';

// Manzia
import AuthDialog from "bulooka/Components/AuthDialog.js";
import AdminLayout from "layouts/Admin.js";

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  // login form
  const [loginEmail, setloginEmail] = React.useState("");
  const [loginEmailState, setloginEmailState] = React.useState("");
  const [loginPassword, setloginPassword] = React.useState("");
  const [loginPasswordState, setloginPasswordState] = React.useState("");
  const [challenge, setChallenge] = React.useState("");
  const [authUser, setAuthUser] = React.useState(null);
  const [authError, setAuthError] = React.useState("");
  const [canLogin, setCanLogin] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);

  setTimeout(function() {
    setCardAnimation("");
  }, 700);

  const classes = useStyles();

  // function that returns true if value is email, false otherwise
  const verifyEmail = value => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };

  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  const loginClick = () => {
    if (loginEmailState === "") {
      setloginEmailState("error");
    }
    if (loginPasswordState === "") {
      setloginPasswordState("error");
    }
  };

  function handleDialog(close) {
    setShowDialog(false);
  }



  async function handleAuth(credential) {
    //console.log("Credential: %j for Authenticated User: %j", credential, authUser);

    const { newPassword, smsCode } = credential;
    try {
        if (challenge === 'SMS_MFA' || challenge === 'SOFTWARE_TOKEN_MFA') {
            // You need to get the code from the UI inputs
            // and then trigger the following function with a button click
            //const code = getCodeFromUserInput();
            // If MFA is enabled, sign-in should be confirmed with the confirmation code
            const loggedUser = await Auth.confirmSignIn(
                authUser,   // Return object from Auth.signIn()
                smsCode,   // Confirmation code  
                challenge // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
            );
            //console.log("Logged user - SMS_MFA: %j", loggedUser);
            setChallenge("");
            setAuthUser(loggedUser.challengeParam.userAttributes);
            setCanLogin(true);

        } else if (challenge === 'NEW_PASSWORD_REQUIRED') {
            //const {requiredAttributes, userAttributes } = authUser.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
            // You need to get the new password and required attributes from the UI inputs
            // and then trigger the following function with a button click
            // For example, the email and phone_number are required attributes
            // userAttributes: {email_verified: "true", email: "roymanzi@gmail.com"}
            //const {username, email, phone_number} = getInfoFromUserInput();
            const loggedUser = await Auth.completeNewPassword(
                authUser,              // the Cognito User Object
                newPassword,       // the new password
                // OPTIONAL, the required attributes
                {
                    email: loginEmail,
                }
            );
            //console.log("Logged user - NEW PASSWORD: %j", loggedUser);
            setChallenge("");
            setAuthUser(loggedUser.challengeParam.userAttributes);
            setCanLogin(true);
        } else if (challenge === 'MFA_SETUP') {
            // This happens when the MFA method is TOTP
            // The user needs to setup the TOTP before using it
            // More info please check the Enabling MFA part
            //Auth.setupTOTP(user);
            //setChallenge("");
            //setAuthError("Login Error. Please contact admin.");
        } else {
            // The user directly signs in
            //console.log(user);
            //setChallenge("");
            //setAuthError("Login Error. Please contact admin.");
        }
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
        setAuthError("Login Error. Please contact admin.");
        setChallenge("");
      } else if (err.code === 'PasswordResetRequiredException') {
          // The error happens when the password is reset in the Cognito console
          // In this case you need to call forgotPassword to reset the password
          // Please check the Forgot Password part.
      } else if (err.code === 'NotAuthorizedException') {
          // The error happens when the incorrect password is provided
          setAuthError("Login Error. Invalid username/password.");
          setChallenge("");
      } else if (err.code === 'UserNotFoundException') {
          // The error happens when the supplied username/email does not exist in the Cognito user pool
          setAuthError("Login Error. Invalid username/password.");
          setChallenge("");
      } else {
          //console.log(err);
          setAuthError("Login Error. Please contact admin.");
          setChallenge("");
      }
      //console.log("Challenge Error: %j", err);
      
    }
  }

  async function handleLogin() {
    try {
        const user = await Auth.signIn(loginEmail, loginPassword);
        //console.log("Auth user: %j", user);
        if (_.isEmpty(user.challengeName)) {
          setAuthUser(user.attributes);
          //setAuthUser(user);
          setShowDialog(false);
          setCanLogin(true);
        } else {
          setChallenge(user.challengeName);
          setShowDialog(user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA' || user.challengeName === 'NEW_PASSWORD_REQUIRED');
          //setAuthUser(user.challengeParam.userAttributes);
          setAuthUser(user);
        }

    } catch (err) {
        if (err.code === 'UserNotConfirmedException') {
            // The error happens if the user didn't finish the confirmation step when signing up
            // In this case you need to resend the code and confirm the user
            // About how to resend the code and confirm the user, please check the signUp part
            //setAuthError("Invalid User/Password combination.");
          
        } else if (err.code === 'PasswordResetRequiredException') {
            // The error happens when the password is reset in the Cognito console
            // In this case you need to call forgotPassword to reset the password
            // Please check the Forgot Password part.
            //setAuthError("Invalid User/Password combination.");
        } else if (err.code === 'NotAuthorizedException') {
            // The error happens when the incorrect password is provided
            //setAuthError("Invalid User/Password combination.");
        } else if (err.code === 'UserNotFoundException') {
            // The error happens when the supplied username/email does not exist in the Cognito user pool
            //setAuthError("Invalid User/Password combination.");
        } else {
            //setAuthError("Invalid User/Password combination.");
        }
        //console.log("Login error: %j", err);
        setAuthError("Invalid User/Password combination.");
    }
  }
  /*
    {
      canLogin ?
      <Link to="/admin/properties">
        <Button color="info" round size="lg">
          Login
        </Button>
      </Link> :
      
    }

  */

  return (
    <div className={classes.container}>
      {canLogin && _.isEmpty(challenge) ? (<Redirect
        to={{
          pathname: "/admin/properties",
          state: { loggedUser: authUser }
        }}
      />) : 
          (<GridContainer justify="center">
          <GridItem xs={12} sm={6} md={6}>
            <form>
              <Card login className={classes[cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="info"
                >
                  <h4 className={classes.cardTitle}><strong>Welcome to Bulooka</strong></h4>
                  <h5 className={classes.cardTitle}>Uganda's Best Real Estate and Auto Deals</h5>
                </CardHeader>
                <CardBody>
                  <AuthDialog 
                    challenge={challenge}
                    authHandler={handleAuth}
                    showDialog={showDialog}
                    dialogHandler={handleDialog}
                  />
                  <br />
                  {!_.isEmpty(authError) && (<h6 style={{ color: 'red' }}>{authError}</h6>)}
                  <CustomInput
                    success={loginEmailState === "success"}
                    error={loginEmailState === "error"}
                    labelText="Email..."
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      onChange: event => {
                        if (verifyEmail(event.target.value)) {
                          setloginEmailState("success");
                        } else {
                          setloginEmailState("error");
                        }
                        if (!_.isEmpty(authError)) {
                          setAuthError("");
                        }
                        setloginEmail(event.target.value);
                      },
                      type: "email"
                    }}
                  />
                  <CustomInput
                    success={loginPasswordState === "success"}
                    error={loginPasswordState === "error"}
                    labelText="Password"
                    id="password"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Icon className={classes.inputAdornmentIcon}>
                            lock_outline
                          </Icon>
                        </InputAdornment>
                      ),
                      onChange: event => {
                        if (verifyLength(event.target.value, 1)) {
                          setloginPasswordState("success");
                        } else {
                          setloginPasswordState("error");
                        }
                        if (!_.isEmpty(authError)) {
                          setAuthError("");
                        }
                        setloginPassword(event.target.value);
                      },
                      type: "password",
                      autoComplete: "off"
                    }}
                  />
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                  <Button color="info" round size="lg" onClick={handleLogin}>
                      Login
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>)
      }
      
    </div>
  );
}
