import React from "react";
import { Link, Redirect } from "react-router-dom";
import _ from "underscore";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
//import CircularProgress from '@material-ui/core/CircularProgress';

// material-ui icons
import Add from "@material-ui/icons/Add";
import Refresh from "@material-ui/icons/RefreshOutlined";
import Search from "@material-ui/icons/Search";

// AWS
// GraphQL
//import { API, graphqlOperation } from "aws-amplify";
import { graphqlOperation }  from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as queries from 'graphql/queries';


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

// Bulooka
import Grid from "../Components/CustomGridItem.js";
import Advertiser from "bulooka/Advertisers/Advertiser";

// Manzia
import { ADVERTISER_TYPES } from "variables/general";

const styles = {
  ...tableStyles,
  ...formStyles,
  centered: {
    textAlign: 'center'
  },
  idSearch: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: '10px',
    }
  },
};

const useStyles = makeStyles(styles);

//const ALPHANUMERIC = new RegExp(/^[a-z0-9]+$/i);

export default function AdvertiserList(props) {
  const { signedOut, user } = props;
  const [advertisers, setAdvertisers] = React.useState([]);
  const [advertiserType, setAdvertiserType] = React.useState("All");

  const [pageToken, setPageToken] = React.useState("");
  const [hasNextPage, setHasNextPage] = React.useState(false);
  const [shortId, setShortId] = React.useState("");
  const [startSearch, setStartSearch] = React.useState(false);

  const classes = useStyles();

  const handleAdvertiserType = event => {
    setAdvertiserType(event.target.value);
  };

  const handleShortId = event => {
    setShortId(event.target.value);
    setStartSearch(false);
  };

  // function that verifies if value contains only numbers
  const verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  
  function handleSearch() {
    setStartSearch(true);
  }

  function fetchMore(items) {
  
    //if (_.isEmpty(pageToken)) return;

    // Remove potential duplicates
    // .filter(ad => ad.id !== 'gid://Bulooka/Advertiser/1593797019127')
    const merged = [ ...advertisers, ...items ];
    const mergeGroup = _.groupBy(merged, 'id');
    const added = Object.keys(mergeGroup).map((key) => {
      return mergeGroup[key][0];
    });
    //setProperties(added);
    return added;
  };

  /*
    enum VehicleType {
	SUV
	Crossover
	Sedan
	Truck
	Hatchback
	Convertible
	Luxury
	Coupe
	Electric
	Hybrid
	Van
	Minivan
	Wagon
	Bus
}
  */
  function renderFilters() {
    return (
      <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
      <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                  <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="advertiserType"
                        className={classes.selectLabel}
                      >
                        Advertiser Type
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={advertiserType}
                        onChange={handleAdvertiserType}
                        inputProps={{
                          name: "advertiserType",
                          id: "advertiserType"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          Advertiser Type
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value="All"
                        >
                          All
                        </MenuItem>
                        {ADVERTISER_TYPES.map(type => {
                            const { name, value } = type;
                            return (
                              <MenuItem
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected
                                  }}
                                  value={value}
                                  key={value}
                                >
                                  {name}
                                </MenuItem>
                            );
                        })}
                      </Select>
                    </FormControl>
                </GridItem>
                
                <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                      labelText={
                        <span>
                          Advertiser ID
                        </span>
                      }
                      id="shortId"
                      name="shortId"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        onChange: handleShortId,
                        value: shortId,
                        placeholder: "e.g 160890045678",
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            className={classes.inputAdornment}
                          >
                            <Button
                              color="info"
                              onClick={handleSearch}
                              size="sm"
                              disabled={!(verifyNumber(shortId) && shortId.length > 7)}
                            > <Search /> Search
                            </Button>
                          </InputAdornment>
                        )
                      }}
                    />
                
                </GridItem>
              </GridContainer>
            </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    );  
  }

  function renderShortID(advertiserId) {
    
    return (
      <Connect query={graphqlOperation(queries.getAdvertiser, { id: `gid://Bulooka/Advertiser/${advertiserId}` })}>
          {({ data: { getAdvertiser }, loading, errors }) => {
          if (!_.isEmpty(errors)) return (<h3>{`Errors: ${JSON.stringify(errors)}`}</h3>);
          if (loading) return (<h3>Loading...</h3>);
          
          if (_.isEmpty(getAdvertiser)) {
            return (<h3>{`No Advertiser found matching ID: ${advertiserId}`}</h3>)
          } else {
              return (
                <React.Fragment>
                    <GridContainer spacing={4}>
                      <Grid xs={12} sm={6} md={4}>
                        <Advertiser advertiser={getAdvertiser} />
                      </Grid>
                    </GridContainer>
                    <br />
                </React.Fragment>
                
              );
          }
              
          }}
      </Connect>
    );
  }

  if (signedOut) return (<Redirect to="/" /> );

  // Query Variables
  let variables = { limit: 100 };

  // Next Page
  if (!_.isEmpty(pageToken) && hasNextPage) {
    Object.assign(variables, { nextToken: pageToken });
  }

  /*
    <GridItem xs={12} sm={6} md={4} key={key}>
                          <Property property={property} />
                        </GridItem>
      makeModel {
        id
        year
        make
        logo
        description
        options
        model
        trim
        series
      }
  */
  return (
    <React.Fragment>
      <div className={classes.center}>
        <Link to="/admin/adcreate">
          <Button color="info" size="lg">
            <Add /> Create Advertiser
          </Button>
        </Link>
      </div>
      {renderFilters()}

      {startSearch ? renderShortID(shortId) :
        (
          <Connect query={graphqlOperation(queries.listAdvertisers, variables)}>
          {({ data: { listAdvertisers }, loading, errors }) => {
          if (!_.isEmpty(errors)) return (<h3>{`Errors: ${JSON.stringify(errors)}`}</h3>);
          if (loading || !listAdvertisers) return (<h3>Loading...</h3>);

          const { items, nextToken } = listAdvertisers;
          const merged = fetchMore(items).filter((advertiser) => {
            const { options } = advertiser;
            if (_.isEmpty(advertiserType) || advertiserType === 'All') return true;
            return options ? options.indexOf(advertiserType) > -1 : false;
          });
          

          if (_.isEmpty(items)) {
            return (<h3>No Advertisers found matching selected criteria.</h3>)
          } else {
              return (
                <React.Fragment>
                    <GridContainer spacing={4}>
                      {merged.map((advertiser, key) => {
                          return (
                            <Grid xs={12} sm={6} md={4} key={key}>
                              <Advertiser advertiser={advertiser} />
                            </Grid>
                          );
                        })}
                    </GridContainer>
                    <br />
                    <br />
                    { (items.length % 100 === 0) && 
                      <div className={classes.centered}>
                        <Button color="info" size="sm" onClick={(e) => {
                              setPageToken(nextToken);
                              setHasNextPage(items.length % 100 === 0);
                              setAdvertisers([...advertisers, ...items]);
                          }}>
                          <Refresh /> Load More
                        </Button>
                      </div>
                    }
                </React.Fragment>
                
              );
          }
              
          }}
      </Connect>
        )
      }
      
      
    </React.Fragment>
    
  );
}
