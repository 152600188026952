import React from 'react';
import { Link } from "react-router-dom";
import _ from "underscore";


import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
//import Typography from '@material-ui/core/Typography';
//import { red } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

// Materials
import SweetAlert from "react-bootstrap-sweetalert";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

// AWS
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from 'graphql/mutations';

// Icons
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';

// Utils
const moment = require("moment");

const useStyles = makeStyles(theme => ({
  ...buttonStyle,
  card: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: "#7E8BF5",
  },
  title: {
    whiteSpace: 'nowrap', 
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  description: {
    whiteSpace: 'wrap', 
    overflow: 'hidden',
    height: '40px',
    textOverflow: 'ellipsis'
  },
}));

const IDButton = withStyles({
  root: {
    textTransform: 'none'
  },
})(Button);

/*
type Advertiser @model(subscriptions: null) {
	id: ID!
	name: String!
	logo: String
	options: String
	adverts: [Advert!]! @connection
	createdAt: String!
	updatedAt: String!
	address: String
	city: String
	province: String
	country: String
	phone: String
	email: String
	website: String
	owner: Owner
}

*/

export default function Advertiser(props) {
  const { advertiser } = props;
  const classes = useStyles();
  const [uploadMessage, setUploadMessage] = React.useState("");
  const [spinner, setSpinner] = React.useState(false);

  function getAdvertiserId() {
    if (_.isEmpty(advertiser)) return "";
    const { id } = advertiser;
    return id.substring(id.lastIndexOf('/')+1);
  }
  
  async function deleteProperty() {
    try {
      const { data } = await API.graphql(graphqlOperation(mutations.deleteAdvertiser, { input: { id: advertiser.id } } ));
      if (data && !_.isEmpty(data.deleteAdvertiser)) {
        const { adverts } = advertiser;
        if (adverts && _.isEmpty(adverts.items)) {
          for (let i=0; i<adverts.items.length; i++) {
            const { id: advertId } = adverts.items[0];
            if (_.isEmpty(advertId)) continue;
              await API.graphql(graphqlOperation(mutations.deleteAdvert, { input: { id: advertId } } ));
          }
        }
        setUploadMessage(`Success. Deleted advertiser: ${advertiser.name}`);
        setSpinner(false);
        window.location.reload();
      }
    } catch (error) {
      setUploadMessage("Error. Failed to delete advertiser.");
      setSpinner(false);
      console.log("Delete advertiser error: " + JSON.stringify(error));
    }
  }
  
  function getAlert() {
    //console.log("Upload message: " + uploadMessage);
    return (
      <SweetAlert
        show={!_.isEmpty(uploadMessage)}
        type={uploadMessage.startsWith("Error") ? "error" : uploadMessage.startsWith("Confirm") ? "warning" : "success"}
        showCancel={uploadMessage.startsWith("Confirm")}
        style={{ display: "block", marginTop: "-100px" }}
        title={uploadMessage.startsWith("Error") ? "Error" : uploadMessage.startsWith("Confirm") ? "Confirm Delete" : "Success"}
        onConfirm={() => {
          if (uploadMessage.startsWith("Confirm")) {
            setSpinner(true);
            setUploadMessage("");
            deleteProperty();
          } else if (uploadMessage.startsWith("Error")) {
            setSpinner(false);
            setUploadMessage("");
          } else {
            setSpinner(false);
            setUploadMessage("");
          }
        }}
        onCancel={() => setUploadMessage("")}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
      >
        {uploadMessage}
      </SweetAlert>
    );
  }

  function handleDelete(e) {
    setUploadMessage(`Confirm. Are you sure you want to delete this advertiser?`)
  }
  // { `Updated: ${moment(property.updatedAt).format("MMM Do YYYY")}`}
  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {`${advertiser.name}`.charAt(0)}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={advertiser.name}
        subheader={advertiser.phone && advertiser.phone !== 'null' ? `${advertiser.phone}` : "N/A"}
        titleTypographyProps={{ noWrap: true }}
      />
      <CardMedia
        className={classes.media}
        image={advertiser.logo ? advertiser.logo : "https://bulooka-images.s3.amazonaws.com/sample/property-placeholder.png"}
        title={advertiser.name}
      />
      <CardContent>
        {getAlert()}
        <h6>{advertiser.city ? `${advertiser.city}` : 'Kampala'}</h6>
        {/* <Typography variant="body2" color="textSecondary" component="p" noWrap>
          {property.description}
        </Typography> */}
      </CardContent>
      <CardActions disableSpacing>
        {spinner && <CircularProgress /> }
        <Link to={{
            pathname: "/admin/adcreate",
            state: { advertiser }
					}}>
            <IconButton aria-label="edit advertiser">
                <EditIcon />
            </IconButton>
        </Link>
        <IconButton aria-label="delete advertiser" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
        <IDButton disabled>
          {`ID: ${getAdvertiserId()}`}
        </IDButton>
      </CardActions>
    </Card>
  );
}
