import React from "react";
import { Link, Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import _ from "underscore";

// @material-ui/icons
import Place from "@material-ui/icons/Place";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import People from "@material-ui/icons/People";
import Add from "@material-ui/icons/Add";
import Search from "@material-ui/icons/Search";
import Save from "@material-ui/icons/Save";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from '@material-ui/core/CircularProgress';
import FormLabel from "@material-ui/core/FormLabel";
//import FormGroup from '@material-ui/core/FormGroup';
//import Checkbox from '@material-ui/core/Checkbox';

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import CardIcon from "components/Card/CardIcon";
import ImageUpload from 'components/CustomUpload/ImageUpload';
import Button from "components/CustomButtons/Button.js";

import SweetAlert from "react-bootstrap-sweetalert";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

// GraphQL
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from 'graphql/queries';
import * as mutations from 'graphql/mutations';


// Manzia
import { DISTRICTS } from "variables/locations.js";
import AdvertsList from "bulooka/Advertisers/AdvertsList";
import CreateAdvert from "bulooka/Advertisers/CreateAdvert";
import { ADVERTISER_TYPES } from "variables/general";
import AppConstants from "variables/constants.js";


// Styles
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.js";

// Utils
const moment = require('moment');
const uuidv1 = require('uuid/v1');

// AWS
const AWS = require('aws-sdk');

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  phoneSearch: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: '10px',
    }
  },
  saveBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > *': {
      marginRight: '10px',
    }
  },
  saveCenterBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      marginRight: '10px',
    }
  },
  centered: {
    textAlign: 'center'
  },
  textRight: {
    textAlign: 'right'
  },
  ...styles,
  ...customSelectStyle,
  ...wizardStyle,
  ...buttonStyle
};

/*
type Advertiser @model(subscriptions: null) {
	id: ID!
	name: String!
	logo: String
	options: String
	adverts: [Advert!]! @connection
	createdAt: String!
	updatedAt: String!
	address: String
	city: String
	province: String
	country: String
	phone: String
	email: String
	website: String
	owner: Owner
}
*/

// const ADVERTISER_TYPES = [ 
//   { name: "Property Manager", value: "propertyManager"}, 
//   { name: "Interior & Exterior Decor", value: "propertyDecor" }, 
//   { name: "Hardware Dealer", value: "propertyHardware" }, 
//   { name: "Vehicle Dealer", value: "VehicleDealer"}, 
//   { name: "Hotel Manager", value: "HotelManager" } 
// ];

class CreateAdvertiser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      advertiserName: "",
      advertiserNameState: "",
      logo: "https://bulooka-images.s3.amazonaws.com/sample/property-placeholder.png",
      logoState: "",
      logoFile: null,
      logoS3key: "",
      address: "",
      addressState: "",
      city: "",
      cityState: "",
      district: "",
      districtState: "",
      phone: "",
      phoneState: "",
      email: "",
      emailState: "",
      website: "",
      websiteState: "",
      ownerId: "",
      selectedAdvertiserType: null,
      searching: false,
      alert: null,
      saving: false
    };

    // AWS S3
    this.s3client = new AWS.S3({apiVersion: '2006-03-01', region: "us-east-1",
      accessKeyId: AppConstants["aws_s3_accessKey"], secretAccessKey: AppConstants["aws_s3_secretKey"]
    });
  }
  

  componentDidMount() {
    const { location: { state} } = this.props;
    if (_.isEmpty(state) || _.isEmpty(state.advertiser)) return;

    const convertedAdvertiser = this.convertStringNullToEmptyValues(state.advertiser);
    //console.log(convertedAdvertiser);
    const { name = "", logo = "", options = {}, address = "", city = "", province: district = "", phone = "", email = "", 
      website = "" } = convertedAdvertiser;

    const optJson = JSON.parse(options);

    
    this.setState({
      advertiserName: _.isEmpty(name) ? "" : name, 
      logo: _.isEmpty(logo) ? "" : logo, 
      address: _.isEmpty(address) ? "" : address, 
      city: _.isEmpty(city) ? "" : city, 
      district: _.isEmpty(district) ? "" : district, 
      phone: _.isEmpty(phone) ? "" : phone, 
      email: _.isEmpty(email) ? "" : email, 
      website: _.isEmpty(website) ? "" : website,
      selectedAdvertiserType: _.isEmpty(optJson) ? "propertyManager" : optJson.type,
      ownerId: _.isEmpty(optJson) || _.isEmpty(optJson.ownerId) ? "" : optJson.ownerId,
      advertiserNameState: _.isEmpty(name) ? "error" : "success",
      logoState: _.isEmpty(logo) ? "error" : "success",
      phoneState: _.isEmpty(phone) ? "error" : "success",
      cityState: _.isEmpty(city) ? "error" : "success"
    });
  }

  convertStringNullToEmptyValues(obj) {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "string" && obj[key] === "null") {
        obj[key] = "";
      } else if (typeof obj[key] === "object" && !_.isEmpty(obj[key])) {
        this.convertStringNullToEmptyValues(obj[key]);
      }
    });
    return obj;
  }

  sendState() {
    return this.state;
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains only numbers
  verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  // verifies if value is a valid URL
  verifyUrl = value => {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  };

  verifyPhones = value => {
    let phones = value.split(",").map(item=>item.trim());
    return phones.every(item => this.verifyNumber(item));
  };

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChecked = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleImage = (file) => {
    if (file) {
      // const reader = new FileReader();

      // reader.onloadend = () => {
      //   //setImagePreviewUrl(reader.result);
      //   this.setState({ logo: reader.result, logoState: "success" });
      // };
      // reader.readAsDataURL(file);
      const { link, s3key } = this.createImageLinks(file);
      this.setState({ logoFile: file, logo: link, logoState: "success", logoS3key: s3key });
    }
  }

  handleSearch = (e) => {
    const { phone } = this.state;
    if (_.isEmpty(phone)) return;
    this.setState({ searching: true });
    const that = this;

    // Fetch
    API.graphql(graphqlOperation(queries.getOwnerByPhone, { phone }
    )).then(function( { data } ) {
      //debugger;
      if (data && !_.isEmpty(data.getOwnerByPhone) && !_.isEmpty(data.getOwnerByPhone.items)) {
        const [owner] = data.getOwnerByPhone.items;
        const { id, displayName = "", email = "", profilePhoto = "", 
          address = "", city = "", province = "", website = "" } = owner;
        
        that.setState({
            district: province, address, advertiserName: displayName, 
            email, website, city,
            advertiserNameState: _.isEmpty(displayName) ? "error" : "success",
            emailState: _.isEmpty(email) ? "error" : "success",
            phoneState: _.isEmpty(phone) ? "error" : "success",
            addressState: _.isEmpty(address) ? "error" : "success",
            cityState: _.isEmpty(city) ? "error" : "success",
            districtState: _.isEmpty(province) ? "error" : "success",
            searching: false,
            ownerId: id.substring(id.lastIndexOf('/')+1)
        });
      } else {
        that.setState({ searching: false });
      }
    }).catch(function(error) {
      //console.log("Error fetching customer by phone: %s", JSON.stringify(error));
      that.setState({ searching: false })
    });
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "phone":
        if (this.verifyPhones(event.target.value) && this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "url":
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }


  isValidated() {
    //return true;
    if (
      this.state.advertiserNameState === "success" &&
      this.state.logoState === "success" &&
      this.state.phoneState === "success" && !_.isEmpty(this.state.selectedAdvertiserType)
    ) {
      return true;
    } else {
      if (this.state.advertiserNameState !== "success") {
        this.setState({ advertiserNameState: "error" });
      }
      if (this.state.logoState !== "success") {
        this.setState({ logoState: "error" });
      }
      if (this.state.cityState !== "success") {
        this.setState({ cityState: "error" });
      }
      if (this.state.phoneState !== "success") {
        this.setState({ phoneState: "error" });
      }
      
    }
    return false;
  }

  getOptions({ options }) {
    const { selectedAdvertiserType } = this.state;
    if (_.isEmpty(options)) return JSON.stringify({ type: selectedAdvertiserType });
    
    const optJson = JSON.parse(options);
    Object.assign(optJson, { type: selectedAdvertiserType });
    return JSON.stringify(optJson);

  }

  handleSave = (e) => {
    const { location: { state} } = this.props;
    const createMode = (_.isEmpty(state) || _.isEmpty(state.advertiser));

    if (!this.isValidated()) {
      this.showAlert({ show: true, type: "error", title: "Missing required fields",
        message: "One or more required fields has invalid values"
      });
      return;
    }

    // Create/Update
    const { advertiserName, logo, address, city, district, phone, email, 
      website, logoFile, logoS3key, selectedAdvertiserType, ownerId = "" } = this.state;
    const now = moment();
    const updateVerb = createMode ? "Created" : "Updated";

    if (selectedAdvertiserType === "propertyManager" && _.isEmpty(ownerId)) {
      this.showAlert({ show: true, type: "error", title: "Unknown Property Owner",
        message: "The phone number(s) you entered do not match any known Property Owners."
      });
      return;
    }


    // Start
    this.setState({ saving: true });
    const options = selectedAdvertiserType === "propertyManager" ? { type: this.state.selectedAdvertiserType, ownerId } : { type: this.state.selectedAdvertiserType }

    API.graphql(graphqlOperation(createMode ?  mutations.createAdvertiser : mutations.updateAdvertiser, 
      { input: { 
        id: createMode ? `gid://Bulooka/Advertiser/${now.unix()}` : state.advertiser.id,
        name: advertiserName,
        logo,
        options: createMode ? JSON.stringify(options) : this.getOptions(state.advertiser),
        updatedAt: now.format(),
        createdAt: createMode ? now.format() : state.advertiser.createdAt,
        address,
        city,
        province: district,
        country: "Uganda",
        phone,
        email,
        website
    } } )).then(({ data }) => {
      if (data && !_.isEmpty(createMode ? data.createAdvertiser : data.updateAdvertiser)) {
          // Handle Image
          if (logoFile) {

            this.saveImage({link: logo, s3key: logoS3key}, logoFile).then((response) => {
              this.setState({ saving: false });
              this.showAlert({ show: true, type: "success", title: `Success: ${updateVerb} Advertiser`, message: `Advertiser was successfully ${updateVerb.toLowerCase()}.`});
            }).catch((error) => {
              console.log(error);
              this.setState({ saving: false });
              this.showAlert({ show: true, type: "error", title: `Error: ${updateVerb} Advertiser failed.`, message: `Error: Advertiser was NOT ${updateVerb.toLowerCase()}.`})
            });

          } else {
            this.setState({ saving: false });
            this.showAlert({ show: true, type: "success", title: `Success: ${updateVerb} Advertiser`, message: `Advertiser was successfully ${updateVerb.toLowerCase()}.`});
          }
      } else {
        this.setState({ saving: false });
          this.showAlert({ show: true, type: "error", title: `Error: ${updateVerb} missing data.`, message: `Error: Advertiser was NOT ${updateVerb.toLowerCase()}.`})
      }
    }).catch(() => {
        // Saved
        this.setState({ saving: false });
        this.showAlert({ show: true, type: "error", title: `Error: ${updateVerb} Advertiser failed.`, message: `Error: Advertiser was NOT ${updateVerb.toLowerCase()}.`});
    });
    
  }

  saveImage(imageKeys, imageFile) {
    //debugger;
    if (_.isEmpty(imageKeys)) { return Promise.resolve("No Advertiser Image"); }
    //const { link, s3key } = createImageLinks(imageFile, isFeatured);
    const { link, s3key } = imageKeys
    //debugger;

		// ACL: 'public-read'
		return this.s3client.upload({
			Bucket: AppConstants["aws_s3_bucket_images"], 
			Key: s3key,
      ACL: 'public-read',
      CacheControl: 'public, max-age=604800, immutable',
			Body: imageFile,
		}).promise();		
  }

  /*
  https://bulooka-images.s3.amazonaws.com/properties/house-kitende-f3e32490-0a37-11ea-847b-b9c7f55abaf5-b.jpg
  */
  createImageLinks(imageFile) {
    // Selected Image
		const lastDot = imageFile.name.lastIndexOf('.');
    const ext = imageFile.name.substring(lastDot + 1);
    const imgName = imageFile.name.substring(0, lastDot);
		const imageKey = this.createS3BucketKey(imgName, ext);
		const imageLink = `https://${AppConstants["aws_s3_bucket_images"]}.${AppConstants["aws_s3_endpoint"]}/${AppConstants["aws_s3_bucket_adimages_path"]}/${imageKey}`;
		const s3imageKey = `${AppConstants["aws_s3_bucket_original_adimages_path"]}/${imageKey}`;

		return { link: imageLink, s3key: s3imageKey };
  }

  createS3BucketKey(name, ext) {
		return name.replace(/\s/ig, "-").toLowerCase() + `-${uuidv1()}.${ext}`;
	}

  hideAlerts() {
		this.setState({ alert: null });
	}

	showAlert({ show = false, type = "success", title, message }) {
		
		this.setState({
			alert: (
			  <SweetAlert
          show={show}
          type={type}
          style={{ display: "block", marginTop: "-100px" }}
          title={title}
          onConfirm={() => this.hideAlerts()}
          onCancel={() => this.hideAlerts()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          >
          {message}
			  </SweetAlert>
			)
		});
	}

  renderAdvertiserTypes() {
      const { classes } = this.props;
      const options = ADVERTISER_TYPES.map((option, key) => {
        return (
          <div
          className={classes.checkboxAndRadio} key={key}>
            <FormControlLabel
              control={
                <Radio
                  checked={this.state.selectedAdvertiserType === option.value}
                  onChange={(event) => this.change(event, "selectedAdvertiserType")}
                  value={option.value}
                  name={option.name}
                  aria-label={option}
                  icon={
                    <FiberManualRecord
                      className={classes.radioUnchecked}
                    />
                  }
                  checkedIcon={
                    <FiberManualRecord
                      className={classes.radioChecked}
                    />
                  }
                  classes={{
                    checked: classes.radio,
                    root: classes.radioRoot
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot
              }}
              label={option.name}
            />
        </div>
        );
      });

      return options;
  }

  renderAdverts() {
    const { location: { state} } = this.props;
    if (_.isEmpty(state) || _.isEmpty(state.advertiser)) return (<div></div>);
    if ( _.isEmpty(state.advertiser.adverts)) return (<div></div>);

    return (<AdvertsList advertiserId={state.advertiser.id} title={state.advertiser.name} />);
    
  }

  renderAdvertiser() {
    const { classes, location: { state } } = this.props;
    const { logo, saving } = this.state;

    return (
      <React.Fragment>
        <br />
          {(!_.isEmpty(state) && !_.isEmpty(state.advertiser)) && (
              
                <div className={classes.saveBtn}>
                  { saving && <CircularProgress /> }
                  <Button 
                    color="info"
                    onClick={this.handleSave}
                  >
                    <Save /> Save
                  </Button>
                </div>
            
          )}
          
          <br />
          
      <GridContainer justify="center">

          <GridItem xs={12} sm={6}>
            <div className={classes.centered}>
              <ImageUpload initialImage={logo} imageHandler={this.handleImage} />
            </div>
            
          </GridItem>
        
          <GridItem xs={12} sm={6}>

            <CustomInput
              success={this.state.advertiserNameState === "success"}
              error={this.state.advertiserNameState === "error"}
              labelText={
                <span>Advertiser Name<small> (*)</small></span>
              }
              id="advertisername"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => this.change(event, "advertiserName", "length", 3),
                value: this.state.advertiserName,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Place className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
            />
            <br />
            <br />
          <div>
            <FormLabel required>
              Advertiser Type
            </FormLabel>
          </div>
            {this.renderAdvertiserTypes()}
            </GridItem>

          <GridItem xs={12} sm={6} md={4}>
            <div className={classes.phoneSearch}>
              { this.state.searching && <CircularProgress /> }
              <CustomInput
                success={this.state.phoneState === "success"}
                error={this.state.phoneState === "error"}
                labelText={
                  <span>
                    Phone <small>(*)</small>
                  </span>
                }
                id="phone"
                name="phone"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event => this.change(event, "phone", "phone", 8),
                  value: this.state.phone,
                  placeholder: "e.g 256772500034, 256772123456",
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className={classes.inputAdornment}
                    >
                      <Button
                        color="info"
                        onClick={this.handleSearch}
                        size="sm"
                      > <Search /> Search
                      </Button>
                    </InputAdornment>
                  )
                }}
              />
            </div>
          </GridItem>

        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            success={this.state.emailState === "success"}
            error={this.state.emailState === "error"}
            labelText={
              <span>
                Email
              </span>
            }
            id="email"
            name="email"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "email", "email"),
              value: this.state.email,
              placeholder: "e.g abc@example.com"
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <CustomInput
            success={this.state.websiteState === "success"}
            error={this.state.websiteState === "error"}
            labelText={
              <span>
                Website
              </span>
            }
            id="website"
            name="website"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "website", "url"),
              value: this.state.website,
              placeholder: "e.g https://www.example.com"
            }}
          />
        </GridItem>

          <GridContainer>
            <GridItem xs={6} sm={4}>
                <CustomInput
                  success={this.state.addressState === "success"}
                  error={this.state.addressState === "error"}
                  labelText={
                    <span>Address</span>
                  }
                  id="address"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => this.change(event, "address", "length", 3),
                    value: this.state.address
                }}
              />
            </GridItem>
            <GridItem xs={6} sm={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel htmlFor="advertiser-district" className={classes.selectLabel}>
                    District
                  </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={this.state.district}
                  onChange={this.handleSimple}
                  inputProps={{
                    name: "district",
                    id: "advertiser-district",
                    value: this.state.district
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    District
                  </MenuItem>
                  {
                    Object.keys(DISTRICTS).map((item, idx) => {
                      return (
                        <MenuItem
                          key={idx}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      );
                    })
                  }
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={6} sm={4}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel htmlFor="advertiser-city" className={classes.selectLabel}>
                    City
                  </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={this.state.city}
                  onChange={event => this.change(event, "city", "length", 1)}
                  inputProps={{
                    name: "city",
                    id: "advertiser-city",
                    value: this.state.city
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    City/Town
                  </MenuItem>
                  {
                    _.sortBy(DISTRICTS[_.isEmpty(this.state.district) ? "Kampala": this.state.district], 'town').map((item, idx) => {
                      return (
                        <MenuItem
                          key={idx}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={item.town}
                        >
                          {item.town}
                        </MenuItem>
                      );
                    })
                  }
                </Select>
              </FormControl>
            
            </GridItem>
            
          </GridContainer>
            
      </GridContainer>
      <br />
      
        {(_.isEmpty(state) || _.isEmpty(state.advertiser)) ? 
          (
            <div className={classes.saveCenterBtn}>
                { saving && <CircularProgress /> }
                <Button 
                    color="info"
                    onClick={this.handleSave}
                  >
                  <Add /> {'Create Advertiser'}
                </Button>
            </div>
          ) : 
          ( 
            <div className={classes.centered}>
                <Link to={{
                  pathname: "/admin/adcreate/id",
                  state: { advertiser: state.advertiser }
                }}>
                  <Button 
                    color="info">
                    <Add /> {'Create Advert'}
                  </Button>
                </Link>
            </div>
          )
        }
          
      
      <br />
      {this.renderAdverts()}
      </React.Fragment>
    )
  }

  render() {
    const { classes, location: { state } } = this.props;
    const { advertiserName } = this.state;
    
    return (
      <Card className={classes.card}>
        <CardHeader color="info" icon>
          <CardIcon color="info">
            <People />
          </CardIcon>
        </CardHeader>
        <CardBody>
          <React.Fragment>
          {this.state.alert}
            <Switch>
              <Route exact path="/admin/adcreate/id" render={ routeProps => ( <CreateAdvert {...routeProps}  advertiserName={advertiserName} /> ) }/>
              {this.renderAdvertiser()}
            </Switch>
          
        </React.Fragment>
      </CardBody>
      </Card>
      
    );
  }
}

CreateAdvertiser.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(CreateAdvertiser);
