import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
// import ContentCopy from "@material-ui/icons/ContentCopy";
import Store from "@material-ui/icons/Store";
// import InfoOutline from "@material-ui/icons/InfoOutline";
import Warning from "@material-ui/icons/Warning";
import DateRange from "@material-ui/icons/DateRange";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Update from "@material-ui/icons/Update";
import TrendingUp from "@material-ui/icons/TrendingUpOutlined";
import Done from "@material-ui/icons/DoneOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Danger from "components/Typography/Danger.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

// Utils
const _ = require("underscore");
const moment = require("moment");

const useStyles = makeStyles(styles);

export default function ReportHeader(props) {
  const { properties, propertyViews } = props;

  function totalViews() {
    if (_.isEmpty(properties)) return 0;
    return _.reduce(properties, function(memo, property) {
      const { views = 0 } = property;
          return memo + views; 
      }, 0);
  }

  function viewsByDays() {
    if (_.isEmpty(propertyViews)) return { sevenDays: 0, thirtyDays: 0, ninetyDays: 0 };
    
    const sevenMom = moment().utc().subtract(7, 'days');
    const thirtyMom = moment().utc().subtract(30, 'days');
    const ninteyMom = moment().utc().subtract(90, 'days');
    let sevenDays = 0;
    let thirtyDays = 0;
    let ninetyDays = 0;

    propertyViews.forEach((propertyView) => {
      const { month, dayViews } = propertyView;
      let momMonth = moment(month, "YYYY-MM");

      if (!_.isEmpty(dayViews)) {
        const dayjson = JSON.parse(dayViews);
        dayjson.forEach((item) => {
          const { day, views } = item;
          momMonth.date(day);
          if (momMonth.isAfter(sevenMom)) sevenDays += views;
          if (momMonth.isAfter(thirtyMom)) thirtyDays += views;
          if (momMonth.isAfter(ninteyMom)) ninetyDays += views;
        });
      }
    });

    return { sevenDays, thirtyDays, ninetyDays };
  }

  const classes = useStyles();
  const { sevenDays, thirtyDays, ninetyDays } = viewsByDays();

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Done />
              </CardIcon>
              <p className={classes.cardCategory}>Last 7 Days</p>
              <h3 className={classes.cardTitle}>
                {sevenDays} <small>views</small>
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              <DateRange />
                {`Last 7 days - All Properties`}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Last 30 Days</p>
              <h3 className={classes.cardTitle}>{thirtyDays} <small>views</small></h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <DateRange />
                Last 30 days - All Properties
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Last 90 days</p>
              <h3 className={classes.cardTitle}>{ninetyDays} <small>views</small></h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
              <DateRange />
                Last 90 days - All Properties
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <TrendingUp />
              </CardIcon>
              <p className={classes.cardCategory}>Total Views</p>
              <h3 className={classes.cardTitle}>{totalViews()} <small>views</small></h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Total Views - All Properties
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    
    </div>
  );
}
