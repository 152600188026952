/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createVehicleView = /* GraphQL */ `
  mutation CreateVehicleView(
    $input: CreateVehicleViewInput!
    $condition: ModelVehicleViewConditionInput
  ) {
    createVehicleView(input: $input, condition: $condition) {
      id
      ownerID
      vehicleID
      month
      dayViews
      views
      duration
      createdAt
      updatedAt
    }
  }
`;
export const updateVehicleView = /* GraphQL */ `
  mutation UpdateVehicleView(
    $input: UpdateVehicleViewInput!
    $condition: ModelVehicleViewConditionInput
  ) {
    updateVehicleView(input: $input, condition: $condition) {
      id
      ownerID
      vehicleID
      month
      dayViews
      views
      duration
      createdAt
      updatedAt
    }
  }
`;
export const deleteVehicleView = /* GraphQL */ `
  mutation DeleteVehicleView(
    $input: DeleteVehicleViewInput!
    $condition: ModelVehicleViewConditionInput
  ) {
    deleteVehicleView(input: $input, condition: $condition) {
      id
      ownerID
      vehicleID
      month
      dayViews
      views
      duration
      createdAt
      updatedAt
    }
  }
`;
export const createVehicle = /* GraphQL */ `
  mutation CreateVehicle(
    $input: CreateVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    createVehicle(input: $input, condition: $condition) {
      id
      title
      slug
      shortID
      options
      owner {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        website
        subscription {
          id
          name
          price
          recurring
          startDate
          status
          lastPaidAt
          options
          createdAt
          updatedAt
        }
        fullyPaid
        ownerType
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      condition
      purpose
      price
      intPrice
      currencyCode
      category {
        id
        name
        image
        options
        createdAt
        updatedAt
      }
      vehicleType
      year
      make
      model
      trim
      mileage
      cityMPG
      highwayMPG
      exterior
      interior
      driveType
      fuelType
      driveSetup
      transmission
      isDutyPaid
      engineSize
      features {
        items {
          id
          createdAt
          options
          updatedAt
        }
        nextToken
      }
      customers {
        items {
          id
          contactedAt
          isFavorite
          purchasedAt
          rentedAt
          viewedAt
          state
          createdAt
          updatedAt
        }
        nextToken
      }
      sellerDescription
      isDealership
      isFeatured
      isDeal
      isForeclosed
      featuredImage
      images
      createdAt
      updatedAt
      address
      addressNumber
      city
      district
      province
      country
      status
      search
      views
      videos
      thumbnails
      vehicleViews {
        items {
          id
          ownerID
          vehicleID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleViewsByDate {
        items {
          id
          ownerID
          vehicleID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateVehicle = /* GraphQL */ `
  mutation UpdateVehicle(
    $input: UpdateVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    updateVehicle(input: $input, condition: $condition) {
      id
      title
      slug
      shortID
      options
      owner {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        website
        subscription {
          id
          name
          price
          recurring
          startDate
          status
          lastPaidAt
          options
          createdAt
          updatedAt
        }
        fullyPaid
        ownerType
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      condition
      purpose
      price
      intPrice
      currencyCode
      category {
        id
        name
        image
        options
        createdAt
        updatedAt
      }
      vehicleType
      year
      make
      model
      trim
      mileage
      cityMPG
      highwayMPG
      exterior
      interior
      driveType
      fuelType
      driveSetup
      transmission
      isDutyPaid
      engineSize
      features {
        items {
          id
          createdAt
          options
          updatedAt
        }
        nextToken
      }
      customers {
        items {
          id
          contactedAt
          isFavorite
          purchasedAt
          rentedAt
          viewedAt
          state
          createdAt
          updatedAt
        }
        nextToken
      }
      sellerDescription
      isDealership
      isFeatured
      isDeal
      isForeclosed
      featuredImage
      images
      createdAt
      updatedAt
      address
      addressNumber
      city
      district
      province
      country
      status
      search
      views
      videos
      thumbnails
      vehicleViews {
        items {
          id
          ownerID
          vehicleID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleViewsByDate {
        items {
          id
          ownerID
          vehicleID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteVehicle = /* GraphQL */ `
  mutation DeleteVehicle(
    $input: DeleteVehicleInput!
    $condition: ModelVehicleConditionInput
  ) {
    deleteVehicle(input: $input, condition: $condition) {
      id
      title
      slug
      shortID
      options
      owner {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        website
        subscription {
          id
          name
          price
          recurring
          startDate
          status
          lastPaidAt
          options
          createdAt
          updatedAt
        }
        fullyPaid
        ownerType
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      condition
      purpose
      price
      intPrice
      currencyCode
      category {
        id
        name
        image
        options
        createdAt
        updatedAt
      }
      vehicleType
      year
      make
      model
      trim
      mileage
      cityMPG
      highwayMPG
      exterior
      interior
      driveType
      fuelType
      driveSetup
      transmission
      isDutyPaid
      engineSize
      features {
        items {
          id
          createdAt
          options
          updatedAt
        }
        nextToken
      }
      customers {
        items {
          id
          contactedAt
          isFavorite
          purchasedAt
          rentedAt
          viewedAt
          state
          createdAt
          updatedAt
        }
        nextToken
      }
      sellerDescription
      isDealership
      isFeatured
      isDeal
      isForeclosed
      featuredImage
      images
      createdAt
      updatedAt
      address
      addressNumber
      city
      district
      province
      country
      status
      search
      views
      videos
      thumbnails
      vehicleViews {
        items {
          id
          ownerID
          vehicleID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleViewsByDate {
        items {
          id
          ownerID
          vehicleID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createVehicleMakeModels = /* GraphQL */ `
  mutation CreateVehicleMakeModels(
    $input: CreateVehicleMakeModelsInput!
    $condition: ModelVehicleMakeModelsConditionInput
  ) {
    createVehicleMakeModels(input: $input, condition: $condition) {
      id
      year
      make
      logo
      options
      model
      trim
      series
      createdAt
      updatedAt
    }
  }
`;
export const updateVehicleMakeModels = /* GraphQL */ `
  mutation UpdateVehicleMakeModels(
    $input: UpdateVehicleMakeModelsInput!
    $condition: ModelVehicleMakeModelsConditionInput
  ) {
    updateVehicleMakeModels(input: $input, condition: $condition) {
      id
      year
      make
      logo
      options
      model
      trim
      series
      createdAt
      updatedAt
    }
  }
`;
export const deleteVehicleMakeModels = /* GraphQL */ `
  mutation DeleteVehicleMakeModels(
    $input: DeleteVehicleMakeModelsInput!
    $condition: ModelVehicleMakeModelsConditionInput
  ) {
    deleteVehicleMakeModels(input: $input, condition: $condition) {
      id
      year
      make
      logo
      options
      model
      trim
      series
      createdAt
      updatedAt
    }
  }
`;
export const createCarFeatures = /* GraphQL */ `
  mutation CreateCarFeatures(
    $input: CreateCarFeaturesInput!
    $condition: ModelCarFeaturesConditionInput
  ) {
    createCarFeatures(input: $input, condition: $condition) {
      id
      name
      icon
      type
      description
      options
      features {
        items {
          id
          createdAt
          options
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCarFeatures = /* GraphQL */ `
  mutation UpdateCarFeatures(
    $input: UpdateCarFeaturesInput!
    $condition: ModelCarFeaturesConditionInput
  ) {
    updateCarFeatures(input: $input, condition: $condition) {
      id
      name
      icon
      type
      description
      options
      features {
        items {
          id
          createdAt
          options
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCarFeatures = /* GraphQL */ `
  mutation DeleteCarFeatures(
    $input: DeleteCarFeaturesInput!
    $condition: ModelCarFeaturesConditionInput
  ) {
    deleteCarFeatures(input: $input, condition: $condition) {
      id
      name
      icon
      type
      description
      options
      features {
        items {
          id
          createdAt
          options
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVehicleFeature = /* GraphQL */ `
  mutation CreateVehicleFeature(
    $input: CreateVehicleFeatureInput!
    $condition: ModelVehicleFeatureConditionInput
  ) {
    createVehicleFeature(input: $input, condition: $condition) {
      id
      vehicle {
        id
        title
        slug
        shortID
        options
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        condition
        purpose
        price
        intPrice
        currencyCode
        category {
          id
          name
          image
          options
          createdAt
          updatedAt
        }
        vehicleType
        year
        make
        model
        trim
        mileage
        cityMPG
        highwayMPG
        exterior
        interior
        driveType
        fuelType
        driveSetup
        transmission
        isDutyPaid
        engineSize
        features {
          nextToken
        }
        customers {
          nextToken
        }
        sellerDescription
        isDealership
        isFeatured
        isDeal
        isForeclosed
        featuredImage
        images
        createdAt
        updatedAt
        address
        addressNumber
        city
        district
        province
        country
        status
        search
        views
        videos
        thumbnails
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      feature {
        id
        name
        icon
        type
        description
        options
        features {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      options
      updatedAt
    }
  }
`;
export const updateVehicleFeature = /* GraphQL */ `
  mutation UpdateVehicleFeature(
    $input: UpdateVehicleFeatureInput!
    $condition: ModelVehicleFeatureConditionInput
  ) {
    updateVehicleFeature(input: $input, condition: $condition) {
      id
      vehicle {
        id
        title
        slug
        shortID
        options
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        condition
        purpose
        price
        intPrice
        currencyCode
        category {
          id
          name
          image
          options
          createdAt
          updatedAt
        }
        vehicleType
        year
        make
        model
        trim
        mileage
        cityMPG
        highwayMPG
        exterior
        interior
        driveType
        fuelType
        driveSetup
        transmission
        isDutyPaid
        engineSize
        features {
          nextToken
        }
        customers {
          nextToken
        }
        sellerDescription
        isDealership
        isFeatured
        isDeal
        isForeclosed
        featuredImage
        images
        createdAt
        updatedAt
        address
        addressNumber
        city
        district
        province
        country
        status
        search
        views
        videos
        thumbnails
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      feature {
        id
        name
        icon
        type
        description
        options
        features {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      options
      updatedAt
    }
  }
`;
export const deleteVehicleFeature = /* GraphQL */ `
  mutation DeleteVehicleFeature(
    $input: DeleteVehicleFeatureInput!
    $condition: ModelVehicleFeatureConditionInput
  ) {
    deleteVehicleFeature(input: $input, condition: $condition) {
      id
      vehicle {
        id
        title
        slug
        shortID
        options
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        condition
        purpose
        price
        intPrice
        currencyCode
        category {
          id
          name
          image
          options
          createdAt
          updatedAt
        }
        vehicleType
        year
        make
        model
        trim
        mileage
        cityMPG
        highwayMPG
        exterior
        interior
        driveType
        fuelType
        driveSetup
        transmission
        isDutyPaid
        engineSize
        features {
          nextToken
        }
        customers {
          nextToken
        }
        sellerDescription
        isDealership
        isFeatured
        isDeal
        isForeclosed
        featuredImage
        images
        createdAt
        updatedAt
        address
        addressNumber
        city
        district
        province
        country
        status
        search
        views
        videos
        thumbnails
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      feature {
        id
        name
        icon
        type
        description
        options
        features {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      options
      updatedAt
    }
  }
`;
export const createPropertyView = /* GraphQL */ `
  mutation CreatePropertyView(
    $input: CreatePropertyViewInput!
    $condition: ModelPropertyViewConditionInput
  ) {
    createPropertyView(input: $input, condition: $condition) {
      id
      ownerID
      propertyID
      month
      dayViews
      views
      duration
      createdAt
      updatedAt
    }
  }
`;
export const updatePropertyView = /* GraphQL */ `
  mutation UpdatePropertyView(
    $input: UpdatePropertyViewInput!
    $condition: ModelPropertyViewConditionInput
  ) {
    updatePropertyView(input: $input, condition: $condition) {
      id
      ownerID
      propertyID
      month
      dayViews
      views
      duration
      createdAt
      updatedAt
    }
  }
`;
export const deletePropertyView = /* GraphQL */ `
  mutation DeletePropertyView(
    $input: DeletePropertyViewInput!
    $condition: ModelPropertyViewConditionInput
  ) {
    deletePropertyView(input: $input, condition: $condition) {
      id
      ownerID
      propertyID
      month
      dayViews
      views
      duration
      createdAt
      updatedAt
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      image
      options
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      image
      options
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      image
      options
      createdAt
      updatedAt
    }
  }
`;
export const createAppSubscription = /* GraphQL */ `
  mutation CreateAppSubscription(
    $input: CreateAppSubscriptionInput!
    $condition: ModelAppSubscriptionConditionInput
  ) {
    createAppSubscription(input: $input, condition: $condition) {
      id
      name
      price
      recurring
      startDate
      status
      lastPaidAt
      payments {
        items {
          id
          amount
          currencyCode
          processedAt
          processedBy
          notes
          paymentType
          createdAt
          updatedAt
        }
        nextToken
      }
      options
      owner {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        website
        subscription {
          id
          name
          price
          recurring
          startDate
          status
          lastPaidAt
          options
          createdAt
          updatedAt
        }
        fullyPaid
        ownerType
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAppSubscription = /* GraphQL */ `
  mutation UpdateAppSubscription(
    $input: UpdateAppSubscriptionInput!
    $condition: ModelAppSubscriptionConditionInput
  ) {
    updateAppSubscription(input: $input, condition: $condition) {
      id
      name
      price
      recurring
      startDate
      status
      lastPaidAt
      payments {
        items {
          id
          amount
          currencyCode
          processedAt
          processedBy
          notes
          paymentType
          createdAt
          updatedAt
        }
        nextToken
      }
      options
      owner {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        website
        subscription {
          id
          name
          price
          recurring
          startDate
          status
          lastPaidAt
          options
          createdAt
          updatedAt
        }
        fullyPaid
        ownerType
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAppSubscription = /* GraphQL */ `
  mutation DeleteAppSubscription(
    $input: DeleteAppSubscriptionInput!
    $condition: ModelAppSubscriptionConditionInput
  ) {
    deleteAppSubscription(input: $input, condition: $condition) {
      id
      name
      price
      recurring
      startDate
      status
      lastPaidAt
      payments {
        items {
          id
          amount
          currencyCode
          processedAt
          processedBy
          notes
          paymentType
          createdAt
          updatedAt
        }
        nextToken
      }
      options
      owner {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        website
        subscription {
          id
          name
          price
          recurring
          startDate
          status
          lastPaidAt
          options
          createdAt
          updatedAt
        }
        fullyPaid
        ownerType
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      subscription {
        id
        name
        price
        recurring
        startDate
        status
        lastPaidAt
        payments {
          nextToken
        }
        options
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        createdAt
        updatedAt
      }
      amount
      currencyCode
      processedAt
      processedBy
      notes
      paymentType
      createdAt
      updatedAt
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      subscription {
        id
        name
        price
        recurring
        startDate
        status
        lastPaidAt
        payments {
          nextToken
        }
        options
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        createdAt
        updatedAt
      }
      amount
      currencyCode
      processedAt
      processedBy
      notes
      paymentType
      createdAt
      updatedAt
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      subscription {
        id
        name
        price
        recurring
        startDate
        status
        lastPaidAt
        payments {
          nextToken
        }
        options
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        createdAt
        updatedAt
      }
      amount
      currencyCode
      processedAt
      processedBy
      notes
      paymentType
      createdAt
      updatedAt
    }
  }
`;
export const createProperty = /* GraphQL */ `
  mutation CreateProperty(
    $input: CreatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    createProperty(input: $input, condition: $condition) {
      id
      name
      slug
      shortID
      options
      amenities {
        items {
          id
          createdAt
          options
          updatedAt
        }
        nextToken
      }
      category {
        id
        name
        image
        options
        createdAt
        updatedAt
      }
      owner {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        website
        subscription {
          id
          name
          price
          recurring
          startDate
          status
          lastPaidAt
          options
          createdAt
          updatedAt
        }
        fullyPaid
        ownerType
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      customers {
        items {
          id
          contactedAt
          isFavorite
          purchasedAt
          rentedAt
          viewedAt
          state
          createdAt
          updatedAt
        }
        nextToken
      }
      isFeatured
      isFeaturedBottom
      isOffPlan
      isForeclosed
      isDeal
      isEstate
      isHotelRoom
      isHostel
      hotelId
      hotelStars
      propertyType
      propertyPurpose
      currencyCode
      salePrice
      intSalePrice
      rentPrice
      intRentPrice
      description
      featuredImage
      images
      createdAt
      updatedAt
      address
      addressNumber
      city
      district
      province
      country
      latitude
      longitude
      bathrooms
      bedrooms
      status
      size
      intSize
      sizeUnits
      search
      views
      videos
      thumbnails
      propertyViews {
        items {
          id
          ownerID
          propertyID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      propertyViewsByDate {
        items {
          id
          ownerID
          propertyID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateProperty = /* GraphQL */ `
  mutation UpdateProperty(
    $input: UpdatePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    updateProperty(input: $input, condition: $condition) {
      id
      name
      slug
      shortID
      options
      amenities {
        items {
          id
          createdAt
          options
          updatedAt
        }
        nextToken
      }
      category {
        id
        name
        image
        options
        createdAt
        updatedAt
      }
      owner {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        website
        subscription {
          id
          name
          price
          recurring
          startDate
          status
          lastPaidAt
          options
          createdAt
          updatedAt
        }
        fullyPaid
        ownerType
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      customers {
        items {
          id
          contactedAt
          isFavorite
          purchasedAt
          rentedAt
          viewedAt
          state
          createdAt
          updatedAt
        }
        nextToken
      }
      isFeatured
      isFeaturedBottom
      isOffPlan
      isForeclosed
      isDeal
      isEstate
      isHotelRoom
      isHostel
      hotelId
      hotelStars
      propertyType
      propertyPurpose
      currencyCode
      salePrice
      intSalePrice
      rentPrice
      intRentPrice
      description
      featuredImage
      images
      createdAt
      updatedAt
      address
      addressNumber
      city
      district
      province
      country
      latitude
      longitude
      bathrooms
      bedrooms
      status
      size
      intSize
      sizeUnits
      search
      views
      videos
      thumbnails
      propertyViews {
        items {
          id
          ownerID
          propertyID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      propertyViewsByDate {
        items {
          id
          ownerID
          propertyID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteProperty = /* GraphQL */ `
  mutation DeleteProperty(
    $input: DeletePropertyInput!
    $condition: ModelPropertyConditionInput
  ) {
    deleteProperty(input: $input, condition: $condition) {
      id
      name
      slug
      shortID
      options
      amenities {
        items {
          id
          createdAt
          options
          updatedAt
        }
        nextToken
      }
      category {
        id
        name
        image
        options
        createdAt
        updatedAt
      }
      owner {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        website
        subscription {
          id
          name
          price
          recurring
          startDate
          status
          lastPaidAt
          options
          createdAt
          updatedAt
        }
        fullyPaid
        ownerType
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      customers {
        items {
          id
          contactedAt
          isFavorite
          purchasedAt
          rentedAt
          viewedAt
          state
          createdAt
          updatedAt
        }
        nextToken
      }
      isFeatured
      isFeaturedBottom
      isOffPlan
      isForeclosed
      isDeal
      isEstate
      isHotelRoom
      isHostel
      hotelId
      hotelStars
      propertyType
      propertyPurpose
      currencyCode
      salePrice
      intSalePrice
      rentPrice
      intRentPrice
      description
      featuredImage
      images
      createdAt
      updatedAt
      address
      addressNumber
      city
      district
      province
      country
      latitude
      longitude
      bathrooms
      bedrooms
      status
      size
      intSize
      sizeUnits
      search
      views
      videos
      thumbnails
      propertyViews {
        items {
          id
          ownerID
          propertyID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      propertyViewsByDate {
        items {
          id
          ownerID
          propertyID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createAmenity = /* GraphQL */ `
  mutation CreateAmenity(
    $input: CreateAmenityInput!
    $condition: ModelAmenityConditionInput
  ) {
    createAmenity(input: $input, condition: $condition) {
      id
      name
      icon
      description
      options
      properties {
        items {
          id
          createdAt
          options
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAmenity = /* GraphQL */ `
  mutation UpdateAmenity(
    $input: UpdateAmenityInput!
    $condition: ModelAmenityConditionInput
  ) {
    updateAmenity(input: $input, condition: $condition) {
      id
      name
      icon
      description
      options
      properties {
        items {
          id
          createdAt
          options
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAmenity = /* GraphQL */ `
  mutation DeleteAmenity(
    $input: DeleteAmenityInput!
    $condition: ModelAmenityConditionInput
  ) {
    deleteAmenity(input: $input, condition: $condition) {
      id
      name
      icon
      description
      options
      properties {
        items {
          id
          createdAt
          options
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPropertyAmenity = /* GraphQL */ `
  mutation CreatePropertyAmenity(
    $input: CreatePropertyAmenityInput!
    $condition: ModelPropertyAmenityConditionInput
  ) {
    createPropertyAmenity(input: $input, condition: $condition) {
      id
      property {
        id
        name
        slug
        shortID
        options
        amenities {
          nextToken
        }
        category {
          id
          name
          image
          options
          createdAt
          updatedAt
        }
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        customers {
          nextToken
        }
        isFeatured
        isFeaturedBottom
        isOffPlan
        isForeclosed
        isDeal
        isEstate
        isHotelRoom
        isHostel
        hotelId
        hotelStars
        propertyType
        propertyPurpose
        currencyCode
        salePrice
        intSalePrice
        rentPrice
        intRentPrice
        description
        featuredImage
        images
        createdAt
        updatedAt
        address
        addressNumber
        city
        district
        province
        country
        latitude
        longitude
        bathrooms
        bedrooms
        status
        size
        intSize
        sizeUnits
        search
        views
        videos
        thumbnails
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
      }
      amenity {
        id
        name
        icon
        description
        options
        properties {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      options
      updatedAt
    }
  }
`;
export const updatePropertyAmenity = /* GraphQL */ `
  mutation UpdatePropertyAmenity(
    $input: UpdatePropertyAmenityInput!
    $condition: ModelPropertyAmenityConditionInput
  ) {
    updatePropertyAmenity(input: $input, condition: $condition) {
      id
      property {
        id
        name
        slug
        shortID
        options
        amenities {
          nextToken
        }
        category {
          id
          name
          image
          options
          createdAt
          updatedAt
        }
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        customers {
          nextToken
        }
        isFeatured
        isFeaturedBottom
        isOffPlan
        isForeclosed
        isDeal
        isEstate
        isHotelRoom
        isHostel
        hotelId
        hotelStars
        propertyType
        propertyPurpose
        currencyCode
        salePrice
        intSalePrice
        rentPrice
        intRentPrice
        description
        featuredImage
        images
        createdAt
        updatedAt
        address
        addressNumber
        city
        district
        province
        country
        latitude
        longitude
        bathrooms
        bedrooms
        status
        size
        intSize
        sizeUnits
        search
        views
        videos
        thumbnails
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
      }
      amenity {
        id
        name
        icon
        description
        options
        properties {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      options
      updatedAt
    }
  }
`;
export const deletePropertyAmenity = /* GraphQL */ `
  mutation DeletePropertyAmenity(
    $input: DeletePropertyAmenityInput!
    $condition: ModelPropertyAmenityConditionInput
  ) {
    deletePropertyAmenity(input: $input, condition: $condition) {
      id
      property {
        id
        name
        slug
        shortID
        options
        amenities {
          nextToken
        }
        category {
          id
          name
          image
          options
          createdAt
          updatedAt
        }
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        customers {
          nextToken
        }
        isFeatured
        isFeaturedBottom
        isOffPlan
        isForeclosed
        isDeal
        isEstate
        isHotelRoom
        isHostel
        hotelId
        hotelStars
        propertyType
        propertyPurpose
        currencyCode
        salePrice
        intSalePrice
        rentPrice
        intRentPrice
        description
        featuredImage
        images
        createdAt
        updatedAt
        address
        addressNumber
        city
        district
        province
        country
        latitude
        longitude
        bathrooms
        bedrooms
        status
        size
        intSize
        sizeUnits
        search
        views
        videos
        thumbnails
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
      }
      amenity {
        id
        name
        icon
        description
        options
        properties {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      options
      updatedAt
    }
  }
`;
export const createOwner = /* GraphQL */ `
  mutation CreateOwner(
    $input: CreateOwnerInput!
    $condition: ModelOwnerConditionInput
  ) {
    createOwner(input: $input, condition: $condition) {
      id
      firstName
      lastName
      name
      displayName
      email
      phone
      profilePhoto
      acceptsCookies
      properties {
        items {
          id
          name
          slug
          shortID
          options
          isFeatured
          isFeaturedBottom
          isOffPlan
          isForeclosed
          isDeal
          isEstate
          isHotelRoom
          isHostel
          hotelId
          hotelStars
          propertyType
          propertyPurpose
          currencyCode
          salePrice
          intSalePrice
          rentPrice
          intRentPrice
          description
          featuredImage
          images
          createdAt
          updatedAt
          address
          addressNumber
          city
          district
          province
          country
          latitude
          longitude
          bathrooms
          bedrooms
          status
          size
          intSize
          sizeUnits
          search
          views
          videos
          thumbnails
        }
        nextToken
      }
      vehicles {
        items {
          id
          title
          slug
          shortID
          options
          condition
          purpose
          price
          intPrice
          currencyCode
          vehicleType
          year
          make
          model
          trim
          mileage
          cityMPG
          highwayMPG
          exterior
          interior
          driveType
          fuelType
          driveSetup
          transmission
          isDutyPaid
          engineSize
          sellerDescription
          isDealership
          isFeatured
          isDeal
          isForeclosed
          featuredImage
          images
          createdAt
          updatedAt
          address
          addressNumber
          city
          district
          province
          country
          status
          search
          views
          videos
          thumbnails
        }
        nextToken
      }
      createdAt
      updatedAt
      address
      city
      province
      country
      website
      subscription {
        id
        name
        price
        recurring
        startDate
        status
        lastPaidAt
        payments {
          nextToken
        }
        options
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        createdAt
        updatedAt
      }
      fullyPaid
      ownerType
      propertyViews {
        items {
          id
          ownerID
          propertyID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      propertyViewsByDate {
        items {
          id
          ownerID
          propertyID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleViews {
        items {
          id
          ownerID
          vehicleID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleViewsByDate {
        items {
          id
          ownerID
          vehicleID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateOwner = /* GraphQL */ `
  mutation UpdateOwner(
    $input: UpdateOwnerInput!
    $condition: ModelOwnerConditionInput
  ) {
    updateOwner(input: $input, condition: $condition) {
      id
      firstName
      lastName
      name
      displayName
      email
      phone
      profilePhoto
      acceptsCookies
      properties {
        items {
          id
          name
          slug
          shortID
          options
          isFeatured
          isFeaturedBottom
          isOffPlan
          isForeclosed
          isDeal
          isEstate
          isHotelRoom
          isHostel
          hotelId
          hotelStars
          propertyType
          propertyPurpose
          currencyCode
          salePrice
          intSalePrice
          rentPrice
          intRentPrice
          description
          featuredImage
          images
          createdAt
          updatedAt
          address
          addressNumber
          city
          district
          province
          country
          latitude
          longitude
          bathrooms
          bedrooms
          status
          size
          intSize
          sizeUnits
          search
          views
          videos
          thumbnails
        }
        nextToken
      }
      vehicles {
        items {
          id
          title
          slug
          shortID
          options
          condition
          purpose
          price
          intPrice
          currencyCode
          vehicleType
          year
          make
          model
          trim
          mileage
          cityMPG
          highwayMPG
          exterior
          interior
          driveType
          fuelType
          driveSetup
          transmission
          isDutyPaid
          engineSize
          sellerDescription
          isDealership
          isFeatured
          isDeal
          isForeclosed
          featuredImage
          images
          createdAt
          updatedAt
          address
          addressNumber
          city
          district
          province
          country
          status
          search
          views
          videos
          thumbnails
        }
        nextToken
      }
      createdAt
      updatedAt
      address
      city
      province
      country
      website
      subscription {
        id
        name
        price
        recurring
        startDate
        status
        lastPaidAt
        payments {
          nextToken
        }
        options
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        createdAt
        updatedAt
      }
      fullyPaid
      ownerType
      propertyViews {
        items {
          id
          ownerID
          propertyID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      propertyViewsByDate {
        items {
          id
          ownerID
          propertyID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleViews {
        items {
          id
          ownerID
          vehicleID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleViewsByDate {
        items {
          id
          ownerID
          vehicleID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteOwner = /* GraphQL */ `
  mutation DeleteOwner(
    $input: DeleteOwnerInput!
    $condition: ModelOwnerConditionInput
  ) {
    deleteOwner(input: $input, condition: $condition) {
      id
      firstName
      lastName
      name
      displayName
      email
      phone
      profilePhoto
      acceptsCookies
      properties {
        items {
          id
          name
          slug
          shortID
          options
          isFeatured
          isFeaturedBottom
          isOffPlan
          isForeclosed
          isDeal
          isEstate
          isHotelRoom
          isHostel
          hotelId
          hotelStars
          propertyType
          propertyPurpose
          currencyCode
          salePrice
          intSalePrice
          rentPrice
          intRentPrice
          description
          featuredImage
          images
          createdAt
          updatedAt
          address
          addressNumber
          city
          district
          province
          country
          latitude
          longitude
          bathrooms
          bedrooms
          status
          size
          intSize
          sizeUnits
          search
          views
          videos
          thumbnails
        }
        nextToken
      }
      vehicles {
        items {
          id
          title
          slug
          shortID
          options
          condition
          purpose
          price
          intPrice
          currencyCode
          vehicleType
          year
          make
          model
          trim
          mileage
          cityMPG
          highwayMPG
          exterior
          interior
          driveType
          fuelType
          driveSetup
          transmission
          isDutyPaid
          engineSize
          sellerDescription
          isDealership
          isFeatured
          isDeal
          isForeclosed
          featuredImage
          images
          createdAt
          updatedAt
          address
          addressNumber
          city
          district
          province
          country
          status
          search
          views
          videos
          thumbnails
        }
        nextToken
      }
      createdAt
      updatedAt
      address
      city
      province
      country
      website
      subscription {
        id
        name
        price
        recurring
        startDate
        status
        lastPaidAt
        payments {
          nextToken
        }
        options
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        createdAt
        updatedAt
      }
      fullyPaid
      ownerType
      propertyViews {
        items {
          id
          ownerID
          propertyID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      propertyViewsByDate {
        items {
          id
          ownerID
          propertyID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleViews {
        items {
          id
          ownerID
          vehicleID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleViewsByDate {
        items {
          id
          ownerID
          vehicleID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createCustomerProperty = /* GraphQL */ `
  mutation CreateCustomerProperty(
    $input: CreateCustomerPropertyInput!
    $condition: ModelCustomerPropertyConditionInput
  ) {
    createCustomerProperty(input: $input, condition: $condition) {
      id
      customer {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        socialLogin
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        isCompany
        website
      }
      property {
        id
        name
        slug
        shortID
        options
        amenities {
          nextToken
        }
        category {
          id
          name
          image
          options
          createdAt
          updatedAt
        }
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        customers {
          nextToken
        }
        isFeatured
        isFeaturedBottom
        isOffPlan
        isForeclosed
        isDeal
        isEstate
        isHotelRoom
        isHostel
        hotelId
        hotelStars
        propertyType
        propertyPurpose
        currencyCode
        salePrice
        intSalePrice
        rentPrice
        intRentPrice
        description
        featuredImage
        images
        createdAt
        updatedAt
        address
        addressNumber
        city
        district
        province
        country
        latitude
        longitude
        bathrooms
        bedrooms
        status
        size
        intSize
        sizeUnits
        search
        views
        videos
        thumbnails
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
      }
      contactedAt
      isFavorite
      purchasedAt
      rentedAt
      viewedAt
      state
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerProperty = /* GraphQL */ `
  mutation UpdateCustomerProperty(
    $input: UpdateCustomerPropertyInput!
    $condition: ModelCustomerPropertyConditionInput
  ) {
    updateCustomerProperty(input: $input, condition: $condition) {
      id
      customer {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        socialLogin
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        isCompany
        website
      }
      property {
        id
        name
        slug
        shortID
        options
        amenities {
          nextToken
        }
        category {
          id
          name
          image
          options
          createdAt
          updatedAt
        }
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        customers {
          nextToken
        }
        isFeatured
        isFeaturedBottom
        isOffPlan
        isForeclosed
        isDeal
        isEstate
        isHotelRoom
        isHostel
        hotelId
        hotelStars
        propertyType
        propertyPurpose
        currencyCode
        salePrice
        intSalePrice
        rentPrice
        intRentPrice
        description
        featuredImage
        images
        createdAt
        updatedAt
        address
        addressNumber
        city
        district
        province
        country
        latitude
        longitude
        bathrooms
        bedrooms
        status
        size
        intSize
        sizeUnits
        search
        views
        videos
        thumbnails
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
      }
      contactedAt
      isFavorite
      purchasedAt
      rentedAt
      viewedAt
      state
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerProperty = /* GraphQL */ `
  mutation DeleteCustomerProperty(
    $input: DeleteCustomerPropertyInput!
    $condition: ModelCustomerPropertyConditionInput
  ) {
    deleteCustomerProperty(input: $input, condition: $condition) {
      id
      customer {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        socialLogin
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        isCompany
        website
      }
      property {
        id
        name
        slug
        shortID
        options
        amenities {
          nextToken
        }
        category {
          id
          name
          image
          options
          createdAt
          updatedAt
        }
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        customers {
          nextToken
        }
        isFeatured
        isFeaturedBottom
        isOffPlan
        isForeclosed
        isDeal
        isEstate
        isHotelRoom
        isHostel
        hotelId
        hotelStars
        propertyType
        propertyPurpose
        currencyCode
        salePrice
        intSalePrice
        rentPrice
        intRentPrice
        description
        featuredImage
        images
        createdAt
        updatedAt
        address
        addressNumber
        city
        district
        province
        country
        latitude
        longitude
        bathrooms
        bedrooms
        status
        size
        intSize
        sizeUnits
        search
        views
        videos
        thumbnails
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
      }
      contactedAt
      isFavorite
      purchasedAt
      rentedAt
      viewedAt
      state
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerVehicle = /* GraphQL */ `
  mutation CreateCustomerVehicle(
    $input: CreateCustomerVehicleInput!
    $condition: ModelCustomerVehicleConditionInput
  ) {
    createCustomerVehicle(input: $input, condition: $condition) {
      id
      customer {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        socialLogin
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        isCompany
        website
      }
      vehicle {
        id
        title
        slug
        shortID
        options
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        condition
        purpose
        price
        intPrice
        currencyCode
        category {
          id
          name
          image
          options
          createdAt
          updatedAt
        }
        vehicleType
        year
        make
        model
        trim
        mileage
        cityMPG
        highwayMPG
        exterior
        interior
        driveType
        fuelType
        driveSetup
        transmission
        isDutyPaid
        engineSize
        features {
          nextToken
        }
        customers {
          nextToken
        }
        sellerDescription
        isDealership
        isFeatured
        isDeal
        isForeclosed
        featuredImage
        images
        createdAt
        updatedAt
        address
        addressNumber
        city
        district
        province
        country
        status
        search
        views
        videos
        thumbnails
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      contactedAt
      isFavorite
      purchasedAt
      rentedAt
      viewedAt
      state
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomerVehicle = /* GraphQL */ `
  mutation UpdateCustomerVehicle(
    $input: UpdateCustomerVehicleInput!
    $condition: ModelCustomerVehicleConditionInput
  ) {
    updateCustomerVehicle(input: $input, condition: $condition) {
      id
      customer {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        socialLogin
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        isCompany
        website
      }
      vehicle {
        id
        title
        slug
        shortID
        options
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        condition
        purpose
        price
        intPrice
        currencyCode
        category {
          id
          name
          image
          options
          createdAt
          updatedAt
        }
        vehicleType
        year
        make
        model
        trim
        mileage
        cityMPG
        highwayMPG
        exterior
        interior
        driveType
        fuelType
        driveSetup
        transmission
        isDutyPaid
        engineSize
        features {
          nextToken
        }
        customers {
          nextToken
        }
        sellerDescription
        isDealership
        isFeatured
        isDeal
        isForeclosed
        featuredImage
        images
        createdAt
        updatedAt
        address
        addressNumber
        city
        district
        province
        country
        status
        search
        views
        videos
        thumbnails
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      contactedAt
      isFavorite
      purchasedAt
      rentedAt
      viewedAt
      state
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomerVehicle = /* GraphQL */ `
  mutation DeleteCustomerVehicle(
    $input: DeleteCustomerVehicleInput!
    $condition: ModelCustomerVehicleConditionInput
  ) {
    deleteCustomerVehicle(input: $input, condition: $condition) {
      id
      customer {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        socialLogin
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        isCompany
        website
      }
      vehicle {
        id
        title
        slug
        shortID
        options
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        condition
        purpose
        price
        intPrice
        currencyCode
        category {
          id
          name
          image
          options
          createdAt
          updatedAt
        }
        vehicleType
        year
        make
        model
        trim
        mileage
        cityMPG
        highwayMPG
        exterior
        interior
        driveType
        fuelType
        driveSetup
        transmission
        isDutyPaid
        engineSize
        features {
          nextToken
        }
        customers {
          nextToken
        }
        sellerDescription
        isDealership
        isFeatured
        isDeal
        isForeclosed
        featuredImage
        images
        createdAt
        updatedAt
        address
        addressNumber
        city
        district
        province
        country
        status
        search
        views
        videos
        thumbnails
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      contactedAt
      isFavorite
      purchasedAt
      rentedAt
      viewedAt
      state
      createdAt
      updatedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer(
    $input: CreateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    createCustomer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      name
      displayName
      email
      phone
      socialLogin
      profilePhoto
      acceptsCookies
      properties {
        items {
          id
          contactedAt
          isFavorite
          purchasedAt
          rentedAt
          viewedAt
          state
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicles {
        items {
          id
          contactedAt
          isFavorite
          purchasedAt
          rentedAt
          viewedAt
          state
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          lookId
          commentDate
          message
          storeId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      address
      city
      province
      country
      isCompany
      website
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      name
      displayName
      email
      phone
      socialLogin
      profilePhoto
      acceptsCookies
      properties {
        items {
          id
          contactedAt
          isFavorite
          purchasedAt
          rentedAt
          viewedAt
          state
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicles {
        items {
          id
          contactedAt
          isFavorite
          purchasedAt
          rentedAt
          viewedAt
          state
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          lookId
          commentDate
          message
          storeId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      address
      city
      province
      country
      isCompany
      website
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer(
    $input: DeleteCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    deleteCustomer(input: $input, condition: $condition) {
      id
      firstName
      lastName
      name
      displayName
      email
      phone
      socialLogin
      profilePhoto
      acceptsCookies
      properties {
        items {
          id
          contactedAt
          isFavorite
          purchasedAt
          rentedAt
          viewedAt
          state
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicles {
        items {
          id
          contactedAt
          isFavorite
          purchasedAt
          rentedAt
          viewedAt
          state
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          lookId
          commentDate
          message
          storeId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      address
      city
      province
      country
      isCompany
      website
    }
  }
`;
export const createAdvertiser = /* GraphQL */ `
  mutation CreateAdvertiser(
    $input: CreateAdvertiserInput!
    $condition: ModelAdvertiserConditionInput
  ) {
    createAdvertiser(input: $input, condition: $condition) {
      id
      name
      logo
      options
      adverts {
        items {
          id
          name
          description
          shortID
          link
          image
          options
          createdAt
          updatedAt
          startAt
          endAt
          fullyPaid
        }
        nextToken
      }
      createdAt
      updatedAt
      address
      city
      province
      country
      phone
      email
      website
      owner {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        website
        subscription {
          id
          name
          price
          recurring
          startDate
          status
          lastPaidAt
          options
          createdAt
          updatedAt
        }
        fullyPaid
        ownerType
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
    }
  }
`;
export const updateAdvertiser = /* GraphQL */ `
  mutation UpdateAdvertiser(
    $input: UpdateAdvertiserInput!
    $condition: ModelAdvertiserConditionInput
  ) {
    updateAdvertiser(input: $input, condition: $condition) {
      id
      name
      logo
      options
      adverts {
        items {
          id
          name
          description
          shortID
          link
          image
          options
          createdAt
          updatedAt
          startAt
          endAt
          fullyPaid
        }
        nextToken
      }
      createdAt
      updatedAt
      address
      city
      province
      country
      phone
      email
      website
      owner {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        website
        subscription {
          id
          name
          price
          recurring
          startDate
          status
          lastPaidAt
          options
          createdAt
          updatedAt
        }
        fullyPaid
        ownerType
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
    }
  }
`;
export const deleteAdvertiser = /* GraphQL */ `
  mutation DeleteAdvertiser(
    $input: DeleteAdvertiserInput!
    $condition: ModelAdvertiserConditionInput
  ) {
    deleteAdvertiser(input: $input, condition: $condition) {
      id
      name
      logo
      options
      adverts {
        items {
          id
          name
          description
          shortID
          link
          image
          options
          createdAt
          updatedAt
          startAt
          endAt
          fullyPaid
        }
        nextToken
      }
      createdAt
      updatedAt
      address
      city
      province
      country
      phone
      email
      website
      owner {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        website
        subscription {
          id
          name
          price
          recurring
          startDate
          status
          lastPaidAt
          options
          createdAt
          updatedAt
        }
        fullyPaid
        ownerType
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
    }
  }
`;
export const createAdvert = /* GraphQL */ `
  mutation CreateAdvert(
    $input: CreateAdvertInput!
    $condition: ModelAdvertConditionInput
  ) {
    createAdvert(input: $input, condition: $condition) {
      id
      name
      description
      shortID
      link
      image
      options
      createdAt
      updatedAt
      startAt
      endAt
      fullyPaid
    }
  }
`;
export const updateAdvert = /* GraphQL */ `
  mutation UpdateAdvert(
    $input: UpdateAdvertInput!
    $condition: ModelAdvertConditionInput
  ) {
    updateAdvert(input: $input, condition: $condition) {
      id
      name
      description
      shortID
      link
      image
      options
      createdAt
      updatedAt
      startAt
      endAt
      fullyPaid
    }
  }
`;
export const deleteAdvert = /* GraphQL */ `
  mutation DeleteAdvert(
    $input: DeleteAdvertInput!
    $condition: ModelAdvertConditionInput
  ) {
    deleteAdvert(input: $input, condition: $condition) {
      id
      name
      description
      shortID
      link
      image
      options
      createdAt
      updatedAt
      startAt
      endAt
      fullyPaid
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      customer {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        socialLogin
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        isCompany
        website
      }
      lookId
      commentDate
      message
      storeId
      createdAt
      updatedAt
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      customer {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        socialLogin
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        isCompany
        website
      }
      lookId
      commentDate
      message
      storeId
      createdAt
      updatedAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      customer {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        socialLogin
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        isCompany
        website
      }
      lookId
      commentDate
      message
      storeId
      createdAt
      updatedAt
    }
  }
`;
