import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

// Manzia
import ALoginPage from "bulooka/LoginPage.js";


const useStyles = makeStyles(styles);

const aroutes = [
  {
    path: "/login-page",
    name: "Login Page",
    component: ALoginPage,
    layout: "/auth"
  },
];

function BulookaPage(props) {
  const{ src, height = '800', width = '1200' } = props;

  return(         
    <div>          
      <iframe src={src} height={height} width={width} title="Bulooka Home Page" sandbox="allow-scripts" />         
    </div>
  )
}

export default function Pages(props) {
  
  const { ...rest } = props;

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });

  const getRoutes = routes => {
    return aroutes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getActiveRoute = routes => {
    let activeRoute = "";
    // for (let i = 0; i < aroutes.length; i++) {
    //   if (aroutes[i].collapse) {
    //     let collapseActiveRoute = getActiveRoute(aroutes[i].views);
    //     if (collapseActiveRoute !== activeRoute) {
    //       return collapseActiveRoute;
    //     }
    //   } else {
    //     if (
    //       window.location.href.indexOf(aroutes[i].layout + aroutes[i].path) !== -1
    //     ) {
    //       return aroutes[i].name;
    //     }
    //   }
    // }
    return activeRoute;
  };

  function renderBulooka() {
    return (
      <BulookaPage 
        src="https://bulooka.co.ug/"
        height={`${window.outerHeight}`}
        width={`${window.outerWidth}`}
      />
    );
  }

  /*
      <Switch>
            {getRoutes(aroutes)}
            <Redirect from="/auth" to="/auth/login-page" />
          </Switch>
  */
  return (
    <div>
      <AuthNavbar brandText={getActiveRoute(aroutes)} {...rest} />
        {renderBulooka()}
    </div>
  );
}
