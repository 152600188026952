const appconstants = {
    "aws_project_region": "us-east-1",
    "aws_s3_accessKey": "AKIATFHLJTZVUL7MDZ6R",
    "aws_s3_secretKey": "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",
    "aws_s3_bucket_images": "bulooka-images",
    "aws_s3_bucket_images_path": "properties",
    "aws_s3_bucket_adimages_path": "adimages",
    "aws_s3_bucket_original_images_path": "original_unresized/properties",
    "aws_s3_bucket_original_adimages_path": "original_unresized/adimages",
    "aws_s3_endpoint": "s3.amazonaws.com",
    "aws_cdn_images": "images.bulooka.com",
    "bulooka_category_realestate": "gid://Bulooka/Category/1122334",
    "bulooka_category_vehicles": "gid://Bulooka/Category/1122335",
    
};


export default appconstants;