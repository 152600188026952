/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";
import ReportLayout from "layouts/Reports.js";
//import ALoginPage from "bulooka/LoginPage.js";
import LoginLayout from "layouts/Login.js";

// AWS
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";

const hist = createBrowserHistory();


// AWS config
Amplify.configure(awsconfig);

// <Redirect from="/" to="/auth/login-page" />
ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/admin" component={AdminLayout} />
      <Route exact path="/reports" component={ReportLayout} />
      <Route exact path="/login" component={LoginLayout} />
      <Route exact path="/" component={AuthLayout} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
