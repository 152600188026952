import React from "react";
import PropTypes from "prop-types";
import _ from "underscore";

// @material-ui/icons
import Place from "@material-ui/icons/Place";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Search from "@material-ui/icons/Search";
import Add from "@material-ui/icons/AddOutlined";
import Dropdown from "@material-ui/icons/ArrowDropDownOutlined";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Fab from '@material-ui/core/Fab';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

// AWS
import { API, graphqlOperation } from 'aws-amplify';

// Styles
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  dutyContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  vehicleSearch: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: '10px',
    }
  },
  modelTrim: {
    marginTop: '20px'
  },
  ...styles,
  ...customSelectStyle
};

const VEHICLES_BYYEAR = `query GetVehiclesByYear($year: Int!) {
  vehiclesByYear(year: $year, limit: 100) {
    items {
      make
    }
  }
}
`;

const VEHICLES_BYYEARMAKE = `query GetVehiclesByYearMake($year: Int!, $make: String!) {
  vehiclesByYearMake(make: $make, year: { eq: $year }, limit: 100) {
    items {
      id
      model
      trim
    }
  }
}
`;


/*
type Vehicle @model(subscriptions: null) {
	id: ID!
	title: String!
	slug: String
	options: String
	owner: Owner! @connection(name: "Vehicles")
	condition: String!
	purpose: VehiclePurpose!
	price: String!
	intPrice: Int!
	currencyCode: String!
	category: Category! @connection
	vehicleType: VehicleType!
	makeModel: VehicleMakeModel @connection
	year: Int
	mileage: Int
	cityMPG: Int
	highwayMPG: Int
	exterior: String
	interior: String
	features: [VehicleFeature!] @connection(name: "VehicleFeatures")
	customers: [CustomerVehicle!] @connection(name: "VehicleCustomers")
	sellerDescription: String
	isDealership: Boolean,
	featuredImage: String!
	images: [String!]
	createdAt: String!
	updatedAt: String!
	address: String!
	addressNumber: String
	city: String!
	district: String
	province: String
	country: String
	status: String
	search: String
	views: Int
	videos: [String!]
	thumbnails: [String!]
	vehicleViews: [VehicleView!] @connection(keyName: "byVehicle", fields: ["id"])
	vehicleViewsByDate: [VehicleView!] @connection(keyName: "byVehicleByDate", fields: ["id"])
}

type VehicleMakeModel {
	id: ID!
	make: String!
	logo: String
	description: String
	options: String
	model: String!
	series: String
}

type VehicleFeatures {
	id: ID!
	name: String!
	icon: String
	type: VehicleFeatureType
	description: String
	options: String
	features: [VehicleFeature!] @connection(name: "FeaturesVehicle")
}

enum VehicleFeatureType {
	BodyStyle
	DriveType
	FuelType
	FuelEconomy
	Transmission
	Cylinders
	ExteriorColor
	InteriorColor
	ExteriorFeature
	InteriorFeature
	Technology
	Safety
}

type VehicleFeature @model(subscriptions: null) {
	id: ID!
	vehicle: Vehicle! @connection(name: "VehicleFeatures")
	feature: VehicleFeatures! @connection(name: "FeaturesVehicle")
	createdAt: String
	options: String
}

enum VehicleType {
	SUV
	Crossover
	Sedan
	Truck
	Hatchback
	Convertible
	Luxury
	Coupe
	Electric
	Hybrid
	Van
	Minivan
	Wagon
}

enum VehiclePurpose {
	Sale
	Lease
}

*/

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyName: "",
      propertyNameState: "",
      propertyDescription: "",
      propertyDescriptionState: "",
      price: "",
      priceState: "",
      currencyCode: "",
      currencyCodeState: "",
      condition: null,
      conditionError: false,
      make: "",
      makeState: "",
      model: "",
      modelState: "",
      year: "",
      yearState: "",
      mileage: "",
      mileageState: "",
      cityMPG: "",
      cityMPGState: "",
      highwayMPG: "",
      highwayMPGState: "",
      exteriorColor: "",
      exteriorColorState: "",
      interiorColor: "",
      interiorColorState: "",
      trim: "",
      trimState: "",
      vehicleType: null,
      vehicleTypeError: false,
      selectedPurpose: null,
      selectedPurposeError: false,
      checkIsFeatured: false,
      checkIsDealership: false,
      checkIsForeclosed: false,
      checkIsDeal: false,
      driveType: "",
      driveTypeState: "",
      fuelType: "",
      fuelTypeState: "",
      driveSetup: "",
      driveSetupState: "",
      transmission: "",
      transmissionState: "",
      isDutyPaid: true,
      engineSize: "",
      engineSizeState: "",
      yearSearching: false,
      modelSearching: false,
      makes: [],
      models: [],
      trims: [],
      toggleModelTrim: false
    };

    this.handleSearch = this.handleSearch.bind(this);

  }

  componentDidMount() {
    const { vehicle } = this.props;
    if (_.isEmpty(vehicle)) return;
    const { title, sellerDescription, purpose, vehicleType, price, 
      currencyCode, condition, make, model, trim, exterior, interior,
      driveType, driveSetup, fuelType, transmission } = this.convertStringNullToEmptyValues(vehicle);
    const { isFeatured = false, isDeal = false, isForeclosed = false, 
      isDealership = false, year, cityMPG, highwayMPG, mileage, engineSize,
      isDutyPaid = false } = vehicle;
    
    this.setState({
      propertyName: title, 
      propertyDescription: sellerDescription, 
      selectedPurpose: purpose,
      makes: !_.isEmpty(make) ? [make] : [],
      models: !_.isEmpty(model) ? [model] : [],
      trims: _.isEmpty(trim) ? [trim] : [], 
      trim: _.isEmpty(trim) ? "": trim, 
      price, vehicleType, condition, make, model,
      exteriorColor: _.isEmpty(exterior) ? "" : exterior,
      interiorColor: _.isEmpty(interior) ? "" : interior, 
      propertyNameState: _.isEmpty(title) ? "error" : "success",
      priceState: _.isEmpty(price) ? "error" : "success",
      propertyDescriptionState: _.isEmpty(sellerDescription) ? "error" : "success",
      checkIsFeatured: isFeatured === true ? true : false,
      checkIsDealership: isDealership === true ? true : false,
      checkIsDeal: isDeal === true ? true : false,
      checkIsForeclosed: isForeclosed === true ? true : false,
      isDutyPaid: isDutyPaid === true ? true : false,
      currencyCode,
      currencyCodeState: _.isEmpty(currencyCode) ? "" : "success", 
      driveSetup: _.isEmpty(driveSetup) ? "" : driveSetup, 
      driveType: _.isEmpty(driveType) ? "" : driveType, 
      fuelType: _.isEmpty(fuelType) ? "" : fuelType, 
      transmission: _.isEmpty(transmission) ? "" : transmission,
      mileage: _.isNumber(mileage) ? `${mileage}` : "",
      mileageState: _.isNumber(mileage) ? "success" : "",
      year: _.isNumber(year) ? `${year}` : "",
      yearState: _.isNumber(year) ? "success" : "",
      makeState: _.isEmpty(make) ? "" : "success",
      modelState: _.isEmpty(model) ? "" : "success",
      driveTypeState: _.isEmpty(driveType) ? "" : "success",
      fuelTypeState: _.isEmpty(fuelType) ? "" : "success",
      transmissionState: _.isEmpty(transmission) ? "" : "success",
      cityMPG: _.isNumber(cityMPG) ? `${cityMPG}` : "",
      highwayMPG: _.isNumber(highwayMPG) ? `${highwayMPG}` : "",
      engineSize: _.isNumber(engineSize) ? `${engineSize}` : ""
    });
  }

  componentDidUpdate(prevProps, prevState) {
      if (this.state.make !== prevState.make || this.state.year !== prevState.year) {
        this.handleModelSearch();
      }
  }

  convertStringNullToEmptyValues(obj) {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "string" && obj[key] === "null") {
        obj[key] = "";
      } else if (typeof obj[key] === "object" && !_.isEmpty(obj[key])) {
        this.convertStringNullToEmptyValues(obj[key]);
      }
    });
    return obj;
  }

  sendState() {
    return this.state;
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains only numbers
  verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  // verifies if value is a valid URL
  verifyUrl = value => {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  };

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChecked = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleSearch(e) {
    const { year, yearState } = this.state;
    if (yearState !== "success") return;

    // Fetch
    this.setState({ yearSearching: true });
    API.graphql(graphqlOperation(VEHICLES_BYYEAR, { year: parseInt(year, 10), limit: 100 }
    )).then(({ data }) => {
        if (data && data.vehiclesByYear && data.vehiclesByYear.items.length > 0) {
          const makes = data.vehiclesByYear.items.map((item) => item.make );

          // Sort
          makes.sort(function(a, b) {
            var nameA = a.toUpperCase(); // ignore upper and lowercase
            var nameB = b.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) { return -1; }
            if (nameA > nameB) { return 1; }
            // names must be equal
            return 0;
          });

          this.setState({ makes: _.uniq(makes, true), yearSearching: false });
        } else {
          this.setState({ yearSearching: false });
        }
    }).catch((error) => {
        this.setState({ yearSearching: false });
        console.log("Error fetching makes for year: %d", year);
    });

  }
 
  handleModelSearch() {
    const { year, yearState, make, makeState } = this.state;
    if (yearState !== "success" || makeState !== "success") return;

    // Fetch
    this.setState({ modelSearching: true });

    API.graphql(graphqlOperation(VEHICLES_BYYEARMAKE, { year: parseInt(year, 10), make, limit: 1000 }
    )).then(({ data }) => {
        if (data && data.vehiclesByYearMake && data.vehiclesByYearMake.items.length > 0) {
          const models = [];
          const trims = [];
          data.vehiclesByYearMake.items.forEach((item) => {
            const { id, model, trim } = item; 
            models.push(model);
            if (!_.isEmpty(trim)) trims.push(trim);
          });

          // Sort
          models.sort(function(a, b) {
            let nameA = a.toUpperCase(); // ignore upper and lowercase
            let nameB = b.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) { return -1; }
            if (nameA > nameB) { return 1; }
            // names must be equal
            return 0;
          });

          trims.sort(function(a, b) {
            let nameA = a.toUpperCase(); // ignore upper and lowercase
            let nameB = b.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) { return -1; }
            if (nameA > nameB) { return 1; }
            // names must be equal
            return 0;
          });

          this.setState({ 
            models: _.uniq(models, true), trims: _.uniq(trims, true), modelSearching: false,
          });
        } else {
          this.setState({ modelSearching: false, models: [], trims: [] });
        }
    }).catch((error) => {
        this.setState({ modelSearching: false });
        console.log("Error fetching models for year: %d and make: %s", year, make);
    });
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "year":
        if (this.verifyNumber(event.target.value) && 
          event.target.value.length === 4 && 
          (event.target.value.startsWith("19") || event.target.value.startsWith("20"))) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "url":
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    switch (stateName) {
      case "selectedPurpose":
          this.setState({ selectedPurposeError: _.isEmpty(event.target.value) });
        break;
      case "condition":
        this.setState({ conditionError: _.isEmpty(event.target.value) });
        break;
      case "vehicleType":
        this.setState({ vehicleTypeError: _.isEmpty(event.target.value) });
          break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }


  isValidated() {
    //return true;
    let isValid = true;
    isValid = isValid && this.state.propertyNameState === "success";
    isValid = isValid && this.state.priceState === "success";
    isValid = isValid && this.state.currencyCodeState === "success";
    isValid = isValid && this.state.propertyDescriptionState === "success";
    isValid = isValid && this.state.makeState === "success";
    isValid = isValid && this.state.modelState === "success";
    isValid = isValid && this.state.yearState === "success";
    isValid = isValid && this.state.mileageState === "success";
    isValid = isValid && this.state.driveTypeState === "success";
    isValid = isValid && this.state.fuelTypeState === "success";
    isValid = isValid && this.state.transmissionState === "success";
    isValid = isValid &&  !_.isEmpty(this.state.selectedPurpose);
    isValid = isValid && !_.isEmpty(this.state.vehicleType);
    isValid = isValid && !_.isEmpty(this.state.condition);

    if (!isValid) {
        if (this.state.propertyNameState !== "success") {
          this.setState({ propertyNameState: "error" });
        }
        if (this.state.propertyDescriptionState !== "success") {
          this.setState({ propertyDescriptionState: "error" });
        }
        if (this.state.priceState !== "success") {
          this.setState({ priceState: "error" });
        }
        if (this.state.currencyCodeState !== "success") {
          this.setState({ currencyCodeState: "error" });
        }
        if (this.state.makeState !== "success") {
          this.setState({ makeState: "error" });
        }
        if (this.state.modelState !== "success") {
          this.setState({ modelState: "error" });
        }
        if (this.state.yearState !== "success") {
          this.setState({ yearState: "error" });
        }
        if (this.state.mileageState !== "success") {
          this.setState({ mileageState: "error" });
        }
        if (this.state.driveTypeState !== "success") {
          this.setState({ driveTypeState: "error" });
        }
        if (this.state.driveSetupState !== "success") {
          this.setState({ driveSetup: "error" });
        }
        if (this.state.fuelTypeState !== "success") {
          this.setState({ fuelTypeState: "error" });
        }
        if (this.state.transmissionState !== "success") {
          this.setState({ transmissionState: "error" });
        }
        if (_.isEmpty(this.state.selectedPurpose))
        this.setState({ selectedPurposeError: true });

        if (_.isEmpty(this.state.vehicleType))
        this.setState({ vehicleTypeError: true });

        if (_.isEmpty(this.state.condition))
        this.setState({ conditionError: true });
    }

    return isValid;
  }

  /*
  SUV
	Crossover
	Sedan
	Truck
	Hatchback
	Convertible
	Luxury
	Coupe
	Electric
	Hybrid
	Van
	Minivan
  Wagon
  Bus
  */
 renderVehicleType() {
  const { classes } = this.props;
  const options = ["Bus", "Convertible", "Coupe", "Crossover", "Electric", "Hatchback", "Hybrid", "Luxury", "Minivan", "Sedan", "SUV", "Truck", "Van", "Wagon"]
    .map((option, key) => {
    return (
      <div
      className={classes.checkboxAndRadio} key={key}>
        <FormControlLabel
          control={
            <Radio
              checked={this.state.vehicleType === option}
              onChange={(event) => this.change(event, "vehicleType")}
              value={option}
              name={option}
              aria-label={option}
              icon={
                <FiberManualRecord
                  className={classes.radioUnchecked}
                />
              }
              checkedIcon={
                <FiberManualRecord
                  className={classes.radioChecked}
                />
              }
              classes={{
                checked: classes.radio,
                root: classes.radioRoot
              }}
            />
          }
          classes={{
            label: classes.label,
            root: classes.labelRoot
          }}
          label={option}
        />
    </div>
    );
  });

  return options;
}


  renderCondition() {
      const { classes } = this.props;
      const options = ["New", "Used", "Certified" ]
        .map((option, key) => {
        return (
          <div
          className={classes.checkboxAndRadio} key={key}>
            <FormControlLabel
              control={
                <Radio
                  checked={this.state.condition === option}
                  onChange={(event) => this.change(event, "condition")}
                  value={option}
                  name={option}
                  aria-label={option}
                  icon={
                    <FiberManualRecord
                      className={classes.radioUnchecked}
                    />
                  }
                  checkedIcon={
                    <FiberManualRecord
                      className={classes.radioChecked}
                    />
                  }
                  classes={{
                    checked: classes.radio,
                    root: classes.radioRoot
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot
              }}
              label={option}
            />
        </div>
        );
      });

      return options;
  }

  renderPurpose() {
    const { classes } = this.props;
    const options = [ "Sale", "Lease" ].map((option, key) => {
      const optionLabel = `For ${option}`;
      return (
        <div
        className={classes.checkboxAndRadio} key={key}>
          <FormControlLabel
            control={
              <Radio
                checked={this.state.selectedPurpose === option}
                onChange={(event) => this.change(event, "selectedPurpose")}
                value={option}
                name={option}
                aria-label={optionLabel}
                icon={
                  <FiberManualRecord
                    className={classes.radioUnchecked}
                  />
                }
                checkedIcon={
                  <FiberManualRecord
                    className={classes.radioChecked}
                  />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot
            }}
            label={optionLabel}
          />
      </div>
      );
    });

    return options;
}


renderCheckedOptions() {

    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.checkIsFeatured}
              onChange={this.handleChecked('checkIsFeatured')}
              value="checkedIsFeatured"
              color="primary"
            />
          }
          label="Featured"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.checkIsDealership}
              onChange={this.handleChecked('checkIsDealership')}
              value="checkedIsDealership"
              color="primary"
            />
          }
          label="Is Dealership"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.checkIsDeal}
              onChange={this.handleChecked('checkIsDeal')}
              value="checkedIsDeal"
              color="primary"
            />
          }
          label="Special/Deal"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.checkIsForeclosed}
              onChange={this.handleChecked('checkIsForeclosed')}
              value="checkedIsForeclosed"
              color="primary"
            />
          }
          label="Foreclosure"
        />
        
      </FormGroup>
    );
}

/*
    endAdornment: (
      <InputAdornment
        position="end"
        className={classes.inputAdornment}
      >
        <Button
          color="info"
          onClick={this.handleSearch}
          size="sm"
        > <Search /> Search
        </Button>
      </InputAdornment>
    )
*/
renderMakeModel() {
    const { classes, makes } = this.props;
    const { models, trims, yearSearching, modelSearching, toggleModelTrim } = this.state;
    
    return (
      <GridContainer>
        <GridItem xs={1}>
          <Fab 
            size="small" 
            aria-label="add manually" 
            className={classes.modelTrim}
            onClick={(e) => this.setState({ toggleModelTrim: !toggleModelTrim })}
          >
            {toggleModelTrim ? <Dropdown /> : <Add /> }
          </Fab>
        </GridItem>

        <GridItem xs={3}>

        <FormControl 
          fullWidth 
          className={classes.selectFormControl}
          error={this.state.makeState === "error"}
        >
              <InputLabel
                htmlFor="vehicle-make"
                className={classes.selectLabel}
              >
                Make
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={this.state.make}
                onChange={event => this.change(event, "make", "length", 2)}
                inputProps={{
                  name: "make",
                  id: "vehicle-make",
                  value: this.state.make ? this.state.make : ""
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Choose Make
                </MenuItem>
                {makes.map((make, idx) => {
                  return (
                    <MenuItem
                      key={idx}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={make}
                    >
                      {make}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
        </GridItem>

        <GridItem xs={2}>
          <div className={classes.vehicleSearch}>
              { yearSearching && <CircularProgress /> }
              <CustomInput
                success={this.state.yearState === "success"}
                error={this.state.yearState === "error"}
                labelText={
                  <span>
                    Year
                  </span>
                }
                id="vehicle-year"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event => this.change(event, "year", "year"),
                  value: this.state.year,
                  placeholder: "e.g 2019",
              }}
            />
          </div>
        </GridItem>

        <GridItem xs={3}>
          { toggleModelTrim ?
            (
              <CustomInput
                success={this.state.modelState === "success"}
                error={this.state.modelState === "error"}
                labelText={
                  <span>
                    Model
                  </span>
                }
                id="vehicle-model-manual"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event => this.change(event, "model", "length", 2),
                  value: this.state.model,
                  placeholder: "e.g Corolla",
              }}
            />
            ) :
            (
              <div className={classes.vehicleSearch}>
              {modelSearching && <CircularProgress />}
              <FormControl 
                fullWidth 
                className={classes.selectFormControl}
                error={this.state.modelState === "error"}
              >
                  <InputLabel
                    htmlFor="vehicle-model"
                    className={classes.selectLabel}
                  >
                    Model
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.model}
                    onChange={event => this.change(event, "model", "length", 2)}
                    inputProps={{
                      name: "model",
                      id: "vehicle-model",
                      value: this.state.model ? this.state.model : ""
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Choose Model
                    </MenuItem>
                    {models.map((item, idx) => {
                      return (
                        <MenuItem
                          key={idx}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={item}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            )
          }
          
        </GridItem>
        <GridItem xs={3}>
          {toggleModelTrim ? 
            (
              <CustomInput
                  success={this.state.trimState === "success"}
                  error={this.state.trimState === "error"}
                  labelText={
                    <span>
                      Trim
                    </span>
                  }
                  id="vehicle-trim-manual"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => this.change(event, "trim", "length", 3),
                    value: this.state.trim,
                    placeholder: "e.g 4WD 4dr Sedan",
                }}
              />
            ) : 
            (
              <FormControl 
              fullWidth 
              className={classes.selectFormControl}
              error={this.state.trimState === "error"}
            >
                <InputLabel
                  htmlFor="vehicle-trim"
                  className={classes.selectLabel}
                >
                  Type (Trim)
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.select
                  }}
                  value={this.state.trim}
                  onChange={event => this.change(event, "trim", "length", 3)}
                  inputProps={{
                    name: "trim",
                    id: "vehicle-trim",
                    value: this.state.trim ? this.state.trim : ""
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}
                  >
                    Choose Trim
                  </MenuItem>
                  {trims.map((item, idx) => {
                    return (
                      <MenuItem
                        key={idx}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={item}
                      >
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )
          }
          
        </GridItem>
      </GridContainer>
    );
}

renderMileage() {
  const { classes } = this.props;
  return (
    <GridContainer>
      
      <GridItem xs={4}>
        <CustomInput
              success={this.state.mileageState === "success"}
              error={this.state.mileageState === "error"}
              labelText={
                <span>
                  Mileage
                </span>
              }
              id="vehicle-mileage"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => this.change(event, "mileage", "number"),
                value: this.state.mileage
            }}
          />
      </GridItem>
      <GridItem xs={4}>
        <CustomInput
              success={this.state.cityMPGState === "success"}
              error={this.state.cityMPGState === "error"}
              labelText={
                <span>
                  City MPG
                </span>
              }
              id="vehicle-city-mpg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => this.change(event, "cityMPG", "number"),
                value: this.state.cityMPG
            }}
          />
      </GridItem>
      <GridItem xs={4}>
        <CustomInput
              success={this.state.highwayMPG === "success"}
              error={this.state.highwayMPG === "error"}
              labelText={
                <span>
                  Highway MPG
                </span>
              }
              id="vehicle-highway-mpg"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => this.change(event, "highwayMPG", "number"),
                value: this.state.highwayMPG
            }}
          />
      </GridItem>
      
    </GridContainer>
  );
}

/*
  driveType: String
	fuelType: String
	driveSetup: String
	transmission: String
	isDutyPaid: Boolean
	engineSize: Int
*/
renderFeatures() {
  const { classes } = this.props;
  return (
    <GridContainer>
      
      <GridItem xs={4}>
        <CustomInput
              success={this.state.interiorColorState === "success"}
              error={this.state.interiorColorState === "error"}
              labelText={
                <span>
                  Interior Color
                </span>
              }
              id="vehicle-int-color"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => this.change(event, "interiorColor", "length", 1),
                value: this.state.interiorColor
            }}
          />
      </GridItem>
      <GridItem xs={4}>
        <CustomInput
              success={this.state.exteriorColorState === "success"}
              error={this.state.exteriorColorState === "error"}
              labelText={
                <span>
                  Exterior Color
                </span>
              }
              id="vehicle-ext-color"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => this.change(event, "exteriorColor", "length", 2),
                value: this.state.exteriorColor
            }}
          />
      </GridItem>
      <GridItem xs={4}>

          <FormControl 
            fullWidth 
            className={classes.selectFormControl}
            error={this.state.driveTypeState === "error"}
          >
              <InputLabel
                htmlFor="drive-type"
                className={classes.selectLabel}
              >
                Drive Type
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={this.state.driveType}
                onChange={event => this.change(event, "driveType", "length", 3)}
                inputProps={{
                  name: "driveType",
                  id: "drive-type",
                  value: this.state.driveType
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Choose Drive Type
                </MenuItem>
                {
                  ["2WD", "4WD", "AWD"].map((item, idx) => {
                    return (
                      <MenuItem
                        key={idx}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={item}
                      >
                        {item}
                      </MenuItem>
                    );
                  }) 
                }
              </Select>
            </FormControl>
        </GridItem>

        <GridItem xs={4}>

          <FormControl 
            fullWidth 
            className={classes.selectFormControl}
            error={this.state.fuelTypeState === "error"}
          >
              <InputLabel
                htmlFor="fuel-type"
                className={classes.selectLabel}
              >
                Fuel Type
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={this.state.fuelType}
                onChange={event => this.change(event, "fuelType", "length", 3)}
                inputProps={{
                  name: "fuelType",
                  id: "fuel-type",
                  value: this.state.fuelType
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Choose Fuel Type
                </MenuItem>
                {
                  ["Diesel", "Petrol", "Electric"].map((item, idx) => {
                    return (
                      <MenuItem
                        key={idx}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={item}
                      >
                        {item}
                      </MenuItem>
                    );
                  }) 
                }
              </Select>
            </FormControl>
        </GridItem>

        <GridItem xs={4}>

          <FormControl 
            fullWidth 
            className={classes.selectFormControl}
            error={this.state.driveSetupState === "error"}
          >
              <InputLabel
                htmlFor="drive-setup"
                className={classes.selectLabel}
              >
                Drive Setup
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={this.state.driveSetup}
                onChange={event => this.change(event, "driveSetup", "length", 3)}
                inputProps={{
                  name: "driveSetup",
                  id: "drive-setup",
                  value: this.state.driveSetup ? this.state.driveSetup : ""
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                >
                  Choose Drive Setup
                </MenuItem>
                {
                  ["Right-hand Drive", "Left-hand Drive" ].map((item, idx) => {
                    return (
                      <MenuItem
                        key={idx}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected
                        }}
                        value={item}
                      >
                        {item}
                      </MenuItem>
                    );
                  }) 
                }
              </Select>
            </FormControl>
        </GridItem>
        <GridItem xs={4}>

        <FormControl 
          fullWidth 
          className={classes.selectFormControl}
          error={this.state.transmissionState === "error"}
        >
            <InputLabel
              htmlFor="transmission-type"
              className={classes.selectLabel}
            >
              Transmission
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={this.state.transmission}
              onChange={event => this.change(event, "transmission", "length", 3)}
              inputProps={{
                name: "transmission",
                id: "transmission-type",
                value: this.state.transmission
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Choose Transmission
              </MenuItem>
              {
                [ "4-speed Automatic", "6-speed Automatic", "Manual" ].map((item, idx) => {
                  return (
                    <MenuItem
                      key={idx}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  );
                }) 
              }
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={4}>
            <CustomInput
              success={this.state.engineSizeState === "success"}
              error={this.state.engineSizeState === "error"}
              labelText={
                <span>Engine Size</span>
              }
              id="engine-size"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => this.change(event, "engineSize", "number"),
                value: this.state.engineSize
            }}
          />
        </GridItem>
      
    </GridContainer>
  );
}

  /*
    <GridItem xs={12} sm={4}>
          <PictureUpload />
        </GridItem>
  */
  render() {
    const { classes } = this.props;
    //const { selectedPropertyType } = this.state;
    //const notLand = !_.isEmpty(selectedPropertyType) && selectedPropertyType !== 'Land';

    return (
      <GridContainer justify="center">
        
        <GridItem xs={12} sm={10}>
          <CustomInput
            success={this.state.propertyNameState === "success"}
            error={this.state.propertyNameState === "error"}
            labelText={
              <span>Vehicle Title<small> (required)</small></span>
            }
            id="propertyname"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "propertyName", "length", 3),
              value: this.state.propertyName,
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Place className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <GridContainer>
            <GridItem xs={4}>
                <CustomInput
                  success={this.state.priceState === "success"}
                  error={this.state.priceState === "error"}
                  labelText={
                    <span>Price</span>
                  }
                  id="price"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => this.change(event, "price", "number"),
                    value: this.state.price
                }}
              />
            </GridItem>
            <GridItem xs={4}>

            <FormControl 
              fullWidth 
              className={classes.selectFormControl}
              error={this.state.currencyCodeState === "error"}
            >
                  <InputLabel
                    htmlFor="currency-code"
                    className={classes.selectLabel}
                  >
                    Currency Code
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.currencyCode}
                    onChange={event => this.change(event, "currencyCode", "length", 3)}
                    inputProps={{
                      name: "currencyCode",
                      id: "currency-code",
                      value: this.state.currencyCode
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Choose Currency
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="UGX"
                    >
                      UGX
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="USD"
                    >
                      USD
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="GBP"
                    >
                      GBP
                    </MenuItem>
                  </Select>
                </FormControl>
            </GridItem>
            <GridItem xs={4}>
              <div className={classes.dutyContainer}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.isDutyPaid}
                      onChange={this.handleChecked('isDutyPaid')}
                      value="isDutyPaid"
                      color="primary"
                    />
                  }
                  label="Duty Paid"
                />
              </div>
            </GridItem>
          </GridContainer>
            <CustomInput
              success={this.state.propertyDescriptionState === "success"}
              error={this.state.propertyDescriptionState === "error"}
              labelText={
                <span>
                  Vehicle Description <small>*</small>
                </span>
              }
              id="propertydescription"
              formControlProps={{
                fullWidth: true,
                variant: 'outlined'
              }}
              inputProps={{
                onChange: event => this.change(event, "propertyDescription", "length", 10),
                multiline: true,
                rows: "5",
                value: this.state.propertyDescription
              }}
            />
          <br />
          <br />
          <div>
            <FormLabel required>
              Make and Model
            </FormLabel>
          </div>
          {this.renderMakeModel()}
          <br />
          <br />
          <div>
            <FormLabel required>
              Mileage
            </FormLabel>
          </div>
          {this.renderMileage()}
          <br />
          <br />
          <div>
            <FormLabel required>
              Features
            </FormLabel>
          </div>
          {this.renderFeatures()}
          <br />
          <br />
          <div>
            <FormLabel required error={this.state.selectedPurposeError}>
              Listing Type
            </FormLabel>
          </div>
          {this.renderPurpose()}
          <br />
          <br />
          <div>
            <FormLabel required error={this.state.selectedPropertyTypeError}>
              Vehicle Type
            </FormLabel>
          </div>
          {this.renderVehicleType()}
          <br />
          <br />
          <div>
            <FormLabel required error={this.state.selectedPropertyTypeError}>
              Condition
            </FormLabel>
          </div>
          {this.renderCondition()}
          <br />
          <br />
          <div>
            <FormLabel required>
              Vehicle Options
            </FormLabel>
          </div>
          <br />
          {this.renderCheckedOptions()}
          <br />
        </GridItem>
      </GridContainer>
    );
  }
}

Step1.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Step1);
