import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

// Manzia
import ReportPage from "bulooka/Reports/ReportPage.js";
import ReportDashboard from "bulooka/Reports/ReportDashboard.js";

import register from "assets/img/register.jpeg";
import login from "assets/img/login.jpeg";
import lock from "assets/img/lock.jpeg";
import error from "assets/img/clint-mckoy.jpg";
import pricing from "assets/img/bg-pricing.jpeg";

const useStyles = makeStyles(styles);

const _ = require("underscore");

const aroutes = [
  {
    path: "/login-page",
    name: "Login Page",
    component: ReportPage,
    layout: "/auth"
  },
];

export default function Pages(props) {
  const { ...rest } = props;
  const [loggedID, setLoggedID] = React.useState("");

  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();

  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });

  const getRoutes = routes => {
    return aroutes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBgImage = () => {
    if (window.location.pathname.indexOf("/auth/register-page") !== -1) {
      return register;
    } else if (window.location.pathname.indexOf("/auth/login-page") !== -1) {
      return register;
    } else if (window.location.pathname.indexOf("/auth/pricing-page") !== -1) {
      return pricing;
    } else if (
      window.location.pathname.indexOf("/auth/lock-screen-page") !== -1
    ) {
      return lock;
    } else if (window.location.pathname.indexOf("/auth/error-page") !== -1) {
      return error;
    } else {
      return register;
    }
  };

  function viewReports(logID) {
    setLoggedID(logID);
  }
  
  /*
      <Switch>
            {getRoutes(aroutes)}
            <Redirect from="/auth" to="/auth/login-page" />
          </Switch>
  */

  return (
    <div>
        { !_.isEmpty(loggedID) ? 
          (
            <React.Fragment>
              
                <ReportDashboard ownerId={loggedID} history={props.history} />
               
                <Footer />
            </React.Fragment>
             ) :
            (
              <React.Fragment>
                <AuthNavbar brandText={"BULOOKA REPORTS"} {...rest} />
                <div className={classes.wrapper} ref={wrapper}>
                    <div
                      className={classes.fullPage}
                      style={{ backgroundImage: "url(" + getBgImage() + ")" }}
                    >
                      <ReportPage loggedHandler={viewReports} />
                      <Footer white />
                    </div>
                </div>
              </React.Fragment>
              
            )
        }

    </div>
  );
}
