import React from "react";
import PropTypes from "prop-types";
import _ from "underscore";

// GraphQL
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from 'graphql/mutations';

// @material-ui/icons
import Place from "@material-ui/icons/Place";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Business from "@material-ui/icons/Business";
import Save from "@material-ui/icons/Save";
import Language from "@material-ui/icons/Language";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import CircularProgress from '@material-ui/core/CircularProgress';
import FormLabel from "@material-ui/core/FormLabel";

import SweetAlert from "react-bootstrap-sweetalert";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import ImageUpload from 'components/CustomUpload/ImageUpload';
import Button from "components/CustomButtons/Button.js";


// Styles
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.js";

// Bulooka
import AppConstants from "variables/constants.js";

// Utils
const moment = require('moment');
const uuidv1 = require('uuid/v1');

// AWS
const AWS = require('aws-sdk');

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  phoneSearch: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: '10px',
    }
  },
  centered: {
    textAlign: 'center'
  },
  saveBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      marginRight: '10px',
    }
  },
  textRight: {
    textAlign: 'right'
  },
  ...styles,
  ...customSelectStyle,
  ...wizardStyle,
  ...buttonStyle
};

/*
type Advert @model(subscriptions: null) {
	id: ID!
	name: String!
	description: String
	shortID: String
	link: String
	image: String
	options: String
	createdAt: String
	updatedAt: String
	startAt: String
	endAt: String
	fullyPaid: Boolean

}
*/

const AD_OPTIONs = _.sortBy([ 
  { id: 7, label: "NHCC Equipment", screen: "NHCC", placementOptions: []},
  { id: 6, label: "Property Managers", screen: "propertyManager", placementOptions: []}, 
  { id: 5, label: "Interior & Exterior Decor", screen: "propertyDecor", placementOptions: [] }, 
  { id: 4, label: "Hardware Dealer", screen: "propertyHardware", placementOptions: [] }, 
  { id: 3, label: "Vehicle Promos", screen: "VehicleDealer", placementOptions: []}, 
  { id: 2, label: "Hotel Tab", screen: "Hotel", placementOptions: [
    { position: "Top Banner", checkedValue: "HomeBannerAds"}
  ] }, 
  { id: 1, label: "Landing Page", screen: "Categories", 
    placementOptions: [ 
      { position: "Top Banner", checkedValue: "HomeBannerAds"}, 
      { position: "Bottom Ads - Top Row", checkedValue: "topRow" },
      { position: "Bottom Ads - Bottom Row", checkedValue: "bottomRow"}
    ] }
], 'id');

class CreateAdvert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adtitle: "",
      adtitleState: "",
      adImage: "",
      adImageState: "",
      adImageFile: null,
      adImageS3key: "",
      adlink: "",
      adlinkState: "",
      adDescription: "",
      adDescriptionState: "",
      ownerId: "",
      ownerIdState: "",
      selectedAdScreen: null,
      selectedAdPlacement: null,
      alert: null,
      saving: false
    };

    // AWS S3
    this.s3client = new AWS.S3({apiVersion: '2006-03-01', region: "us-east-1",
      accessKeyId: AppConstants["aws_s3_accessKey"], secretAccessKey: AppConstants["aws_s3_secretKey"]
    });
    
  }
  

  componentDidMount() {
    const { location: { state} } = this.props;
    if (_.isEmpty(state) || _.isEmpty(state.advert)) return;

    const convertedAdvert = this.convertStringNullToEmptyValues(state.advert);
    
    const { name = "", image = "", options = {}, link = "", description = "" } = convertedAdvert;

    const optJson = JSON.parse(options);
    // selectedAdvertiserType: _.isEmpty(optJson) ? "propertyManager" : optJson.type,
    this.setState({
      adtitle: _.isEmpty(name) ? "" : name, 
      adImage: !_.isEmpty(image) ? image : "https://bulooka-images.s3.amazonaws.com/sample/property-placeholder.png", 
      adlink: _.isEmpty(link) ? "" : link, 
      adDescription: _.isEmpty(description) ? "" : description, 
      ownerId: _.isEmpty(optJson) || _.isEmpty(optJson.ownerId) ? "" : optJson.ownerId,
      adtitleState: _.isEmpty(name) ? "error" : "success",
      adImageState: _.isEmpty(image) ? "error" : "success",
      selectedAdScreen: _.isEmpty(optJson) ? null : optJson.screen,
      selectedAdPlacement: _.isEmpty(optJson) ? null : optJson.position,
    });
  }

  convertStringNullToEmptyValues(obj) {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "string" && obj[key] === "null") {
        obj[key] = "";
      } else if (typeof obj[key] === "object" && !_.isEmpty(obj[key])) {
        this.convertStringNullToEmptyValues(obj[key]);
      }
    });
    return obj;
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains only numbers
  verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  // verifies if value is a valid URL
  verifyUrl = value => {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  };

  verifyPhones = value => {
    let phones = value.split(",").map(item=>item.trim());
    return phones.every(item => this.verifyNumber(item));
  };

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChecked = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleImage = (file) => {
    if (file) {
      // const reader = new FileReader();

      // reader.onloadend = () => {
      //   //setImagePreviewUrl(reader.result);
      //   this.setState({ logo: reader.result, logoState: "success" });
      // };
      // reader.readAsDataURL(file);
      const { link, s3key } = this.createImageLinks(file);
      this.setState({ adImageFile: file, adImage: link, adImageState: "success", adImageS3key: s3key });
    }
  }


  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "phone":
        if (this.verifyPhones(event.target.value) && this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "url":
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }


  isValidated() {
    //return true;
    if (
      this.state.adtitleState === "success" &&
      this.state.adImageState === "success" && !_.isEmpty(this.state.selectedAdScreen)
    ) {
      return true;
    } else {
      if (this.state.adtitleState !== "success") {
        this.setState({ adtitleState: "error" });
      }
      if (this.state.adImageState !== "success") {
        this.setState({ adImageState: "error" });
      }
      if (this.state.adlinkState !== "success") {
        this.setState({ adlinkState: "error" });
      }
      if (this.state.ownerIdState !== "success") {
        this.setState({ ownerIdState: "error" });
      }
      
    }
    return false;
  }

  getOptions(advert) {
    const { selectedAdScreen, selectedAdPlacement, ownerId, adlink } = this.state;
    if (_.isEmpty(advert)) {

        const optjson = { type: selectedAdScreen, position: selectedAdPlacement };
        if (!_.isEmpty(ownerId)) { Object.assign(optjson, { ownerId }); }
        if (!_.isEmpty(adlink)) { Object.assign(optjson, { link: adlink }); }
        return JSON.stringify(optjson);

    } else {
      const { options } = advert;
      if (_.isEmpty(options)) {
        const optsjson = { type: selectedAdScreen, position: selectedAdPlacement };
        if (!_.isEmpty(ownerId)) { Object.assign(optsjson, { ownerId }); }
        if (!_.isEmpty(adlink)) { Object.assign(optsjson, { link: adlink }); }
        
        return JSON.stringify(optsjson);
      } else {
        const optJson = JSON.parse(options);
        Object.assign(optJson, { type: selectedAdScreen, position: selectedAdPlacement });
        if (!_.isEmpty(ownerId)) {
          Object.assign(optJson, { ownerId });
        }
        return JSON.stringify(optJson);
      }
    }
  }

  handleSave = (e) => {
    const { location: { state}, advertiserId } = this.props;
    const createMode = (_.isEmpty(state) || _.isEmpty(state.advert));

    if (!this.isValidated()) {
      this.showAlert({ show: true, type: "error", title: "Missing required fields",
        message: "One or more required fields has invalid values"
      });
      return;
    }

    // Create/Update
    const { adtitle, adImage, adDescription, adlink, adImageS3key, adImageFile } = this.state;
    const now = moment();
    const updateVerb = createMode ? "Created" : "Updated";

    // Start
    this.setState({ saving: true });

    API.graphql(graphqlOperation(createMode ?  mutations.createAdvert : mutations.updateAdvert, 
      { input: { 
        id: createMode ? `gid://Bulooka/Advert/${now.unix()}` : state.advert.id,
        name: adtitle,
        image: adImage,
        options: createMode ? this.getOptions() : this.getOptions(state.advert),
        updatedAt: now.format(),
        createdAt: createMode ? now.format() : state.advert.createdAt,
        link: adlink,
        description: adDescription,
        advertiserAdvertsId: createMode ? state.advertiser.id : advertiserId
    } } )).then(({ data }) => {
      if (data && !_.isEmpty(createMode ? data.createAdvert : data.updateAdvert)) {
          // Handle Image
          if (adImageFile) {

            this.saveImage({link: adImage, s3key: adImageS3key}, adImageFile).then((response) => {
              this.setState({ saving: false });
              this.showAlert({ show: true, type: "success", title: `Success: ${updateVerb} Advert`, message: `Advert was successfully ${updateVerb.toLowerCase()}.`});
            }).catch((error) => {
              console.log(error);
              this.setState({ saving: false });
              this.showAlert({ show: true, type: "error", title: `Error: ${updateVerb} Advert failed.`, message: `Error: Advert was NOT ${updateVerb.toLowerCase()}.`});
            });

          } else {
            this.setState({ saving: false });
            this.showAlert({ show: true, type: "success", title: `Success: ${updateVerb} Advert`, message: `Advert was successfully ${updateVerb.toLowerCase()}.`});
          }
      } else {
          this.setState({ saving: false });
          this.showAlert({ show: true, type: "error", title: `Error: ${updateVerb} missing data.`, message: `Error: Advert was NOT ${updateVerb.toLowerCase()}.`});
      }
    }).catch(() => {
        // Saved
        this.setState({ saving: false });
        this.showAlert({ show: true, type: "error", title: `Error: ${updateVerb} Advert failed.`, message: `Error: Advert was NOT ${updateVerb.toLowerCase()}.`})
    });
    
  }

  saveImage(imageKeys, imageFile) {
    //debugger;
    if (_.isEmpty(imageKeys)) { return Promise.resolve("No Advertiser Image"); }
    //const { link, s3key } = createImageLinks(imageFile, isFeatured);
    const { link, s3key } = imageKeys
    //debugger;

		// ACL: 'public-read'
		return this.s3client.upload({
			Bucket: AppConstants["aws_s3_bucket_images"], 
			Key: s3key,
      ACL: 'public-read',
      CacheControl: 'public, max-age=604800, immutable',
			Body: imageFile,
		}).promise();		
  }

  /*
  https://bulooka-images.s3.amazonaws.com/properties/house-kitende-f3e32490-0a37-11ea-847b-b9c7f55abaf5-b.jpg
  */
  createImageLinks(imageFile) {
    // Selected Image
		const lastDot = imageFile.name.lastIndexOf('.');
    const ext = imageFile.name.substring(lastDot + 1);
    const imgName = imageFile.name.substring(0, lastDot);
		const imageKey = this.createS3BucketKey(imgName, ext);
		const imageLink = `https://${AppConstants["aws_s3_bucket_images"]}.${AppConstants["aws_s3_endpoint"]}/${AppConstants["aws_s3_bucket_adimages_path"]}/${imageKey}`;
		const s3imageKey = `${AppConstants["aws_s3_bucket_original_adimages_path"]}/${imageKey}`;

		return { link: imageLink, s3key: s3imageKey };
  }

  createS3BucketKey(name, ext) {
		return name.replace(/\s/ig, "-").toLowerCase() + `-${uuidv1()}.${ext}`;
	}


  hideAlerts() {
		this.setState({ alert: null });
	}

	showAlert({ show = false, type = "success", title, message }) {
		
		this.setState({
			alert: (
			  <SweetAlert
          show={show}
          type={type}
          style={{ display: "block", marginTop: "-100px" }}
          title={title}
          onConfirm={() => this.hideAlerts()}
          onCancel={() => this.hideAlerts()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          >
          {message}
			  </SweetAlert>
			)
		});
	}

  renderAdScreens() {
      const { classes } = this.props;
      const options = AD_OPTIONs.map((option, key) => {
        return (
          <div
          className={classes.checkboxAndRadio} key={key}>
            <FormControlLabel
              control={
                <Radio
                  checked={this.state.selectedAdScreen === option.screen}
                  onChange={(event) => this.change(event, "selectedAdScreen")}
                  value={option.screen}
                  name={option.screen}
                  aria-label={option.screen}
                  icon={
                    <FiberManualRecord
                      className={classes.radioUnchecked}
                    />
                  }
                  checkedIcon={
                    <FiberManualRecord
                      className={classes.radioChecked}
                    />
                  }
                  classes={{
                    checked: classes.radio,
                    root: classes.radioRoot
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot
              }}
              label={option.label}
            />
        </div>
        );
      });

      return options;
  }

  renderAdPlacements() {
    const { classes } = this.props;
    const { selectedAdScreen } = this.state;
    if (_.isEmpty(selectedAdScreen)) { return (<div></div>); }

    const placement = _.findWhere(AD_OPTIONs, { screen: selectedAdScreen });
    if (_.isEmpty(placement) || _.isEmpty(placement.placementOptions)) { return (<div></div>); }

    const options = placement.placementOptions.map((option, key) => {
      return (
        <div
        className={classes.checkboxAndRadio} key={key}>
          <FormControlLabel
            control={
              <Radio
                checked={this.state.selectedAdPlacement === option.checkedValue}
                onChange={(event) => this.change(event, "selectedAdPlacement")}
                value={option.checkedValue}
                name={option.checkedValue}
                aria-label={option.position}
                icon={
                  <FiberManualRecord
                    className={classes.radioUnchecked}
                  />
                }
                checkedIcon={
                  <FiberManualRecord
                    className={classes.radioChecked}
                  />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot
            }}
            label={option.position}
          />
      </div>
      );
    });

    return options;
  }

  /*
    <GridItem xs={12} sm={4}>
          <PictureUpload />
        </GridItem>
  */
  render() {
    const { classes, location: { state } } = this.props;
    const { adImage, saving } = this.state;
    const updateVerb = !_.isEmpty(state.advertiser) ? "Create" : "Update";
    const advertiserName = !_.isEmpty(state.advertiser) ? state.advertiser.name : !_.isEmpty(state.advert) ? state.advert.name : "Advertiser";
    
    return (
      
        <React.Fragment>
          <br />
          <h4 className={classes.infoText}><strong>{`${updateVerb} Advert for ${advertiserName}`}</strong></h4>
          <br />
          {this.state.alert}
      <GridContainer justify="center">

          <GridItem xs={12}>
            <div className={classes.centered}>
              {_.isEmpty(adImage) ? 
              (<ImageUpload imageHandler={this.handleImage} />) : 
              (<ImageUpload initialImage={adImage} imageHandler={this.handleImage} />)
            }
            </div>
            
          </GridItem>
        
          <GridItem xs={12} sm={4}>

            <CustomInput
              success={this.state.adtitleState === "success"}
              error={this.state.adtitleState === "error"}
              labelText={
                <span>Title<small> (*)</small></span>
              }
              id="adtitle"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => this.change(event, "adtitle", "length", 3),
                value: this.state.adtitle,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Place className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                )
              }}
            />
            {/* <br />
            <br />
          <div>
            <FormLabel required>
              Advertiser Type
            </FormLabel>
          </div>
            {this.renderAdvertiserTypes()} */}
            </GridItem>

        <GridItem xs={12} sm={4}>
          <CustomInput
            success={this.state.ownerIdState === "success"}
            error={this.state.ownerIdState === "error"}
            labelText={
              <span>
                OwnerId
              </span>
            }
            id="ownerId"
            name="ownerId"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "ownerId", "number"),
              value: this.state.ownerId,
              placeholder: "e.g 1678900002345",
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Business className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem> 
        <GridItem xs={12} sm={4}>
          <CustomInput
            success={this.state.adlinkState === "success"}
            error={this.state.adlinkState === "error"}
            labelText={
              <span>
                Link
              </span>
            }
            id="adlink"
            name="adlink"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "adlink", "url"),
              value: this.state.adlink,
              placeholder: "e.g https://www.example.com/ad/property123",
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Language className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
        </GridItem>
        <GridItem xs={12}>
        <CustomInput
              success={this.state.adDescriptionState === "success"}
              error={this.state.adDescriptionState === "error"}
              labelText={
                <span>
                  Description
                </span>
              }
              id="adDescription"
              formControlProps={{
                fullWidth: true,
                variant: 'outlined'
              }}
              inputProps={{
                onChange: event => this.change(event, "adDescription", "length", 10),
                multiline: true,
                rows: "5",
                value: this.state.adDescription
              }}
            />
        </GridItem>
        <GridItem xs={12}>
          <div>
            <FormLabel required>
              Advert Screen
            </FormLabel>
          </div>
              {this.renderAdScreens()}
        </GridItem>
        <GridItem xs={12}>
          <div>
            <br />
            <FormLabel required>
              Advert Placement
            </FormLabel>
          </div>
              {this.renderAdPlacements()}
        </GridItem>
      </GridContainer>
      <br />
      <br />
      <div className={classes.saveBtn}>
        { saving && <CircularProgress /> }
          <Button 
            color="info"
            onClick={this.handleSave}
          >
            <Save /> {'Save Advert'}
          </Button>
      </div>
      
      </React.Fragment>
      
    );
  }
}

CreateAdvert.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(CreateAdvert);
