import React from "react";
import PropTypes from "prop-types";
import _ from "underscore";

// GraphQL
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from 'graphql/queries';

// @material-ui/icons
import Place from "@material-ui/icons/Place";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Search from "@material-ui/icons/Search";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

// Styles
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  hotelText: {
    fontWeight: "300",
    textAlign: "left"
  },
  hotelTextRed: {
    fontWeight: "300",
    textAlign: "left",
    color: 'red'
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
  phoneSearch: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginRight: '10px',
    }
  },
  ...styles,
  ...customSelectStyle
};

/*
type Property @model(subscriptions: null) {
	id: ID!
	name: String!
	slug: String
	options: String
	amenities: [PropertyAmenity!] @connection(name: "PropertyAmenities")
	category: Category! @connection
	owner: Owner! @connection(name: "Properties")
	customers: [CustomerProperty!] @connection(name: "PropertyCustomers")
  isFeatured: Boolean
  isOffPlan: Boolean
  isForeclosed: Boolean
  isDeal: Boolean
	propertyType: PropertyType!
	propertyPurpose: PropertyPurpose!
	currencyCode: String
	salePrice: String
	rentPrice: String
	description: String
	featuredImage: String!
	images: [String!]
	createdAt: String!
	updatedAt: String!
	address: String!
	city: String!
	district: String
	province: String
	country: String
	latitude: String
	longitude: String
	bathrooms: Int
	bedrooms: Int
	status: String
	size: String
  sizeUnits: String
  views
  search

}

*/

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      propertyName: "",
      propertyNameState: "",
      propertyDescription: "",
      propertyDescriptionState: "",
      price: "",
      priceState: "",
      currencyCode: "",
      size: "",
      sizeState: "",
      sizeUnits: "",
      selectedPropertyType: null,
      selectedPropertyTypeError: false,
      selectedBedrooms: null,
      selectedBathrooms: null,
      selectedPurpose: null,
      selectedPurposeError: false,
      checkIsFeatured: false,
      checkIsFeaturedBottom: false,
      checkIsOffPlan: false,
      checkIsForeclosed: false,
      checkIsDeal: false,
      checkIsEstate: false,
      checkIsHostel: false,
      checkIsHotelRoom: false,
      hotelStars: null,
      hotelId: "",
      hotelIdState: "",
      hotelName: "",
      hotelProperty: null,
      searchHotels: false
    };

  }
  

  componentDidMount() {
    const { property } = this.props;
    if (_.isEmpty(property)) return;
    const { name, description, propertyPurpose, propertyType, salePrice, rentPrice, size,
      sizeUnits, bathrooms, bedrooms, currencyCode } = this.convertStringNullToEmptyValues(property);
    const { isFeatured = false, isFeaturedBottom = false, isDeal = false, isForeclosed = false, 
      isOffPlan = false, isEstate = false, isHostel = false, isHotelRoom = false,
      hotelId, hotelStars = 0 } = property;
    const purpose = `${propertyPurpose}`;
    const type = `${propertyType}`;
    this.setState({
      propertyName: name, propertyDescription: description, selectedPurpose: purpose, 
      selectedPropertyType: type, price: purpose === 'Sale' ? salePrice : rentPrice, size, sizeUnits,
      selectedBathrooms: bathrooms ? `${bathrooms}` : null, 
      selectedBedrooms: bedrooms ? `${bedrooms}` : null, currencyCode, 
      propertyNameState: _.isEmpty(name) ? "error" : "success",
      priceState: _.isEmpty(salePrice) && _.isEmpty(rentPrice) ? "error" : "success",
      propertyDescriptionState: _.isEmpty(description) ? "error" : "success",
      checkIsFeatured: isFeatured ? true : false,
      checkIsFeaturedBottom: isFeaturedBottom ? true : false,
      checkIsOffPlan: isOffPlan ? true : false,
      checkIsDeal: isDeal ? true : false,
      checkIsForeclosed: isForeclosed ? true : false,
      checkIsEstate: isEstate ? true : false,
      checkIsHostel: isHostel ? true : false,
      checkIsHotelRoom: isHotelRoom ? true : false,
      hotelId,
      hotelStars: `${hotelStars}`
    });
  }

  convertStringNullToEmptyValues(obj) {
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === "string" && obj[key] === "null") {
        obj[key] = "";
      } else if (typeof obj[key] === "object" && !_.isEmpty(obj[key])) {
        this.convertStringNullToEmptyValues(obj[key]);
      }
    });
    return obj;
  }

  sendState() {
    return this.state;
  }
  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }
  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains only numbers
  verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  // verifies if value is a valid URL
  verifyUrl = value => {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  };

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChecked = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleHotelSearch = () => {
    const { hotelId } = this.state;
    this.setState({ searchHotels: true });

    const propertyId = `gid://Bulooka/Property/${hotelId}`;

    // Fetch
    API.graphql(graphqlOperation(queries.getProperty, 
      { id: propertyId }
    )).then(( { data } ) => {
      //debugger;
      if (data && !_.isEmpty(data.getProperty)) {
        const { name, owner } = data.getProperty;
        this.setState({
            hotelName: data.getProperty.name,
            hotelProperty: data.getProperty,
            searchHotels: false
        });
      } else {
        this.setState({ searchHotels: false, hotelName: "" });
      }
    }).catch((error) => {
      //console.log("Error fetching customer by phone: %s", JSON.stringify(error));
      this.setState({ searchHotels: false, hotelName: "" })
    });
      
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case "url":
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + "State"]: "success" });
        } else {
          this.setState({ [stateName + "State"]: "error" });
        }
        break;
      case 'hotel':
        if (this.verifyNumber(event.target.value) && 
          this.verifyLength(event.target.value, stateNameEqualTo)) {
            this.setState({ [stateName + "State"]: "success" });
          } else {
            this.setState({ [stateName + "State"]: "error" });
          }
        break;
      default:
        break;
    }
    switch (stateName) {
      case "selectedPropertyType":
          this.setState({ selectedPropertyTypeError: _.isEmpty(event.target.value ) });
        break;
      case "selectedPurpose":
          this.setState({ selectedPurposeError: _.isEmpty(event.target.value) });
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  }


  isValidated() {
    //return true;
    let validHotel = true;
    if (this.state.selectedPropertyType === 'Hotel') {
      validHotel = validHotel && !_.isEmpty(this.state.hotelStars);
    }

    if (this.state.checkIsHotelRoom === true) {
      validHotel = validHotel && this.state.hotelIdState === "success";
      validHotel = validHotel && !_.isEmpty(this.state.hotelName);
    }

    if (
      this.state.propertyNameState === "success" &&
      this.state.propertyDescriptionState === "success" &&
      this.state.priceState === "success" && !_.isEmpty(this.state.selectedPropertyType) &&
      !_.isEmpty(this.state.selectedPurpose) && !_.isEmpty(this.state.currencyCode) 
      && validHotel === true

    ) {
      return true;
    } else {
      if (this.state.propertyNameState !== "success") {
        this.setState({ propertyNameState: "error" });
      }
      if (this.state.propertyDescriptionState !== "success") {
        this.setState({ propertyDescriptionState: "error" });
      }
      if (this.state.priceState !== "success") {
        this.setState({ priceState: "error" });
      }
      if (this.state.hotelIdState !== "success") {
        this.setState({ hotelIdState: "error" });
      }
      this.setState({ selectedPurposeError: _.isEmpty(this.state.selectedPurpose) });
      this.setState({ selectedPropertyTypeError: _.isEmpty(this.state.selectedPropertyType) });
      
    }
    return false;
  }
  

  renderPropertyTypes() {
      const { classes } = this.props;
      const options = [ "House", "Apartment", "Land", "Commercial", "Hotel" ].map((option, key) => {
        return (
          <div
          className={classes.checkboxAndRadio} key={key}>
            <FormControlLabel
              control={
                <Radio
                  checked={this.state.selectedPropertyType === option}
                  onChange={(event) => this.change(event, "selectedPropertyType")}
                  value={option}
                  name={option}
                  aria-label={option}
                  icon={
                    <FiberManualRecord
                      className={classes.radioUnchecked}
                    />
                  }
                  checkedIcon={
                    <FiberManualRecord
                      className={classes.radioChecked}
                    />
                  }
                  classes={{
                    checked: classes.radio,
                    root: classes.radioRoot
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot
              }}
              label={option}
            />
        </div>
        );
      });

      return options;
  }

  renderPurpose() {
    const { classes } = this.props;
    const options = [ "Sale", "Rent" ].map((option, key) => {
      const optionLabel = `For ${option}`;
      return (
        <div
        className={classes.checkboxAndRadio} key={key}>
          <FormControlLabel
            control={
              <Radio
                checked={this.state.selectedPurpose === option}
                onChange={(event) => this.change(event, "selectedPurpose")}
                value={option}
                name={option}
                aria-label={optionLabel}
                icon={
                  <FiberManualRecord
                    className={classes.radioUnchecked}
                  />
                }
                checkedIcon={
                  <FiberManualRecord
                    className={classes.radioChecked}
                  />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot
            }}
            label={optionLabel}
          />
      </div>
      );
    });

    return options;
}

  renderBedrooms() {
    const { classes } = this.props;
    const options = [ "1", "2", "3", "4", "5", "6+" ].map((option, key) => {
      const optionLabel = option === "1" ? `${option} Bedroom` : `${option} Bedrooms`;
      return (
        <div
        className={classes.checkboxAndRadio} key={key}>
          <FormControlLabel
            control={
              <Radio
                checked={this.state.selectedBedrooms === option}
                onChange={(event) => this.change(event, "selectedBedrooms")}
                value={option}
                name={optionLabel}
                aria-label={optionLabel}
                icon={
                  <FiberManualRecord
                    className={classes.radioUnchecked}
                  />
                }
                checkedIcon={
                  <FiberManualRecord
                    className={classes.radioChecked}
                  />
                }
                classes={{
                  checked: classes.radio,
                  root: classes.radioRoot
                }}
              />
            }
            classes={{
              label: classes.label,
              root: classes.labelRoot
            }}
            label={optionLabel}
          />
      </div>
      );
    });

    return options;
}

renderBathrooms() {
  const { classes } = this.props;
  const options = [ "1", "2", "3", "4", "5", "6+" ].map((option, key) => {
    const optionLabel = option === "1" ? `${option} Bathroom` : `${option} Bathrooms`;
    return (
      <div
      className={classes.checkboxAndRadio} key={key}>
        <FormControlLabel
          control={
            <Radio
              checked={this.state.selectedBathrooms === option}
              onChange={(event) => this.change(event, "selectedBathrooms")}
              value={option}
              name={optionLabel}
              aria-label={optionLabel}
              icon={
                <FiberManualRecord
                  className={classes.radioUnchecked}
                />
              }
              checkedIcon={
                <FiberManualRecord
                  className={classes.radioChecked}
                />
              }
              classes={{
                checked: classes.radio,
                root: classes.radioRoot
              }}
            />
          }
          classes={{
            label: classes.label,
            root: classes.labelRoot
          }}
          label={optionLabel}
        />
    </div>
    );
  });

  return options;
}

renderCheckedOptions() {
    const { selectedPropertyType } = this.state;
    return (
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.checkIsFeatured}
              onChange={this.handleChecked('checkIsFeatured')}
              value="checkedIsFeatured"
              color="primary"
            />
          }
          label="Featured"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.checkIsFeaturedBottom}
              onChange={this.handleChecked('checkIsFeaturedBottom')}
              value="checkedIsFeaturedBottom"
              color="primary"
            />
          }
          label="Featured Bottom"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.checkIsEstate}
              onChange={this.handleChecked('checkIsEstate')}
              value="checkedIsEstate"
              color="primary"
            />
          }
          label="Estate"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.checkIsHostel}
              onChange={this.handleChecked('checkIsHostel')}
              value="checkedIsHostel"
              color="primary"
            />
          }
          label="Hostel"
        />
        { selectedPropertyType === 'Hotel' && (
            <FormControlLabel
            control={
              <Checkbox
                checked={this.state.checkIsHotelRoom}
                onChange={this.handleChecked('checkIsHotelRoom')}
                value="checkedIsHotelRoom"
                color="primary"
              />
            }
            label="Hotel Room"
          />
        )}
        
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.checkIsOffPlan}
              onChange={this.handleChecked('checkIsOffPlan')}
              value="checkedIsOffPlan"
              color="primary"
            />
          }
          label="Off Plan"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.checkIsDeal}
              onChange={this.handleChecked('checkIsDeal')}
              value="checkedIsDeal"
              color="primary"
            />
          }
          label="Special/Deal"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={this.state.checkIsForeclosed}
              onChange={this.handleChecked('checkIsForeclosed')}
              value="checkedIsForeclosed"
              color="primary"
            />
          }
          label="Foreclosure"
        />
        
      </FormGroup>
    );
}

renderHotelRoom() {
    const { hotelName } = this.state;
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
          <GridItem xs={12} sm={6}>
            <div className={classes.phoneSearch}>
              { this.state.searchHotels && <CircularProgress /> }
              <CustomInput
                success={this.state.hotelIdState === "success"}
                error={this.state.hotelIdState === "error"}
                labelText={
                  <span>
                    Hotel ID <small>(required)</small>
                  </span>
                }
                id="hotelId"
                name="hotelId"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  onChange: event => this.change(event, "hotelId", "hotel", 12),
                  value: this.state.hotelId,
                  placeholder: "e.g 161296201776900",
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      className={classes.inputAdornment}
                    >
                      <Button
                        color="info"
                        onClick={this.handleHotelSearch}
                        size="sm"
                        disabled={this.state.hotelIdState !== "success"}
                      > <Search /> Search
                      </Button>
                    </InputAdornment>
                  )
                }}
              />
            </div>
          </GridItem>
          <GridItem xs={12} sm={6}>
            {
              _.isEmpty(hotelName) ? (<h4 className={classes.hotelTextRed}>
                {'No Hotel Found. Try again'}
              </h4>) : (<h4 className={classes.hotelText}>
                {hotelName}
              </h4>)
            }
            
          </GridItem>
      </GridContainer>
    );
}

renderHotelStars() {
  const { classes } = this.props;
  const options = [ "1", "2", "3", "4", "5" ].map((option, key) => {
    const optionLabel = option === "1" ? `${option} Star` : `${option} Stars`;
    return (
      <div
      className={classes.checkboxAndRadio} key={key}>
        <FormControlLabel
          control={
            <Radio
              checked={this.state.hotelStars === option}
              onChange={(event) => this.change(event, "hotelStars")}
              value={option}
              name={optionLabel}
              aria-label={optionLabel}
              icon={
                <FiberManualRecord
                  className={classes.radioUnchecked}
                />
              }
              checkedIcon={
                <FiberManualRecord
                  className={classes.radioChecked}
                />
              }
              classes={{
                checked: classes.radio,
                root: classes.radioRoot
              }}
            />
          }
          classes={{
            label: classes.label,
            root: classes.labelRoot
          }}
          label={optionLabel}
        />
    </div>
    );
  });

  return options;
}

  /*
    <GridItem xs={12} sm={4}>
          <PictureUpload />
        </GridItem>
  */
  render() {
    const { classes } = this.props;
    const { selectedPropertyType, checkIsHotelRoom } = this.state;
    const notLand = !_.isEmpty(selectedPropertyType) && selectedPropertyType !== 'Land';
    return (
      <GridContainer justify="center">
        
        <GridItem xs={12} sm={10}>
          <CustomInput
            success={this.state.propertyNameState === "success"}
            error={this.state.propertyNameState === "error"}
            labelText={
              <span>Property Name<small> (required)</small></span>
            }
            id="propertyname"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: event => this.change(event, "propertyName", "length", 3),
              value: this.state.propertyName,
              endAdornment: (
                <InputAdornment
                  position="end"
                  className={classes.inputAdornment}
                >
                  <Place className={classes.inputAdornmentIcon} />
                </InputAdornment>
              )
            }}
          />
          <GridContainer>
            <GridItem xs={3}>
                <CustomInput
                  success={this.state.priceState === "success"}
                  error={this.state.priceState === "error"}
                  labelText={
                    <span>Price</span>
                  }
                  id="price"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: event => this.change(event, "price", "number"),
                    value: this.state.price
                }}
              />
            </GridItem>
            <GridItem xs={3}>

            <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="currency-code"
                    className={classes.selectLabel}
                  >
                    Currency Code
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.currencyCode}
                    onChange={this.handleSimple}
                    inputProps={{
                      name: "currencyCode",
                      id: "currency-code",
                      value: this.state.currencyCode
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Choose Currency
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="UGX"
                    >
                      UGX
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="USD"
                    >
                      USD
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="GBP"
                    >
                      GBP
                    </MenuItem>
                  </Select>
                </FormControl>
            </GridItem>
            <GridItem xs={3}>
              <CustomInput
                    success={this.state.sizeState === "success"}
                    error={this.state.sizeState === "error"}
                    labelText={
                      <span>
                        Area/Size
                      </span>
                    }
                    id="size"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: event => this.change(event, "size", "number"),
                      value: this.state.size
                  }}
                />
            </GridItem>
            <GridItem xs={3}>

            <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    Area/Size Units
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu
                    }}
                    classes={{
                      select: classes.select
                    }}
                    value={this.state.sizeUnits}
                    onChange={this.handleSimple}
                    inputProps={{
                      name: "sizeUnits",
                      id: "size-units",
                      value: this.state.sizeUnits
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem
                      }}
                    >
                      Choose Units
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="Decimals"
                    >
                      Decimals
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="Sq Meters"
                    >
                      Square Meters
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="Sq Km"
                    >
                      Square Kilometers
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="Sq Miles"
                    >
                      Square Miles
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="Acres"
                    >
                      Acres
                    </MenuItem>
                    <MenuItem
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value="Hectares"
                    >
                      Hectares
                    </MenuItem>
                  </Select>
                </FormControl>
            </GridItem>
          </GridContainer>
            <CustomInput
              success={this.state.propertyDescriptionState === "success"}
              error={this.state.propertyDescriptionState === "error"}
              labelText={
                <span>
                  Property Description <small>*</small>
                </span>
              }
              id="propertydescription"
              formControlProps={{
                fullWidth: true,
                variant: 'outlined'
              }}
              inputProps={{
                onChange: event => this.change(event, "propertyDescription", "length", 10),
                multiline: true,
                rows: "5",
                value: this.state.propertyDescription
              }}
            />
          <br />
          <br />
          <div>
            <FormLabel required error={this.state.selectedPropertyTypeError}>
              Property Type
            </FormLabel>
          </div>
          {this.renderPropertyTypes()}
          <br />
          <br />
          <div>
            <FormLabel required error={this.state.selectedPurposeError}>
              Listing Type
            </FormLabel>
          </div>
          {this.renderPurpose()}
          <br />
          <br />
          <div>
            <FormLabel required>
              Property Options
            </FormLabel>
          </div>
          <br />
          {this.renderCheckedOptions()}
          <br />
          <br />
              {checkIsHotelRoom === true && (
                <React.Fragment>
                  <div>
                    <FormLabel required>
                      Enter Hotel ID
                    </FormLabel>
                  </div>
                  {this.renderHotelRoom()}
                </React.Fragment>
              )}
          <br />
          <br />
          { selectedPropertyType === 'Hotel' && 
            (
              <React.Fragment>
                <div>
                  <FormLabel required>
                    Hotel Stars
                  </FormLabel>
                </div>
                {this.renderHotelStars()}
              </React.Fragment>
              
            )}
          <br />
          <br />
          { notLand && 
            <React.Fragment>
              <div>
                <FormLabel required>
                  Bedrooms
                </FormLabel>
              </div>
              {this.renderBedrooms()}
              <br />
              <br />
              <div>
                <FormLabel required>
                  Bathrooms
                </FormLabel>
              </div>
              {this.renderBathrooms()}
            </React.Fragment>
          }
          
        </GridItem>
      </GridContainer>
    );
  }
}

Step1.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Step1);
