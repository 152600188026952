/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getVehicleView = /* GraphQL */ `
  query GetVehicleView($id: ID!) {
    getVehicleView(id: $id) {
      id
      ownerID
      vehicleID
      month
      dayViews
      views
      duration
      createdAt
      updatedAt
    }
  }
`;
export const listVehicleViews = /* GraphQL */ `
  query ListVehicleViews(
    $filter: ModelVehicleViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVehicleViews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerID
        vehicleID
        month
        dayViews
        views
        duration
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVehicle = /* GraphQL */ `
  query GetVehicle($id: ID!) {
    getVehicle(id: $id) {
      id
      title
      slug
      shortID
      options
      owner {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        website
        subscription {
          id
          name
          price
          recurring
          startDate
          status
          lastPaidAt
          options
          createdAt
          updatedAt
        }
        fullyPaid
        ownerType
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      condition
      purpose
      price
      intPrice
      currencyCode
      category {
        id
        name
        image
        options
        createdAt
        updatedAt
      }
      vehicleType
      year
      make
      model
      trim
      mileage
      cityMPG
      highwayMPG
      exterior
      interior
      driveType
      fuelType
      driveSetup
      transmission
      isDutyPaid
      engineSize
      features {
        items {
          id
          createdAt
          options
          updatedAt
        }
        nextToken
      }
      customers {
        items {
          id
          contactedAt
          isFavorite
          purchasedAt
          rentedAt
          viewedAt
          state
          createdAt
          updatedAt
        }
        nextToken
      }
      sellerDescription
      isDealership
      isFeatured
      isDeal
      isForeclosed
      featuredImage
      images
      createdAt
      updatedAt
      address
      addressNumber
      city
      district
      province
      country
      status
      search
      views
      videos
      thumbnails
      vehicleViews {
        items {
          id
          ownerID
          vehicleID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleViewsByDate {
        items {
          id
          ownerID
          vehicleID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listVehicles = /* GraphQL */ `
  query ListVehicles(
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVehicles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        slug
        shortID
        options
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        condition
        purpose
        price
        intPrice
        currencyCode
        category {
          id
          name
          image
          options
          createdAt
          updatedAt
        }
        vehicleType
        year
        make
        model
        trim
        mileage
        cityMPG
        highwayMPG
        exterior
        interior
        driveType
        fuelType
        driveSetup
        transmission
        isDutyPaid
        engineSize
        features {
          items {
              id
              feature {
                  id
                  name
                  icon
                  type
                  options
              }
          }
          nextToken
        }
        customers {
          items {
            id
          }
          nextToken
        }
        sellerDescription
        isDealership
        isFeatured
        isDeal
        isForeclosed
        featuredImage
        images
        createdAt
        updatedAt
        address
        addressNumber
        city
        district
        province
        country
        status
        search
        views
        videos
        thumbnails
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getVehicleMakeModels = /* GraphQL */ `
  query GetVehicleMakeModels($id: ID!, $make: String!, $year: Int!) {
    getVehicleMakeModels(id: $id, make: $make, year: $year) {
      id
      year
      make
      logo
      options
      model
      trim
      series
      createdAt
      updatedAt
    }
  }
`;
export const listVehicleMakeModelss = /* GraphQL */ `
  query ListVehicleMakeModelss(
    $id: ID
    $makeYear: ModelVehicleMakeModelsPrimaryCompositeKeyConditionInput
    $filter: ModelVehicleMakeModelsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listVehicleMakeModelss(
      id: $id
      makeYear: $makeYear
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        year
        make
        logo
        options
        model
        trim
        series
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCarFeatures = /* GraphQL */ `
  query GetCarFeatures($id: ID!) {
    getCarFeatures(id: $id) {
      id
      name
      icon
      type
      description
      options
      features {
        items {
          id
          createdAt
          options
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCarFeaturess = /* GraphQL */ `
  query ListCarFeaturess(
    $filter: ModelCarFeaturesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarFeaturess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        icon
        type
        description
        options
        features {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVehicleFeature = /* GraphQL */ `
  query GetVehicleFeature($id: ID!) {
    getVehicleFeature(id: $id) {
      id
      vehicle {
        id
        title
        slug
        shortID
        options
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        condition
        purpose
        price
        intPrice
        currencyCode
        category {
          id
          name
          image
          options
          createdAt
          updatedAt
        }
        vehicleType
        year
        make
        model
        trim
        mileage
        cityMPG
        highwayMPG
        exterior
        interior
        driveType
        fuelType
        driveSetup
        transmission
        isDutyPaid
        engineSize
        features {
          nextToken
        }
        customers {
          nextToken
        }
        sellerDescription
        isDealership
        isFeatured
        isDeal
        isForeclosed
        featuredImage
        images
        createdAt
        updatedAt
        address
        addressNumber
        city
        district
        province
        country
        status
        search
        views
        videos
        thumbnails
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      feature {
        id
        name
        icon
        type
        description
        options
        features {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      options
      updatedAt
    }
  }
`;
export const listVehicleFeatures = /* GraphQL */ `
  query ListVehicleFeatures(
    $filter: ModelVehicleFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVehicleFeatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        vehicle {
          id
          title
          slug
          shortID
          options
          condition
          purpose
          price
          intPrice
          currencyCode
          vehicleType
          year
          make
          model
          trim
          mileage
          cityMPG
          highwayMPG
          exterior
          interior
          driveType
          fuelType
          driveSetup
          transmission
          isDutyPaid
          engineSize
          sellerDescription
          isDealership
          isFeatured
          isDeal
          isForeclosed
          featuredImage
          images
          createdAt
          updatedAt
          address
          addressNumber
          city
          district
          province
          country
          status
          search
          views
          videos
          thumbnails
        }
        feature {
          id
          name
          icon
          type
          description
          options
          createdAt
          updatedAt
        }
        createdAt
        options
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPropertyView = /* GraphQL */ `
  query GetPropertyView($id: ID!) {
    getPropertyView(id: $id) {
      id
      ownerID
      propertyID
      month
      dayViews
      views
      duration
      createdAt
      updatedAt
    }
  }
`;
export const listPropertyViews = /* GraphQL */ `
  query ListPropertyViews(
    $filter: ModelPropertyViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPropertyViews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ownerID
        propertyID
        month
        dayViews
        views
        duration
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      image
      options
      createdAt
      updatedAt
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        options
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAppSubscription = /* GraphQL */ `
  query GetAppSubscription($id: ID!) {
    getAppSubscription(id: $id) {
      id
      name
      price
      recurring
      startDate
      status
      lastPaidAt
      payments {
        items {
          id
          amount
          currencyCode
          processedAt
          processedBy
          notes
          paymentType
          createdAt
          updatedAt
        }
        nextToken
      }
      options
      owner {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        website
        subscription {
          id
          name
          price
          recurring
          startDate
          status
          lastPaidAt
          options
          createdAt
          updatedAt
        }
        fullyPaid
        ownerType
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAppSubscriptions = /* GraphQL */ `
  query ListAppSubscriptions(
    $filter: ModelAppSubscriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        price
        recurring
        startDate
        status
        lastPaidAt
        payments {
          nextToken
        }
        options
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      subscription {
        id
        name
        price
        recurring
        startDate
        status
        lastPaidAt
        payments {
          nextToken
        }
        options
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        createdAt
        updatedAt
      }
      amount
      currencyCode
      processedAt
      processedBy
      notes
      paymentType
      createdAt
      updatedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        subscription {
          id
          name
          price
          recurring
          startDate
          status
          lastPaidAt
          options
          createdAt
          updatedAt
        }
        amount
        currencyCode
        processedAt
        processedBy
        notes
        paymentType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProperty = /* GraphQL */ `
  query GetProperty($id: ID!) {
    getProperty(id: $id) {
      id
      name
      slug
      shortID
      options
      amenities {
        items {
          id
          createdAt
          options
          updatedAt
        }
        nextToken
      }
      category {
        id
        name
        image
        options
        createdAt
        updatedAt
      }
      owner {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        website
        subscription {
          id
          name
          price
          recurring
          startDate
          status
          lastPaidAt
          options
          createdAt
          updatedAt
        }
        fullyPaid
        ownerType
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      customers {
        items {
          id
          contactedAt
          isFavorite
          purchasedAt
          rentedAt
          viewedAt
          state
          createdAt
          updatedAt
        }
        nextToken
      }
      isFeatured
      isFeaturedBottom
      isOffPlan
      isForeclosed
      isDeal
      isEstate
      isHotelRoom
      isHostel
      hotelId
      hotelStars
      propertyType
      propertyPurpose
      currencyCode
      salePrice
      intSalePrice
      rentPrice
      intRentPrice
      description
      featuredImage
      images
      createdAt
      updatedAt
      address
      addressNumber
      city
      district
      province
      country
      latitude
      longitude
      bathrooms
      bedrooms
      status
      size
      intSize
      sizeUnits
      search
      views
      videos
      thumbnails
      propertyViews {
        items {
          id
          ownerID
          propertyID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      propertyViewsByDate {
        items {
          id
          ownerID
          propertyID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listPropertys = /* GraphQL */ `
  query ListPropertys(
    $filter: ModelPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPropertys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        slug
        shortID
        options
        amenities {
          items {
            id
            amenity {
              id
              icon
              options
              description
              name
            }
          }
          nextToken
        }
        category {
          id
          name
          image
          options
          createdAt
          updatedAt
        }
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        customers {
          items {
            id
          }
          nextToken
        }
        isFeatured
        isFeaturedBottom
        isOffPlan
        isForeclosed
        isDeal
        isEstate
        isHotelRoom
        isHostel
        hotelId
        hotelStars
        propertyType
        propertyPurpose
        currencyCode
        salePrice
        intSalePrice
        rentPrice
        intRentPrice
        description
        featuredImage
        images
        createdAt
        updatedAt
        address
        addressNumber
        city
        district
        province
        country
        latitude
        longitude
        bathrooms
        bedrooms
        status
        size
        intSize
        sizeUnits
        search
        views
        videos
        thumbnails
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getAmenity = /* GraphQL */ `
  query GetAmenity($id: ID!) {
    getAmenity(id: $id) {
      id
      name
      icon
      description
      options
      properties {
        items {
          id
          createdAt
          options
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAmenitys = /* GraphQL */ `
  query ListAmenitys(
    $filter: ModelAmenityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAmenitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        icon
        description
        options
        properties {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOwner = /* GraphQL */ `
  query GetOwner($id: ID!) {
    getOwner(id: $id) {
      id
      firstName
      lastName
      name
      displayName
      email
      phone
      profilePhoto
      acceptsCookies
      properties(limit: 100) {
        items {
          id
          name
          slug
          shortID
          options
          isFeatured
          isFeaturedBottom
          isOffPlan
          isForeclosed
          isDeal
          isEstate
          isHotelRoom
          isHostel
          hotelId
          hotelStars
          propertyType
          propertyPurpose
          currencyCode
          salePrice
          intSalePrice
          rentPrice
          intRentPrice
          description
          featuredImage
          images
          createdAt
          updatedAt
          address
          addressNumber
          city
          district
          province
          country
          latitude
          longitude
          bathrooms
          bedrooms
          status
          size
          intSize
          sizeUnits
          search
          views
          videos
          thumbnails
        }
        nextToken
      }
      vehicles(limit: 100) {
        items {
          id
          title
          slug
          shortID
          options
          condition
          purpose
          price
          intPrice
          currencyCode
          vehicleType
          year
          make
          model
          trim
          mileage
          cityMPG
          highwayMPG
          exterior
          interior
          driveType
          fuelType
          driveSetup
          transmission
          isDutyPaid
          engineSize
          sellerDescription
          isDealership
          isFeatured
          isDeal
          isForeclosed
          featuredImage
          images
          createdAt
          updatedAt
          address
          addressNumber
          city
          district
          province
          country
          status
          search
          views
          videos
          thumbnails
        }
        nextToken
      }
      createdAt
      updatedAt
      address
      city
      province
      country
      website
      subscription {
        id
        name
        price
        recurring
        startDate
        status
        lastPaidAt
        payments {
          nextToken
        }
        options
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        createdAt
        updatedAt
      }
      fullyPaid
      ownerType
      propertyViews {
        items {
          id
          ownerID
          propertyID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      propertyViewsByDate {
        items {
          id
          ownerID
          propertyID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleViews {
        items {
          id
          ownerID
          vehicleID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicleViewsByDate {
        items {
          id
          ownerID
          vehicleID
          month
          dayViews
          views
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listOwners = /* GraphQL */ `
  query ListOwners(
    $filter: ModelOwnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOwners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        profilePhoto
        acceptsCookies
        properties(limit: 100) {
          items {
            id
            name
          }
          nextToken
        }
        vehicles(limit: 100) {
          items {
            id
            title
          }
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        website
        subscription {
          id
          name
          price
          recurring
          startDate
          status
          lastPaidAt
          options
          createdAt
          updatedAt
        }
        fullyPaid
        ownerType
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCustomerProperty = /* GraphQL */ `
  query GetCustomerProperty($id: ID!) {
    getCustomerProperty(id: $id) {
      id
      customer {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        socialLogin
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        isCompany
        website
      }
      property {
        id
        name
        slug
        shortID
        options
        amenities {
          nextToken
        }
        category {
          id
          name
          image
          options
          createdAt
          updatedAt
        }
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        customers {
          nextToken
        }
        isFeatured
        isFeaturedBottom
        isOffPlan
        isForeclosed
        isDeal
        isEstate
        isHotelRoom
        isHostel
        hotelId
        hotelStars
        propertyType
        propertyPurpose
        currencyCode
        salePrice
        intSalePrice
        rentPrice
        intRentPrice
        description
        featuredImage
        images
        createdAt
        updatedAt
        address
        addressNumber
        city
        district
        province
        country
        latitude
        longitude
        bathrooms
        bedrooms
        status
        size
        intSize
        sizeUnits
        search
        views
        videos
        thumbnails
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
      }
      contactedAt
      isFavorite
      purchasedAt
      rentedAt
      viewedAt
      state
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerPropertys = /* GraphQL */ `
  query ListCustomerPropertys(
    $filter: ModelCustomerPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerPropertys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          socialLogin
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          isCompany
          website
        }
        property {
          id
          name
          slug
          shortID
          options
          isFeatured
          isFeaturedBottom
          isOffPlan
          isForeclosed
          isDeal
          isEstate
          isHotelRoom
          isHostel
          hotelId
          hotelStars
          propertyType
          propertyPurpose
          currencyCode
          salePrice
          intSalePrice
          rentPrice
          intRentPrice
          description
          featuredImage
          images
          createdAt
          updatedAt
          address
          addressNumber
          city
          district
          province
          country
          latitude
          longitude
          bathrooms
          bedrooms
          status
          size
          intSize
          sizeUnits
          search
          views
          videos
          thumbnails
        }
        contactedAt
        isFavorite
        purchasedAt
        rentedAt
        viewedAt
        state
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomerVehicle = /* GraphQL */ `
  query GetCustomerVehicle($id: ID!) {
    getCustomerVehicle(id: $id) {
      id
      customer {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        socialLogin
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        isCompany
        website
      }
      vehicle {
        id
        title
        slug
        shortID
        options
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        condition
        purpose
        price
        intPrice
        currencyCode
        category {
          id
          name
          image
          options
          createdAt
          updatedAt
        }
        vehicleType
        year
        make
        model
        trim
        mileage
        cityMPG
        highwayMPG
        exterior
        interior
        driveType
        fuelType
        driveSetup
        transmission
        isDutyPaid
        engineSize
        features {
          nextToken
        }
        customers {
          nextToken
        }
        sellerDescription
        isDealership
        isFeatured
        isDeal
        isForeclosed
        featuredImage
        images
        createdAt
        updatedAt
        address
        addressNumber
        city
        district
        province
        country
        status
        search
        views
        videos
        thumbnails
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      contactedAt
      isFavorite
      purchasedAt
      rentedAt
      viewedAt
      state
      createdAt
      updatedAt
    }
  }
`;
export const listCustomerVehicles = /* GraphQL */ `
  query ListCustomerVehicles(
    $filter: ModelCustomerVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerVehicles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customer {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          socialLogin
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          isCompany
          website
        }
        vehicle {
          id
          title
          slug
          shortID
          options
          condition
          purpose
          price
          intPrice
          currencyCode
          vehicleType
          year
          make
          model
          trim
          mileage
          cityMPG
          highwayMPG
          exterior
          interior
          driveType
          fuelType
          driveSetup
          transmission
          isDutyPaid
          engineSize
          sellerDescription
          isDealership
          isFeatured
          isDeal
          isForeclosed
          featuredImage
          images
          createdAt
          updatedAt
          address
          addressNumber
          city
          district
          province
          country
          status
          search
          views
          videos
          thumbnails
        }
        contactedAt
        isFavorite
        purchasedAt
        rentedAt
        viewedAt
        state
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      firstName
      lastName
      name
      displayName
      email
      phone
      socialLogin
      profilePhoto
      acceptsCookies
      properties(limit: 200) {
        items {
          id
          contactedAt
          isFavorite
          purchasedAt
          rentedAt
          viewedAt
          state
          createdAt
          updatedAt
        }
        nextToken
      }
      vehicles(limit: 200) {
        items {
          id
          contactedAt
          isFavorite
          purchasedAt
          rentedAt
          viewedAt
          state
          createdAt
          updatedAt
        }
        nextToken
      }
      comments {
        items {
          id
          lookId
          commentDate
          message
          storeId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      address
      city
      province
      country
      isCompany
      website
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        socialLogin
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        isCompany
        website
      }
      nextToken
    }
  }
`;
export const getAdvertiser = /* GraphQL */ `
  query GetAdvertiser($id: ID!) {
    getAdvertiser(id: $id) {
      id
      name
      logo
      options
      adverts {
        items {
          id
          name
          description
          shortID
          link
          image
          options
          createdAt
          updatedAt
          startAt
          endAt
          fullyPaid
        }
        nextToken
      }
      createdAt
      updatedAt
      address
      city
      province
      country
      phone
      email
      website
      owner {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        website
        subscription {
          id
          name
          price
          recurring
          startDate
          status
          lastPaidAt
          options
          createdAt
          updatedAt
        }
        fullyPaid
        ownerType
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
    }
  }
`;
export const listAdvertisers = /* GraphQL */ `
  query ListAdvertisers(
    $filter: ModelAdvertiserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdvertisers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        logo
        options
        adverts(limit: 200) {
          items {
            id
            name
            description
            link
            image
            options
            createdAt
            updatedAt
            startAt
            endAt
            fullyPaid
          }
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        phone
        email
        website
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
      }
      nextToken
    }
  }
`;
export const getAdvert = /* GraphQL */ `
  query GetAdvert($id: ID!) {
    getAdvert(id: $id) {
      id
      name
      description
      shortID
      link
      image
      options
      createdAt
      updatedAt
      startAt
      endAt
      fullyPaid
    }
  }
`;
export const listAdverts = /* GraphQL */ `
  query ListAdverts(
    $filter: ModelAdvertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdverts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        shortID
        link
        image
        options
        createdAt
        updatedAt
        startAt
        endAt
        fullyPaid
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      customer {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        socialLogin
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        isCompany
        website
      }
      lookId
      commentDate
      message
      storeId
      createdAt
      updatedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customer {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          socialLogin
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          isCompany
          website
        }
        lookId
        commentDate
        message
        storeId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const vehiclesByYearMake = /* GraphQL */ `
  query VehiclesByYearMake(
    $make: String
    $year: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleMakeModelsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehiclesByYearMake(
      make: $make
      year: $year
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        year
        make
        logo
        options
        model
        trim
        series
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const vehiclesByMake = /* GraphQL */ `
  query VehiclesByMake(
    $make: String
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleMakeModelsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehiclesByMake(
      make: $make
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        year
        make
        logo
        options
        model
        trim
        series
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOwnerByPhone = /* GraphQL */ `
  query GetOwnerByPhone(
    $phone: String
    $sortDirection: ModelSortDirection
    $filter: ModelOwnerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getOwnerByPhone(
      phone: $phone
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        name
        displayName
        email
        phone
        profilePhoto
        acceptsCookies
        properties {
          nextToken
        }
        vehicles {
          nextToken
        }
        createdAt
        updatedAt
        address
        city
        province
        country
        website
        subscription {
          id
          name
          price
          recurring
          startDate
          status
          lastPaidAt
          options
          createdAt
          updatedAt
        }
        fullyPaid
        ownerType
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const vehiclesByShortId = /* GraphQL */ `
  query VehiclesByShortId(
    $shortID: String
    $sortDirection: ModelSortDirection
    $filter: ModelVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vehiclesByShortID(
      shortID: $shortID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        slug
        shortID
        options
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        condition
        purpose
        price
        intPrice
        currencyCode
        category {
          id
          name
          image
          options
          createdAt
          updatedAt
        }
        vehicleType
        year
        make
        model
        trim
        mileage
        cityMPG
        highwayMPG
        exterior
        interior
        driveType
        fuelType
        driveSetup
        transmission
        isDutyPaid
        engineSize
        features {
          items {
              id
              feature {
                  id
                  name
                  icon
                  type
                  options
              }
          }
          nextToken
        }
        customers {
          nextToken
        }
        sellerDescription
        isDealership
        isFeatured
        isDeal
        isForeclosed
        featuredImage
        images
        createdAt
        updatedAt
        address
        addressNumber
        city
        district
        province
        country
        status
        search
        views
        videos
        thumbnails
        vehicleViews {
          nextToken
        }
        vehicleViewsByDate {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const propertiesByShortId = /* GraphQL */ `
  query PropertiesByShortId(
    $shortID: String
    $sortDirection: ModelSortDirection
    $filter: ModelPropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    propertiesByShortID(
      shortID: $shortID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        slug
        shortID
        options
        amenities {
          items {
            id
            amenity {
              id
              icon
              options
              description
              name
            }
          }
          nextToken
        }
        category {
          id
          name
          image
          options
          createdAt
          updatedAt
        }
        owner {
          id
          firstName
          lastName
          name
          displayName
          email
          phone
          profilePhoto
          acceptsCookies
          createdAt
          updatedAt
          address
          city
          province
          country
          website
          fullyPaid
          ownerType
        }
        customers {
          nextToken
        }
        isFeatured
        isFeaturedBottom
        isOffPlan
        isForeclosed
        isDeal
        isEstate
        isHotelRoom
        isHostel
        hotelId
        hotelStars
        propertyType
        propertyPurpose
        currencyCode
        salePrice
        intSalePrice
        rentPrice
        intRentPrice
        description
        featuredImage
        images
        createdAt
        updatedAt
        address
        addressNumber
        city
        district
        province
        country
        latitude
        longitude
        bathrooms
        bedrooms
        status
        size
        intSize
        sizeUnits
        search
        views
        videos
        thumbnails
        propertyViews {
          nextToken
        }
        propertyViewsByDate {
          nextToken
        }
      }
      nextToken
    }
  }
`;