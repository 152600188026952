import React from 'react';
import { Link } from "react-router-dom";
import _ from "underscore";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

// Materials
import SweetAlert from "react-bootstrap-sweetalert";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

// AWS
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from 'graphql/mutations';

// Icons
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';

// Utils
const moment = require("moment");

const useStyles = makeStyles(theme => ({
  ...buttonStyle,
  card: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: "#7E8BF5",
  },
  title: {
    whiteSpace: 'nowrap', 
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  description: {
    whiteSpace: 'wrap', 
    overflow: 'hidden',
    height: '40px',
    textOverflow: 'ellipsis'
  },
}));

const IDButton = withStyles({
  root: {
    textTransform: 'none'
  },
})(Button);

/*
type Vehicle @model(subscriptions: null) {
	id: ID!
	title: String!
	slug: String
	options: String
	owner: Owner! @connection(name: "Vehicles")
	condition: String!
	purpose: VehiclePurpose!
	price: String!
	intPrice: Int!
	currencyCode: String!
	category: Category! @connection
	vehicleType: VehicleType!
	makeModel: VehicleMakeModel @connection
	year: Int
	mileage: Int
	cityMPG: Int
	highwayMPG: Int
	exterior: String
	interior: String
	features: [VehicleFeature!] @connection(name: "VehicleFeatures")
	customers: [CustomerVehicle!] @connection(name: "VehicleCustomers")
	sellerDescription: String
	isDealership: Boolean,
	featuredImage: String!
	images: [String!]
	createdAt: String!
	updatedAt: String!
	address: String!
	addressNumber: String
	city: String!
	district: String
	province: String
	country: String
	status: String
	search: String
	views: Int
	videos: [String!]
	thumbnails: [String!]
	vehicleViews: [VehicleView!] @connection(keyName: "byVehicle", fields: ["id"])
	vehicleViewsByDate: [VehicleView!] @connection(keyName: "byVehicleByDate", fields: ["id"])
}
*/

export default function Vehicle(props) {
  const { vehicle } = props;
  const { make, model, trim } = vehicle;
  const classes = useStyles();
  const [uploadMessage, setUploadMessage] = React.useState("");
  const [spinner, setSpinner] = React.useState(false);

  const shillingsFormatter = new Intl.NumberFormat({ style: 'currency', currency: 'UGX' });

  async function deleteProperty() {
    try {
      const { data } = await API.graphql(graphqlOperation(mutations.deleteVehicle, { input: { id: vehicle.id } } ));
      if (data && !_.isEmpty(data.deleteVehicle)) {
        const { customers, features } = vehicle;
        if (customers && _.isEmpty(customers.items)) {
          for (let i=0; i<customers.items.length; i++) {
            const { id: customerVehicleId } = customers.items[0];
            if (_.isEmpty(customerVehicleId)) continue;
              await API.graphql(graphqlOperation(mutations.deleteCustomerVehicle, { input: { id: customerVehicleId } } ));
          }
        }
        if (features && _.isEmpty(features.items)) {
          for (let i=0; i<features.items.length; i++) {
            const { id: vehicleFeatureId } = features.items[0];
            if (_.isEmpty(vehicleFeatureId)) continue;
              await API.graphql(graphqlOperation(mutations.deleteVehicleFeature, { input: { id: vehicleFeatureId } } ));
          }

        }
        setUploadMessage(`Success. Deleted property: ${vehicle.title}`);
        setSpinner(false);
        window.location.reload(false);
      }
    } catch (error) {
      setUploadMessage("Error. Failed to delete property.");
      setSpinner(false);
      console.log("Delete property error: " + JSON.stringify(error));
    }
  }
  
  function getAlert() {
    //console.log("Upload message: " + uploadMessage);
    return (
      <SweetAlert
        show={!_.isEmpty(uploadMessage)}
        type={uploadMessage.startsWith("Error") ? "error" : uploadMessage.startsWith("Confirm") ? "warning" : "success"}
        showCancel={uploadMessage.startsWith("Confirm")}
        style={{ display: "block", marginTop: "-100px" }}
        title={uploadMessage.startsWith("Error") ? "Error" : uploadMessage.startsWith("Confirm") ? "Confirm Delete" : "Success"}
        onConfirm={() => {
          if (uploadMessage.startsWith("Confirm")) {
            setSpinner(true);
            setUploadMessage("");
            deleteProperty();
          } else if (uploadMessage.startsWith("Error")) {
            setSpinner(false);
            setUploadMessage("");
          } else {
            setSpinner(false);
            setUploadMessage("");
          }
        }}
        onCancel={() => setUploadMessage("")}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
      >
        {uploadMessage}
      </SweetAlert>
    );
  }

  function handleDelete(e) {
    setUploadMessage(`Confirm. Are you sure you want to delete this vehicle?`)
  }

  // { `Updated: ${moment(vehicle.updatedAt).format("MMM Do YYYY")}`}

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {`${vehicle.vehicleType}`.charAt(0)}
          </Avatar>
        }
        action={
          <IconButton aria-label="settings">
            <MoreVertIcon />
          </IconButton>
        }
        title={vehicle.title}
        subheader={`${vehicle.currencyCode} ${shillingsFormatter.format(vehicle.intPrice)}`}
        titleTypographyProps={{ noWrap: true }}
      />
      <CardMedia
        className={classes.media}
        image={vehicle.featuredImage}
        title={vehicle.title}
      />
      <CardContent>
        {getAlert()}
        <h6>{`${vehicle.city}, ${vehicle.district}`}</h6>
        <Typography variant="body2" color="textSecondary" component="p" noWrap>
          {`${vehicle.year} ${make} ${model}`}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        {spinner && <CircularProgress /> }
        <Link to={{
            pathname: "/admin/addvehicle",
            state: { vehicle }
					}}>
            <IconButton aria-label="edit vehicle">
                <EditIcon />
            </IconButton>
        </Link>
        <IconButton aria-label="delete property" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
        <IDButton disabled>
          {`ShortID: ${vehicle.shortID}`}
        </IDButton>
      </CardActions>
    </Card>
  );
}
