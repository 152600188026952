/*
import React from 'react';
import _ from "underscore";

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';  
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';


import ChevronRight from '@material-ui/icons/ChevronRight';
import Button from "components/CustomButtons/Button.jsx";

// GraphQL
//import { useQuery } from '@apollo/react-hooks';
import { useQuery } from 'react-apollo';
import { shopifyQueries } from "variables/graphql.jsx";

// Manzia
import LoadInfo from "views/GetStarted/LoadInfo.jsx";

const styles = {
	root: {
	  overflow: 'auto',
	  maxHeight: 800,
	},
  };

function LooksMenu(props) {
	const { store: { shopifyDomain: storeId, name }, selectHandler, 
	firstLookHandler, classes, errorHandler, commentMap } = props;
	const stores = [storeId ];
	const [selectedLook, setSelectedLook] = React.useState(null);
	const [hasClicked, setHasClicked] = React.useState(false);

	function handleListItemClick(look, event) {
      	setSelectedLook(look);
	  	if (selectHandler) {
		  	selectHandler(look);
	  	}
	}

	function renderLooks(looks) {
		const items = _.sortBy(looks, 'lookName').map(function(look, idx) {
			const { id, lookName } = look;
			const comments = commentMap.has(id) ? commentMap.get(id) : [];
			return (
				<ListItem key={idx + 1}
					button
					divider
					selected={!_.isEmpty(selectedLook) && selectedLook.id === id}
					onClick={(event) => handleListItemClick(look, event)}>
						<ListItemText primary={`${lookName} (${comments.length})`}/>
						<ListItemSecondaryAction>
						  <IconButton aria-label="Expand">
							<ChevronRight />
						  </IconButton>
						</ListItemSecondaryAction>
				  </ListItem>
			);
		});

		return items;
	}

	// Fetch
	const { loading, error, data, fetchMore } = useQuery(shopifyQueries.looksByStore(), 
			{ 
				variables: { stores, offset: 0 },
				onCompleted(data) {
					if (data && Array.isArray(data.Look) && data.Look.length > 0) {
						// Set Comments via Parent
						//debugger;
						firstLookHandler(data.Look[0]);
						
					}
				}
			});
	
	if (loading) return (<LoadInfo />);
    if (error) {
		
		return (<h4>{`Error! ${error.message}`}</h4>);
	}

	if (Array.isArray(data.Look) && data.Look.length > 0) {
		// Render
		const hasNextPage= data.Look.length % 20 === 0;
		const looks = renderLooks(data.Look);

		return (
			<div className={classes.root}>
				<List component="nav">
					{looks}
				</List>
				<br />
				{ hasNextPage &&
					<Button 
						color="primary"
						onClick={() =>
							fetchMore({
								variables: {
									offset: data.Look.length
								},
								updateQuery: (prev, { fetchMoreResult }) => {
									if (_.isEmpty(fetchMoreResult) || _.isEmpty(fetchMoreResult.Look)) return prev;
									return Object.assign({}, prev, {
										Look: [...prev.Look, ...fetchMoreResult.Look]
									});
								}
							})
						}
					> 
					Load More 
				</Button>
				}
			</div>
			
		);

	} else {  
		return ( <h4>{`No Looks for: ${name}`}</h4>);
	}
	
}

export default withStyles(styles)(LooksMenu);
*/

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import propertyImage from "assets/img/card-3.jpeg";

// Utils
const _ = require("underscore");
const moment = require("moment");

const useStyles = makeStyles(theme => ({
  root: {
	width: '100%',
	overflow: 'auto',
	maxHeight: 800,
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
}));

export default function PropertyMenu(props) {
	const { properties, propertyHandler } = props;
	const [selectProperty, setSelectProperty] = React.useState(_.isEmpty(properties) ? null : properties[0]);
	const classes = useStyles();
	const shillingsFormatter = new Intl.NumberFormat({ style: 'currency', currency: 'UGX' });
  

  return (
	  <div className={classes.root}>
		  <List component="nav">
			{
				properties.map((property, idx) => {
					const { id, name, featuredImage, propertyPurpose, salePrice, rentPrice, 
						currencyCode, city, district  } = property;
					const price = `${propertyPurpose}` === "Sale" ? parseInt(salePrice, 10) : parseInt(rentPrice, 10);

					return (
						<ListItem 
							alignItems="center" 
							key={idx}
							button
							divider
							selected={!_.isEmpty(selectProperty) && selectProperty.id === id}
							onClick={(e) => {
								setSelectProperty(property);
								propertyHandler(property);
							}}
						>
							<ListItemAvatar>
							<Avatar alt={name} src={featuredImage} className={classes.bigAvatar} />
							</ListItemAvatar>
							<ListItemText
							primary={name}
							secondary={
								<React.Fragment>
								<Typography
									component="span"
									variant="body2"
									className={classes.inline}
									color="textPrimary"
								>
									{`${currencyCode} ${shillingsFormatter.format(price)}`}
								</Typography>
								{` — ${city}, ${district}`}
								</React.Fragment>
							}
							/>
						</ListItem>
						
					);
				})
			}
		</List>
	  </div>
    
  );
}
