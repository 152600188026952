import React from "react";
import _ from "underscore";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// GraphQL
import { API, graphqlOperation } from "aws-amplify";
import * as queries from 'graphql/queries';

// core components
import GridContainer from "components/Grid/GridContainer.js";

//import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
//import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

// Bulooka
import Grid from "../Components/CustomGridItem.js";
import Advert from "bulooka/Advertisers/Advert";

const styles = {
  centered: {
    textAlign: 'center'
  },
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
};

const useStyles = makeStyles(styles);

export default function AdvertsList(props) {
  const { advertiserId, title = "Advertiser" } = props;
  const [adverts, setAdverts] = React.useState([]);

  const classes = useStyles();

  React.useEffect(() => {
    async function fetchAdverts() {
        try {
          const { data } = await API.graphql(graphqlOperation(queries.getAdvertiser, { id: advertiserId }
            ));
          if (data && !_.isEmpty(data.getAdvertiser) && !_.isEmpty(data.getAdvertiser.adverts)) {
            const { items = []} = data.getAdvertiser.adverts;
            setAdverts(items);
          }
        } catch (error) {
          console.log(error);
        }
    }

    fetchAdverts();
    
  }, []);

  if (_.isEmpty(adverts)) {
    return (<h4 className={classes.infoText}>{`No Adverts found for ${title}`}</h4>)
  } else {
      return (
        <React.Fragment>
            <br />
            <h4 className={classes.infoText}>{`Adverts from ${title}`}</h4>
            <GridContainer spacing={4}>
              {adverts.map((advert, key) => {
                  return (
                    <Grid xs={12} sm={6} md={4} key={key}>
                      <Advert advert={advert} advertiserId={advertiserId} />
                    </Grid>
                  );
                })}
            </GridContainer>
            <br />
            <br />
        </React.Fragment>
        
      );
  }

}
