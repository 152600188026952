import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import _ from "underscore";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";


// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import NavPills from "components/NavPills/NavPills.js";
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

// AWS
import { graphqlOperation }  from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as queries from 'graphql/queries';

// Manzia
import PropertyOwnersTable from "bulooka/Tables/propertyownerstable.js";
import PropertyOwner from "./PropertyOwner.js";

const FETCH_LIMIT = 1000;

const styles = {
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  cardKeywordTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
	textAlign: "center"
  },
};

class PropertyOwners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
	  // General
      storeOptions: [
          'All',
          'ABC Store - Kirkland, WA',
          'ABC Store - Bellevue, WA'
      ],
      pageToken: "",
      fetchedOwners: []
    };

    this.fetchMore = this.fetchMore.bind(this);
  }

  componentDidMount() {
    
  }

  fetchMore(items, nextToken) {
    if (_.isEmpty(nextToken)) return;

      this.setState((state, props) => {
        return { pageToken: nextToken, fetchedOwners: [...state.fetchedOwners, ...items] };
      });
  };
  
  renderOwners() {
    const { classes, user } = this.props;
    const { pageToken, fetchedOwners } = this.state;

    // Next Page
    const variables = { limit: FETCH_LIMIT };
    if (!_.isEmpty(pageToken)) { Object.assign(variables, { nextToken: pageToken }); }
    const that = this;

    return (
      <React.Fragment>
            <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" icon>
              <CardIcon color="info">
                <MailOutline />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{`Property Owners`}</h4>
            </CardHeader>
            <CardBody>
          <br />
          <NavPills
            color="info"
                tabs={[
                  {
                    tabButton: "All Owners",
                    tabContent: (

                      <React.Fragment>
                        <h4 className={classes.cardKeywordTitle}>{"All Owners"}</h4>

                        <Connect query={graphqlOperation(queries.listOwners, variables )}>
                            {({ data: { listOwners }, loading, errors }) => {
                              if (!_.isEmpty(errors)) return (<h3 className={classes.cardKeywordTitle}>{`Errors: ${JSON.stringify(errors)}`}</h3>);
                              if (loading || !listOwners) return (<h3 className={classes.cardKeywordTitle}>Loading...</h3>);
                              
                              if (_.isEmpty(listOwners.items)) {
                                return (<h3 className={classes.cardKeywordTitle}>No Property owners found.</h3>)
                              } else {
                                const { items, nextToken } = listOwners;
                                //console.log("owners: " + JSON.stringify(listOwners));
            
                                  return (
                                    <PropertyOwnersTable 
                                      owners={[...fetchedOwners, ...items ]}
                                      hasNextPage={items.length > 0 && items.length % FETCH_LIMIT === 0}
                                      onLoadMore={() => that.fetchMore(items, nextToken)}
                                      user={user}
                                    />
                                  );
                              }
                            }}
                        </Connect>
                      </React.Fragment>
                    )
                  },
                  {
                    tabButton: "Property Owners",
                    tabContent: (

                      <React.Fragment>
                        <h4 className={classes.cardKeywordTitle}>{"Active Landlords"}</h4>

                        <Connect query={graphqlOperation(queries.listOwners, variables )}>
                            {({ data: { listOwners }, loading, errors }) => {
                              if (!_.isEmpty(errors)) return (<h3 className={classes.cardKeywordTitle}>{`Errors: ${JSON.stringify(errors)}`}</h3>);
                              if (loading || !listOwners) return (<h3 className={classes.cardKeywordTitle}>Loading...</h3>);
                              
                              if (_.isEmpty(listOwners.items)) {
                                return (<h3 className={classes.cardKeywordTitle}>No Property owners found.</h3>)
                              } else {
                                const { items, nextToken } = listOwners;
                                //console.log("owners: " + JSON.stringify(listOwners));
            
                                  return (
                                    <PropertyOwnersTable 
                                      owners={[...fetchedOwners, ...items ].filter(item => {
                                        const { properties: { items } } = item;
                                        return Array.isArray(items) && items.length > 0
                                      })}
                                      hasNextPage={items.length > 0 && items.length % FETCH_LIMIT === 0}
                                      onLoadMore={() => that.fetchMore(items, nextToken)}
                                      user={user}
                                    />
                                  );
                              }
                            }}
                        </Connect>
                      </React.Fragment>
                    )
                  },
                  {
                    tabButton: "Vehicle Owners",
                    tabContent: (
                      <React.Fragment>
                      <h4 className={classes.cardKeywordTitle}>{"Active Vehicle Owners"}</h4>

                      <Connect query={graphqlOperation(queries.listOwners, variables )}>
                          {({ data: { listOwners }, loading, errors }) => {
                            if (!_.isEmpty(errors)) return (<h3 className={classes.cardKeywordTitle}>{`Errors: ${JSON.stringify(errors)}`}</h3>);
                            if (loading || !listOwners) return (<h3 className={classes.cardKeywordTitle}>Loading...</h3>);
                            
                            if (_.isEmpty(listOwners.items)) {
                              return (<h3 className={classes.cardKeywordTitle}>No Property owners found.</h3>)
                            } else {
                              const { items, nextToken } = listOwners;
                              //console.log("owners: " + JSON.stringify(listOwners));
          
                                return (
                                  <PropertyOwnersTable 
                                    owners={[...fetchedOwners, ...items ].filter(item => {
                                      const { vehicles: { items } } = item;
                                      return Array.isArray(items) && items.length > 0
                                    })}
                                    hasNextPage={items.length > 0 && items.length % FETCH_LIMIT === 0}
                                    onLoadMore={() => that.fetchMore(items, nextToken)}
                                    user={user}
                                  />
                                );
                            }
                          }}
                      </Connect>
                    </React.Fragment>
                      
                    )
                  },

                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      </React.Fragment>
      
    );
  }

  render() {
    const { classes, signedOut, user } = this.props;
    if (signedOut) return (<Redirect to="/" /> );
    return (
      <React.Fragment>
          <Switch>
            <Route exact path="/admin/owners/detail" render={ routeProps => ( <PropertyOwner user={user} {...routeProps} /> ) }/>
            {this.renderOwners()}
        </Switch>
        
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(PropertyOwners);
