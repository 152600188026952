import React from 'react';
import _ from "underscore";

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';

// Icons
import Done from "@material-ui/icons/DoneOutlined";
import Add from "@material-ui/icons/AddOutlined";

// Material
import Button from "components/CustomButtons/Button.js";

// Manzia
import { PaymentTypes, SubscriptionTypes, LOGGED_USER } from "variables/general.js";
import CloseableSnack from "bulooka/Components/CloseableSnack.js";

// AWS
import { Connect } from "aws-amplify-react";
import { API, graphqlOperation } from 'aws-amplify';

// Styles
import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";
import { display } from '@material-ui/system';

// Utils
const moment = require("moment");
const nonce = require('nonce')();

const useStyles = makeStyles(theme => ({
    cardTitle,
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        '& > *': {
            marginRight: '15px',
        }
    },
    textField: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
        width: 300,
    },
    textFieldSmall: {
        marginLeft: theme.spacing(1.5),
        marginRight: theme.spacing(1.5),
        width: 150,
    },
    menu: {
        width: 300,
    },
    menuSmall: {
        width: 150,
    },
    cardKeywordTitle: {
        ...cardTitle,
        marginTop: "10px",
        marginBottom: "10px",
        textAlign: "center"
    },
    centered: {
        marginTop: "5px",
        marginBottom: "5px",
        textAlign: "center"
    }
}));

const subscriptionStatus = [ "active", "cancelled", "pending" ];

const currencies = [ "UGX", "USD", "GBP" ];

export default function OwnerPayments(props) {
    const { onPayment, onSubscriptionUpdate, owner, subscriptionId, newSubscriber, user } = props;
    const [subType] = SubscriptionTypes;
    const astatus = _.isEmpty(owner.subscription) ? subscriptionStatus[0] : owner.subscription.status;
    const aplan = _.isEmpty(owner.subscription) ? subType.name : owner.subscription.name;
    const [subscription, setSubscription] = React.useState(aplan);
    const [status, setStatus] = React.useState(astatus);
    const [currency, setCurrency] = React.useState(currencies[0]);
    const [payment, setPayment] = React.useState(subType.price);
    const [paymentType, setPaymentType] = React.useState(PaymentTypes[0]);
    const [notes, setNotes] = React.useState("");
    const [fullyPaid, setFullyPaid] = React.useState(owner.fullyPaid);
    const [message, setMessage] = React.useState("");
    const [subscribeId, setSubscribeId] = React.useState("");

    const displayId = subscriptionId.substring(subscriptionId.lastIndexOf("/") + 1);
    const classes = useStyles();

    const handleChange = event => {
        switch(event.target.name) {
            case 'subscription':
                setSubscription(event.target.value);
                break;
            case 'status':
                setStatus(event.target.value);
                break;
            case 'currency':
                setCurrency(event.target.value);
                break;
            case 'payment':
                setPayment(event.target.value);
                break;
            case 'paymentType':
                setPaymentType(event.target.value);
                break;
            case 'notes':
                setNotes(event.target.value);
                break;
            default:
                break;
        }
        
    };

    const handleSwitch = event => {
        setFullyPaid(event.target.checked)
    };

    function subscribeMutation() {
        // Mutation
        let mutation = "";
    
        // Create Date
        const now = moment().utc().format();
        const { price, recurring } = _.findWhere(SubscriptionTypes, { name: subscription });


        if (newSubscriber) {
            // Create App Subscription
            mutation += ` a1: createAppSubscription(input: {
                id: "${subscriptionId}", name: "${subscription}", price: "${price}", 
                recurring: "${recurring}", startDate: "${now}", status: "${status}",
                appSubscriptionOwnerId: "${owner.id}"
            }) {
                id
            },
            a2: updateOwner(input: {
                id: "${owner.id}", fullyPaid: ${fullyPaid}, ownerSubscriptionId: "${subscriptionId}"
              }) {
                id
              }
            `;
        } else {
            // Update App Subscription
            mutation += ` a1: updateAppSubscription(input: {
                id: "${subscriptionId}", name: "${subscription}", price: "${price}", 
                recurring: "${recurring}", status: "${status}"
            }) {
                id
            },
            a2: updateOwner(input: {
                id: "${owner.id}", fullyPaid: ${fullyPaid}
              }) {
                id
              }
            `;
        }

        return `mutation ownerSubscription { ${mutation} }`;

    }

    function paymentMutation() {
        
        // Variables
        const now = moment().utc().format();
        const paymentId = `gid://Bulooka/Payment/${nonce()}`;
        const processedBy = _.isEmpty(user) ? LOGGED_USER : user.attributes.email;
        
        // Mutation
        const mutation = `mutation ownerPayment($paymentType: PaymentType!) { 
            p1: createPayment(input: {
                id: "${paymentId}", amount: "${payment}", currencyCode: "${currency}", 
                processedBy: "${processedBy}", processedAt: "${now}", notes: "${notes}",
                paymentType: $paymentType, paymentSubscriptionId: "${subscriptionId}"
            }) {
                id
                subscription {
                    id
                }
                amount
                currencyCode
                processedAt
                processedBy
                notes
                paymentType
            },
            p2: updateAppSubscription(input: {
                id: "${subscriptionId}", lastPaidAt: "${now}"
            }) {
                id
            }
         }`;
         //console.log("pay mutation: " + mutation);
         return mutation;

    }

    /*
        Response:
        {"data":{"a1":{"id":"gid://Bulooka/AppSubscription/157427897437800"},"a2":{"id":"gid://Bulooka/Owner/100100"}}}
    */
    async function submitMutation(mutation, isPayment, paymentInput) {
        //debugger;
        try {
            const resp = isPayment ? await mutation(paymentInput) : await mutation();
            //console.log("Response: " + JSON.stringify(resp));
            setMessage( isPayment ? "Success. Posted payment." : "Success: Updated subscription.");
            if (!isPayment) {
                const { data: { a1: { id }}} = resp;
                setSubscribeId(id);
                if (onSubscriptionUpdate) {
                    onSubscriptionUpdate(id);
                }
            } else {
                if (onPayment) {
                    const { data: { p1 }} = resp;
                    setNotes("");
                    onPayment(p1);
                }
            }
        } catch (err) {
            console.error(err);
            setMessage( isPayment ? "Error. Failed to post payment." : "Error: Failed to update subscription.");
        }
    }

    return (
        <React.Fragment>
            {!_.isEmpty(message) && 
                <div className={classes.centered}>
                    <CloseableSnack 
                        message={message} 
                        color={message.startsWith("Error") ? "danger" : "success"}
                        closeHandler={() => {
                            setMessage("");
                            //window.location.reload(false);
                        }}
                        close
                    />
                </div>
            }
            { _.isEmpty(subscribeId) ? 
                <h4 className={classes.cardKeywordTitle}>{newSubscriber ? "Create Subscription" : `Subscription ID: ${displayId}`}</h4> :
                <h4 className={classes.cardKeywordTitle}>{`Subscription ID: ${subscribeId.substring(subscribeId.lastIndexOf("/") + 1)}`}</h4>
            }
            
            <div className={classes.container}>
                <div>
                    <TextField
                        id="subscription-plan"
                        select
                        label="Subscription Plan"
                        name="subscription"
                        className={classes.textField}
                        value={subscription}
                        onChange={handleChange}
                        SelectProps={{
                            MenuProps: {
                            className: classes.menu,
                            },
                        }}
                        margin="normal"
                        >
                        {SubscriptionTypes.map((option, idx) => (
                            <MenuItem key={`${idx+1}`} value={option.name}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div>
                    <TextField
                        id="subscription-status"
                        select
                        label="Subscription Status"
                        name="status"
                        className={classes.textField}
                        value={status}
                        onChange={handleChange}
                        SelectProps={{
                            MenuProps: {
                            className: classes.menu,
                            },
                        }}
                        margin="normal"
                        >
                        {subscriptionStatus.map((option, idx) => (
                            <MenuItem key={`${idx+1}`} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div>
                    <FormControlLabel
                        control={
                        <Switch
                            checked={fullyPaid}
                            onChange={handleSwitch}
                            value="fullyPaid"
                            color="primary"
                        />
                        }
                        label="Fully Paid?"
                    />
                </div>
                <div>
                    <Connect mutation={graphqlOperation(subscribeMutation())}>
                        {({mutation, loading, errors, data}) => {
                            if (loading) return (<CircularProgress />);
                            return (
                                <Button 
                                    color="info" 
                                    size="sm" 
                                    onClick={(e) => {
                                        e.persist();
                                        submitMutation(mutation, false);
                                    }}
                                >
                                    <Done /> { (newSubscriber && _.isEmpty(subscribeId)) ? "Create" : "Update"}
                                </Button>
                            );
                        }}
                    </Connect>
                    
                </div>
            </div>
            <br />
            <br />
            <h4 className={classes.cardKeywordTitle}>Make a Payment</h4>
            <div className={classes.container}>
                <div>
                    <TextField
                        id="payment-amount"
                        label="Payment Amount"
                        type="number"
                        name="payment"
                        value={payment}
                        onChange={handleChange}
                        className={classes.textFieldSmall}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        margin="normal"
                    />
                </div>
                <div>
                    <TextField
                        id="currency-code"
                        select
                        label="Currency"
                        name="currency"
                        className={classes.textFieldSmall}
                        value={currency}
                        onChange={handleChange}
                        SelectProps={{
                            MenuProps: {
                            className: classes.menuSmall,
                            },
                        }}
                        margin="normal"
                        >
                        {currencies.map((option, idx) => (
                            <MenuItem key={`${idx+1}`} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div>
                    <TextField
                        id="payment-type"
                        select
                        label="Payment Type"
                        name="paymentType"
                        className={classes.textFieldSmall}
                        value={paymentType}
                        onChange={handleChange}
                        SelectProps={{
                            MenuProps: {
                            className: classes.menuSmall,
                            },
                        }}
                        margin="normal"
                        >
                        {PaymentTypes.map((option, idx) => (
                            <MenuItem key={`${idx+1}`} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </div>
                <div>
                    <TextField
                        id="payment-notes"
                        label="Notes"
                        placeholder="Payment Notes"
                        name="notes"
                        value={notes}
                        onChange={handleChange}
                        multiline
                        className={classes.textField}
                        margin="normal"
                    />
                </div>
                <div>
                    <Connect mutation={graphqlOperation(paymentMutation())}>
                        {({mutation, loading, errors, data}) => {
                            if (loading) return (<CircularProgress />);
                            if (!_.isEmpty(errors)) {
                                setMessage("Error: " + JSON.stringify(errors));
                            }
                            if (!_.isEmpty(data)) {
                                //console.log("payment data: " + JSON.stringify(data));
                                setMessage("Success: Posted payment.");
                            }
                            return (
                                <Button 
                                    color="info" 
                                    size="sm" 
                                    onClick={(e) => {
                                        e.persist();
                                        submitMutation(mutation, true, { paymentType });
                                    }}
                                    disabled={(_.isEmpty(subscribeId) && newSubscriber) || _.isEmpty(payment) || payment.length < 2 || _.isEmpty(notes)}
                                >
                                    <Add /> Submit
                                </Button>
                            );
                        }}
                    </Connect>
                    
                </div>
            </div>
            <br />
        </React.Fragment>
        
    );
}
