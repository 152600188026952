import React from "react";
import PropTypes from "prop-types";
import _ from "underscore";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


import { API, graphqlOperation } from 'aws-amplify';
import * as queries from 'graphql/queries';

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  choiche: {
    textAlign: "center",
    cursor: "pointer",
    marginTop: "20px"
  },
  ...customSelectStyle,
  ...customCheckboxRadioSwitch
};

const getPropertyAmenities = `query GetPropertyAmenities($id: ID!) {
  getProperty(id: $id) {
    id
    amenities(limit: 100) {
      items {
        id
        createdAt
        options
        amenity {
          id
          name
          icon
          description
          options
        }
      }
      nextToken
    }
  }
}
`;

const getAVehicleFeatures = `query GetAVehicleFeatures($id: ID!) {
  getVehicle(id: $id) {
    id
    features(limit: 100) {
      items {
        id
        createdAt
        options
        feature {
          id
          name
          icon
          description
          options
          type
        }
      }
      nextToken
    }
  }
}
`;

/*
{
  "data": {
    "getProperty": {
      "id": "gid://Bulooka/Property/100800",
      "amenities": {
        "items": [
          {
            "id": "gid://Bulooka/PropertyAmenity/100203",
            "amenity": {
              "id": "gid://Bulooka/Amenity/100204",
              "icon": "mug-hot",
              "name": "Microwave"
            }
          },
          {
            "id": "gid://Bulooka/PropertyAmenity/100200",
            "amenity": {
              "id": "gid://Bulooka/Amenity/100200",
              "icon": "wifi",
              "name": "Wi-Fi"
            }
          },
          {
            "id": "gid://Bulooka/PropertyAmenity/100201",
            "amenity": {
              "id": "gid://Bulooka/Amenity/100201",
              "icon": "parking",
              "name": "Parking"
            }
          }
        ]
      }
    }
  }
}
*/


class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      vehicleFeatures: [],
      fetchedVehicleFeatures: false,
      amenities: [],
      fetchedAmenities: false
    };
  }

  async componentDidMount() {
    const { vehicle } = this.props;

    try {
      const { data } = await API.graphql(graphqlOperation(queries.listCarFeaturess, { limit: 100 }));
      if (data && !_.isEmpty(data.listCarFeaturess) && Array.isArray(data.listCarFeaturess.items) ) {
          // Set State
          const amenState = {};
          let amens = data.listCarFeaturess.items.map((item, idx) => {
             const { icon, name, id } = item;
             let iconValue = `fas fa-${icon} `;
             let stateValue = name.replace(/\W/g, "").toLowerCase();
             amenState[stateValue] = false;
             return { icon: iconValue, label: name, state: stateValue, featureId: id };
          });

          this.setState({ vehicleFeatures: amens, loading: false, ...amenState });
      }

      if (!_.isEmpty(vehicle)) {
        try {
          const { data: propData } = await API.graphql(graphqlOperation(getAVehicleFeatures, { id: vehicle.id }));
          if (propData && !_.isEmpty(propData.getVehicle) && !_.isEmpty(propData.getVehicle.features)) {
              const { items } = propData.getVehicle.features;

              if (Array.isArray(items)) {
                const amenObj = {};
                const propAmens = {};
                for (let i=0; i<items.length; i++) {
                  const { feature: { id: featureId, name }, id } = items[i];
                  let stateValue = name.replace(/\W/g, "").toLowerCase();
                  amenObj[stateValue] = true;
                  propAmens[featureId] = { vehicleId: vehicle.id, vehicleFeatureId: id };
                }
                //debugger;
                const pamens = this.state.vehicleFeatures.map((amen) => {
                  const { featureId } = amen;
                  if (propAmens[featureId]) {
                    const { vehicleId, vehicleFeatureId } = propAmens[featureId];
                    return Object.assign({}, amen, { vehicleId, vehicleFeatureId } );
                  } else {
                    return Object.assign({}, amen, { vehicleId: null, vehicleFeatureId: null } );
                  }
                });
                
                this.setState({ ...amenObj, vehicleFeatures: pamens, loading: false });
              }
          }
        } catch (error) {
          console.log("Error fetching vehicle features: %s", JSON.stringify(error));
        }
      }

    } catch (error) {
        console.log("Error fetching features: %s", JSON.stringify(error));
        this.setState({ loading: false });
    }
  }


  // async componentDidMount() {
  //   const { property } = this.props;

  //   try {
  //     const { data } = await API.graphql(graphqlOperation(queries.listAmenitys, { limit: 50 }));
  //     if (data && !_.isEmpty(data.listAmenitys) && Array.isArray(data.listAmenitys.items) ) {
  //         // Set State
  //         const amenState = {};
  //         let amens = data.listAmenitys.items.map((item, idx) => {
  //            const { icon, name, id } = item;
  //            let iconValue = `fas fa-${icon} `;
  //            let stateValue = name.replace(/\W/g, "").toLowerCase();
  //            amenState[stateValue] = false;
  //            return { icon: iconValue, label: name, state: stateValue, amenityId: id };
  //         });

  //         this.setState({ amenities: amens, loading: false, ...amenState });
  //     }

  //     if (!_.isEmpty(property)) {
  //       try {
  //         const { data: propData } = await API.graphql(graphqlOperation(getPropertyAmenities, { id: property.id }));
  //         if (propData && !_.isEmpty(propData.getProperty) && !_.isEmpty(propData.getProperty.amenities)) {
  //             const { items } = propData.getProperty.amenities;

  //             if (Array.isArray(items)) {
  //               const amenObj = {};
  //               const propAmens = {};
  //               for (let i=0; i<items.length; i++) {
  //                 const { amenity: { id: amenityId, name }, id } = items[i];
  //                 let stateValue = name.replace(/\W/g, "").toLowerCase();
  //                 amenObj[stateValue] = true;
  //                 propAmens[amenityId] = { propertyId: property.id, propertyAmenityId: id };
  //               }
  //               //debugger;
  //               const pamens = this.state.amenities.map((amen) => {
  //                 const { amenityId } = amen;
  //                 if (propAmens[amenityId]) {
  //                   const { propertyId, propertyAmenityId } = propAmens[amenityId];
  //                   return Object.assign({}, amen, { propertyId, propertyAmenityId } );
  //                 } else {
  //                   return Object.assign({}, amen, { propertyId: null, propertyAmenityId: null } );
  //                 }
  //               });
                
  //               this.setState({ ...amenObj, amenities: pamens, loading: false });
  //             }
  //         }
  //       } catch (error) {
  //         console.log("Error fetching property amenities: %s", JSON.stringify(error));
  //       }
  //     }

  //   } catch (error) {
  //       console.log("Error fetching amenities: %s", JSON.stringify(error));
  //       this.setState({ loading: false });
  //   }
  // }

  // async componentDidUpdate(prevProps, prevState) {
  //   debugger;
  //   const { property } = prevProps;
  //   const { amenities, fetchedAmenities } = this.state;
  //   if (_.isEmpty(property) || _.isEmpty(amenities) || fetchedAmenities === true) return;

    

  // }

  sendState() {
    return this.state;
  }
  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };
  isValidated() {
    return true;
  }

  renderFeatures() {
    const { classes } = this.props;
    const { vehicleFeatures, loading } = this.state;
    if (_.isEmpty(vehicleFeatures) && !loading) return (<h3>No vehicle features were found</h3>);

    const amens = vehicleFeatures.map((option, key) => {
      const { icon, label, state } = option;
      return (
        <GridItem xs={12} sm={4} key={key}>
          <div className={classes.choiche}>
            <Checkbox
              checked={this.state[state]}
              tabIndex={-1}
              onClick={this.handleChange(state)}
              checkedIcon={
                <i
                  className={
                    icon + classes.iconCheckboxIcon
                  }
                />
              }
              icon={
                <i
                  className={
                    icon + classes.iconCheckboxIcon
                  }
                />
              }
              classes={{
                checked: classes.iconCheckboxChecked,
                root: classes.iconCheckbox
              }}
            />
            <h6>{label}</h6>
          </div>
        </GridItem>
      );
      
    });
    return amens;
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <h4 className={classes.infoText}>What vehicle features are available?</h4>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={10}>
            <GridContainer>
              {this.renderFeatures()}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Step2.propTypes = {
  classes: PropTypes.object
};

export default withStyles(style)(Step2);
