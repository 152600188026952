import React from 'react';
import { Link } from 'react-router-dom';
import _ from "underscore";


// DataTables
//import "assets/css/jquery.dataTables.min.css";
import "assets/css/buttons.dataTables.min.css";

//import EditableTable from '../components/editabletable'

// Material Core
import withStyles from "@material-ui/core/styles/withStyles";

// Material Icons
import MoreRight from '@material-ui/icons/ChevronRight';
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";


import Button from "components/CustomButtons/Button.js";

// Manzia
// import { LooksViewMode } from "variables/constants.jsx";
// import { imageURL } from "variables/general.jsx";
// import DeleteLook from "views/Looks/DeleteLook.jsx";

// Styles
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";
import { green } from '@material-ui/core/colors';
import { classes } from 'istanbul-lib-coverage';

// DataTables
const $ = require('jquery');
$.DataTable = require( 'datatables.net' );
//const buttons = require( 'datatables.net-buttons' );
require( 'datatables.net-buttons/js/buttons.colVis.js' ); // Column visibility
require( 'datatables.net-buttons/js/buttons.html5.js' );  // HTML 5 file export
require( 'datatables.net-buttons/js/buttons.print.js' );  // Print view button

const moment = require("moment");

const styles = {
	...buttonStyle,
	hasPaid: {
		color: 'green',
		fontWeight: 'bold'
	},
	notPaid: {
		color: 'red',
		fontWeight: 'bold'
	},
	centered: {
		textAlign: 'center'
	}
};


class PaymentsTable extends React.Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			start: 0,
			addedPayments: false
		};
		
		// Table
		this.datatable = null;
	}
	
	
	componentDidMount() { 
		const { hasNextPage, onLoadMore, classes } = this.props;

		this.$el = $(this.el);
		this.datatable = this.$el.DataTable({
			"dom": "Bfrtip",
			"rowId": "ID",
			"ordering": true,
			"order": [[ 2, 'desc' ]],
			"columns": [
				{ className: classes.centered },
				{ className: classes.centered },
				{ className: classes.centered },
				{ className: classes.centered },
				{ className: classes.centered },
				{ className: classes.centered },
				{ className: classes.centered, width: '20%' },
			],
			"columnDefs": [
				{ "orderable": false, "targets": [ 0, 6 ] }
			],
			"scrollX": false,
			"scrollY": 800,
			"scrollCollapse": true,
			"deferRender": true,
			"scroller": {
			    loadingIndicator: true
			},
	        select: true,
	        buttons: [
				{
					extend: 'collection',
					text: 'Export',
					buttons: [
						'excel',
						'csv',
						'pdf'
					]
				}
	    	]
		});
		
		// Page Events
		const that = this;
		this.datatable.on( 'page.dt', function ( e, settings) {
			if (hasNextPage && onLoadMore) {
				const { page, pages } = that.datatable.page.info();
				if (pages - page === 1) {
					// Last page
					onLoadMore();
				}
			}
		} );
	}
	
	componentWillUnmount() {
		this.datatable.off('page.dt');
		this.datatable.destroy(true);
	}

	componentDidUpdate(prevProps) {
		if (this.props.payments !== prevProps.payments) {
			this.setState({ addedPayments: true });
			//console.log("Table data: " + this.datatable.data().length);
			//console.log( this.datatable.row('#157434389088000').data() );

			// Sync payments
			const { add, remove } = this.syncPayments(prevProps.payments, this.props.payments);
			if (!_.isEmpty(remove)) {
				// Remove data/nodes
				this.datatable.rows(remove).remove();
			} 

			if (!_.isEmpty(add)) {
				// Add new data/nodes and draw
				//const tableRows = this.renderPayments(add);
				const tableRows = this.displayPayments(add);
				this.datatable.rows.add(tableRows);
			}
		}
	}

	/*
		Note: This method is required to sync jQuery-based DataTables with virtual-DOM based React.
		1- The add array has the objects to add to the datatable
		2- The remove array has the rowIds to remove from the datatable
	*/
	syncPayments(prevPayments, currPayments) {
		if (!Array.isArray(prevPayments) || !Array.isArray(currPayments)) return { add: [], remove: [] };
		const prevGroup = _.groupBy(prevPayments, "id");
		const currGroup = _.groupBy(currPayments, 'id');
		const currKeys = Object.keys(currGroup);
		const prevKeys = Object.keys(prevGroup);
		const addArr = _.difference(currKeys, prevKeys);
		const removeArr = _.difference(prevKeys, currKeys);
		const addPayments = addArr.map((key) => {
			return currGroup[key][0];
		});
		const removeIds = removeArr.map((key) => {
			return `#${key}`;
		});
		
		return { add: addPayments, remove: removeIds };
	}

	displayPayments(payments) {
		
		const shillingsFormatter = new Intl.NumberFormat({ style: 'currency', currency: 'UGX' });
		return payments.map(function(value) {
			const { id, amount, currencyCode, processedAt, processedBy, notes, paymentType } = value;
			const displayId = id.substring(id.lastIndexOf("/") + 1);
			const amountInt = parseInt(amount, 10);
			const displayAmount = shillingsFormatter.format(amountInt);
			
			return [ 
				displayId, processedBy, moment(processedAt).format("MMM Do YYYY"), displayAmount,
				currencyCode, `${paymentType}`, notes
			];
		});
	}
	
	// <td style={tdStyle}>{moment(createdAt).format("MMM Do YYYY")}</td>
	// <th>CreatedAt</th>
	renderPayments(payments) {
		const shillingsFormatter = new Intl.NumberFormat({ style: 'currency', currency: 'UGX' });
		return payments.map(function(value) {
			const { id, amount, currencyCode, processedAt, processedBy, notes, paymentType } = value;
			const displayId = id.substring(id.lastIndexOf("/") + 1);
			const amountInt = parseInt(amount, 10);
			const displayAmount = shillingsFormatter.format(amountInt);
			
			return (
				<tr key={displayId} id={displayId}>
					<td>{displayId}</td>
					<td>{processedBy}</td>
					<td>{moment(processedAt).format("MMM Do YYYY")}</td>
					<td>{displayAmount}</td>
					<td>{currencyCode}</td>
					<td>{`${paymentType}`}</td>
					<td>{notes}</td>
				</tr>
			);
		});
	}

	render() {
		const { payments, classes } = this.props;
		const tableStyle = { width: '100%' };
		
		return (  
              <section className="panel">
                  
                  <div className="panel-body">
						
                    <table className="display" id="editable-products" style={tableStyle} ref={el => this.el = el}>
						<thead>
                          <tr>
                              <th>ID</th>
							  <th>Processed By</th>
							  <th>Payment Date</th>
							  <th>Amount</th>
							  <th>Currency</th>
							  <th>Type</th>
							  <th>Notes</th>
                          </tr>
                          </thead>
						 <tbody>
						 	{this.renderPayments(payments)}
						</tbody>
                    </table>
			
                  </div>
              </section>
            
		);
	}
}

export default withStyles(styles)(PaymentsTable);
