import React from 'react';
import _ from "underscore";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const PASSWORD_REGEX = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$*.{}?"!@#%&/,><\':;|_~`^\\]\\[\\)\\(]).{6,}');

export default function AuthDialog(props) {
    const { challenge, authHandler, showDialog, dialogHandler } = props;
  //const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [smsCode, setSmsCode] = React.useState("");

  // Effect to check if logged in
//   React.useEffect(() => {
//     setOpen(challenge === 'SMS_MFA' || challenge === 'SOFTWARE_TOKEN_MFA' || challenge === 'NEW_PASSWORD_REQUIRED')
//   }, [challenge]);


  const handleChange = prop => event => {
      switch (prop) {
        case 'newPassword':
            setNewPassword(event.target.value)
            break;
        case 'confirmPassword':
            setConfirmPassword(event.target.value);
            break;
        case 'smsCode':
            setSmsCode(event.target.value);
            break;
        default:
            break;
      }
      if (!_.isEmpty(error)) {
        setError("");
      }
  };

  function handleClose() {
    //setOpen(false);
    if (dialogHandler){
        dialogHandler(false);
    }
    setError("");
  }

  function renderSMSCode() {
      
    return (
        <div>
            <Dialog open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Enter Code</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Enter the six (6) digit code that was sent to you email.
                </DialogContentText>
                <TextField
                    autoFocus
                    error={!_.isEmpty(error)}
                    required
                    margin="dense"
                    id="sms-Code"
                    name="smsCode"
                    value={smsCode}
                    onChange={handleChange('smsCode')}
                    label="Enter Code"
                    type="text"
                    helperText={error}
                    fullWidth
                />
                <br />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSMSCode} color="primary">
                    Submit
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
  }

  function handleSMSCode() {
      if (verifyNumber(smsCode) && smsCode.length === 6) {
          //setOpen(false);
        if (dialogHandler) {
            dialogHandler(false);
        }
        if (authHandler) {
            authHandler({ smsCode });
        }
      } else {
          setError("Invalid sms code entered.")
      }
  }

  function handlePassword() {
    if (newPassword !== confirmPassword) {
        setError("Passwords entered do not match. Please retry.");
        return;
    } else if (!PASSWORD_REGEX.test(newPassword)) {
        setError("Password entered does not meet the requirements above.");
        return;
    } else {
        if (dialogHandler) {
            dialogHandler(false);
        }
        if (authHandler) {
            //setOpen(false);
            authHandler({ newPassword });
        }
    }
  }

  function verifyNumber(value) {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  function renderChangePassword() {
    //const showOpen = challenge === 'SMS_MFA' || challenge === 'SOFTWARE_TOKEN_MFA' || challenge === 'NEW_PASSWORD_REQUIRED';
    return (
        <div>
            <Dialog open={showDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Enter a new password to access Bulooka. Password must have at least 8 characaters and 
                    include at least one uppercase, one lowercase letter, one number and a special character.
                </DialogContentText>
                <TextField
                    autoFocus
                    error={!_.isEmpty(error)}
                    required
                    margin="dense"
                    id="new-password"
                    name="newPassword"
                    value={newPassword}
                    onChange={handleChange('newPassword')}
                    label="New Password"
                    type="password"
                    fullWidth
                    helperText={error}
                />
                <br />
                <TextField
                    error={!_.isEmpty(error)}
                    required
                    margin="dense"
                    id="confirm-password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={handleChange('confirmPassword')}
                    label="Confirm Password"
                    type="password"
                    fullWidth
                    helperText={error}
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handlePassword} color="primary">
                    Submit
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
  }

  if (challenge === 'SMS_MFA' || challenge === 'SOFTWARE_TOKEN_MFA') {
      return renderSMSCode();
  } else if (challenge === 'NEW_PASSWORD_REQUIRED') {
      return renderChangePassword();
  } else {
      return (<div></div>)
  }
}
