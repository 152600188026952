import React from "react";
import _ from "underscore";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress";

// @material-ui/icons
import Person from "@material-ui/icons/PersonOutline";
// import LockOutline from "@material-ui/icons/LockOutline";

//AWS
import { API, graphqlOperation } from 'aws-amplify';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import loginstyles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(loginstyles);

const GETOWNER = `query GetOwner($id: ID!) {
  getOwner(id: $id) {
    id
    displayName
    email
    phone
  }
}
`;


export default function ReportPage(props) {
  const { loggedHandler } = props;
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  // login form
  const [loginID, setloginID] = React.useState("");
  const [loginIDState, setloginIDState] = React.useState("");
  const [authError, setAuthError] = React.useState("");
  const [spinner, setSpinner] = React.useState(false);

  setTimeout(function() {
    setCardAnimation("");
  }, 700);

  const classes = useStyles();

  // function that verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  // function that verifies if value contains only numbers
  const verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };

  const loginClick = () => {
    if (loginIDState === "") {
      setloginIDState("error");
    }
  };
  
  function handleLoginReports() {
    if (!verifyNumber(loginID) || !verifyLength(loginID, 8)) {
      setAuthError("Invalid ID. Please try again.");
      return;
    } else {
      // Verify
      setSpinner(true);
      API.graphql(graphqlOperation(GETOWNER, { id: `gid://Bulooka/Owner/${loginID}` }))
      .then(({ data }) => {

          setSpinner(false)
          if (data && data.getOwner && !_.isEmpty(data.getOwner.id) && loggedHandler) {
            loggedHandler(data.getOwner.id);
          }
      })
      .catch((err) => {
        setAuthError("Unknown ID. Please try again.");
        setSpinner(false);
      });

    }
    // if (loginID === "123456789") {
    //   if (loggedHandler) {
    //     loggedHandler(loginID);
    //   }
    // } else {
    //   setAuthError("Invalid ID. Please try again.");
    // }
  }

  function renderLoginID() {
    return (
      <div className={classes.container}>
      <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={6}>
            <form>
              <Card login className={classes[cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                  color="info"
                >
                  <h4 className={classes.cardTitle}><strong>Bulooka Reports</strong></h4>
                  <h5 className={classes.cardTitle}>Uganda's Best Real Estate and Auto Deals</h5>
                </CardHeader>
                <CardBody>
                  <br />
                  <h5 className={`${classes.cardMessage} ${classes.textCenter}`}>Enter your Bulooka ID below to view your reports.</h5>
                  <br />
                  
                  <CustomInput
                    success={loginIDState === "success"}
                    error={loginIDState === "error"}
                    labelText="Bulooka ID..."
                    id="ownerId"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Person className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                      ),
                      onChange: event => {
                        if (verifyNumber(event.target.value) && verifyLength(event.target.value, 8)) {
                          setloginIDState("success");
                        } else {
                          setloginIDState("error");
                        }
                        if (!_.isEmpty(authError)) {
                          setAuthError("");
                        }
                        setloginID(event.target.value);
                      },
                      type: "text"
                    }}
                  />
                  <br />
                  {!_.isEmpty(authError) && (<h6 style={{ color: 'red' }}>{authError}</h6>)}
                </CardBody>
                <CardFooter className={classes.justifyContentCenter}>
                    {spinner && <CircularProgress />}
                    <Button color="info" round size="lg" onClick={handleLoginReports}>
                        View Reports
                    </Button>
                 
                  
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      
    </div>
    );
  }

  return (
    <React.Fragment>
        {renderLoginID()}
    </React.Fragment>
  );
}
