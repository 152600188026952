import React from 'react';
import { Link } from 'react-router-dom';
import _ from "underscore";


// DataTables
//import "assets/css/jquery.dataTables.min.css";
import "assets/css/buttons.dataTables.min.css";

//import EditableTable from '../components/editabletable'

// Material Core
import withStyles from "@material-ui/core/styles/withStyles";

// Material Icons
import MoreRight from '@material-ui/icons/ChevronRight';

import Button from "components/CustomButtons/Button.js";

// Manzia
// import { LooksViewMode } from "variables/constants.jsx";
// import { imageURL } from "variables/general.jsx";
// import DeleteLook from "views/Looks/DeleteLook.jsx";

// Styles
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

// DataTables
const $ = require('jquery');
$.DataTable = require( 'datatables.net' );
//const buttons = require( 'datatables.net-buttons' );
require( 'datatables.net-buttons/js/buttons.colVis.js' ); // Column visibility
require( 'datatables.net-buttons/js/buttons.html5.js' );  // HTML 5 file export
require( 'datatables.net-buttons/js/buttons.print.js' );  // Print view button

const styles = {
	...buttonStyle,
	hasPaid: {
		color: 'green',
		fontWeight: 'bold'
	},
	notPaid: {
		color: 'red',
		fontWeight: 'bold'
	}
};


class PropertyOwnersTable extends React.Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			start: 0,
		};
		
		// Table
		this.datatable = null;
	}
	
	
	componentDidMount() { 
		const { hasNextPage, onLoadMore } = this.props;

		this.$el = $(this.el);
		this.datatable = this.$el.DataTable({
	    	"dom": "Bfrtip",
			"ordering": true,
			"columnDefs": [
				{ "orderable": false, "targets": [ 1, 7 ] }
			],
			"scrollX": false,
			"scrollY": 800,
			"scrollCollapse": true,
			"deferRender": true,
			"scroller": {
			    loadingIndicator: true
			},
	        select: true,
	        buttons: [
				{
					extend: 'collection',
					text: 'Export',
					buttons: [
						'excel',
						'csv',
						'pdf'
					]
				}
	    	]
		});
		
		// Page Events
		const that = this;
		this.datatable.on( 'page.dt', function ( e, settings) {
			if (hasNextPage && onLoadMore) {
				const { page, pages } = that.datatable.page.info();
				if (pages - page === 1) {
					// Last page
					onLoadMore();
				}
			}
		} );
	}
	
	componentWillUnmount() {
		this.datatable.off('page.dt');
		this.datatable.destroy(true);
	}
	
	// <td style={tdStyle}>{moment(createdAt).format("MMM Do YYYY")}</td>
	// <th>CreatedAt</th>
	renderOwners() {
		const { owners, classes, user } = this.props;
		const tdStyle = { textAlign: 'center' };

		return owners.map(function(value) {
			const owner = Object.assign({}, value);
			const { id, firstName = "", lastName = "", displayName = "", phone, createdAt, fullyPaid,
				ownerType, city  } = value;
			const displayId = id.substring(id.lastIndexOf("/") + 1);
			const name = _.isEmpty(displayName) ? `${firstName} ${lastName}` : displayName;
			const isIndividual = `${ownerType}` === 'Individual';
			return (
				<tr key={displayId}>
					<td style={tdStyle}>{displayId}</td>
					<td style={tdStyle}>{isIndividual ? `${firstName} ${lastName}` : ""}</td>
					<td style={tdStyle}>{displayName}</td>
					<td style={tdStyle}>{phone}</td>
					<td style={tdStyle}>{city}</td>
					<td style={tdStyle}>{`${ownerType}`}</td>
					<td style={tdStyle}>{fullyPaid ? 
						<span className={classes.hasPaid}>YES</span> : 
						<span className={classes.notPaid}>NO</span>}
					</td>
					<td style={tdStyle}>
						<Link
							to={{
								pathname: "/admin/owners/detail",
								state: { owner }
							}}>
							<Button justIcon round size="sm" color="info" aria-label="Owner Details"><MoreRight /> </Button>
						</Link>
					</td>
				</tr>
			);
		});
	}
	
	render() {
		
		// Table Styles
		const tableStyle = { width: '100%' };
		// {this.renderLooks()}
		return (  
              <section className="panel">
                  
                  <div className="panel-body">
						
                       <table className="display" id="editable-products" style={tableStyle} ref={el => this.el = el}>
                          <thead>
                          <tr>
                              <th>ID</th>
							  <th>Name</th>
							  <th>Organization</th>
							  <th>Phone</th>
							  <th>City</th>
							  <th>Type</th>
							  <th>Fully Paid</th>
							  <th>Actions</th>
                          </tr>
                          </thead>
						 <tbody>
							{this.renderOwners()}
						</tbody>
                              
                        </table>
			
                  </div>
              </section>
            
		);
	}
}

export default withStyles(styles)(PropertyOwnersTable);
