import React from "react";
import { Link, Redirect } from "react-router-dom";
import _ from "underscore";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";

// material-ui icons
import Add from "@material-ui/icons/Add";
import Refresh from "@material-ui/icons/RefreshOutlined";
import Search from "@material-ui/icons/Search";

// AWS
// GraphQL
//import { API, graphqlOperation } from "aws-amplify";
import { graphqlOperation }  from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as queries from 'graphql/queries';


// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import formStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";

// Bulooka
import Vehicle from "bulooka/Components/Vehicle";
import Grid from "../Components/CustomGridItem.js";

const styles = {
  ...tableStyles,
  ...formStyles,
  centered: {
    textAlign: 'center'
  }
};

const BULOOKA_S3_IMAGES = 'https://bulooka-images.s3.amazonaws.com/properties/';

const useStyles = makeStyles(styles);

const ALPHANUMERIC = new RegExp(/^[a-z0-9]+$/i);

export default function ExtendedTables(props) {
  const { signedOut, user } = props;
  const [properties, setProperties] = React.useState([]);
  const [purpose, setPurpose] = React.useState("All");
  const [propertyType, setPropertyType] = React.useState("All");
  const [pageToken, setPageToken] = React.useState("");
  const [hasNextPage, setHasNextPage] = React.useState(false);
  const [fetchedItems, setFetchedItems] = React.useState([]);
  const [shortId, setShortId] = React.useState("");
  const [startSearch, setStartSearch] = React.useState(false);

  const classes = useStyles();

  // React.useEffect(() => {
  //   // Remove potential duplicates
  //   debugger;
  //   const merged = [ ...properties, ...fetchedItems ];
  //   const mergeGroup = _.groupBy(merged, 'id');
  //   const added = Object.keys(mergeGroup).map((key) => {
  //     return mergeGroup[key][0];
  //   });
  //   setProperties(added);
  // }, [fetchedItems]);
  
  const handlePurpose = event => {
    setPurpose(event.target.value);
  };

  const handlePropertyType = event => {
    setPropertyType(event.target.value);
  };

  const handleShortId = event => {
    setShortId(event.target.value);
    setStartSearch(false);
  };

  
  function handleSearch() {
    setStartSearch(true);
  }

  function fetchMore(items) {
  
    //if (_.isEmpty(pageToken)) return;

    // Remove potential duplicates
    const merged = [ ...properties, ...items ];
    const mergeGroup = _.groupBy(merged, 'id');
    const added = Object.keys(mergeGroup).map((key) => {
      return mergeGroup[key][0];
    });
    //setProperties(added);
    return added;
  };

  /*
    enum VehicleType {
	SUV
	Crossover
	Sedan
	Truck
	Hatchback
	Convertible
	Luxury
	Coupe
	Electric
	Hybrid
	Van
	Minivan
	Wagon
	Bus
}
  */
  function renderFilters() {
    return (
      <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
      <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                  <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="select-purpose"
                        className={classes.selectLabel}
                      >
                        Vehicle Purpose
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={purpose}
                        onChange={handlePurpose}
                        inputProps={{
                          name: "purpose",
                          id: "select-purpose"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          Purpose
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value="All"
                        >
                          All
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value="Lease"
                        >
                          Lease
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value="Sale"
                        >
                          Sale
                        </MenuItem>
                      </Select>
                    </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                
                  <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="select-type"
                        className={classes.selectLabel}
                      >
                        Vehicle Type
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={propertyType}
                        onChange={handlePropertyType}
                        inputProps={{
                          name: "propertyType",
                          id: "select-type"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          Vehicle Type
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value="All"
                        >
                          All
                        </MenuItem>
                        {
                          [ "SUV", "Crossover", "Sedan", "Truck", "Hatchback", "Convertible",
                            "Luxury", "Coupe", "Electric", "Hybrid", "Van", "Minivan",
                            "Wagon", "Bus" ].map((type, idx) => {
                                return (
                                  <MenuItem
                                    key={idx}
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected
                                    }}
                                    value={type}
                                  >
                                    {type}
                                  </MenuItem>
                                );
                            })
                        }
                      </Select>
                    </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                    labelText={
                      <span>
                        Short ID
                      </span>
                    }
                    id="shortId"
                    name="shortId"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      onChange: handleShortId,
                      value: shortId,
                      placeholder: "e.g Vj12345",
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          className={classes.inputAdornment}
                        >
                          <Button
                            color="info"
                            onClick={handleSearch}
                            size="sm"
                            disabled={!(ALPHANUMERIC.test(shortId) && shortId.length === 7)}
                          > <Search /> Search
                          </Button>
                        </InputAdornment>
                      )
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    );  
  }

  function renderShortID() {
    //const variables = { limit: 1000, filter: { shortID: { eq: shortId } }};
    return (
      <Connect query={graphqlOperation(queries.vehiclesByShortId, { shortID: shortId } )}>
          {({ data: { vehiclesByShortID }, loading, errors }) => {
          if (!_.isEmpty(errors)) return (<h3>{`Errors: ${JSON.stringify(errors)}`}</h3>);
          if (loading || !vehiclesByShortID) return (<h3>Loading...</h3>);

          const { items, nextToken } = vehiclesByShortID;
          //console.log("Properties: %j", listPropertys);
          //const aitems = items.filter((value) => !_.isEmpty(value));
          //const aitems = properties.concat(items);
          //const merged = fetchMore(items);
          

          if (_.isEmpty(items)) {
            return (<h3>{`No Vehicles found matching Short ID: ${shortId}`}</h3>)
          } else {
              return (
                <React.Fragment>
                    <GridContainer spacing={4}>
                      {items.map((vehicle, key) => {
                          return (
                            <Grid xs={12} sm={6} md={4} key={key}>
                              <Vehicle vehicle={vehicle} />
                            </Grid>
                          );
                        })}
                    </GridContainer>
                    <br />
                </React.Fragment>
                
              );
          }
              
          }}
      </Connect>
    );
  }

  if (signedOut) return (<Redirect to="/" /> );

  // Query Variables
  let variables = { limit: 100 };
  if (purpose !== "All" && propertyType !== "All") {
    Object.assign(variables, { filter: { vehicleType: { eq: propertyType }, 
      purpose: { eq: purpose } } });
  } else if (purpose !== "All") {
    Object.assign(variables, { filter: { purpose: { eq: purpose } } });
  } else if ( propertyType !== "All") {
    Object.assign(variables, { filter: { vehicleType: { eq: propertyType } } });
  }

  // Next Page
  if (!_.isEmpty(pageToken) && hasNextPage) {
    Object.assign(variables, { nextToken: pageToken });
  }

  /*
    <GridItem xs={12} sm={6} md={4} key={key}>
                          <Property property={property} />
                        </GridItem>
      makeModel {
        id
        year
        make
        logo
        description
        options
        model
        trim
        series
      }
  */
  return (
    <React.Fragment>
      <div className={classes.center}>
        <Link to="/admin/addvehicle">
          <Button color="info" size="lg">
            <Add /> Create Vehicle
          </Button>
        </Link>
      </div>
      {renderFilters()}

      {startSearch ? renderShortID() :
        (
          <Connect query={graphqlOperation(queries.listVehicles, variables)}>
          {({ data: { listVehicles }, loading, errors }) => {
          if (!_.isEmpty(errors)) return (<h3>{`Errors: ${JSON.stringify(errors)}`}</h3>);
          if (loading || !listVehicles) return (<h3>Loading...</h3>);

          const { items, nextToken } = listVehicles;
          //console.log("Properties: %j", listPropertys);
          //const aitems = items.filter((value) => !_.isEmpty(value));
          //const aitems = properties.concat(items);
          const merged = fetchMore(items);
          

          if (_.isEmpty(items)) {
            return (<h3>No Vehicles found matching selected criteria.</h3>)
          } else {
              return (
                <React.Fragment>
                    <GridContainer spacing={4}>
                      {merged.map((vehicle, key) => {
                          return (
                            <Grid xs={12} sm={6} md={4} key={key}>
                              <Vehicle vehicle={vehicle} />
                            </Grid>
                          );
                        })}
                    </GridContainer>
                    <br />
                    <br />
                    { (items.length % 100 === 0) && 
                      <div className={classes.centered}>
                        <Button color="info" size="sm" onClick={(e) => {
                              setPageToken(nextToken);
                              setHasNextPage(items.length % 100 === 0);
                              setProperties([...properties, ...items]);
                          }}>
                          <Refresh /> Load More
                        </Button>
                      </div>
                    }
                </React.Fragment>
                
              );
          }
              
          }}
      </Connect>
        )
      }
      
      
    </React.Fragment>
    
  );
}
