import React from 'react';
import _ from "underscore";

import { makeStyles } from '@material-ui/core/styles';
import withStyles from "@material-ui/core/styles/withStyles";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import VideoCall from '@material-ui/icons/VideoCall';

// Manzia
import PropertyImageUpload from '../../Components/ImageUpload.js';
import ReactPlayer from 'react-player'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  videoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: 800,
    backgroundColor: theme.palette.background.paper,
  },
  video: {
    height: 200,
    margin: 10
  },
  gridList: {
    width: 800,
    height: 600,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  centered: {
    textAlign: 'center'
  }
}));

const mainStyle = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  center: {
    textAlign: "center"
  },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  }
};


function PropertyGridList(props) {
  const classes = useStyles();
  const { images, deleteHandler } = props;

  function handleDelete(index, e) {
    if (deleteHandler) {
      deleteHandler(index);
    }
  }
  
  return (

    <div className={classes.root}>
      <GridList cellHeight={220} className={classes.gridList}>
        
        {images.map((img, idx) => (
          <GridListTile key={`${idx+1}`}>
            <img 
              src={img.imgLink ? img.imgLink : img.dataLink} 
              alt={idx === 0 ? "Featured Image" : `Image ${idx+1}`} 
            />
            <GridListTileBar
              title={idx === 0 ? "Featured Image" : `Image ${idx+1}`}
              subtitle={""}
              actionIcon={
                <IconButton 
                    aria-label={`Delete image ${idx+1}`} 
                    className={classes.icon}
                    onClick={(e) => handleDelete(idx, e)}
                >
                  <DeleteIcon />
                </IconButton>
              }
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
}


function PropertyVideoList(props) {
  const classes = useStyles();
  const { vehicle: { videos = [], thumbnails = [] } } = props;

  function matchVideos(thumbnails, videos) {
    let matches = [...thumbnails, ...videos];
    const grpMatches = _.groupBy(matches, function(vid) {
        const vidName = vid.substring(vid.lastIndexOf("/"));
        return vidName.substring(1, vidName.lastIndexOf("-"));
    });
    matches = Object.keys(grpMatches).map((vid) => {
        const [avid, bvid] = grpMatches[vid];
        const result = {};
        if (avid.indexOf("thumbnail") > -1) {
            Object.assign(result, { thumbnail: avid, video: bvid });
        } else if (bvid.indexOf("thumbnail") > -1) {
            Object.assign(result, { thumbnail: bvid, video: avid });
        }
        return result;
    }).filter((item) => !_.isEmpty(item));
    return matches;
  }

  if (_.isEmpty(videos) || _.isEmpty(thumbnails)) {
    return (
      <h4 className={classes.centered}>{"No vehicle videos found"}</h4>
    );
  }

  const matchedVideos = matchVideos(thumbnails, videos);

  return (

    <div className={classes.videoContainer}>
        {matchedVideos.map((vid, idx) => {
          const { video, thumbnail } = vid;
          const videoName = video.substring(video.lastIndexOf("/") + 1);
            return (
              
                <div key={idx+1} className={classes.video}>
                  <ReactPlayer 
                    url={video} 
                    controls={true}
                    light={thumbnail}
                    height={"180px"}
                    width={"320px"}
                  />
                  <p><strong>{videoName}</strong></p>
                </div>
              
              
            );
          }
        )}
    </div>
  );
}


// 
class Step4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [], 
      deleteImages: [] // Only has S3 URLs e.g https//s3....
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  async componentDidMount() {
    const { vehicle } = this.props;
    if (_.isEmpty(vehicle)) return;
    if (Array.isArray(vehicle.images)) {
      const propImages = vehicle.images.map((image) => {
          return { dataLink: null, file: null, imgLink: image };
      });
      this.setState({ images: propImages });
    }
  }

  sendState() {
    return this.state;
  }

  isValidated() {
    
    return !_.isEmpty(this.state.images);
  }

  handleDelete(index) {
    const { images, deleteImages } = this.state;
    const filtered = images.filter((image, idx) => {
        return idx !== index;
    });
    const aimage = images[index];
    this.setState({ images: filtered, deleteImages: [ ...deleteImages, aimage ] });
  }

  handleImage =(file) => {
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        this.setState((state, props) => ({
          images: [...state.images, { dataLink: reader.result, file: file, imgLink: null } ]
        }));
      };
      reader.readAsDataURL(file);

    }
  }

  
  
  render() {
    const { classes, loading, vehicle } = this.props;
    const { images } = this.state;

    return (
      <div>
          <div className={classes.center}>
            <PropertyImageUpload imageHandler={this.handleImage} loading={loading} />
          </div>
          <br />
          <h4 className={classes.centered}>{_.isEmpty(images) ? "No Vehicle Images" : "Vehicle Images"}</h4>
          <PropertyGridList images={images} deleteHandler={this.handleDelete} />
          <br />
          <h4 className={classes.centered}>{"Vehicle Videos"}</h4>
          {!_.isEmpty(vehicle) && (
            <React.Fragment>
              <PropertyVideoList vehicle={vehicle} />
            </React.Fragment>
            
          )}
      </div>
    );
  }
};

export default withStyles(mainStyle)(Step4);